import React, { Component } from 'react';
import { Switch, Route } from 'react-router-dom';
import {
  Main,
  Review,
  Get,
  Talk,
  Login,
  Signup,
  GetWriting,
  ReviewWriting,
  TalkWriting,
  MyPage,
  OtherPage,
  MyInfo,
  Event,
  Search,
  Terms,
  Privacy,
} from 'pages';

import axios from 'axios';
import { tagMangerService } from 'lib/TagManagerService';

axios.interceptors.response.use(async (res) => {
  const newToken = res.headers["new-token"]
  if (newToken) {
    axios.defaults.headers.token = newToken;
    localStorage.setItem("token", newToken);
  }
  return res;
}, async (error) => {
  if (error.response.data.msg === '토큰이 만료되었습니다.') {
    // message.warn("로그인이 만료되었습니다. 다시 로그인 해주세요.")
    // await mutateUser({ isLoggedIn: false })
    // await router.push('/login')
    localStorage.removeItem('token');
    // window.location.href = "/";
    return;
  }
  return Promise.reject(error);
});

tagMangerService.init();

class App extends Component {
  render() {
    return (
      <div>
        <Switch>
          <Route path="/" exact={true} component={Main} />
          <Route path="/login" exact={true} component={Login} />
          <Route path="/signup" exact={true} component={Signup} />
          <Route path="/review" exact={true} component={Review} />
          <Route path="/get" exact={true} component={Get} />
          <Route path="/talk" exact={true} component={Talk} />
          <Route path="/event" exact={true} component={Event} />
          <Route path="/search" exact={true} component={Search} />
          <Route path="/writing/get" exact={true} component={GetWriting} />
          <Route path="/writing/review" exact={true} component={ReviewWriting} />
          <Route path="/writing/talk" exact={true} component={TalkWriting} />
          <Route path="/mypage" exact={true} component={MyPage} />
          <Route path="/otherpage/:id" exact={true} component={OtherPage} />
          <Route path="/myinfo" exact={true} component={MyInfo} />
          <Route path="/terms" exact={true} component={Terms} />
          <Route path="/privacy" exact={true} component={Privacy} />
          <Route component={Main} />
        </Switch>
      </div>
    );
  }
}

export default App;
