import React, { Component } from 'react';
import ReviewWriting from 'components/ReviewWriting';

class ReviewWritingContainer extends Component {
  render() {
    return <ReviewWriting />;
  }
}

export default ReviewWritingContainer;
