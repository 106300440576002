import React, { Component } from 'react';
import Event from 'components/event/Event';

class EventContainer extends Component {
  render() {
    return <Event />;
  }
}

export default EventContainer;
