import React, { Component } from 'react';
import Talk from 'components/Talk';

class TalkContainer extends Component {
  render() {
    return <Talk />;
  }
}

export default TalkContainer;
