import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import './TalkPopup.css';
import { gradeName, abbreviateNumber, numberWithCommas, compareTime } from 'lib/common';
import Declaration from 'components/common/Declaration';
import Carousel from 'components/common/Carousel';
import axios from 'axios';
import { API } from 'config';
import { tagMangerService } from 'lib/TagManagerService';

class TalkPopup extends Component {
  state = {
    slider: [],
    comments: [],
    childComments: [],
    content: '',
    childContents: [],
    declation: {
      isClicked: false,
      type: '',
      id: 0,
      title: '',
      content: '',
    },
    order: false,
    commentLike: [],
    selectedComment: [],
    follower: false,
  };

  // 정품 판독 선택
  handleMyPick = async (talkId, comment) => {
    try {
      const result = await axios.patch(
        `${API}/comments/${comment.id}`,
        {
          isSelected: true,
        },
        {
          headers: {
            token: localStorage.getItem('token'),
          },
        },
      );
      if (result && result.status === 200) {
        alert('선정 성공:)');
        const selectedComment = [comment];
        this.setState({
          ...this.state,
          selectedComment,
        });
        this.props.getTalk(talkId);
      }
    } catch (e) {
      if (e.response && e.response.data && e.response.data.msg && e.response.data.msg === '토큰이 만료되었습니다.') {
        localStorage.removeItem('token');
        window.location.href = "/";
        return;
      }
    }
  };

  cancelMyPick = async (talkId, commentId) => {
    try {
      const result = await axios.patch(
        `${API}/comments/${commentId}`,
        {
          isSelected: false,
        },
        {
          headers: {
            token: localStorage.getItem('token'),
          },
        },
      );
      if (result && result.status === 200) {
        alert('선정 취소 성공:)');
        this.setState({
          ...this.state,
          selectedComment: [],
        });
        this.props.getTalk(talkId);
      }
    } catch (e) {
      if (e.response && e.response.data && e.response.data.msg && e.response.data.msg === '토큰이 만료되었습니다.') {
        localStorage.removeItem('token');
        window.location.href = "/";
        return;
      }
    }
  };

  handleBuy = async (talkId) => {
    try {
      const result = await axios.post(
        `${API}/talks/${talkId}/buy`,
        {},
        {
          headers: {
            token: localStorage.getItem('token'),
          },
        },
      );
      if (result && result.status === 201) {
        alert('사요 추천 성공:)');
        this.props.getTalk(talkId);
      } else if (result.status === 204) {
        alert('사요 추천 취소 성공:)');
        this.props.getTalk(talkId);
      }
    } catch (e) {
      if (e.response && e.response.data && e.response.data.msg && e.response.data.msg === '토큰이 만료되었습니다.') {
        localStorage.removeItem('token');
        window.location.href = "/";
        return;
      }
    }
  };

  handleNotBuy = async (talkId) => {
    try {
      const result = await axios.post(
        `${API}/talks/${talkId}/not-buy`,
        {},
        {
          headers: {
            token: localStorage.getItem('token'),
          },
        },
      );
      if (result && result.status === 201) {
        alert('마요 추천 성공:)');
        this.props.getTalk(talkId);
      } else if (result.status === 204) {
        alert('마요 추천 취소 성공:)');
        this.props.getTalk(talkId);
      }
    } catch (e) {
      if (e.response && e.response.data && e.response.data.msg && e.response.data.msg === '토큰이 만료되었습니다.') {
        localStorage.removeItem('token');
        window.location.href = "/";
        return;
      }
    }
  };

  //   /talks/:talkId/buy:
  //   post:
  //     tags:
  //     - "오늘의 토크"
  //     summary: "토크 사요 버튼"
  //     produces:
  //     - "application/json"
  //     responses:
  //       "201":
  //         description: "successful operation"
  //       "403":
  //         description: "토큰 필요"
  // /talks/:talkId/not-buy:

  componentDidMount() {
    this.getSlider();
    this.getCommnets(this.props.popupData.id);
  }

  componentWillReceiveProps(newProps) {
    if (newProps.popupData && newProps.popupData.id && newProps.popupData.id !== this.props.popupData.id) {
      this.getCommnets(newProps.popupData.id);
    }
  }

  handleOrder = () => {
    tagMangerService.buyButtonClickEvent();

    this.setState({
      ...this.state,
      order: true,
    });
  };

  closeOrder = () => {
    this.setState({
      ...this.state,
      order: false,
    });
  };

  handleFollow = async (userId) => {
    try {
      const result = await axios.post(
        `${API}/users/${userId}/follow`,
        {},
        {
          headers: {
            token: `${localStorage.getItem('token')}`,
          },
        },
      );
      if (result.status === 201) {
        alert('팔로우 성공 :)');
      } else {
        alert('팔로우 신청 실패');
      }
    } catch (e) {
      if (e.response && e.response.data && e.response.data.msg && e.response.data.msg === "본인은 팔로우 할 수 없습니다.") {
        alert('본인은 팔로우 할 수 없습니다:)');
      } else {
        if (e.response && e.response.data && e.response.data.msg && e.response.data.msg === '토큰이 만료되었습니다.') {
          localStorage.removeItem('token');
          window.location.href = "/";
          return;
        }
        // alert('조금만 뒤에 다시 시도해주세요:)');
      }
    }
  };

  handleCommentLike = async (e, id) => {
    e.stopPropagation();

    if (!localStorage.getItem('token')) return alert('로그인이 필요합니다:)');
    try {
      const result = await axios.post(
        `${API}/comments/${id}/like`,
        {},
        {
          headers: {
            token: `${localStorage.getItem('token')}`,
          },
        },
      );

      var commentLike = [...this.state.commentLike];

      if (result.status === 201) {
        commentLike.push(id);
        this.setState({
          ...this.state,
          commentLike,
        });
      } else if (result.status === 204) {
        commentLike = commentLike.filter((el) => Number(el) !== Number(id));
        this.setState({
          ...this.state,
          commentLike,
        });
      }
    } catch (e) {
      if (e.response && e.response.data && e.response.data.msg && e.response.data.msg === '토큰이 만료되었습니다.') {
        localStorage.removeItem('token');
        window.location.href = "/";
        return;
      }
      // alert('조금만 뒤에 다시 시도해주세요:)');
    }
    // /{reviews}/{id}/like
  };

  openDeclation = (type, id) => {
    this.setState({
      ...this.state,
      declation: {
        isClicked: true,
        type,
        id,
      },
    });
  };

  handleDeclation = (e) => {
    this.setState({
      ...this.state,
      declation: {
        ...this.state.declation,
        [e.target.name]: e.target.value,
      },
    });
  };

  submitDeclation = async () => {
    const { title, content, type, id } = this.state.declation;

    try {
      if (type === 'talk') {
        const result = await axios.post(
          `${API}/reports`,
          {
            title,
            content,
            talkId: id,
          },
          {
            headers: {
              token: `${localStorage.getItem('token')}`,
            },
          },
        );
        if (result.status === 200) {
          this.setState({
            ...this.state,
            declation: {
              isClicked: false,
              type: '',
              id: 0,
              title: '',
              conent: '',
            },
          });
          alert('신고하기 성공 :)');
        }
      } else {
        const result = await axios.post(
          `${API}/reports`,
          {
            title,
            content,
            commentId: id,
          },
          {
            headers: {
              token: `${localStorage.getItem('token')}`,
            },
          },
        );
        if (result.status === 200) {
          this.setState({
            ...this.state,
            declation: {
              isClicked: false,
              type: '',
              id: 0,
              title: '',
              conent: '',
            },
          });
          alert('신고하기 성공 :)');
        }
      }
    } catch (e) {
      if (e.response && e.response.data && e.response.data.msg && e.response.data.msg === '토큰이 만료되었습니다.') {
        localStorage.removeItem('token');
        window.location.href = "/";
        return;
      }
    }
  };

  getSlider = async () => {
    if (this.props.popupData && this.props.popupData.user && this.props.popupData.user.id) {
      const { id } = this.props.popupData.user;
      try {
        const result = await axios.get(`${API}/talks/?userId=${id}&limit=6`, {
          headers: {
            token: `${localStorage.getItem('token')}`,
          },
        });
        this.setState({
          ...this.state,
          slider: [...result.data[0]],
        });
      } catch (e) {
        if (e.response && e.response.data && e.response.data.msg && e.response.data.msg === '토큰이 만료되었습니다.') {
          localStorage.removeItem('token');
          window.location.href = "/";
          return;
        }
      }
    }
  };

  getCommnets = async (id) => {
    if (id) {
      // const { id } = this.props.popupData;
      try {
        const result = await axios.get(`${API}/talks/${id}/comments`, {
          headers: {
            token: `${localStorage.getItem('token')}`,
          },
        });
        let childComments = [];
        let childContents = [];
        let selectedComment = [];

        result.data.map((el) => {
          childComments.push(false);
          childContents.push('');
          if (el.isSelected) {
            selectedComment.push(el);
          }
        });

        this.setState({
          ...this.state,
          comments: [...result.data],
          selectedComment,
          childComments,
          childContents,
        });
      } catch (e) {
        if (e.response && e.response.data && e.response.data.msg && e.response.data.msg === '토큰이 만료되었습니다.') {
          localStorage.removeItem('token');
          window.location.href = "/";
          return;
        }
      }
    }
  };

  deleteComment = async (id) => {
    if (window.confirm('정말 삭제하시겠습니까?')) {
      try {
        const result = await axios.delete(`${API}/comments/${id}`, {
          headers: {
            token: `${localStorage.getItem('token')}`,
          },
        });
      } catch (e) {
        if (e.response && e.response.data && e.response.data.msg && e.response.data.msg === '토큰이 만료되었습니다.') {
          localStorage.removeItem('token');
          window.location.href = "/";
          return;
        }
        // alert('조금만 뒤에 다시 시도해주세요:)');
      }
      this.getCommnets(this.props.popupData.id);
    }
  };

  handleChildComment = (i) => {
    let child = [...this.state.childComments];
    child[i] = !child[i];
    this.setState({
      ...this.state,
      childComments: child,
    });
  };

  handleChange = (e) => {
    this.setState({
      ...this.state,
      [e.target.name]: e.target.value,
    });
  };

  handleChildConent = (e, i) => {
    const contents = [...this.state.childContents];
    contents[i] = e.target.value;
    this.setState({
      ...this.state,
      childContents: contents,
    });
  };

  handleComment = async (type, id, i) => {
    const { content, childContents, childComments } = this.state;

    const token = localStorage.getItem('token');
    if (!token) return alert('로그인을 먼저 해주세요!');
    // content,
    // reviewId

    // commentId

    // talkId
    // linkId
    try {
      if (type === 'talk') {
        if (!content) return alert('내용을 입력해주세요!');
        const result = await axios.post(
          `${API}/comments`,
          {
            content: content.trim(),
            talkId: id,
          },
          {
            headers: {
              token: `${localStorage.getItem('token')}`,
            },
          },
        );
        this.setState({
          ...this.state,
          content: '',
        });
      } else {
        if (!childContents[i]) return alert('내용을 입력해주세요!');
        const result = await axios.post(
          `${API}/comments`,
          {
            content: childContents[i].trim(),
            commentId: id,
          },
          {
            headers: {
              token: `${localStorage.getItem('token')}`,
            },
          },
        );
        let contents = [...childContents];
        contents[i] = '';
        let comments = [...childComments];
        comments[i] = false;

        this.setState({
          ...this.state,
          childContents: contents,
          childComments: comments,
        });
      }

      this.getCommnets(this.props.popupData.id);
    } catch (e) {
      if (e.response && e.response.data && e.response.data.msg && e.response.data.msg === '토큰이 만료되었습니다.') {
        localStorage.removeItem('token');
        window.location.href = "/";
        return;
      }
    }
  };

  render() {
    const {
      handleChange,
      handleComment,
      handleChildComment,
      handleChildConent,
      handleDeclation,
      submitDeclation,
      closeDeclation,
      openDeclation,
      handleCommentLike,
      deleteComment,
      handleBuy,
      handleNotBuy,
      handleMyPick,
      cancelMyPick,
    } = this;
    const {
      slider,
      content,
      comments,
      childComments,
      childContents,
      declation,
      commentLike,
      selectedComment,
    } = this.state;
    const {
      isOpen,
      closePopup,
      popupData,
      user,
      type,
      like,
      handleLike,
      deleteTalk,
      editTalk,
    } = this.props;
    if (!popupData) return <> </>;
    if (!popupData.user) return <> </>;

    return (
      <>
        {(type === 'free' || popupData.category === '자유토크') && (
          <div className={'popup-bg talk-popup ' + (isOpen ? ' show' : '')}>
            <div className="popup">
              <div className="popup-functions">
                {user.id === popupData.user.id ? (
                  <>
                    <button className="popup-edit" onClick={() => editTalk(popupData.id)}></button>
                    <button
                      className="popup-delete"
                      onClick={() => deleteTalk(popupData.id)}
                    ></button>
                  </>
                ) : (
                    <button
                      className="popup-declation"
                      onClick={() => openDeclation('talk', popupData.id)}
                    ></button>
                  )}

                <button className="popup-close" onClick={closePopup}></button>
              </div>
              <div className="popup-top-bg">
                <div className="popup-product-img-bg">
                  <div className="popup-product-img">
                    <Carousel images={popupData.productImgUrls} />
                    {/* <span
                      className="img"
                      style={{ backgroundImage: `url(${popupData.productImgUrls[0]})` }}
                    ></span>
                    <span className="popup-img-prev-button"></span>
                    <span className="popup-img-next-button"></span> */}
                  </div>
                </div>
                <div className="popup-content-bg">
                  <div className="main-intro-content-review-box">
                    <div className="main-intro-content-review-box-top">
                      <div className="main-intro-content-user-bg">
                        <span
                          className={
                            'main-intro-content-user-profile-bg ' + `grade-${popupData.user.grade}`
                          }
                          onClick={() => {
                            if (user.id !== popupData.user.id) {
                              // this.props.history.push(`/otherpage/${popupData.user.id}`)
                              window.location.href = `/otherpage/${popupData.user.id}`;
                            };
                          }}
                        >
                          <span
                            className={'main-intro-content-user-profile '}
                            style={popupData.user.profileImgUrl ? {
                              backgroundImage: `url(${popupData.user.profileImgUrl})`,
                            } : {}}
                          ></span>
                        </span>
                        <span className="main-intro-content-user-name">
                          {popupData.user.nickname}
                        </span>
                        <span
                          className={
                            'main-intro-content-user-grade ' + `grade-${popupData.user.grade}`
                          }
                        >
                          {gradeName(popupData.user.grade)}
                        </span>
                      </div>
                      <div className="main-intro-content-review-info-bg">
                        <span
                          className={
                            'main-intro-content-info-like ' +
                            (like && like.indexOf(popupData.id) > -1 ? 'active' : '')
                          }
                        ></span>
                        <span className="main-intro-content-info-number">
                          {popupData.liked && like && like.indexOf(popupData.id) > -1
                            ? abbreviateNumber(parseInt(popupData.likeCount), 0)
                            : !popupData.liked && like && like.indexOf(popupData.id) > -1
                              ? abbreviateNumber(parseInt(popupData.likeCount + 1), 0)
                              : !popupData.liked && like && like.indexOf(popupData.id) === -1
                                ? abbreviateNumber(parseInt(popupData.likeCount), 0)
                                : abbreviateNumber(parseInt(popupData.likeCount - 1), 0)}
                        </span>
                      </div>
                    </div>
                    <div className="main-intro-content-review-box-middle">
                      <div className="main-intro-content-review-value-bg">
                        <span className="popup-category">자유토크</span>
                      </div>
                      {/* <div className="main-intro-content-review-tag-bg">
                        <span className="main-intro-content-tag">#{popupData.styletag}</span>
                      </div> */}
                    </div>
                  </div>
                  <div className="popup-title-bg">
                    <h2 className="popup-title">{popupData.title}</h2>
                    <div className="popup-desc">{popupData.content}</div>
                  </div>
                  <div className="popup-button-bg">
                    <button
                      className="popup-button"
                      onClick={(e) => handleLike(e, 'talks', popupData.id)}
                    >
                      <span className="popup-button-like"></span>
                      <p className="popup-button-desc">좋아요</p>
                    </button>
                  </div>
                </div>
              </div>
              <div className="popup-bottom-bg">
                <div className="popup-bottom-container">
                  <div className="popup-comment-header-bg">
                    <div className="popup-comment-header">
                      <span>댓글 {comments.length} 건</span>
                    </div>
                    {user && user.grade && (
                      <div className="popup-comment-input-bg">
                        <div className="popup-comment-input">
                          <span
                            className={
                              'main-intro-content-user-profile-bg ' + `grade-${user.grade}`
                            }
                          // onClick={() => {
                          //   this.props.history.push(`/otherpage/${user.id}`);
                          // }}
                          >
                            <span
                              className={'main-intro-content-user-profile '}
                              style={user.profileImgUrl ? {
                                backgroundImage: `url(${user.profileImgUrl})`,
                              } : {}}
                            ></span>
                          </span>
                          <textarea
                            name="content"
                            id=""
                            cols="30"
                            rows="10"
                            placeholder="댓글을 입력해주세요."
                            value={content}
                            onChange={handleChange}
                          ></textarea>
                        </div>
                        <div className="popup-comment-button-bg">
                          <button
                            className="popup-comment-button"
                            onClick={() => handleComment('talk', popupData.id)}
                          >
                            댓글쓰기
                          </button>
                        </div>
                      </div>
                    )}
                  </div>
                  {comments.length !== 0 && (
                    <div className="popup-comment-content-bg">
                      {comments &&
                        comments.map((el, i) => {
                          return (
                            <div key={i}>
                              <div className="popup-comment-content-item">
                                <div className="popup-comment-content-item-title">
                                  <div className="popup-comment-content-item-user-bg">
                                    <span
                                      className={
                                        'main-intro-content-user-profile-bg ' +
                                        `grade-${el.user.grade}`
                                      }
                                      onClick={() => {
                                        if (user.id !== el.user.id) {
                                          // this.props.history.push(`/otherpage/${el.user.id}`)
                                          window.location.href = `/otherpage/${el.user.id}`
                                        };
                                      }}
                                    >
                                      <span
                                        className={'main-intro-content-user-profile '}
                                        style={el.user.profileImgUrl ? {
                                          backgroundImage: `url(${el.user.profileImgUrl})`,
                                        } : {}}
                                      ></span>
                                    </span>
                                    <span className="popup-comment-content-item-user-name">
                                      {el.user.nickname}
                                    </span>
                                    <span className="popup-comment-content-item-user-grade">
                                      {gradeName(el.user.grade)}
                                    </span>
                                  </div>
                                  <div className="popup-comment-content-item-time-bg">
                                    <p className="popup-comment-content-item-time">
                                      {compareTime(el.createdAt)}
                                    </p>
                                  </div>
                                </div>
                                <div className="popup-comment-content-item-body">
                                  <div className="popup-comment-content-item-desc-bg">
                                    <p className="popup-comment-content-item-desc">{el.content}</p>
                                  </div>
                                  <div className="popup-comment-content-item-func-bg">
                                    {user.id === el.user.id && (
                                      <button
                                        className="popup-comment-content-item-delete"
                                        onClick={() => deleteComment(el.id)}
                                      >
                                        삭제하기
                                      </button>
                                    )}
                                    <button
                                      className="popup-comment-content-item-alert"
                                      onClick={() => openDeclation('comment', el.id)}
                                    >
                                      신고하기
                                    </button>
                                    <button
                                      className="popup-comment-content-item-comment"
                                      onClick={() => handleChildComment(i)}
                                    >
                                      {childComments[i] ? '답글닫기' : '답글달기'}
                                    </button>
                                    <button className="popup-comment-content-item-like">
                                      <span
                                        className={
                                          'main-intro-content-info-like ' +
                                          (commentLike && commentLike.indexOf(el.id) > -1
                                            ? 'active'
                                            : '')
                                        }
                                        onClick={(e) => handleCommentLike(e, el.id)}
                                      ></span>
                                      <span
                                        className="main-intro-content-info-number"
                                        onClick={(e) => handleCommentLike(e, el.id)}
                                      >
                                        {el.liked && commentLike && commentLike.indexOf(el.id) > -1
                                          ? abbreviateNumber(parseInt(el.likeCount), 0)
                                          : !el.liked &&
                                            commentLike &&
                                            commentLike.indexOf(el.id) > -1
                                            ? abbreviateNumber(parseInt(el.likeCount + 1), 0)
                                            : !el.liked &&
                                              commentLike &&
                                              commentLike.indexOf(el.id) === -1
                                              ? abbreviateNumber(parseInt(el.likeCount), 0)
                                              : abbreviateNumber(parseInt(el.likeCount - 1), 0)}
                                      </span>
                                    </button>
                                  </div>
                                </div>
                              </div>
                              {el.childComments &&
                                el.childComments.map((child, childIndex) => {
                                  if (child.isDeleted) return;

                                  return (
                                    <div className="popup-comment-content-item" key={childIndex}>
                                      <div className="child-comment">
                                        <img src="/images/child_comment.svg" alt="" />
                                      </div>
                                      <div className="popup-comment-content-item-title">
                                        <div className="popup-comment-content-item-user-bg">
                                          <span
                                            className={
                                              'main-intro-content-user-profile-bg ' +
                                              `grade-${child.user.grade}`
                                            }
                                            onClick={() => {
                                              if (user.id !== child.user.id) {
                                                // this.props.history.push(`/otherpage/${child.user.id}`)
                                                window.location.href = `/otherpage/${child.user.id}`;
                                              };
                                            }}
                                          >
                                            <span
                                              className={'main-intro-content-user-profile '}
                                              style={child.user.profileImgUrl ? {
                                                backgroundImage: `url(${child.user.profileImgUrl})`,
                                              } : {}}
                                            ></span>
                                          </span>
                                          <span className="popup-comment-content-item-user-name">
                                            {child.userNickname}
                                          </span>
                                          <span className="popup-comment-content-item-user-grade">
                                            {/* {gradeName(child.user.grade)} */}
                                          </span>
                                        </div>
                                        <div className="popup-comment-content-item-time-bg">
                                          <p className="popup-comment-content-item-time">
                                            {compareTime(child.createdAt)}
                                          </p>
                                        </div>
                                      </div>
                                      <div className="popup-comment-content-item-body">
                                        <div className="popup-comment-content-item-desc-bg">
                                          <p className="popup-comment-content-item-desc">
                                            {child.content}
                                          </p>
                                        </div>
                                        <div className="popup-comment-content-item-func-bg">
                                          {user.id === child.user.id && (
                                            <button
                                              className="popup-comment-content-item-delete"
                                              onClick={() => deleteComment(child.id)}
                                            >
                                              삭제하기
                                            </button>
                                          )}
                                          <button
                                            className="popup-comment-content-item-alert"
                                            onClick={() => openDeclation('comment', child.id)}
                                          >
                                            신고하기
                                          </button>
                                        </div>
                                      </div>
                                    </div>
                                  );
                                })}
                              {childComments[i] && (
                                <div className="popup-comment-input-bg">
                                  <div className="popup-comment-input">
                                    <div className="child-comment">
                                      <img src="/images/child_comment.svg" alt="" />
                                    </div>
                                    <span
                                      className={
                                        'main-intro-content-user-profile-bg ' +
                                        `grade-${user.grade}`
                                      }
                                    // onClick={() => {
                                    //   this.props.history.push(`/otherpage/${user.id}`);
                                    // }}
                                    >
                                      <span
                                        className={'main-intro-content-user-profile '}
                                        style={user.profileImgUrl ? {
                                          backgroundImage: `url(${user.profileImgUrl})`,
                                        } : {}}
                                      ></span>
                                    </span>
                                    <textarea
                                      name="content"
                                      id=""
                                      cols="30"
                                      rows="10"
                                      placeholder="댓글을 입력해주세요."
                                      value={childContents[i]}
                                      onChange={(e) => handleChildConent(e, i)}
                                    ></textarea>
                                  </div>
                                  <div className="popup-comment-button-bg">
                                    <button
                                      className="popup-comment-button"
                                      onClick={() => handleComment('comment', el.id, i)}
                                    >
                                      답글쓰기
                                    </button>
                                  </div>
                                </div>
                              )}
                            </div>
                          );
                        })}
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        )}
        {(type === 'recommend' || popupData.category === '사요마요') && (
          <div className={'popup-bg talk-popup ' + (isOpen ? ' show' : '')}>
            <div className="popup">
              <div className="popup-functions">
                {user.id === popupData.user.id ? (
                  <>
                    <button className="popup-edit" onClick={() => editTalk(popupData.id)}></button>
                    <button
                      className="popup-delete"
                      onClick={() => deleteTalk(popupData.id)}
                    ></button>
                  </>
                ) : (
                    <button
                      className="popup-declation"
                      onClick={() => openDeclation('talk', popupData.id)}
                    ></button>
                  )}
                <button className="popup-close" onClick={closePopup}></button>
              </div>
              <div className="popup-top-bg">
                <div className="popup-product-img-bg">
                  <div className="popup-product-img">
                    <Carousel images={popupData.productImgUrls} />
                    {/* <span
                      className="img"
                      style={{ backgroundImage: `url(${popupData.productImgUrls[0]})` }}
                    ></span>
                    <span className="popup-img-prev-button"></span>
                    <span className="popup-img-next-button"></span> */}
                  </div>
                </div>
                <div className="popup-content-bg">
                  <div className="main-intro-content-review-box">
                    <div className="main-intro-content-review-box-top">
                      <div className="main-intro-content-user-bg">
                        <span
                          className={
                            'main-intro-content-user-profile-bg ' + `grade-${popupData.user.grade}`
                          }
                          onClick={() => {
                            if (user.id !== popupData.user.id) {
                              // this.props.history.push(`/otherpage/${popupData.user.id}`)
                              window.location.href = `/otherpage/${popupData.user.id}`;
                            };
                          }}
                        >
                          <span
                            className={'main-intro-content-user-profile '}
                            style={popupData.user.profileImgUrl ? {
                              backgroundImage: `url(${popupData.user.profileImgUrl})`,
                            } : {}}
                          ></span>
                        </span>
                        <span className="main-intro-content-user-name">
                          {popupData.user.nickname}
                        </span>
                        <span
                          className={
                            'main-intro-content-user-grade ' + `grade-${popupData.user.grade}`
                          }
                        >
                          {gradeName(popupData.user.grade)}
                        </span>
                      </div>
                      <div className="main-intro-content-review-info-bg">
                        <span
                          className={
                            'main-intro-content-info-like ' +
                            (like && like.indexOf(popupData.id) > -1 ? 'active' : '')
                          }
                        ></span>
                        <span className="main-intro-content-info-number">
                          {popupData.liked && like && like.indexOf(popupData.id) > -1
                            ? abbreviateNumber(parseInt(popupData.likeCount), 0)
                            : !popupData.liked && like && like.indexOf(popupData.id) > -1
                              ? abbreviateNumber(parseInt(popupData.likeCount + 1), 0)
                              : !popupData.liked && like && like.indexOf(popupData.id) === -1
                                ? abbreviateNumber(parseInt(popupData.likeCount), 0)
                                : abbreviateNumber(parseInt(popupData.likeCount - 1), 0)}
                        </span>
                      </div>
                    </div>
                    <div className="main-intro-content-review-box-middle">
                      <div className="main-intro-content-review-value-bg">
                        <span className="popup-category">사요마요</span>
                      </div>
                      {/* <div className="main-intro-content-review-tag-bg">
                        <span className="main-intro-content-tag">#스타일태그</span>
                      </div> */}
                    </div>
                  </div>
                  <div className="popup-title-bg">
                    <h2 className="popup-title">{popupData.title}</h2>
                    <div
                      className="popup-desc"
                    // dangerouslySetInnerHTML={{ __html: popupData.content }}
                    >
                      {popupData.content}
                    </div>
                  </div>
                  <div className="popup-button-bg">
                    <button
                      className="popup-button"
                      onClick={(e) => handleLike(e, 'talks', popupData.id)}
                    >
                      <span className="popup-button-like"></span>
                      <p className="popup-button-desc">좋아요</p>
                    </button>
                    <button className="popup-button" onClick={() => handleBuy(popupData.id)}>
                      <span className="popup-button-positive"></span>
                      <p className="popup-button-desc">{popupData.buyCount}</p>
                    </button>
                    <button className="popup-button" onClick={() => handleNotBuy(popupData.id)}>
                      <span className="popup-button-negative"></span>
                      <p className="popup-button-desc">{popupData.notBuyCount}</p>
                    </button>
                  </div>
                </div>
              </div>
              <div className="popup-bottom-bg">
                <div className="popup-bottom-container">
                  <div className="popup-comment-header-bg">
                    <div className="popup-comment-header">
                      <span>댓글 {comments.length} 건</span>
                    </div>
                    {user && user.grade && (
                      <div className="popup-comment-input-bg">
                        <div className="popup-comment-input">
                          <span
                            className={
                              'main-intro-content-user-profile-bg ' + `grade-${user.grade}`
                            }
                          // onClick={() => {
                          //   this.props.history.push(`/otherpage/${user.id}`);
                          // }}
                          >
                            <span
                              className={'main-intro-content-user-profile '}
                              style={user.profileImgUrl ? {
                                backgroundImage: `url(${user.profileImgUrl})`,
                              } : {}}
                            ></span>
                          </span>
                          <textarea
                            name="content"
                            id=""
                            cols="30"
                            rows="10"
                            placeholder="댓글을 입력해주세요."
                            value={content}
                            onChange={handleChange}
                          ></textarea>
                        </div>
                        <div className="popup-comment-button-bg">
                          <button
                            className="popup-comment-button"
                            onClick={() => handleComment('talk', popupData.id)}
                          >
                            댓글쓰기
                          </button>
                        </div>
                      </div>
                    )}
                  </div>
                  {comments.length !== 0 && (
                    <div className="popup-comment-content-bg">
                      {comments &&
                        comments.map((el, i) => {
                          return (
                            <div key={i}>
                              <div className="popup-comment-content-item">
                                <div className="popup-comment-content-item-title">
                                  <div className="popup-comment-content-item-user-bg">
                                    <span
                                      className={
                                        'main-intro-content-user-profile-bg ' +
                                        `grade-${el.user.grade}`
                                      }
                                      onClick={() => {
                                        if (user.id !== el.user.id) {
                                          // this.props.history.push(`/otherpage/${el.user.id}`)
                                          window.location.href = `/otherpage/${el.user.id}`;
                                        };
                                      }}
                                    >
                                      <span
                                        className={'main-intro-content-user-profile '}
                                        style={el.user.profileImgUrl ? {
                                          backgroundImage: `url(${el.user.profileImgUrl})`,
                                        } : {}}
                                      ></span>
                                    </span>
                                    <span className="popup-comment-content-item-user-name">
                                      {el.user.nickname}
                                    </span>
                                    <span className="popup-comment-content-item-user-grade">
                                      {gradeName(el.user.grade)}
                                    </span>
                                  </div>
                                  <div className="popup-comment-content-item-time-bg">
                                    <p className="popup-comment-content-item-time">
                                      {compareTime(el.createdAt)}
                                    </p>
                                  </div>
                                </div>
                                <div className="popup-comment-content-item-body">
                                  <div className="popup-comment-content-item-desc-bg">
                                    <p className="popup-comment-content-item-desc">{el.content}</p>
                                  </div>
                                  <div className="popup-comment-content-item-func-bg">
                                    {user.id === el.user.id && (
                                      <button
                                        className="popup-comment-content-item-delete"
                                        onClick={() => deleteComment(el.id)}
                                      >
                                        삭제하기
                                      </button>
                                    )}
                                    <button
                                      className="popup-comment-content-item-alert"
                                      onClick={() => openDeclation('comment', el.id)}
                                    >
                                      신고하기
                                    </button>
                                    <button
                                      className="popup-comment-content-item-comment"
                                      onClick={() => handleChildComment(i)}
                                    >
                                      {childComments[i] ? '답글닫기' : '답글달기'}
                                    </button>
                                    <button className="popup-comment-content-item-like">
                                      <span
                                        className={
                                          'main-intro-content-info-like ' +
                                          (commentLike && commentLike.indexOf(el.id) > -1
                                            ? 'active'
                                            : '')
                                        }
                                        onClick={(e) => handleCommentLike(e, el.id)}
                                      ></span>
                                      <span
                                        className="main-intro-content-info-number"
                                        onClick={(e) => handleCommentLike(e, el.id)}
                                      >
                                        {el.liked && commentLike && commentLike.indexOf(el.id) > -1
                                          ? abbreviateNumber(parseInt(el.likeCount), 0)
                                          : !el.liked &&
                                            commentLike &&
                                            commentLike.indexOf(el.id) > -1
                                            ? abbreviateNumber(parseInt(el.likeCount + 1), 0)
                                            : !el.liked &&
                                              commentLike &&
                                              commentLike.indexOf(el.id) === -1
                                              ? abbreviateNumber(parseInt(el.likeCount), 0)
                                              : abbreviateNumber(parseInt(el.likeCount - 1), 0)}
                                      </span>
                                    </button>
                                  </div>
                                </div>
                              </div>
                              {el.childComments &&
                                el.childComments.map((child, childIndex) => {
                                  if (child.isDeleted) return;

                                  return (
                                    <div className="popup-comment-content-item" key={childIndex}>
                                      <div className="child-comment">
                                        <img src="/images/child_comment.svg" alt="" />
                                      </div>
                                      <div className="popup-comment-content-item-title">
                                        <div className="popup-comment-content-item-user-bg">
                                          <span
                                            className={
                                              'main-intro-content-user-profile-bg ' +
                                              `grade-${child.user.grade}`
                                            }
                                            onClick={() => {
                                              if (user.id !== child.user.id) {
                                                // this.props.history.push(`/otherpage/${child.user.id}`)
                                                window.location.href = `/otherpage/${child.user.id}`;
                                              };
                                            }}
                                          >
                                            <span
                                              className={'main-intro-content-user-profile '}
                                              style={child.user.profileImgUrl ? {
                                                backgroundImage: `url(${child.user.profileImgUrl})`,
                                              } : {}}
                                            ></span>
                                          </span>
                                          <span className="popup-comment-content-item-user-name">
                                            {child.userNickname}
                                          </span>
                                          <span className="popup-comment-content-item-user-grade">
                                            {/* {gradeName(child.user.grade)} */}
                                          </span>
                                        </div>
                                        <div className="popup-comment-content-item-time-bg">
                                          <p className="popup-comment-content-item-time">
                                            {compareTime(child.createdAt)}
                                          </p>
                                        </div>
                                      </div>
                                      <div className="popup-comment-content-item-body">
                                        <div className="popup-comment-content-item-desc-bg">
                                          <p className="popup-comment-content-item-desc">
                                            {child.content}
                                          </p>
                                        </div>
                                        <div className="popup-comment-content-item-func-bg">
                                          {user.id === child.user.id && (
                                            <button
                                              className="popup-comment-content-item-delete"
                                              onClick={() => deleteComment(child.id)}
                                            >
                                              삭제하기
                                            </button>
                                          )}
                                          <button
                                            className="popup-comment-content-item-alert"
                                            onClick={() => openDeclation('comment', child.id)}
                                          >
                                            신고하기
                                          </button>
                                        </div>
                                      </div>
                                    </div>
                                  );
                                })}
                              {childComments[i] && (
                                <div className="popup-comment-input-bg">
                                  <div className="popup-comment-input">
                                    <div className="child-comment">
                                      <img src="/images/child_comment.svg" alt="" />
                                    </div>
                                    <span
                                      className={
                                        'main-intro-content-user-profile-bg ' +
                                        `grade-${user.grade}`
                                      }
                                    // onClick={() => {
                                    //   this.props.history.push(`/otherpage/${user.id}`);
                                    // }}
                                    >
                                      <span
                                        className={'main-intro-content-user-profile '}
                                        style={user.profileImgUrl ? {
                                          backgroundImage: `url(${user.profileImgUrl})`,
                                        } : {}}
                                      ></span>
                                    </span>
                                    <textarea
                                      name="content"
                                      id=""
                                      cols="30"
                                      rows="10"
                                      placeholder="댓글을 입력해주세요."
                                      value={childContents[i]}
                                      onChange={(e) => handleChildConent(e, i)}
                                    ></textarea>
                                  </div>
                                  <div className="popup-comment-button-bg">
                                    <button
                                      className="popup-comment-button"
                                      onClick={() => handleComment('comment', el.id, i)}
                                    >
                                      답글쓰기
                                    </button>
                                  </div>
                                </div>
                              )}
                            </div>
                          );
                        })}
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        )}
        {(type === 'genuine' || popupData.category === '정품판독') && (
          <div className={'popup-bg talk-popup ' + (isOpen ? ' show' : '')}>
            <div className="popup">
              <div className="popup-functions">
                {user.id === popupData.user.id ? (
                  <>
                    <button className="popup-edit" onClick={() => editTalk(popupData.id)}></button>
                    <button
                      className="popup-delete"
                      onClick={() => deleteTalk(popupData.id)}
                    ></button>
                  </>
                ) : (
                    <button
                      className="popup-declation"
                      onClick={() => openDeclation('talk', popupData.id)}
                    ></button>
                  )}
                <button className="popup-close" onClick={closePopup}></button>
              </div>
              <div className="popup-top-bg">
                <div className="popup-product-img-bg">
                  <div className="popup-product-img">
                    <Carousel images={popupData.productImgUrls} />
                    {/* <span
                      className="img"
                      style={{ backgroundImage: `url(${popupData.productImgUrls[0]})` }}
                    ></span>
                    <span className="popup-img-prev-button"></span>
                    <span className="popup-img-next-button"></span> */}
                  </div>
                </div>
                <div className="popup-content-bg">
                  <div className="main-intro-content-review-box">
                    <div className="main-intro-content-review-box-top">
                      <div className="main-intro-content-user-bg">
                        <span
                          className={
                            'main-intro-content-user-profile-bg ' + `grade-${popupData.user.grade}`
                          }
                          onClick={() => {
                            if (user.id !== popupData.user.id) {
                              // this.props.history.push(`/otherpage/${popupData.user.id}`)
                              window.location.href = `/otherpage/${popupData.user.id}`;
                            };
                          }}
                        >
                          <span
                            className={'main-intro-content-user-profile '}
                            style={popupData.user.profileImgUrl ? {
                              backgroundImage: `url(${popupData.user.profileImgUrl})`,
                            } : {}}
                          ></span>
                        </span>
                        <span className="main-intro-content-user-name">
                          {popupData.user.nickname}
                        </span>
                        <span
                          className={
                            'main-intro-content-user-grade ' + `grade-${popupData.user.grade}`
                          }
                        >
                          {gradeName(popupData.user.grade)}
                        </span>
                      </div>
                      <div className="main-intro-content-review-info-bg">
                        <span
                          className={
                            'main-intro-content-info-like ' +
                            (like && like.indexOf(popupData.id) > -1 ? 'active' : '')
                          }
                        ></span>
                        <span className="main-intro-content-info-number">
                          {popupData.liked && like && like.indexOf(popupData.id) > -1
                            ? abbreviateNumber(parseInt(popupData.likeCount), 0)
                            : !popupData.liked && like && like.indexOf(popupData.id) > -1
                              ? abbreviateNumber(parseInt(popupData.likeCount + 1), 0)
                              : !popupData.liked && like && like.indexOf(popupData.id) === -1
                                ? abbreviateNumber(parseInt(popupData.likeCount), 0)
                                : abbreviateNumber(parseInt(popupData.likeCount - 1), 0)}
                        </span>
                      </div>
                    </div>
                    <div className="main-intro-content-review-box-middle">
                      <div className="main-intro-content-review-value-bg">
                        <span className="popup-category">정품판독</span>
                      </div>
                      {/* <div className="main-intro-content-review-tag-bg">
                        <span className="main-intro-content-tag">#스타일태그</span>
                      </div> */}
                    </div>
                  </div>
                  <div className="popup-title-bg">
                    <h2 className="popup-title">{popupData.title}</h2>
                    <div className="popup-desc">{popupData.content}</div>
                  </div>
                  <div className="popup-button-bg">
                    <button
                      className="popup-button"
                      onClick={(e) => handleLike(e, 'talks', popupData.id)}
                    >
                      <span className="popup-button-like"></span>
                      <p className="popup-button-desc">좋아요</p>
                    </button>
                    {selectedComment.length > 0 ? (
                      <button className="popup-button">
                        <span className="popup-button-readout selected"></span>
                        <p className="popup-button-desc">판독완료</p>
                      </button>
                    ) : (
                        <button className="popup-button">
                          <span className="popup-button-readout"></span>
                          <p className="popup-button-desc">판독중</p>
                        </button>
                      )}
                  </div>
                </div>
              </div>
              <div className="popup-bottom-bg">
                <div className="popup-bottom-container">
                  <div className="popup-comment-header-bg">
                    <div className="popup-comment-header">
                      <span>댓글 {comments.length} 건</span>
                    </div>
                    {user && user.grade && (
                      <div className="popup-comment-input-bg">
                        <div className="popup-comment-input">
                          <span
                            className={
                              'main-intro-content-user-profile-bg ' + `grade-${user.grade}`
                            }
                          // onClick={() => {
                          //   this.props.history.push(`/otherpage/${user.id}`);
                          // }}
                          >
                            <span
                              className={'main-intro-content-user-profile '}
                              style={user.profileImgUrl ? {
                                backgroundImage: `url(${user.profileImgUrl})`,
                              } : {}}
                            ></span>
                          </span>
                          <textarea
                            name="content"
                            id=""
                            cols="30"
                            rows="10"
                            placeholder="댓글을 입력해주세요."
                            value={content}
                            onChange={handleChange}
                          ></textarea>
                        </div>
                        <div className="popup-comment-button-bg">
                          <button
                            className="popup-comment-button"
                            onClick={() => handleComment('talk', popupData.id)}
                          >
                            댓글쓰기
                          </button>
                        </div>
                      </div>
                    )}
                  </div>
                  {comments.length !== 0 && (
                    <div className="popup-comment-content-bg">
                      {comments &&
                        comments.map((el, i) => {
                          return (
                            <div key={i}>
                              <div className="popup-comment-content-item">
                                <div className="popup-comment-content-item-title">
                                  <div className="popup-comment-content-item-user-bg">
                                    <span
                                      className={
                                        'main-intro-content-user-profile-bg ' +
                                        `grade-${el.user.grade}`
                                      }
                                      onClick={() => {
                                        if (user.id !== el.user.id) {
                                          // this.props.history.push(`/otherpage/${el.user.id}`)
                                          window.location.href = `/otherpage/${el.user.id}`;
                                        };
                                      }}
                                    >
                                      <span
                                        className={'main-intro-content-user-profile '}
                                        style={el.user.profileImgUrl ? {
                                          backgroundImage: `url(${el.user.profileImgUrl})`,
                                        } : {}}
                                      ></span>
                                      {selectedComment.length > 0 &&
                                        el.id === selectedComment[0].id && (
                                          <span className="popup-comment-we-want"></span>
                                        )}
                                    </span>
                                    <span className="popup-comment-content-item-user-name">
                                      {el.user.nickname}
                                    </span>
                                    <span className="popup-comment-content-item-user-grade">
                                      {gradeName(el.user.grade)}
                                    </span>
                                  </div>
                                  <div className="popup-comment-content-item-time-bg">
                                    {selectedComment.length === 0 && user.id === popupData.user.id && (
                                      <span
                                        className="popup-comment-selection"
                                        onClick={() => handleMyPick(popupData.id, el)}
                                      >
                                        정품판독
                                      </span>
                                    )}
                                    {selectedComment.length > 0 &&
                                      user.id === popupData.user.id &&
                                      el.id === selectedComment[0].id && (
                                        <span
                                          className="popup-comment-selection"
                                          onClick={() => cancelMyPick(popupData.id, el.id)}
                                        >
                                          정품판독 취소
                                        </span>
                                      )}
                                    <p className="popup-comment-content-item-time">
                                      {compareTime(el.createdAt)}
                                    </p>
                                  </div>
                                </div>
                                <div className="popup-comment-content-item-body">
                                  <div className="popup-comment-content-item-desc-bg">
                                    <p className="popup-comment-content-item-desc">{el.content}</p>
                                  </div>
                                  <div className="popup-comment-content-item-func-bg">
                                    {user.id === el.user.id && (
                                      <button
                                        className="popup-comment-content-item-delete"
                                        onClick={() => deleteComment(el.id)}
                                      >
                                        삭제하기
                                      </button>
                                    )}
                                    <button
                                      className="popup-comment-content-item-alert"
                                      onClick={() => openDeclation('comment', el.id)}
                                    >
                                      신고하기
                                    </button>
                                    <button
                                      className="popup-comment-content-item-comment"
                                      onClick={() => handleChildComment(i)}
                                    >
                                      {childComments[i] ? '답글닫기' : '답글달기'}
                                    </button>
                                    <button className="popup-comment-content-item-like">
                                      <span
                                        className={
                                          'main-intro-content-info-like ' +
                                          (commentLike && commentLike.indexOf(el.id) > -1
                                            ? 'active'
                                            : '')
                                        }
                                        onClick={(e) => handleCommentLike(e, el.id)}
                                      ></span>
                                      <span className="main-intro-content-info-number">
                                        {el.liked && commentLike && commentLike.indexOf(el.id) > -1
                                          ? abbreviateNumber(parseInt(el.likeCount), 0)
                                          : !el.liked &&
                                            commentLike &&
                                            commentLike.indexOf(el.id) > -1
                                            ? abbreviateNumber(parseInt(el.likeCount + 1), 0)
                                            : !el.liked &&
                                              commentLike &&
                                              commentLike.indexOf(el.id) === -1
                                              ? abbreviateNumber(parseInt(el.likeCount), 0)
                                              : abbreviateNumber(parseInt(el.likeCount - 1), 0)}
                                      </span>
                                    </button>
                                  </div>
                                </div>
                              </div>
                              {el.childComments &&
                                el.childComments.map((child, childIndex) => {
                                  if (child.isDeleted) return;

                                  return (
                                    <div className="popup-comment-content-item" key={childIndex}>
                                      <div className="child-comment">
                                        <img src="/images/child_comment.svg" alt="" />
                                      </div>
                                      <div className="popup-comment-content-item-title">
                                        <div className="popup-comment-content-item-user-bg">
                                          <span
                                            className={
                                              'main-intro-content-user-profile-bg ' +
                                              `grade-${child.user.grade}`
                                            }
                                            onClick={() => {
                                              if (user.id !== child.user.id) {
                                                // this.props.history.push(`/otherpage/${child.user.id}`)
                                                window.location.href = `/otherpage/${child.user.id}`;
                                              };
                                            }}
                                          >
                                            <span
                                              className={'main-intro-content-user-profile '}
                                              style={child.user.profileImgUrl ? {
                                                backgroundImage: `url(${child.user.profileImgUrl})`,
                                              } : {}}
                                            ></span>
                                          </span>
                                          <span className="popup-comment-content-item-user-name">
                                            {child.userNickname}
                                          </span>
                                          <span className="popup-comment-content-item-user-grade">
                                            {/* {gradeName(child.user.grade)} */}
                                          </span>
                                        </div>
                                        <div className="popup-comment-content-item-time-bg">
                                          <p className="popup-comment-content-item-time">
                                            {compareTime(child.createdAt)}
                                          </p>
                                        </div>
                                      </div>
                                      <div className="popup-comment-content-item-body">
                                        <div className="popup-comment-content-item-desc-bg">
                                          <p className="popup-comment-content-item-desc">
                                            {child.content}
                                          </p>
                                        </div>
                                        <div className="popup-comment-content-item-func-bg">
                                          {user.id === child.user.id && (
                                            <button
                                              className="popup-comment-content-item-delete"
                                              onClick={() => deleteComment(child.id)}
                                            >
                                              삭제하기
                                            </button>
                                          )}
                                          <button
                                            className="popup-comment-content-item-alert"
                                            onClick={() => openDeclation('comment', child.id)}
                                          >
                                            신고하기
                                          </button>
                                        </div>
                                      </div>
                                    </div>
                                  );
                                })}
                              {childComments[i] && (
                                <div className="popup-comment-input-bg">
                                  <div className="popup-comment-input">
                                    <div className="child-comment">
                                      <img src="/images/child_comment.svg" alt="" />
                                    </div>
                                    <span
                                      className={
                                        'main-intro-content-user-profile-bg ' +
                                        `grade-${user.grade}`
                                      }
                                    // onClick={() => {
                                    //   this.props.history.push(`/otherpage/${user.id}`);
                                    // }}
                                    >
                                      <span
                                        className={'main-intro-content-user-profile '}
                                        style={user.profileImgUrl ? {
                                          backgroundImage: `url(${user.profileImgUrl})`,
                                        } : {}}
                                      ></span>
                                    </span>
                                    <textarea
                                      name="content"
                                      id=""
                                      cols="30"
                                      rows="10"
                                      placeholder="댓글을 입력해주세요."
                                      value={childContents[i]}
                                      onChange={(e) => handleChildConent(e, i)}
                                    ></textarea>
                                  </div>
                                  <div className="popup-comment-button-bg">
                                    <button
                                      className="popup-comment-button"
                                      onClick={() => handleComment('comment', el.id, i)}
                                    >
                                      답글쓰기
                                    </button>
                                  </div>
                                </div>
                              )}
                            </div>
                          );
                        })}
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        )}
        {(type === 'search' || popupData.category === '찾아줘요') && (
          <div className={'popup-bg talk-popup ' + (isOpen ? ' show' : '')}>
            <div className="popup">
              <div className="popup-functions">
                {user.id === popupData.user.id ? (
                  <>
                    <button className="popup-edit" onClick={() => editTalk(popupData.id)}></button>
                    <button
                      className="popup-delete"
                      onClick={() => deleteTalk(popupData.id)}
                    ></button>
                  </>
                ) : (
                    <button
                      className="popup-declation"
                      onClick={() => openDeclation('talk', popupData.id)}
                    ></button>
                  )}
                <button className="popup-close" onClick={closePopup}></button>
              </div>
              <div className="popup-top-bg">
                <div className="popup-product-img-bg">
                  <div className="popup-product-img">
                    <Carousel images={popupData.productImgUrls} />
                    {/* <span
                      className="img"
                      style={{ backgroundImage: `url(${popupData.productImgUrls[0]})` }}
                    ></span>
                    <span className="popup-img-prev-button"></span>
                    <span className="popup-img-next-button"></span> */}
                  </div>
                </div>
                <div className="popup-content-bg">
                  <div className="main-intro-content-review-box">
                    <div className="main-intro-content-review-box-top">
                      <div className="main-intro-content-user-bg">
                        <span
                          className={
                            'main-intro-content-user-profile-bg ' + `grade-${popupData.user.grade}`
                          }
                          onClick={() => {
                            if (user.id !== popupData.user.id) {
                              // this.props.history.push(`/otherpage/${popupData.user.id}`)
                              window.location.href = `/otherpage/${popupData.user.id}`;
                            };
                          }}
                        >
                          <span
                            className={'main-intro-content-user-profile '}
                            style={popupData.user.profileImgUrl ? {
                              backgroundImage: `url(${popupData.user.profileImgUrl})`,
                            } : {}}
                          ></span>
                        </span>
                        <span className="main-intro-content-user-name">
                          {popupData.user.nickname}
                        </span>
                        <span
                          className={
                            'main-intro-content-user-grade ' + `grade-${popupData.user.grade}`
                          }
                        >
                          {gradeName(popupData.user.grade)}
                        </span>
                      </div>
                      <div className="main-intro-content-review-info-bg">
                        <span
                          className={
                            'main-intro-content-info-like ' +
                            (like && like.indexOf(popupData.id) > -1 ? 'active' : '')
                          }
                        ></span>
                        <span className="main-intro-content-info-number">
                          {popupData.liked && like && like.indexOf(popupData.id) > -1
                            ? abbreviateNumber(parseInt(popupData.likeCount), 0)
                            : !popupData.liked && like && like.indexOf(popupData.id) > -1
                              ? abbreviateNumber(parseInt(popupData.likeCount + 1), 0)
                              : !popupData.liked && like && like.indexOf(popupData.id) === -1
                                ? abbreviateNumber(parseInt(popupData.likeCount), 0)
                                : abbreviateNumber(parseInt(popupData.likeCount - 1), 0)}
                        </span>
                      </div>
                    </div>
                    <div className="main-intro-content-review-box-middle">
                      <div className="main-intro-content-review-value-bg">
                        <span className="popup-category">찾아줘요</span>
                      </div>
                      {/* <div className="main-intro-content-review-tag-bg">
                        <span className="main-intro-content-tag">#스타일태그</span>
                      </div> */}
                    </div>
                  </div>
                  <div className="popup-title-bg">
                    <h2 className="popup-title">{popupData.title}</h2>
                    <div className="popup-desc">{popupData.content}</div>
                  </div>
                  <div className="popup-button-bg">
                    <button
                      className="popup-button"
                      onClick={(e) => handleLike(e, 'talks', popupData.id)}
                    >
                      <span className="popup-button-like"></span>
                      <p className="popup-button-desc">좋아요</p>
                    </button>
                    {selectedComment.length > 0 ? (
                      <button className="popup-button">
                        <span className="popup-button-search selected"></span>
                        <p className="popup-button-desc">찾기 완료</p>
                      </button>
                    ) : (
                        <button className="popup-button">
                          <span className="popup-button-search"></span>
                          <p className="popup-button-desc">찾는 중</p>
                        </button>
                      )}
                  </div>
                </div>
              </div>
              <div className="popup-bottom-bg">
                <div className="popup-bottom-container">
                  <div className="popup-comment-header-bg">
                    <div className="popup-comment-header">
                      <span>댓글 {comments.length} 건</span>
                    </div>
                    {user && user.grade && (
                      <div className="popup-comment-input-bg">
                        <div className="popup-comment-input">
                          <span
                            className={
                              'main-intro-content-user-profile-bg ' + `grade-${user.grade}`
                            }
                          // onClick={() => {
                          //   this.props.history.push(`/otherpage/${user.id}`);
                          // }}
                          >
                            <span
                              className={'main-intro-content-user-profile '}
                              style={user.profileImgUrl ? {
                                backgroundImage: `url(${user.profileImgUrl})`,
                              } : {}}
                            ></span>
                          </span>
                          <textarea
                            name="content"
                            id=""
                            cols="30"
                            rows="10"
                            placeholder="댓글을 입력해주세요."
                            value={content}
                            onChange={handleChange}
                          ></textarea>
                        </div>
                        <div className="popup-comment-button-bg">
                          <button
                            className="popup-comment-button"
                            onClick={() => handleComment('talk', popupData.id)}
                          >
                            댓글쓰기
                          </button>
                        </div>
                      </div>
                    )}
                  </div>
                  {comments.length !== 0 && (
                    <div className="popup-comment-content-bg">
                      {comments &&
                        comments.map((el, i) => {
                          return (
                            <div key={i}>
                              <div className="popup-comment-content-item">
                                <div className="popup-comment-content-item-title">
                                  <div className="popup-comment-content-item-user-bg">
                                    <span
                                      className={
                                        'main-intro-content-user-profile-bg ' +
                                        `grade-${el.user.grade}`
                                      }
                                      onClick={() => {
                                        if (user.id !== el.user.id) {
                                          // this.props.history.push(`/otherpage/${el.user.id}`)
                                          window.location.href = `/otherpage/${el.user.id}`;
                                        };
                                      }}
                                    >
                                      <span
                                        className={'main-intro-content-user-profile '}
                                        style={el.user.profileImgUrl ? {
                                          backgroundImage: `url(${el.user.profileImgUrl})`,
                                        } : {}}
                                      ></span>
                                      {selectedComment.length > 0 &&
                                        el.id === selectedComment[0].id && (
                                          <span className="popup-comment-we-want"></span>
                                        )}
                                    </span>
                                    <span className="popup-comment-content-item-user-name">
                                      {el.user.nickname}
                                    </span>
                                    <span className="popup-comment-content-item-user-grade">
                                      {gradeName(el.user.grade)}
                                    </span>
                                  </div>
                                  <div className="popup-comment-content-item-time-bg">
                                    {selectedComment.length === 0 && user.id === popupData.user.id && (
                                      <span
                                        className="popup-comment-selection"
                                        onClick={() => handleMyPick(popupData.id, el)}
                                      >
                                        찾아줘요
                                      </span>
                                    )}
                                    {selectedComment.length > 0 &&
                                      user.id === popupData.user.id &&
                                      el.id === selectedComment[0].id && (
                                        <span
                                          className="popup-comment-selection"
                                          onClick={() => cancelMyPick(popupData.id, el.id)}
                                        >
                                          찾아줘요 취소
                                        </span>
                                      )}
                                    <p className="popup-comment-content-item-time">
                                      {compareTime(el.createdAt)}
                                    </p>
                                  </div>
                                </div>
                                <div className="popup-comment-content-item-body">
                                  <div className="popup-comment-content-item-desc-bg">
                                    <p className="popup-comment-content-item-desc">{el.content}</p>
                                  </div>
                                  <div className="popup-comment-content-item-func-bg">
                                    {user.id === el.user.id && (
                                      <button
                                        className="popup-comment-content-item-delete"
                                        onClick={() => deleteComment(el.id)}
                                      >
                                        삭제하기
                                      </button>
                                    )}
                                    <button
                                      className="popup-comment-content-item-alert"
                                      onClick={() => openDeclation('comment', el.id)}
                                    >
                                      신고하기
                                    </button>
                                    <button
                                      className="popup-comment-content-item-comment"
                                      onClick={() => handleChildComment(i)}
                                    >
                                      {childComments[i] ? '답글닫기' : '답글달기'}
                                    </button>
                                    <button className="popup-comment-content-item-like">
                                      <span
                                        className={
                                          'main-intro-content-info-like ' +
                                          (commentLike && commentLike.indexOf(el.id) > -1
                                            ? 'active'
                                            : '')
                                        }
                                        onClick={(e) => handleCommentLike(e, el.id)}
                                      ></span>
                                      <span className="main-intro-content-info-number">
                                        {el.liked && commentLike && commentLike.indexOf(el.id) > -1
                                          ? abbreviateNumber(parseInt(el.likeCount), 0)
                                          : !el.liked &&
                                            commentLike &&
                                            commentLike.indexOf(el.id) > -1
                                            ? abbreviateNumber(parseInt(el.likeCount + 1), 0)
                                            : !el.liked &&
                                              commentLike &&
                                              commentLike.indexOf(el.id) === -1
                                              ? abbreviateNumber(parseInt(el.likeCount), 0)
                                              : abbreviateNumber(parseInt(el.likeCount - 1), 0)}
                                      </span>
                                    </button>
                                  </div>
                                </div>
                              </div>
                              {el.childComments &&
                                el.childComments.map((child, childIndex) => {
                                  if (child.isDeleted) return;

                                  return (
                                    <div className="popup-comment-content-item" key={childIndex}>
                                      <div className="child-comment">
                                        <img src="/images/child_comment.svg" alt="" />
                                      </div>
                                      <div className="popup-comment-content-item-title">
                                        <div className="popup-comment-content-item-user-bg">
                                          <span
                                            className={
                                              'main-intro-content-user-profile-bg ' +
                                              `grade-${child.user.grade}`
                                            }
                                            onClick={() => {
                                              if (user.id !== child.user.id) {
                                                // this.props.history.push(`/otherpage/${child.user.id}`)
                                                window.location.href = `/otherpage/${child.user.id}`;
                                              };
                                            }}
                                          >
                                            <span
                                              className={'main-intro-content-user-profile '}
                                              style={child.user.profileImgUrl ? {
                                                backgroundImage: `url(${child.user.profileImgUrl})`,
                                              } : {}}
                                            ></span>
                                          </span>
                                          <span className="popup-comment-content-item-user-name">
                                            {child.userNickname}
                                          </span>
                                          <span className="popup-comment-content-item-user-grade">
                                            {/* {gradeName(child.user.grade)} */}
                                          </span>
                                        </div>
                                        <div className="popup-comment-content-item-time-bg">
                                          <p className="popup-comment-content-item-time">
                                            {compareTime(child.createdAt)}
                                          </p>
                                        </div>
                                      </div>
                                      <div className="popup-comment-content-item-body">
                                        <div className="popup-comment-content-item-desc-bg">
                                          <p className="popup-comment-content-item-desc">
                                            {child.content}
                                          </p>
                                        </div>
                                        <div className="popup-comment-content-item-func-bg">
                                          {user.id === child.user.id && (
                                            <button
                                              className="popup-comment-content-item-delete"
                                              onClick={() => deleteComment(child.id)}
                                            >
                                              삭제하기
                                            </button>
                                          )}
                                          <button
                                            className="popup-comment-content-item-alert"
                                            onClick={() => openDeclation('comment', child.id)}
                                          >
                                            신고하기
                                          </button>
                                        </div>
                                      </div>
                                    </div>
                                  );
                                })}
                              {childComments[i] && (
                                <div className="popup-comment-input-bg">
                                  <div className="popup-comment-input">
                                    <div className="child-comment">
                                      <img src="/images/child_comment.svg" alt="" />
                                    </div>
                                    <span
                                      className={
                                        'main-intro-content-user-profile ' + `grade-${user.grade}`
                                      }
                                    // onClick={() => {
                                    //   this.props.history.push(`/otherpage/${user.id}`);
                                    // }}
                                    // style={{ backgroundImage: `url(${popupData.user.profileImgUrl})` }}
                                    ></span>
                                    <textarea
                                      name="content"
                                      id=""
                                      cols="30"
                                      rows="10"
                                      placeholder="댓글을 입력해주세요."
                                      value={childContents[i]}
                                      onChange={(e) => handleChildConent(e, i)}
                                    ></textarea>
                                  </div>
                                  <div className="popup-comment-button-bg">
                                    <button
                                      className="popup-comment-button"
                                      onClick={() => handleComment('comment', el.id, i)}
                                    >
                                      답글쓰기
                                    </button>
                                  </div>
                                </div>
                              )}
                            </div>
                          );
                        })}
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        )}
        {declation.isClicked && (
          <Declaration
            data={declation}
            handleClose={closeDeclation}
            handleChange={handleDeclation}
            handleSubmit={submitDeclation}
          />
        )}
      </>
    );
  }
}

export default withRouter(TalkPopup);
