import React, { Component } from 'react';
import GlobalNavigation from 'components/structure/GlobalNavigation';

class GlobalNavigationContainer extends Component {
  render(){
    return (
      <GlobalNavigation />
    )
  }
}

export default GlobalNavigationContainer;