import React, { Component } from 'react';
import './EventItem.css';
import Imagebox from 'components/common/Imagebox';

class EventItem extends Component {
  render() {
    const { data, handlePopup } = this.props;
    if (!data) return <></>;

    return (
      <div
        className="event-item-bg"
        onClick={() => handlePopup(data.id, 'event')}
      // style={{ backgroundImage: `url(${data.imageUrl || data.productImgUrls[0]})` }}
      >
        <Imagebox link={data.imageUrl || data.productImgUrls[0]} />
      </div>
    );
  }
}

export default EventItem;
