import React, { Component } from 'react';
import './SignoutModal.css';

class SignoutModal extends Component {
  render() {
    const { isOpen, handleClose, handleChange, data, handleClick } = this.props;

    return (
      <div className={'popup-bg center ' + (isOpen ? 'show' : '')}>
        <div className="bsj-withdrawal">
          <h2>회원 탈퇴하기</h2>
          <p>정말 회원 오늘의 직구 회원을 탈퇴하시겠습니까?</p>
          <p className="bsj-red-font">
            탈퇴 처리된 계정은 복구 할 수 없고 계정의 모든 정보들은 완전히 삭제되므로 신중하게
            결정해주세요.
          </p>
          <div className="bsj-withdrawal-bottom">
            <label htmlFor="">회원을 탈퇴하는 사유를 알려주세요.</label>
            <textarea
              name="content"
              value={data.content}
              onChange={handleChange}
              placeholder="회원 탈퇴에 대한 상세 의견을 작성 부탁드립니다.&#13;&#10; &#13;&#10;소중한 의견을 바탕으로 더 나은 오늘의 직구 서비스를 제공할 수 있도록 노력하겠습니다."
            ></textarea>
          </div>
          <div className="bsj-button">
            <button className="bsj-cancle" onClick={handleClose}>
              취소
            </button>
            <button onClick={handleClick}>탈퇴하기</button>
          </div>
        </div>
      </div>
    );
  }
}

export default SignoutModal;
