import React, { Component } from 'react';
import Get from 'components/Get';

class GetContainer extends Component {
  render() {
    return <Get />;
  }
}

export default GetContainer;
