import React, { Component } from 'react';
import './Declaration.css';

class Declaration extends Component {
  // shouldComponentUpdate() {
  //   return true;
  // }

  render() {
    const { data, handleClose, handleChange, handleSubmit } = this.props;
    return (
      <div className="sub-popup-bg">
        <div className="bsj-notify">
          <h2>신고하기</h2>
          <div>
            <label htmlFor="declation">신고하시는 이유를 알려주세요</label>
            <textarea
              id="declation"
              name="content"
              value={data.content}
              onChange={handleChange}
              placeholder="비방, 욕설, 잘못된 정보 등을 신고해주시면, 관리자가 확인 후 해당 글의 노출이 제한 될 수 있습니다. 하지만 타당한 사유 없이 허위 신고 시 신고자에 대한 활동 제한이 가해질 수 있으니, 신고 전에 신중하게 재고해주시기 바랍니다."
            ></textarea>
          </div>
          <button className="bsj-cancle" onClick={handleClose}>
            취소
          </button>
          <button onClick={handleSubmit}>신고하기</button>
        </div>
      </div>
    );
  }
}

export default Declaration;
