import React, { Component } from 'react';
import { gradeName, abbreviateNumber, numberWithCommas } from 'lib/common';
import { withRouter } from 'react-router-dom';
import './FollowPopup.css';

class FollowPopup extends Component {
  render() {
    const { type, isOpen, closePopup, handleType, data, handleFollow, handleUnFollow } = this.props;

    return (
      <div className={'follow-popup-bg ' + (isOpen ? ' show' : '')}>
        <div className="follow-popup">
          <div className="follow-popup-mo"></div>
          <div className="follow-popup-header">
            <button
              className={'follow-popup-button ' + (type === 'follower' ? 'active' : '')}
              onClick={() => handleType('follower')}
            >
              팔로우 <strong>{abbreviateNumber(parseInt(data.followerCount), 0)}</strong>
            </button>
            <button
              className={'follow-popup-button ' + (type === 'following' ? 'active' : '')}
              onClick={() => handleType('following')}
            >
              팔로잉 <strong>{abbreviateNumber(parseInt(data.followingCount), 0)}</strong>
            </button>
            <span className="follow-popup-close" onClick={closePopup}></span>
          </div>
          <div className="follow-popup-body">
            {type === 'follower' ? (
              <>
                {data.followers &&
                  data.followers.map((el, i) => {
                    return (
                      <div className="follow-popup-item" key={i}>
                        <div className="main-intro-content-user-bg">
                          <span
                            className={'main-intro-content-user-profile-bg ' + `grade-${el.grade}`}
                            onClick={() => {
                              // this.props.history.push(`/otherpage/${el.id}`);
                              window.location.href = `/otherpage/${el.id}`;
                            }}
                          >
                            <span
                              className={'main-intro-content-user-profile '}
                              style={el.profileImgUrl ? {
                                backgroundImage: `url(${el.profileImgUrl})`,
                              } : {}}
                            ></span>
                          </span>
                          <span className="main-intro-content-user-name">{el.nickname}</span>
                          <span className={'main-intro-content-user-grade ' + `grade-${el.grade}`}>
                            {gradeName(el.grade)}
                          </span>
                        </div>
                        {data.followings && data.followings.some((item) => item.id === el.id) ? (
                          <button
                            className="follow-popup-item-button"
                            onClick={() => handleUnFollow(el.id)}
                          >
                            팔로잉
                          </button>
                        ) : (
                            <button
                              className="follow-popup-item-button active"
                              onClick={() => handleFollow(el.id)}
                            >
                              팔로우
                          </button>
                          )}
                      </div>
                    );
                  })}
              </>
            ) : (
                <>
                  {data.followings &&
                    data.followings.map((el, i) => {

                      return (
                        <div className="follow-popup-item" key={i}>
                          <div className="main-intro-content-user-bg">
                            <span
                              className={'main-intro-content-user-profile-bg ' + `grade-${el.grade}`}
                              onClick={() => {
                                // this.props.history.push(`/otherpage/${el.id}`);
                                window.location.href = `/otherpage/${el.id}`;
                              }}
                            >
                              <span
                                className={'main-intro-content-user-profile '}
                                style={el.profileImgUrl ? {
                                  backgroundImage: `url(${el.profileImgUrl})`,
                                } : {}}
                              ></span>
                            </span>
                            <span className="main-intro-content-user-name">{el.nickname}</span>
                            <span className={'main-intro-content-user-grade ' + `grade-${el.grade}`}>
                              {gradeName(el.grade)}
                            </span>
                          </div>
                          <button
                            className="follow-popup-item-button"
                            onClick={() => handleUnFollow(el.id)}
                          >
                            팔로잉
                        </button>
                        </div>
                      );
                    })}
                </>
              )}
          </div>
        </div>
      </div>
    );
  }
}

export default withRouter(FollowPopup);
