import React, { Component } from 'react';
import './Get.css';
import HeaderTag from 'components/HeaderTag';
import GetItem from 'components/GetItem';
import GetPopup from 'components/GetPopup';
import axios from 'axios';
import { API } from 'config';
import jwt_decode from 'jwt-decode';
import { withRouter } from 'react-router-dom';

var like = {
  get: [],
};

var dib = {
  get: [],
};

class Get extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: false,
      isOpen: false,
      isFirst: true,
      total: 0,
      items: [],
      offset: 0,
      limit: 20,
      popupData: {},
      currency: {},
      user: {},
      catchPhrase: {},
      like: {
        get: [],
      },
      dib: {
        get: [],
      },
      back: ''
    };
  }

  componentWillMount() {
    localStorage.getItem('token') && this.getUser();
  }

  componentDidMount() {
    localStorage.getItem('token') && this.getUserData();
    this.getCurrency();
    this.getLinks();
    this.getCatch();

    const params = this.props.location.search.split('&');
    const id = params[0] && params[0].replace('?id=', '') || '';
    const back = params[1] && params[1].replace('back=', '') || '';
    if (this.props.location.search && id) {
      this.getLink(id);
    }

    this.setState({
      ...this.state,
      back
    })
    window.addEventListener('scroll', this._infiniteScroll, true);
  }

  componentWillUnmount() {
    this.setState({
      isLoading: false,
      isOpen: false,
      isFirst: true,
      total: 0,
      items: [],
      offset: 0,
      limit: 20,
      popupData: {},
      currency: {},
      user: {},
      catchPhrase: {},
      like: {
        get: [],
      },
      dib: {
        get: [],
      },
      back: ''
    });
    window.removeEventListener('scroll', this._infiniteScroll);
  }

  handleLike = async (e, type, id) => {
    e.stopPropagation();
    const { review } = this.state.like;
    if (!localStorage.getItem('token')) return alert('로그인이 필요합니다:)');
    try {
      const result = await axios.post(
        `${API}/${type}/${id}/like`,
        {},
        {
          headers: {
            token: `${localStorage.getItem('token')}`,
          },
        },
      );

      let rtype = '';
      if (type === 'reviews') {
        rtype = 'review';
      } else if (type === 'links') {
        rtype = 'get';
      } else if (type === 'talks') {
        rtype = 'talk';
      }

      var rlike = [...this.state.like[rtype]];
      if (result.status === 201) {
        rlike.push(id);
        this.setState({
          ...this.state,
          like: {
            ...this.state.like,
            [rtype]: rlike,
          },
        });
      } else if (result.status === 204) {
        rlike = rlike.filter((el) => Number(el) !== Number(id));
        this.setState({
          ...this.state,
          like: {
            ...this.state.like,
            [rtype]: rlike,
          },
        });
      }
    } catch (e) {
      if (e.response && e.response.data && e.response.data.msg && e.response.data.msg === '토큰이 만료되었습니다.') {
        localStorage.removeItem('token');
        window.location.href = "/";
        return;
      }
      // alert('조금만 뒤에 다시 시도해주세요:)');
    }
    // /{reviews}/{id}/like
  };

  handleDib = async (e, type, id) => {
    e.stopPropagation();
    if (!localStorage.getItem('token')) return alert('로그인이 필요합니다:)');
    try {
      const result = await axios.post(
        `${API}/${type}/${id}/dib`,
        {},
        {
          headers: {
            token: `${localStorage.getItem('token')}`,
          },
        },
      );

      let rtype = '';
      if (type === 'reviews') {
        rtype = 'review';
      } else if (type === 'links') {
        rtype = 'get';
      } else if (type === 'talks') {
        rtype = 'talk';
      }

      var rdib = [...this.state.dib[rtype]];

      if (result.status === 201) {
        rdib.push(id);
        this.setState({
          ...this.state,
          dib: {
            ...this.state.dib,
            [rtype]: rdib,
          },
        });
      } else if (result.status === 204) {
        rdib = rdib.filter((el) => Number(el) !== Number(id));
        this.setState({
          ...this.state,
          dib: {
            ...this.state.dib,
            [rtype]: rdib,
          },
        });
      }
    } catch (e) {
      if (e.response && e.response.data && e.response.data.msg && e.response.data.msg === '토큰이 만료되었습니다.') {
        localStorage.removeItem('token');
        window.location.href = "/";
        return;
      }
    }
  };

  getUser = () => {
    try {
      const user = jwt_decode(localStorage.getItem('token'));
      if (user) {
        this.setState({
          ...this.state,
          user: {
            id: user.id,
          },
        });
      } else {
        localStorage.removeItem('token');
      }
    } catch (e) {
      localStorage.removeItem('token');
      window.location.reload();
    }
  };

  getUserData = async () => {
    const user = jwt_decode(localStorage.getItem('token'));
    if (user) {
      try {
        const result = await axios.get(`${API}/users/my`, {
          headers: {
            token: `${localStorage.getItem('token')}`,
          }
        });

        const newToken = result.headers["new-token"] || '';
        if (newToken) {
          axios.defaults.headers.token = newToken;
          localStorage.setItem("token", newToken);
        }

        if (result && result.status === 200) {

          let user = result.data;

          this.setState({
            ...this.state,
            user,
          });
        } else {
          localStorage.removeItem('token');
          window.location.reload();
        }
      } catch (e) {
        localStorage.removeItem('token');
        window.location.reload();
      }
    } else {
      localStorage.removeItem('token');
    }
  };

  getCatch = async () => {
    try {
      const result = await axios.get(`${API}/extra/catch-phrase`);
      const catchPhrase = { ...result.data.data };
      this.setState({
        ...this.state,
        catchPhrase,
      });
    } catch (e) {
      if (e.response && e.response.data && e.response.data.msg && e.response.data.msg === '토큰이 만료되었습니다.') {
        localStorage.removeItem('token');
        window.location.href = "/";
        return;
      }
    }
  };

  getCurrency = async () => {
    try {
      let currency = {};
      const result = await axios.get(`${API}/extra/exchange-rate`);
      result.data.map((el) => {
        currency[el.currency] = el.amount;
      });
      this.setState({
        ...this.state,
        currency,
      });
    } catch (e) {
      if (e.response && e.response.data && e.response.data.msg && e.response.data.msg === '토큰이 만료되었습니다.') {
        localStorage.removeItem('token');
        window.location.href = "/";
        return;
      }
    }
  };

  getLink = async (id) => {
    try {
      const result = await axios.get(`${API}/links/${id}`, {
        headers: {
          token: `${localStorage.getItem('token')}`,
        },
      });
      if (result.data.liked) {
        like['get'].push(result.data.id);
      }

      if (result.data.dib) {
        dib['get'].push(result.data.id);
      }

      this.setState({
        ...this.state,
        isOpen: true,
        popupData: result.data,
        like,
        dib,
      });
    } catch (e) {
      if (e.response && e.response.data && e.response.data.msg && e.response.data.msg.indexOf('토큰이 만료되었습니다') > -1) {
        localStorage.removeItem('token');
        window.location.reload();
      }
    }
  };

  editLink = async (id) => {
    // this.props.history.push(`/writing/get/?id=${id}`);
    window.location.href = `/writing/get/?id=${id}`;
  };

  deleteLink = async (id) => {
    if (window.confirm('정말 삭제하시겠습니까?')) {
      try {
        const result = await axios.delete(`${API}/links/${id}`, {
          headers: {
            token: `${localStorage.getItem('token')}`,
          },
        });
        if (result.status === 200) {
          this.setState({
            ...this.state,
            isOpen: false,
            popupData: {},
            items: this.state.items.filter((el) => el.id !== id),
          });

          alert('삭제 성공 :)');
        }
      } catch (e) {
        // alert('조금만 뒤에 다시 시도해주세요:)');
        if (e.response && e.response.data && e.response.data.msg && e.response.data.msg === '토큰이 만료되었습니다.') {
          localStorage.removeItem('token');
          window.location.href = "/";
          return;
        }
      }
    }
  };

  getLinks = async () => {
    const { isFirst } = this.state;

    try {
      const result = await axios.get(`${API}/links`, {
        params: {
          limit: this.state.limit,
          offset: this.state.offset,
          // title: 'jaldjflkasjf',
          // content: 'ajsldfjaslfjlkaj',
          // brand: '',
          // store: '',
          // styletag: '',
        },
        headers: {
          token: `${localStorage.getItem('token')}`,
        },
      });

      result.data[0].map((el) => {
        if (el.liked) {
          like['get'].push(el.id);
        }

        if (el.dib) {
          dib['get'].push(el.id);
        }
      });

      if (isFirst) {
        this.setState({
          ...this.state,
          isFirst: false,
          total: result.data[1],
          items: result.data[0],
          isLoading: false,
          like,
          dib,
        });
      } else {
        this.setState({
          ...this.state,
          isFirst: false,
          total: result.data[1],
          items: [...this.state.items, ...result.data[0]],
          isLoading: false,
          like,
          dib,
        });
      }
    } catch (e) {
      if (e.response && e.response.data && e.response.data.msg && e.response.data.msg.indexOf('토큰이 만료되었습니다') > -1) {
        localStorage.removeItem('token');
        window.location.reload();
      }
    }
  };

  _infiniteScroll = () => {
    let scrollHeight = Math.max(document.documentElement.scrollHeight, document.body.scrollHeight);
    let scrollTop = Math.max(document.documentElement.scrollTop, document.body.scrollTop);

    let clientHeight = document.documentElement.clientHeight;

    if (!this.state.isLoading && (Number(scrollTop) + Number(clientHeight) + 1000) >= Number(scrollHeight)) {
      this.setState({
        ...this.state,
        offset: this.state.offset + 20,
        limit: this.state.limit,
        isLoading: true,
      }, () => {
        if (this.state.offset <= this.state.total) {
          this.getLinks();
        }
      });
    }
  };

  handlePopup = (id) => {
    this.setState({
      ...this.state,
      isOpen: true,
      popupData: this.state.items.filter((el) => el.id === id)[0],
    });
    window.history.pushState(
      null,
      null,
      `/get?id=${id}`,
    );
  };

  closePopup = () => {
    const { back } = this.state;
    if (back === 'mypage') return window.location.href = '/mypage';
    // this.props.history.push('/mypage')

    this.setState({
      ...this.state,
      isOpen: false,
    });
    window.history.pushState(
      null,
      null,
      `/get`,
    );
  };

  render() {
    const { handlePopup, closePopup, handleLike, handleDib, deleteLink, editLink } = this;
    const { isOpen, items, currency, popupData, user, catchPhrase, like, dib } = this.state;

    return (
      <>
        <div className="get-bg">
          <div className="wrapper">
            <HeaderTag />
            <div className="main-get-title-bg">
              <p className="main-intro-sub">오늘의 득템,</p>
              <p className="main-intro-title">
                <strong>{catchPhrase.link}</strong>
              </p>
            </div>
            <div className="get-content-bg">
              {items &&
                items.map((el, i) => {
                  return (
                    <GetItem
                      data={el}
                      key={i}
                      user={user}
                      handlePopup={handlePopup}
                      currency={currency}
                      handleLike={handleLike}
                      handleDib={handleDib}
                      like={like.get}
                      dib={dib.get}
                    ></GetItem>
                  );
                })}

              {items.length === 0 && (
                <div className="content-no-data">
                  오늘의 득템을 준비 중입니다. 조금만 기다려주세요:{')'}
                </div>
              )}
            </div>
          </div>
        </div>
        {popupData && popupData.user && popupData.user.id && (
          <GetPopup
            isOpen={isOpen}
            popupData={popupData}
            currency={currency}
            closePopup={closePopup}
            user={user}
            handleLike={handleLike}
            handleDib={handleDib}
            like={like.get}
            dib={dib.get}
            deleteLink={deleteLink}
            editLink={editLink}
          />
        )}
      </>
    );
  }
}

export default withRouter(Get);
