import React, { Component } from 'react';
import Header from 'components/structure/Header';

class HeaderContainer extends Component {

  render(){
    return (
      <Header />
    )
  }
}

export default HeaderContainer;