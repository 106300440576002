import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import axios from 'axios';
import { API } from 'config';
import './LoginSearchEmail.css';

class LoginSearchEmail extends Component {
  state = {
    searchProcess: 1,
    name: '',
    year: '',
    month: '',
    date: '',
    email: '',
  };

  handleChange = (e) => {
    if (e.target.name === 'month') {
      if (Number(e.target.value) > 12) return;
    }

    if (e.target.name === 'date') {
      if (Number(e.target.value) > 31) return;
    }

    this.setState({
      ...this.state,
      [e.target.name]: e.target.value,
    });
  };

  findId = async () => {
    const { name, year, month, date } = this.state;
    if (!name.trim()) return;
    if (!year.trim()) return;
    if (!month.trim()) return;
    if (!date.trim()) return;
    let m, d;

    if (month && Number(month) < 10) {
      m = '0' + month;
    } else {
      m = month;
    }

    if (date && Number(date) < 10) {
      d = '0' + date;
    } else {
      d = date;
    }
    const birthday = year + '-' + m + '-' + d;
    try {
      const result = await axios.get(`${API}/users/`, {
        params: {
          name: name.trim(),
          birthday,
        },
      });
      if (result && result.status === 200) {
        this.setState({
          ...this.state,
          searchProcess: 2,
          email: result.data[0][0].email,
        });
      } else {
        this.setState({
          ...this.state,
          searchProcess: 3,
          name: '',
          year: '',
          month: '',
          date: '',
        });
      }
    } catch (e) {
      this.setState({
        ...this.state,
        searchProcess: 3,
        name: '',
        year: '',
        month: '',
        date: '',
      });
    }
  };

  hanldeSearchProcess = (searchProcess) => {
    this.setState({
      ...this.state,
      searchProcess,
    });
  };

  render() {
    const { handleChange, hanldeSearchProcess, findId } = this;
    const { name, year, month, date, searchProcess, email } = this.state;

    return (
      <>
        {searchProcess === 1 && (
          <div className="bjs-bg search-id-1">
            <div className="bjs-password">
              <div className="bjs-logo">
                <img src="/images/logo.png" alt="logo" />
              </div>
              <h2>이메일 계정 찾기</h2>
              <p>이메일 계정을 잊어버리셨나요?</p>
              <p>아래 정보를 입력해주세요.</p>
              <div className="bjs-address">
                <label htmlFor="">이름</label>
                <input type="text" name="name" value={name} onChange={handleChange} />
              </div>
              <p className="bjs-day">생년월일</p>
              <div className="bjs-year">
                <div className="bjs-year-item">
                  <label htmlFor="">년</label>
                  <input type="text" name="year" value={year} onChange={handleChange} />
                </div>
                <div className="bjs-year-item">
                  <label htmlFor="">월</label>
                  <input
                    className="bjs-month"
                    type="text"
                    name="month"
                    value={month}
                    onChange={handleChange}
                  />
                </div>
                <div className="bjs-year-item">
                  <label htmlFor="">일</label>
                  <input type="text" name="date" value={date} onChange={handleChange} />
                </div>
              </div>
              <button onClick={() => findId()}>ID 찾기</button>
            </div>
          </div>
        )}
        {searchProcess === 2 && (
          <div className="bjs-bg search-id-2">
            <div className="bjs-password">
              <div className="bjs-logo">
                <img src="/images/logo.png" alt="logo" />
              </div>
              <h2>이메일 계정 찾기</h2>
              <p>이메일 계정 정보를 찾았습니다!</p>
              <p className="bjs-email-id">{email}</p>
              <button
                onClick={() => {
                  window.location.href = '/login';
                }}
              >
                로그인 화면
              </button>
            </div>
          </div>
        )}
        {searchProcess === 3 && (
          <div className="bjs-bg search-id-3">
            <div className="bjs-password">
              <div className="bjs-logo">
                <img src="/images/logo.png" alt="logo" />
              </div>
              <h2>이메일 계정 찾기</h2>
              <div className="bjs-email-txt">
                <p>입력하신 정보와 일치하는 계정을 찾지 못하였습니다.</p>
                <p>
                  신규 사용자이신가요?
                  <strong
                    className="cursor"
                    onClick={() => {
                      // this.props.history.push('/signup');
                      window.location.href = '/signup';
                    }}
                  >
                    계정만들기
                  </strong>
                </p>
                <p>
                  정보를 다시 입력하시겠어요?
                  <strong className="cursor" onClick={() => hanldeSearchProcess(1)}>
                    되돌아가기
                  </strong>
                </p>
              </div>
              <button onClick={() => (window.location.href = '/login')}>로그인 화면</button>
            </div>
          </div>
        )}
      </>
    );
  }
}

export default withRouter(LoginSearchEmail);
