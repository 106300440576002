import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import Imagebox from 'components/common/Imagebox';
import axios from 'axios';
import { API } from 'config';
import './Signup.css';
import { checkPassword } from 'lib/common';
import jwt_decode from 'jwt-decode';
import { tagMangerService } from 'lib/TagManagerService';

class Signup extends Component {
  state = {
    // 8, 9
    process: 1,
    userId: 1,
    terms: '',
    privacy: '',
    promotions: '',
    styletags: [],
    selectStyle: [],
    posts: {},
    follow: {
      0: [false, false, false],
      1: [false, false, false],
      2: [false, false, false],
    },
    show: {
      email: false,
      phone: false,
    },
    confirm: {
      email: false,
      phone: false,
    },
    data: {
      allAgree: false,
      termsAgree: false,
      privacyAgree: false,
      locationUsageAgree: false,
      promotionUsageAgree: false,
      email: '',
      emailCode: '',
      password: '',
      password2: '',
      nickname: '',
      name: '',
      gender: 'male',
      birthday: '',
      year: '',
      month: '',
      day: '',
      phone: '',
      phoneCode: '',
      phone1: '',
      phone2: '',
      phone3: '',
      zonecode: '',
      address1: '',
      address2: '',
      pccc: '',
      height: 0,
      weight: 'XS',
      bodyShape: 0,
      shoeSize: 0,
      openBodyInfo: true,
    },
    user: {},
  };
  // 1.이용약관, 2.계정 만들기, 3.추가정보 입력하기, 4.키 몸무게, 5.어떤 체형, 6.평소 신발, 7.어떤 스타일, 8. 팔로우 9. 가입 완료
  componentDidMount() {
    this.getTerms();
    this.getPrivacy();
    this.getPromotions();
  }

  getTerms = async () => {
    try {
      const result = await axios.get(`${API}/extra/service-rule`);
      this.setState({
        ...this.state,
        terms: result.data.data,
      });
    } catch (e) {
      if (
        e.response &&
        e.response.data &&
        e.response.data.msg &&
        e.response.data.msg === '토큰이 만료되었습니다.'
      ) {
        localStorage.removeItem('token');
        window.location.href = '/';
        return;
      }
    }
  };

  getPromotions = async () => {
    try {
      const result = await axios.get(`${API}/extra/option-rule2`);
      this.setState({
        ...this.state,
        promotions: result.data.data,
      });
    } catch (e) {
      if (
        e.response &&
        e.response.data &&
        e.response.data.msg &&
        e.response.data.msg === '토큰이 만료되었습니다.'
      ) {
        localStorage.removeItem('token');
        window.location.href = '/';
        return;
      }
    }
  };

  //   /v1/extra/option-rule1
  // /v1/extra/option-rule2

  getPrivacy = async () => {
    try {
      const result = await axios.get(`${API}/extra/personal-info-rule`);
      this.setState({
        ...this.state,
        privacy: result.data.data,
      });
    } catch (e) {
      if (
        e.response &&
        e.response.data &&
        e.response.data.msg &&
        e.response.data.msg === '토큰이 만료되었습니다.'
      ) {
        localStorage.removeItem('token');
        window.location.href = '/';
        return;
      }
    }
  };

  handleFollow = async (postId, index, userId) => {
    const { follow } = this.state;

    try {
      if (!follow[postId][index]) {
        const result = await axios.post(
          `${API}/users/${userId}/follow`,
          {},
          {
            headers: {
              token: `${localStorage.getItem('token')}`,
            },
          },
        );
        if (result.status === 201) {
          alert('팔로우 성공 :)');
          this.setState({
            ...this.state,
            follow: {
              ...this.state.follow,
              [postId]: {
                ...this.state.follow[postId],
                [index]: true,
              },
            },
          });
        } else {
          alert('팔로우 신청 실패');
        }
      } else {
        const result = await axios.post(
          `${API}/users/${userId}/unfollow`,
          {},
          {
            headers: {
              token: `${localStorage.getItem('token')}`,
            },
          },
        );

        if (result.status === 201) {
          alert('팔로우 취소 성공 :)');
          this.setState({
            ...this.state,
            follow: {
              ...this.state.follow,
              [postId]: {
                ...this.state.follow[postId],
                [index]: false,
              },
            },
          });
        } else {
          alert('팔로우 취소 신청 실패');
        }
      }
    } catch (e) {
      if (
        e.response &&
        e.response.data &&
        e.response.data.msg &&
        e.response.data.msg === '본인은 팔로우 할 수 없습니다.'
      ) {
        alert('본인은 팔로우 할 수 없습니다:)');
      } else {
        // alert('조금만 뒤에 다시 시도해주세요:)');
        if (
          e.response &&
          e.response.data &&
          e.response.data.msg &&
          e.response.data.msg === '토큰이 만료되었습니다.'
        ) {
          localStorage.removeItem('token');
          window.location.href = '/';
          return;
        }
      }
    }
  };

  getStylePosts = async (name) => {
    try {
      const result = await axios.get(`${API}/reviews/`, {
        params: {
          styletag: name,
          limit: 3,
        },
        headers: {
          token: `${localStorage.getItem('token')}`,
        },
      });
      return result;
    } catch (e) {
      if (
        e.response &&
        e.response.data &&
        e.response.data.msg &&
        e.response.data.msg === '토큰이 만료되었습니다.'
      ) {
        localStorage.removeItem('token');
        window.location.href = '/';
        return;
      }
    }
  };

  handleStyle = async () => {
    const { selectStyle } = this.state;

    if (selectStyle.length === 0) {
      return this.setState({
        ...this.state,
        process: 9,
      });
    }

    try {
      const posts = {};
      if (selectStyle[0]) {
        const result = await this.getStylePosts(selectStyle[0]);
        posts['0'] = [...result.data[0]];
      }

      if (selectStyle[1]) {
        const result = await this.getStylePosts(selectStyle[1]);
        posts['1'] = [...result.data[0]];
      }

      if (selectStyle[2]) {
        const result = await this.getStylePosts(selectStyle[2]);
        posts['2'] = [...result.data[0]];
      }

      this.setState({
        ...this.state,
        posts,
        process: 8,
      });
    } catch (e) {
      // alert('조금만 뒤에 다시 시도해주세요:)');
      if (
        e.response &&
        e.response.data &&
        e.response.data.msg &&
        e.response.data.msg === '토큰이 만료되었습니다.'
      ) {
        localStorage.removeItem('token');
        window.location.href = '/';
        return;
      }
    }
  };

  getStyleTag = async () => {
    // localhost:7000/v1/static/style-tags?hidden=false
    try {
      const result = await axios.get(`${API}/static/style-tags?hidden=false`);
      this.setState({
        ...this.state,
        styletags: [...result.data],
        process: 7,
      });
    } catch (e) {
      // alert('조금만 뒤에 다시 시도해주세요:)');
      if (
        e.response &&
        e.response.data &&
        e.response.data.msg &&
        e.response.data.msg === '토큰이 만료되었습니다.'
      ) {
        localStorage.removeItem('token');
        window.location.href = '/';
        return;
      }
    }
  };

  handleStyleTag = (e, i) => {
    const { styletags, selectStyle } = this.state;

    let style = [...selectStyle];
    if (style.indexOf(styletags[i].value) > -1) {
      style = style.filter((el) => el !== styletags[i].value);
    } else {
      style.push(styletags[i].value);
    }

    if (style.length > 3)
      return alert('최대 3개까지 선택 가능하십니다. 다른 선택을 해제해주세요:)');

    this.setState({
      ...this.state,
      selectStyle: style,
    });
  };

  handlePhoneVerify = async () => {
    const { phone1, phone2, phone3 } = this.state.data;

    const phone = phone1 + phone2 + phone3;
    if (!phone) return;

    try {
      const check = await axios.get(`${API}/users`, {
        params: {
          phone,
        },
      });

      if (check.data[0].length > 0) return alert('이미 가입한 휴대전화입니다!');

      const result = await axios.post(`${API}/users/verify-phone`, {
        phone,
      });
      if (result.data === 'ok') {
        this.setState({
          ...this.state,
          show: {
            ...this.state.show,
            phone: true,
          },
          confirm: {
            ...this.state.confirm,
            phone: false,
          },
        });
      }
    } catch (e) {
      if (
        e.response &&
        e.response.data &&
        e.response.data.msg &&
        e.response.data.msg === '토큰이 만료되었습니다.'
      ) {
        localStorage.removeItem('token');
        window.location.href = '/';
        return;
      }
    }
  };

  handlePhoneCode = async () => {
    const { phone1, phone2, phone3, phoneCode } = this.state.data;
    try {
      const result = await axios.post(`${API}/users/verify-phone-code`, {
        phone: phone1 + phone2 + phone3,
        code: phoneCode,
      });
      if (result.data === 'ok') {
        alert('휴대폰 인증 성공:)');
        this.setState({
          ...this.state,
          show: {
            ...this.state.show,
            phone: false,
          },
          confirm: {
            ...this.state.confirm,
            phone: true,
          },
        });
      } else {
        alert('휴대폰 인증을 다시 시도해주세요:)');
      }
    } catch (e) {
      if (
        e.response &&
        e.response.data &&
        e.response.data.msg &&
        e.response.data.msg === '토큰이 만료되었습니다.'
      ) {
        localStorage.removeItem('token');
        window.location.href = '/';
        return;
      }
    }
  };

  handleEmailVerify = async () => {
    // users/verify-email
    const { email } = this.state.data;

    try {
      const check = await axios.get(`${API}/users`, {
        params: {
          email,
        },
      });

      if (check.data[0].length > 0) return alert('이미 가입한 이메일입니다!');

      const result = await axios.post(`${API}/users/verify-email`, {
        email,
      });
      if (result.data === 'ok') {
        this.setState({
          ...this.state,
          show: {
            ...this.state.show,
            email: true,
          },
          confirm: {
            ...this.state.confirm,
            email: false,
          },
        });
      }
    } catch (e) {
      if (
        e.response &&
        e.response.data &&
        e.response.data.msg &&
        e.response.data.msg === '토큰이 만료되었습니다.'
      ) {
        localStorage.removeItem('token');
        window.location.href = '/';
        return;
      }
    }
  };

  handleEmailCode = async () => {
    const { email, emailCode } = this.state.data;
    try {
      const result = await axios.post(`${API}/users/verify-email-code`, {
        email,
        code: emailCode,
      });
      if (result.data === 'ok') {
        alert('이메일 인증 성공:)');
        this.setState({
          ...this.state,
          show: {
            ...this.state.show,
            email: false,
          },
          confirm: {
            ...this.state.confirm,
            email: true,
          },
        });
      } else {
        alert('이메일 인증을 다시 시도해주세요:)');
      }
    } catch (e) {
      if (
        e.response &&
        e.response.data &&
        e.response.data.msg &&
        e.response.data.msg === '토큰이 만료되었습니다.'
      ) {
        localStorage.removeItem('token');
        window.location.href = '/';
        return;
      }
    }
  };

  handleBodyShape = (i) => {
    this.setState({
      ...this.state,
      data: {
        ...this.state.data,
        bodyShape: i,
      },
    });
  };

  handleAgree = (e, type) => {
    if (type === 'allAgree') {
      this.setState({
        ...this.state,
        data: {
          allAgree: !this.state.data.allAgree,
          termsAgree: !this.state.data.allAgree,
          privacyAgree: !this.state.data.allAgree,
          locationUsageAgree: !this.state.data.allAgree,
          promotionUsageAgree: !this.state.data.allAgree,
        },
      });
    } else {
      this.setState({
        ...this.state,
        data: {
          ...this.state.data,
          allAgree: false,
          [e.target.name]: !this.state.data[e.target.name],
        },
      });
    }
  };

  handleSignup = async () => {
    const { confirm } = this.state;
    const {
      locationUsageAgree,
      promotionUsageAgree,
      email,
      password,
      password2,
      nickname,
      name,
      birthday,
      phone,
      gender,
      phone1,
      phone2,
      phone3,
      year,
      month,
      day,
    } = this.state.data;

    // var emailRule = /^[0-9a-zA-Z]([-_.]?[0-9a-zA-Z])*@[0-9a-zA-Z]([-_.]?[0-9a-zA-Z])*.[a-zA-Z]{2,3}$/i; //이메일 정규식

    var emailRule = /[a-zA-Z0-9_.-]+@[0-9a-zA-Z]([-_.]?[0-9a-zA-Z])*.[a-zA-Z]{2,3}$/i;

    // var emailRule = /(^[0-9a-zA-Z]+(?:[._-][0-9a-zA-Z]+)*)@([0-9a-zA-Z]+(?:[._-][0-9a-zA-Z]+)*\.[0-9a-zA-Z]{2,3})$/i;

    var passRule = /(?=.*\d)(?=.*[a-z]).{10,}$/;

    // console.log('emailRule.test(email) ', emailRule.test(email));

    if (!emailRule.test(email)) return alert('이메일 형식에 맞춰 작성 해주세요:)');
    if (!passRule.test(password)) return alert('패스워드 형식에 맞춰 작성 해주세요:)');

    if (!confirm.email) return alert('이메일 인증을 먼저 해주세요:)');
    if (!confirm.phone) return alert('휴대전화 인증을 먼저 해주세요:)');
    // if (!checkPassword(password)) return alert('패스워드 설정안내를 확인해 주세요:)');
    if (password.length < 10) return alert('패스워드를 10자리 이상으로 입력해주세요:)');
    if (password !== password2) return alert('입력하신 패스워드가 다릅니다:)');

    try {
      const result = await axios.post(`${API}/users/signup`, {
        email,
        password,
        nickname,
        name,
        gender,
        birthday:
          year +
          '-' +
          (month.length === 2 ? month : '0' + month) +
          '-' +
          (day.length === 2 ? day : '0' + day), //0000-00-00
        phone: phone1 + phone2 + phone3, //00000000000
        locationUsageAgree,
        promotionUsageAgree,
        openBodyInfo: true,
      });
      // this.setState({
      //   ...this.state,
      //   process,
      // });

      if (result.status === 200) {
        // alert('회원가입 성공!');
        localStorage.setItem('token', result.data.token);
        // this.props.history.push('/');
        this.setState({
          ...this.state,
          userId: result.data.id,
          process: 3,
        });

        tagMangerService.signUpEvent();
      } else {
        alert('각 항목에 맞게 입력을 해주세요 :)');
      }
    } catch (e) {
      // alert('조금만 뒤에 다시 시도해주세요:)');
      if (
        e.response &&
        e.response.data &&
        e.response.data.msg &&
        e.response.data.msg === '토큰이 만료되었습니다.'
      ) {
        localStorage.removeItem('token');
        window.location.href = '/';
        return;
      }
    }
  };

  handleAddAddress = async () => {
    const { userId } = this.state;
    const { zonecode, address1, address2, pccc } = this.state.data;
    // address1: '',
    // address2: '',
    // pccc: '',
    try {
      const result = await axios.patch(`${API}/users/${userId}`, {
        zonecode,
        address1,
        address2,
        pccc,
      });
      if (result.status === 200) {
        this.setState({
          ...this.state,
          process: 4,
        });
      } else {
        alert('각 항목에 맞게 입력을 해주세요 :)');
      }
    } catch (e) {
      // alert('조금만 뒤에 다시 시도해주세요:)');
      if (
        e.response &&
        e.response.data &&
        e.response.data.msg &&
        e.response.data.msg === '토큰이 만료되었습니다.'
      ) {
        localStorage.removeItem('token');
        window.location.href = '/';
        return;
      }
    }
  };

  handlePerson = async () => {
    const { userId } = this.state;
    const { weight, height } = this.state.data;
    // console.log("handlePerson weight ", weight)
    try {
      const result = await axios.patch(`${API}/users/${userId}`, {
        weight: weight,
        height: Number(height),
      });
      if (result.status === 200) {
        this.setState({
          ...this.state,
          process: 5,
        });
      } else {
        alert('각 항목에 맞게 입력을 해주세요 :)');
      }
    } catch (e) {
      // alert('조금만 뒤에 다시 시도해주세요:)');
      if (
        e.response &&
        e.response.data &&
        e.response.data.msg &&
        e.response.data.msg === '토큰이 만료되었습니다.'
      ) {
        localStorage.removeItem('token');
        window.location.href = '/';
        return;
      }
    }
  };

  handleShape = async () => {
    const { userId } = this.state;
    const { bodyShape } = this.state.data;
    let shape = '';
    switch (bodyShape) {
      case 1:
        shape = '상체에 비해 하체가 발달한 편';
        break;
      case 2:
        shape = '하체에 비해 상체가 발달한 편';
        break;
      case 3:
        shape = '전체적으로 몸과 팔다리가 가는 편';
        break;
      case 4:
        shape = '다른곳에 비해 허리가 많이 잘록한편';
        break;
      case 5:
        shape = '상하체가 균형있고 평범한 편';
        break;
      default:
        break;
    }

    try {
      const result = await axios.patch(`${API}/users/${userId}`, {
        bodyShape: shape,
      });
      if (result.status === 200) {
        this.setState({
          ...this.state,
          process: 6,
        });
      } else {
        alert('항목에 맞게 선택을 해주세요 :)');
      }
    } catch (e) {
      // alert('조금만 뒤에 다시 시도해주세요:)');
      if (
        e.response &&
        e.response.data &&
        e.response.data.msg &&
        e.response.data.msg === '토큰이 만료되었습니다.'
      ) {
        localStorage.removeItem('token');
        window.location.href = '/';
        return;
      }
    }
  };

  handleShoe = async () => {
    const { userId } = this.state;
    const { shoeSize } = this.state.data;

    try {
      const result = await axios.patch(`${API}/users/${userId}`, {
        shoeSize: Number(shoeSize),
      });
      if (result.status === 200) {
        // this.getStyleTag();
        this.setState({
          ...this.state,
          process: 9,
        });
      } else {
        alert('항목에 맞게 입력을 해주세요 :)');
      }
    } catch (e) {
      // alert('조금만 뒤에 다시 시도해주세요:)');
      if (
        e.response &&
        e.response.data &&
        e.response.data.msg &&
        e.response.data.msg === '토큰이 만료되었습니다.'
      ) {
        localStorage.removeItem('token');
        window.location.href = '/';
        return;
      }
    }
  };

  handleMap = () => {
    const _this = this;
    window.daum.postcode.load(function () {
      new window.daum.Postcode({
        oncomplete: function (result) {
          // 팝업에서 검색결과 항목을 클릭했을때 실행할 코드를 작성하는 부분입니다.
          // 예제를 참고하여 다양한 활용법을 확인해 보세요.
          if (result.userSelectedType === 'R') {
            _this.setState({
              ..._this.state,
              data: {
                ..._this.state.data,
                zonecode: result.zonecode,
                address1: result.roadAddress,
              },
            });
          } else {
            _this.setState({
              ..._this.state,
              data: {
                ..._this.state.data,
                zonecode: result.zonecode,
                address1: result.jibunAddress,
              },
            });
          }
        },
      }).open();
    });
  };

  handleInput = (e) => {
    if (e.target.name === 'email' || e.target.name === 'phone') {
      this.setState({
        ...this.state,
        data: {
          ...this.state.data,
          [e.target.name]: e.target.value.trim(),
        },
        confirm: {
          ...this.state.confirm,
          [e.target.name]: false,
        },
      });
    } else {
      this.setState({
        ...this.state,
        data: {
          ...this.state.data,
          [e.target.name]: e.target.value.trim(),
        },
      });
    }
  };

  handleFinish = () => {
    this.setState({
      ...this.state,
      user: jwt_decode(localStorage.getItem('token')),
      process: 9,
    });
  };

  handleProcess = (process) => {
    const { termsAgree, privacyAgree } = this.state.data;
    if (process === 2) {
      if (!termsAgree) return alert('이용약관을 동의해주시면 감사하겠습니다!');
      if (!privacyAgree) return alert('개인정보 수집 및 이용 동의해주시면 감사하겠습니다!');
    }

    this.setState({
      ...this.state,
      process,
    });
  };

  goHome = () => {
    // this.props.history.push('/');
    window.location.href = '/';
  };

  render() {
    const {
      process,
      data,
      confirm,
      show,
      styletags,
      selectStyle,
      posts,
      follow,
      user,
      terms,
      privacy,
      promotions,
    } = this.state;
    const {
      handleProcess,
      goHome,
      handleInput,
      handleAgree,
      handleMap,
      handleEmailVerify,
      handleEmailCode,
      handleSignup,
      handlePhoneVerify,
      handlePhoneCode,
      handleAddAddress,
      handlePerson,
      handleBodyShape,
      handleShape,
      handleShoe,
      handleStyleTag,
      handleStyle,
      handleFollow,
      handleFinish,
    } = this;
    return (
      <div className="signup-bg">
        {process === 1 && (
          <div className="signup-popup">
            <div className="signup-popup-header">
              <img className="login-logo" src="/images/login_logo.svg" alt="" onClick={goHome} />
              <h2 className="signup-popup-title">이용약관</h2>
              <span className="signup-popup-desc">이미 계정이 있으신가요?</span>
              <button
                className="signup-popup-login"
                onClick={() => (window.location.href = '/signup')}
              >
                로그인
              </button>
            </div>
            <div className="signup-popup-body">
              <div className="signup-popup-item notext">
                <div className="signup-popup-check-bg">
                  <input
                    type="checkbox"
                    className={'signup-popup-check ' + (data.allAgree ? 'checked' : '')}
                    id="cb1"
                    value={data.allAgree}
                    name={'allAgree'}
                    onClick={(e) => handleAgree(e, 'allAgree')}
                  />
                  <label htmlFor="cb1"></label>
                </div>
                <div className="signup-popup-desc-bg">
                  <span className="signup-popup-desc underline">
                    오늘의직구 이용약관, 개인정보 수집 및 이용, 위치정보 이용약관(선택), 프로모션
                    정보 수신(선택)에 모두 동의합니다.
                  </span>
                </div>
              </div>
              <div className="signup-popup-item">
                <div className="signup-popup-item-header">
                  <div className="signup-popup-check-bg">
                    <input
                      type="checkbox"
                      className={'signup-popup-check ' + (data.termsAgree ? 'checked' : '')}
                      id="cb2"
                      name={'termsAgree'}
                      value={data.termsAgree}
                      onClick={(e) => handleAgree(e, 'termsAgree')}
                    />
                    <label htmlFor="cb2"></label>
                  </div>
                  <div className="signup-popup-desc-bg">
                    <span className="signup-popup-desc">오늘의직구 이용약관 동의</span>
                    <span className="singup-point required">(필수)</span>
                  </div>
                </div>
                <div className="signup-popup-item-body">
                  <div className="signup-popup-item-agree">{terms}</div>
                </div>
              </div>
              <div className="signup-popup-item">
                <div className="signup-popup-item-header">
                  <div className="signup-popup-check-bg">
                    <input
                      type="checkbox"
                      className={'signup-popup-check ' + (data.privacyAgree ? 'checked' : '')}
                      id="cb3"
                      name={'privacyAgree'}
                      value={data.privacyAgree}
                      onClick={(e) => handleAgree(e, 'privacyAgree')}
                    />
                    <label htmlFor="cb3"></label>
                  </div>
                  <div className="signup-popup-desc-bg">
                    <span className="signup-popup-desc">개인정보 수집 및 이용 동의</span>
                    <span className="singup-point required">(필수)</span>
                  </div>
                </div>
                <div className="signup-popup-item-body">
                  <div className="signup-popup-item-agree">{privacy}</div>
                </div>
              </div>
              {/* <div className="signup-popup-item">
                <div className="signup-popup-item-header">
                  <div className="signup-popup-check-bg">
                    <input
                      type="checkbox"
                      className={'signup-popup-check ' + (data.locationUsageAgree ? 'checked' : '')}
                      id="cb4"
                      name={'locationUsageAgree'}
                      value={data.locationUsageAgree}
                      onClick={(e) => handleAgree(e, 'locationUsageAgree')}
                    />
                    <label htmlFor="cb4"></label>
                  </div>
                  <div className="signup-popup-desc-bg">
                    <span className="signup-popup-desc">위치정보 이용약관 동의</span>
                    <span className="singup-point">(선택)</span>
                  </div>
                </div>
                <div className="signup-popup-item-body">
                  <div className="signup-popup-item-agree">
                    여러분을 환영합니다. 주며, 구하지 피부가 무엇을 크고 예수는 쓸쓸하랴? 길을
                    보이는 아니한 구할 목숨이 피부가 뿐이다. 별과 따뜻한 붙잡아 불어 길을 충분히
                    황금시대다. 가는 광야에서 끝까지 노래하며 대한 위하여서 관현악이며, 있는 아니다.
                    수 든 구하지 뿐이다. 스며들어 장식하는 시들어 풀밭에 가치를 이성은 남는 미인을
                    약동하다. 같은 전인 기쁘며, 꽃 철환하였는가? 지혜는 대고, 그들은 몸이 생명을
                    가진 것은 교향악이다. 끓는 곧 사랑의 봄바람이다. 위하여 같은 인간이 긴지라
                    우리의 가슴이 광야에서 얼마나 그들의 아니다.
                  </div>
                </div>
              </div> */}
              <div className="signup-popup-item">
                <div className="signup-popup-item-header">
                  <div className="signup-popup-check-bg">
                    <input
                      type="checkbox"
                      className={
                        'signup-popup-check ' + (data.promotionUsageAgree ? 'checked' : '')
                      }
                      id="cb5"
                      name={'promotionUsageAgree'}
                      value={data.promotionUsageAgree}
                      onClick={(e) => handleAgree(e, 'promotionUsageAgree')}
                    />
                    <label htmlFor="cb5"></label>
                  </div>
                  <div className="signup-popup-desc-bg">
                    <span className="signup-popup-desc">프로모션 정보 수신 동의</span>
                    <span className="singup-point">(선택)</span>
                  </div>
                </div>
                <div className="signup-popup-item-body">
                  <div className="signup-popup-item-agree">{promotions}</div>
                </div>
              </div>
            </div>
            <div className="signup-popup-button-bg">
              <button className="signup-popup-button-cancel" onClick={goHome}>
                취소
              </button>
              <button className="signup-popup-button-agree" onClick={() => handleProcess(2)}>
                확인
              </button>
            </div>
          </div>
        )}
        {process === 2 && (
          <div className="signup-popup">
            <div className="signup-popup-header">
              <img className="login-logo" src="/images/login_logo.svg" alt="" onClick={goHome} />
              <h2 className="signup-popup-title">계정 만들기</h2>
              <span className="signup-popup-desc">이미 계정이 있으신가요?</span>
              <button className="signup-popup-login" onClick={() => (window.location.href = '/')}>
                로그인
              </button>
            </div>
            <div className="signup-popup-body">
              <div className="signup-popup-item">
                <div className="popup-input-bg">
                  <label htmlFor="">이메일</label>
                  <div className="signup-popup-item-child">
                    <input
                      className="popup-input"
                      type="text"
                      name="email"
                      onChange={handleInput}
                    />
                    {confirm && confirm.email ? (
                      <button className="popup-button-email">인증완료</button>
                    ) : (
                      <button className="popup-button-email" onClick={handleEmailVerify}>
                        이메일 인증
                      </button>
                    )}
                  </div>
                </div>
                {show && show.email && (
                  <div className="popup-input-bg">
                    <label htmlFor="">이메일 인증코드</label>
                    <div className="signup-popup-item-child">
                      <input
                        className="popup-input"
                        type="text"
                        name="emailCode"
                        onChange={handleInput}
                      />
                      <button className="popup-button-email" onClick={handleEmailCode}>
                        이메일 코드 인증
                      </button>
                    </div>
                  </div>
                )}
              </div>
              <div className="signup-popup-item">
                <div className="popup-input-bg">
                  <label htmlFor="">비밀번호</label>
                  <input
                    className="popup-input"
                    type="password"
                    name="password"
                    placeholder={'소문자 및 숫자를 함께 이용해서 10자리이상 입력해주세요:)'}
                    onChange={handleInput}
                  />
                </div>
              </div>
              <div className="signup-popup-item">
                <div className="popup-input-bg">
                  <label htmlFor="">비밀번호 재확인</label>
                  <input
                    className="popup-input"
                    type="password"
                    name="password2"
                    onChange={handleInput}
                  />
                </div>
              </div>
              <div className="signup-popup-item">
                <div className="popup-input-bg">
                  <label htmlFor="">닉네임</label>
                  <input
                    className="popup-input"
                    type="text"
                    name="nickname"
                    maxLength={6}
                    onChange={handleInput}
                  />
                </div>
              </div>
              <div className="signup-popup-item">
                <div className="popup-input-bg">
                  <label htmlFor="">이름</label>
                  <input className="popup-input" type="text" name="name" onChange={handleInput} />
                </div>
              </div>
              <div className="signup-popup-item">
                <label htmlFor="">생년월일</label>
                <div className="signup-popup-item-child grid-3">
                  <div className="popup-input-bg">
                    <label htmlFor="">년</label>
                    <input className="popup-input" type="text" name="year" onChange={handleInput} />
                  </div>
                  <div className="popup-input-bg">
                    <label htmlFor="">월</label>
                    <input
                      className="popup-input"
                      type="text"
                      name="month"
                      onChange={handleInput}
                    />
                  </div>
                  <div className="popup-input-bg">
                    <label htmlFor="">일</label>
                    <input className="popup-input" type="text" name="day" onChange={handleInput} />
                  </div>
                </div>
              </div>
              <div className="signup-popup-item">
                <div className="popup-input-bg">
                  <label htmlFor="">성별</label>
                  <select name="gender" id="" onChange={handleInput}>
                    <option value="male">남성</option>
                    <option value="female">여성</option>
                  </select>
                </div>
              </div>
              <div className="signup-popup-item phone">
                <div>
                  <label htmlFor="">휴대전화</label>
                </div>
                <div className="signup-popup-item-child">
                  <input className="popup-input" type="text" name="phone1" onChange={handleInput} />
                  <span className="phone-divider"></span>
                  <input className="popup-input" type="text" name="phone2" onChange={handleInput} />
                  <span className="phone-divider"></span>
                  <input className="popup-input" type="text" name="phone3" onChange={handleInput} />
                  <button className="popup-button-certi" onClick={handlePhoneVerify}>
                    인증번호 받기
                  </button>
                </div>
              </div>
              {show && show.phone && (
                <div className="signup-popup-item">
                  <div className="popup-input-bg">
                    <label htmlFor="">인증번호</label>
                    <div className="signup-popup-item-child">
                      <input
                        className="popup-input"
                        type="text"
                        name="phoneCode"
                        onChange={handleInput}
                      />
                      {confirm && confirm.phone ? (
                        <button className="popup-button-email">인증완료</button>
                      ) : (
                        <button className="popup-button-email" onClick={handlePhoneCode}>
                          휴대폰 인증
                        </button>
                      )}
                    </div>
                  </div>
                </div>
              )}
            </div>
            <div className="signup-popup-button-bg">
              <button className="signup-popup-button-cancel" onClick={goHome}>
                취소
              </button>
              <button
                className="signup-popup-button-agree"
                onClick={() => {
                  handleSignup();
                }}
              >
                확인
              </button>
            </div>
          </div>
        )}
        {process === 3 && (
          <div className="signup-popup address">
            <div className="signup-popup-header">
              <img className="login-logo" src="/images/login_logo.svg" alt="" onClick={goHome} />
              <h2 className="signup-popup-title">추가정보 입력하기</h2>
              <span className="signup-popup-desc">주문을 위한 추가정보를 입력해주세요.</span>
            </div>
            <div className="signup-popup-body">
              <div className="signup-popup-item">
                <div className="popup-input-bg">
                  <label htmlFor="">주소</label>
                  <div className="popup-button-address-bg">
                    <input className="popup-input" type="text" value={data.zonecode} />
                    <button className="popup-button-address" onClick={handleMap}>
                      주소검색
                    </button>
                  </div>
                  <input className="popup-input" type="text" value={data.address1} />
                  <input
                    className="popup-input"
                    type="text"
                    name="address2"
                    value={data.address2}
                    onChange={handleInput}
                  />
                </div>
              </div>
              <div className="signup-popup-item">
                <div className="popup-input-bg">
                  <label htmlFor="">개인통관부호</label>
                  <input
                    className="popup-input"
                    type="text"
                    name="pccc"
                    value={data.pccc}
                    onChange={handleInput}
                  />
                </div>
              </div>
              <div className="signup-popup-info-bg">
                <p className="signup-popup-info">다음에 다시 입력하실 수 있습니다.</p>
                <p className="signup-popup-info">건너뛰셔도 됩니다!</p>
              </div>
            </div>
            <div className="signup-popup-button-bg">
              <button
                className="signup-popup-button-cancel"
                onClick={() => this.setState({ ...this.state, process: 9 })}
              >
                건너뛰기
              </button>
              <button className="signup-popup-button-agree" onClick={handleAddAddress}>
                다음
              </button>
            </div>
          </div>
        )}
        {process === 4 && (
          <div className="signup-popup address">
            <div className="signup-popup-header">
              <img className="login-logo" src="/images/login_logo.svg" alt="" onClick={goHome} />
              <h2 className="signup-popup-title">
                고객님의 체형과 비슷한 분들의
                <br />
                다양한 후기를 만나보세요 :)
              </h2>
              <span className="signup-popup-desc">
                솔직한 정보공유를 위해 필요하지만
                <br />
                원치않으시면 비공개로 변경하실 수 있어요!
              </span>
            </div>
            <div className="signup-popup-body">
              <div className="signup-popup-item">
                <div className="signup-popup-item-child grid-2">
                  <div className="popup-input-bg">
                    <label htmlFor="">키</label>
                    <input
                      className="popup-input"
                      type="number"
                      name="height"
                      value={data.height}
                      onChange={handleInput}
                    />
                  </div>
                  <div className="popup-input-bg">
                    <label htmlFor="">신체사이즈</label>
                    {/* XS, S, M, L , XL, XXL */}
                    <select
                      className="popup-input"
                      name="weight"
                      value={data.weight}
                      id=""
                      onChange={handleInput}
                    >
                      <option value="XS">XS</option>
                      <option value="S">S</option>
                      <option value="M">M</option>
                      <option value="L">L</option>
                      <option value="XL">XL</option>
                      <option value="XXL">XXL</option>
                    </select>
                    {/* <input
                      className="popup-input"
                      type="number"
                      name="weight"
                      value={data.weight}
                      onChange={handleInput}
                    /> */}
                  </div>
                </div>
              </div>
            </div>
            <div className="signup-popup-button-bg">
              <button className="signup-popup-button-cancel" onClick={() => handleProcess(3)}>
                이전
              </button>
              <button className="signup-popup-button-agree" onClick={handlePerson}>
                다음
              </button>
            </div>
          </div>
        )}
        {process === 5 && (
          <div className="signup-popup">
            <div className="signup-popup-header">
              <img className="login-logo" src="/images/login_logo.svg" alt="" onClick={goHome} />
              <h2 className="signup-popup-title">
                어떤 체형에
                <br />
                가까우신가요?
              </h2>
            </div>
            <div className="signup-popup-body">
              <div className="shape-button-bg">
                <button className={'shape-button ' + (data.bodyShape === 1 ? 'active' : '')}>
                  <img
                    src="/images/shape_1_default.svg"
                    onClick={() => handleBodyShape(1)}
                    alt=""
                  />
                  <img src="/images/shape_1_active.svg" onClick={() => handleBodyShape(0)} alt="" />
                </button>
                <button className={'shape-button ' + (data.bodyShape === 2 ? 'active' : '')}>
                  <img
                    src="/images/shape_2_default.svg"
                    onClick={() => handleBodyShape(2)}
                    alt=""
                  />
                  <img src="/images/shape_2_active.svg" onClick={() => handleBodyShape(0)} alt="" />
                </button>
                <button className={'shape-button ' + (data.bodyShape === 3 ? 'active' : '')}>
                  <img
                    src="/images/shape_3_default.svg"
                    onClick={() => handleBodyShape(3)}
                    alt=""
                  />
                  <img src="/images/shape_3_active.svg" onClick={() => handleBodyShape(0)} alt="" />
                </button>
                <button className={'shape-button ' + (data.bodyShape === 4 ? 'active' : '')}>
                  <img
                    src="/images/shape_4_default.svg"
                    onClick={() => handleBodyShape(4)}
                    alt=""
                  />
                  <img src="/images/shape_4_active.svg" onClick={() => handleBodyShape(0)} alt="" />
                </button>
                <button className={'shape-button ' + (data.bodyShape === 5 ? 'active' : '')}>
                  <img
                    src="/images/shape_5_default.svg"
                    onClick={() => handleBodyShape(5)}
                    alt=""
                  />
                  <img src="/images/shape_5_active.svg" onClick={() => handleBodyShape(0)} alt="" />
                </button>
                <button className="shape-button" style={{ visibility: 'hidden' }}>
                  <img src="/images/shape_5_default.svg" alt="" />
                </button>
              </div>
            </div>
            <div className="signup-popup-button-bg">
              <button className="signup-popup-button-cancel" onClick={() => handleProcess(4)}>
                이전
              </button>
              <button className="signup-popup-button-agree" onClick={() => handleShape()}>
                다음
              </button>
            </div>
          </div>
        )}
        {process === 6 && (
          <div className="signup-popup address">
            <div className="signup-popup-header">
              <img className="login-logo" src="/images/login_logo.svg" alt="" onClick={goHome} />
              <h2 className="signup-popup-title">
                평소 신는 신발
                <br />
                사이즈를 알려주세요
              </h2>
              <span className="signup-popup-desc">
                같은 사이즈의 신발 포스트를
                <br />
                쉽게 모아볼 수 있어요
              </span>
            </div>
            <div className="signup-popup-body">
              <div className="signup-popup-item">
                <div className="signup-popup-item-child grid-2">
                  <div className="popup-input-bg">
                    <label htmlFor="">신발 사이즈</label>
                    <input
                      className="popup-input"
                      type="number"
                      name="shoeSize"
                      value={data.shoeSize}
                      onChange={handleInput}
                    />
                  </div>
                  <div className="popup-input-bg" style={{ visibility: 'hidden' }}>
                    <label htmlFor=""></label>
                    <input className="popup-input" type="number" />
                  </div>
                </div>
              </div>
            </div>
            <div className="signup-popup-button-bg">
              <button className="signup-popup-button-cancel" onClick={() => handleProcess(5)}>
                이전
              </button>
              <button className="signup-popup-button-agree" onClick={() => handleShoe()}>
                다음
              </button>
            </div>
          </div>
        )}
        {process === 7 && (
          <div className="signup-popup">
            <div className="signup-popup-header">
              <img className="login-logo" src="/images/login_logo.svg" alt="" onClick={goHome} />
              <h2 className="signup-popup-title">
                어떤 스타일
                <br />
                좋아하세요?
              </h2>
            </div>
            <div className="signup-popup-body">
              <div className="style-tag-bg">
                {styletags &&
                  styletags.map((el, i) => {
                    return (
                      <button
                        key={i}
                        className={
                          'style-tag ' + (selectStyle.indexOf(el.value) > -1 ? 'active' : '')
                        }
                        onClick={(e) => handleStyleTag(e, i)}
                      >
                        <p># {el.value}</p>
                      </button>
                    );
                  })}
              </div>
            </div>
            <div className="signup-popup-button-bg">
              <button className="signup-popup-button-cancel" onClick={() => handleProcess(6)}>
                이전
              </button>
              <button className="signup-popup-button-agree" onClick={() => handleStyle()}>
                다음
              </button>
            </div>
          </div>
        )}
        {process === 8 && (
          <div className="signup-popup style">
            <div className="signup-popup-header">
              <img className="login-logo" src="/images/login_logo.svg" alt="" onClick={goHome} />
              <h2 className="signup-popup-title">
                어떤 스타일
                <br />
                좋아하세요?
              </h2>
            </div>
            <div className="signup-popup-body">
              {selectStyle && selectStyle[0] && (
                <div className="signup-popup-follow-bg">
                  <div className="signup-popup-follow-title-bg">
                    <p className="signup-popup-follow-title"># {selectStyle[0]}</p>
                  </div>
                  <div className="signup-popup-follow-item-bg">
                    {posts['0'].length === 0 && (
                      <div className="signup-popup-follow-no-data">게시글이 아직 없네요:{')'}</div>
                    )}
                    {posts['0'] &&
                      posts['0'].map((el, i) => {
                        return (
                          <div className="signup-popup-follow-item" key={i}>
                            {/* <img
                              className="signup-popup-follow-item-img"
                              src={el.productImgUrls[0]}
                              alt=""
                            /> */}
                            <Imagebox link={el.productImgUrls[0]} />
                            <div className="signup-popup-follow-item-desc">
                              <div className="main-intro-content-user-bg">
                                <span
                                  className={
                                    'main-intro-content-user-profile-bg ' + `grade-${el.user.grade}`
                                  }
                                >
                                  {!el.user ? (
                                    <span className={'main-intro-content-user-profile '}></span>
                                  ) : (
                                    <span
                                      className={'main-intro-content-user-profile '}
                                      style={
                                        el.user.profileImgUrl
                                          ? {
                                              backgroundImage: `url(${el.user.profileImgUrl})`,
                                            }
                                          : {}
                                      }
                                    ></span>
                                  )}
                                </span>
                                <span className="main-intro-content-user-name">
                                  {el.user.nickname}
                                </span>
                              </div>
                              <button
                                className={
                                  'signup-popup-follow-item-button ' +
                                  (follow[0][i] ? 'active' : '')
                                }
                                onClick={() => handleFollow(0, i, el.user.id)}
                              >
                                {follow[0][i] ? '팔로잉' : '팔로우'}
                              </button>
                            </div>
                          </div>
                        );
                      })}
                  </div>
                </div>
              )}
              {selectStyle && selectStyle[1] && (
                <div className="signup-popup-follow-bg">
                  <div className="signup-popup-follow-title-bg">
                    <p className="signup-popup-follow-title"># {selectStyle[1]}</p>
                  </div>
                  <div className="signup-popup-follow-item-bg">
                    {posts['1'].length === 0 && (
                      <div className="signup-popup-follow-no-data">게시글이 아직 없네요:{')'}</div>
                    )}
                    {posts['1'] &&
                      posts['1'].map((el, i) => {
                        return (
                          <div className="signup-popup-follow-item" key={i}>
                            <Imagebox link={el.productImgUrls[0]} />
                            {/* <img
                              className="signup-popup-follow-item-img"
                              src={el.productImgUrls[0]}
                              alt=""
                            /> */}
                            <div className="signup-popup-follow-item-desc">
                              <div className="main-intro-content-user-bg">
                                <span
                                  className={
                                    'main-intro-content-user-profile-bg ' + `grade-${el.user.grade}`
                                  }
                                >
                                  {!el.user ? (
                                    <span className={'main-intro-content-user-profile'}></span>
                                  ) : (
                                    <span
                                      className={'main-intro-content-user-profile '}
                                      style={
                                        el.user.profileImgUrl
                                          ? {
                                              backgroundImage: `url(${el.user.profileImgUrl})`,
                                            }
                                          : {}
                                      }
                                    ></span>
                                  )}
                                </span>
                                <span className="main-intro-content-user-name">
                                  {el.user.nickname}
                                </span>
                              </div>
                              <button
                                className={
                                  'signup-popup-follow-item-button ' +
                                  (follow[1][i] ? 'active' : '')
                                }
                              >
                                팔로우
                              </button>
                            </div>
                          </div>
                        );
                      })}
                  </div>
                </div>
              )}
              {selectStyle && selectStyle[2] && (
                <div className="signup-popup-follow-bg">
                  <div className="signup-popup-follow-title-bg">
                    <p className="signup-popup-follow-title"># {selectStyle[2]}</p>
                  </div>
                  <div className="signup-popup-follow-item-bg">
                    {posts['2'].length === 0 && (
                      <div className="signup-popup-follow-no-data">게시글이 아직 없네요:{')'}</div>
                    )}
                    {posts['2'] &&
                      posts['2'].map((el, i) => {
                        return (
                          <div className="signup-popup-follow-item" key={i}>
                            {/* <img
                              className="signup-popup-follow-item-img"
                              src={el.productImgUrls[0]}
                              alt=""
                            /> */}
                            <Imagebox link={el.productImgUrls[0]} />
                            <div className="signup-popup-follow-item-desc">
                              <div className="main-intro-content-user-bg">
                                <span
                                  className={
                                    'main-intro-content-user-profile-bg ' + `grade-${el.user.grade}`
                                  }
                                >
                                  {!el.user ? (
                                    <span className={'main-intro-content-user-profile '}></span>
                                  ) : (
                                    <span
                                      className={'main-intro-content-user-profile '}
                                      style={
                                        el.user.profileImgUrl
                                          ? {
                                              backgroundImage: `url(${el.user.profileImgUrl})`,
                                            }
                                          : {}
                                      }
                                    ></span>
                                  )}
                                </span>
                                <span className="main-intro-content-user-name">
                                  {el.user.nickname}
                                </span>
                              </div>
                              <button
                                className={
                                  'signup-popup-follow-item-button ' +
                                  (follow[2][i] ? 'active' : '')
                                }
                              >
                                팔로우
                              </button>
                            </div>
                          </div>
                        );
                      })}
                  </div>
                </div>
              )}
            </div>
            <div className="signup-popup-button-bg">
              <button className="signup-popup-button-cancel" onClick={() => handleProcess(7)}>
                이전
              </button>
              <button className="signup-popup-button-agree" onClick={() => handleFinish()}>
                다음
              </button>
            </div>
          </div>
        )}
        {process === 9 && (
          <div className="signup-popup">
            <div className="signup-popup-header">
              <img className="login-logo" src="/images/login_logo.svg" alt="" onClick={goHome} />
              <h2 className="signup-popup-title">
                회원가입이 <br className="mo-only" />
                완료되었습니다.
              </h2>
            </div>
            <div className="signup-popup-body">
              <div className="signup-popup-success-bg">
                <div className="signup-popup-success-top">
                  <img src="/images/default-user.svg" alt="" />
                  <p className="signup-popup-success-name">{user.name}</p>
                  <p className="signup-popup-success-grade">신입 오직러</p>
                </div>
                <div className="signup-popup-success-bottom">
                  <p className="signup-popup-success-desc">환영합니다 {user.name} 님 :)</p>
                  <p className="signup-popup-success-desc">오늘의 직구, 지금부터 시작해볼까요?</p>
                </div>
              </div>
            </div>
            <div className="signup-popup-button-bg">
              <button
                className="signup-popup-button-agree"
                onClick={() => (window.location.href = '/')}
              >
                시작하기
              </button>
            </div>
          </div>
        )}
      </div>
    );
  }
}

export default withRouter(Signup);
