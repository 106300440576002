import React, { Component } from 'react';
import GetItem from 'components/GetItem';
import GetPopup from 'components/GetPopup';
import axios from 'axios';
import { API } from 'config';
import './MyWishGet.css';

class MyWishGet extends Component {
  // constructor(props) {
  //   super(props);
  //   this.state = {
  //     isOpen: false,
  //     offset: 0,
  //     limit: 2,
  //   };
  // }

  // componentDidMount() {
  //   this.getWishLink();
  // }

  // getWishLink = async () => {
  //   try {
  //     const result = await axios.get(`${API}/users/dib/links`, {
  //       params: {
  //         limit: this.state.limit,
  //         offset: this.state.offset,
  //       },
  //       headers: {
  //         token: `${localStorage.getItem('token')}`,
  //       },
  //     });
  //   } catch (e) { }
  // };

  // handlePopup = () => {
  //   this.setState({
  //     ...this.state,
  //     isOpen: true,
  //   });
  // };

  // closePopup = () => {
  //   this.setState({
  //     ...this.state,
  //     isOpen: false,
  //   });
  // };

  render() {
    // const { handlePopup, closePopup } = this;
    // const { isOpen } = this.state;
    const { myWishLinkData, like, dib, handleLike, handleDib, currency, handlePopup } = this.props;

    return (
      <>
        <div className="mywish-get-bg">
          {myWishLinkData &&
            myWishLinkData.map((el, i) => {
              return (
                <GetItem
                  key={i}
                  data={el}
                  like={like}
                  dib={dib}
                  handleLike={(e) => handleLike(e, 'links', el.id, 'wishLink')}
                  handleDib={(e) => handleDib(e, 'links', el.id, 'wishLink')}
                  handlePopup={() => handlePopup('wishlink', el)}
                  currency={currency}
                />
              );
            })}
          {/* <GetItem handlePopup={handlePopup}></GetItem>
          <GetItem handlePopup={handlePopup}></GetItem>
          <GetItem handlePopup={handlePopup}></GetItem>
          <GetItem handlePopup={handlePopup}></GetItem>
          <GetItem handlePopup={handlePopup}></GetItem>
          <GetItem handlePopup={handlePopup}></GetItem>
          <GetItem handlePopup={handlePopup}></GetItem>
          <GetItem handlePopup={handlePopup}></GetItem>
          <GetItem handlePopup={handlePopup}></GetItem>
          <GetItem handlePopup={handlePopup}></GetItem> */}
        </div>
        {/* <GetPopup isOpen={isOpen} closePopup={closePopup} /> */}
      </>
    )
  }
}

export default MyWishGet;
