import React from 'react';
import MainStructure from 'components/structure/MainStructure';
import EventContainer from 'containers/EventContainer';

const Event = () => {
  return (
    <MainStructure>
      <EventContainer />
    </MainStructure>
  );
};

export default Event;
