import React, { Component } from 'react';
import TalkWriting from 'components/TalkWriting';

class TalkWritingContainer extends Component {
  render() {
    return <TalkWriting />;
  }
}

export default TalkWritingContainer;
