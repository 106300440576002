import React, { Component } from 'react';
import MyPage from 'components/mypage/MyPage';

class MyPageContainer extends Component {
  render() {
    return <MyPage />;
  }
}

export default MyPageContainer;
