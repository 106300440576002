import React, { Component } from 'react';
import Search from 'components/Search';

class SearchContainer extends Component {
  render() {
    return <Search />;
  }
}

export default SearchContainer;
