import React, { Component } from 'react';
import OtherPage from 'components/otherpage/OtherPage';

class OtherPageContainer extends Component {
  render() {
    return <OtherPage />;
  }
}

export default OtherPageContainer;
