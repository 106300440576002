import React from 'react';
import MainStructure from 'components/structure/MainStructure';
import ReviewWritingContainer from 'containers/ReviewWritingContainer';

const ReviewWriting = () => {
  return (
    <MainStructure>
      <ReviewWritingContainer />
    </MainStructure>
  );
};

export default ReviewWriting;
