import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import './EventPopup.css';
import { gradeName, abbreviateNumber, numberWithCommas, compareTime } from 'lib/common';
import Declaration from 'components/common/Declaration';
import Carousel from 'components/common/Carousel';
import Profile from 'components/common/Profile';
import axios from 'axios';
import { API } from 'config';

class EventPopup extends Component {
  state = {
    slider: [],
    comments: [],
    childComments: [],
    content: '',
    childContents: [],
    declation: {
      isClicked: false,
      type: '',
      id: 0,
      title: '',
      content: '',
    },
    commentLike: [],
  };

  componentDidMount() {
    this.getSlider();
    this.getCommnets(this.props.popupData.id);
  }

  componentWillReceiveProps(newProps) {
    if (newProps.popupData && newProps.popupData.id && newProps.popupData.id !== this.props.popupData.id) {
      this.getCommnets(newProps.popupData.id);
    }
  }

  handleCommentLike = async (e, id) => {
    e.stopPropagation();

    if (!localStorage.getItem('token')) return alert('로그인이 필요합니다:)');
    try {
      const result = await axios.post(
        `${API}/comments/${id}/like`,
        {},
        {
          headers: {
            token: `${localStorage.getItem('token')}`,
          },
        },
      );

      var commentLike = [...this.state.commentLike];

      if (result.status === 201) {
        commentLike.push(id);
        this.setState({
          ...this.state,
          commentLike,
        });
      } else if (result.status === 204) {
        commentLike = commentLike.filter((el) => Number(el) !== Number(id));
        this.setState({
          ...this.state,
          commentLike,
        });
      }
    } catch (e) {
      if (e.response && e.response.data && e.response.data.msg && e.response.data.msg === '토큰이 만료되었습니다.') {
        localStorage.removeItem('token');
        window.location.href = "/";
        return;
      }
      // alert('조금만 뒤에 다시 시도해주세요:)');
    }
    // /{reviews}/{id}/like
  };

  openDeclation = (type, id) => {
    this.setState({
      ...this.state,
      declation: {
        isClicked: true,
        type,
        id,
      },
    });
  };

  handleDeclation = (e) => {
    this.setState({
      ...this.state,
      declation: {
        ...this.state.declation,
        [e.target.name]: e.target.value,
      },
    });
  };

  submitDeclation = async () => {
    const { title, content, type, id } = this.state.declation;

    try {
      if (type === 'events') {
        const result = await axios.post(
          `${API}/reports`,
          {
            title,
            content,
            reviewId: id,
          },
          {
            headers: {
              token: `${localStorage.getItem('token')}`,
            },
          },
        );
        if (result.status === 200) {
          this.setState({
            ...this.state,
            declation: {
              isClicked: false,
              type: '',
              id: 0,
              title: '',
              conent: '',
            },
          });
          alert('신고하기 성공 :)');
        }
      } else {
        const result = await axios.post(
          `${API}/reports`,
          {
            title,
            content,
            commentId: id,
          },
          {
            headers: {
              token: `${localStorage.getItem('token')}`,
            },
          },
        );
        if (result.status === 200) {
          this.setState({
            ...this.state,
            declation: {
              isClicked: false,
              type: '',
              id: 0,
              title: '',
              conent: '',
            },
          });
          alert('신고하기 성공 :)');
        }
      }
    } catch (e) {
      if (e.response && e.response.data && e.response.data.msg && e.response.data.msg === '토큰이 만료되었습니다.') {
        localStorage.removeItem('token');
        window.location.href = "/";
        return;
      }
    }
  };

  closeDeclation = () => {
    this.setState({
      ...this.state,
      declation: {
        isClicked: false,
        type: '',
        id: 0,
        title: '',
        conent: '',
      },
    });
  };

  getSlider = async () => {
    const { id } = this.props.popupData.user;
    try {
      const result = await axios.get(`${API}/talks/?userId=${id}&limit=6`);
      this.setState({
        ...this.state,
        slider: [...result.data[0]],
      });
    } catch (e) {
      if (e.response && e.response.data && e.response.data.msg && e.response.data.msg === '토큰이 만료되었습니다.') {
        localStorage.removeItem('token');
        window.location.href = "/";
        return;
      }
    }
  };

  getCommnets = async (id) => {
    if (id) {
      // const { id } = this.props.popupData;
      try {
        const result = await axios.get(`${API}/talks/${id}/comments`, {
          headers: {
            token: `${localStorage.getItem('token')}`,
          },
        });
        let childComments = [];
        let childContents = [];

        let commentLike = [];

        result.data.map((el) => {
          childComments.push(false);
          childContents.push('');
          if (el.liked) {
            commentLike.push(el.id);
          }
        });

        this.setState({
          ...this.state,
          comments: [...result.data],
          childComments,
          childContents,
          commentLike,
        });
      } catch (e) {
        if (e.response && e.response.data && e.response.data.msg && e.response.data.msg === '토큰이 만료되었습니다.') {
          localStorage.removeItem('token');
          window.location.href = "/";
          return;
        }
      }
    }
  };

  deleteComment = async (id) => {
    if (window.confirm('정말 삭제하시겠습니까?')) {
      try {
        const result = await axios.delete(`${API}/comments/${id}`, {
          headers: {
            token: `${localStorage.getItem('token')}`,
          },
        });
      } catch (e) {
        if (e.response && e.response.data && e.response.data.msg && e.response.data.msg === '토큰이 만료되었습니다.') {
          localStorage.removeItem('token');
          window.location.href = "/";
          return;
        }
        // alert('조금만 뒤에 다시 시도해주세요:)');
      }
      this.getCommnets(this.props.popupData.id);
    }
  };

  handleChildComment = (i) => {
    let child = [...this.state.childComments];
    child[i] = !child[i];
    this.setState({
      ...this.state,
      childComments: child,
    });
  };

  handleChange = (e) => {
    this.setState({
      ...this.state,
      [e.target.name]: e.target.value,
    });
  };

  handleChildConent = (e, i) => {
    const contents = [...this.state.childContents];
    contents[i] = e.target.value;
    this.setState({
      ...this.state,
      childContents: contents,
    });
  };

  handleComment = async (type, id, i) => {
    const { content, childContents, childComments } = this.state;

    const token = localStorage.getItem('token');
    if (!token) return alert('로그인을 먼저 해주세요!');
    // content,
    // reviewId

    // commentId

    // talkId
    // linkId
    try {
      if (type === 'event') {
        if (!content) return alert('내용을 입력해주세요!');
        const result = await axios.post(
          `${API}/comments`,
          {
            content: content.trim(),
            talkId: id,
          },
          {
            headers: {
              token: `${localStorage.getItem('token')}`,
            },
          },
        );
        this.setState({
          ...this.state,
          content: '',
        });
      } else {
        if (!childContents[i]) return alert('내용을 입력해주세요!');
        const result = await axios.post(
          `${API}/comments`,
          {
            content: childContents[i].trim(),
            commentId: id,
          },
          {
            headers: {
              token: `${localStorage.getItem('token')}`,
            },
          },
        );
        let contents = [...childContents];
        contents[i] = '';
        let comments = [...childComments];
        comments[i] = false;

        this.setState({
          ...this.state,
          childContents: contents,
          childComments: comments,
        });
      }

      this.getCommnets(this.props.popupData.id);
    } catch (e) {
      if (e.response && e.response.data && e.response.data.msg && e.response.data.msg === '토큰이 만료되었습니다.') {
        localStorage.removeItem('token');
        window.location.href = "/";
        return;
      }
    }
  };

  render() {
    const {
      handleChange,
      handleComment,
      handleChildComment,
      handleChildConent,
      deleteComment,
      handleCommentLike,
      closeDeclation,
      handleDeclation,
      submitDeclation,
      openDeclation,
    } = this;
    const {
      slider,
      content,
      comments,
      childComments,
      childContents,
      commentLike,
      declation,
    } = this.state;
    const { isOpen, closePopup, popupData, user, like, handleLike } = this.props;

    if (!popupData) return <> </>;
    if (!popupData.user) return <> </>;

    return (
      <>
        <div className={'popup-bg event-popup ' + (isOpen ? ' show' : '')}>
          <div className="popup">
            <div className="popup-functions">
              <button className="popup-close" onClick={closePopup}></button>
            </div>
            <div className="popup-top-bg">
              <div className="popup-product-img-bg">
                <div className="popup-product-img">
                  <Carousel images={popupData.productImgUrls} />
                  {/* <span
                  className="img"
                  style={{ backgroundImage: `url(${popupData.productImgUrls[0]})` }}
                ></span>
                <span className="popup-img-prev-button"></span>
                <span className="popup-img-next-button"></span> */}
                </div>
              </div>
              <div className="popup-content-bg">
                <div className="main-intro-content-review-box">
                  <div className="main-intro-content-review-box-top">
                    {/* <Profile user={user} data={popupData} /> */}
                    <div
                      className="main-intro-content-user-bg"
                      onClick={() => {
                        if (user.id !== popupData.user.id) {
                          window.location.href = `/otherpage/${popupData.user.id}`;
                          // this.props.history.push(`/otherpage/${popupData.user.id}`)
                        }
                      }}
                    >
                      <span
                        className={
                          'main-intro-content-user-profile-bg ' + `grade-${popupData.user.grade}`
                        }
                      >
                        <span
                          className={'main-intro-content-user-profile '}
                          style={popupData.user.profileImgUrl ? { backgroundImage: `url(${popupData.user.profileImgUrl})` } : {}}
                        ></span>
                      </span>
                      <span className="main-intro-content-user-name">
                        {popupData.user.nickname}
                      </span>
                      <span
                        className={
                          'main-intro-content-user-grade ' + `grade-${popupData.user.grade}`
                        }
                      >
                        {gradeName(popupData.user.grade)}
                      </span>
                    </div>
                    <div className="main-intro-content-review-info-bg">
                      <span
                        className={
                          'main-intro-content-info-like ' +
                          (like && like.indexOf(popupData.id) > -1 ? 'active' : '')
                        }
                      ></span>
                      <span className="main-intro-content-info-number">
                        {popupData.liked && like && like.indexOf(popupData.id) > -1
                          ? abbreviateNumber(parseInt(popupData.likeCount), 0)
                          : !popupData.liked && like && like.indexOf(popupData.id) > -1
                            ? abbreviateNumber(parseInt(popupData.likeCount + 1), 0)
                            : !popupData.liked && like && like.indexOf(popupData.id) === -1
                              ? abbreviateNumber(parseInt(popupData.likeCount), 0)
                              : abbreviateNumber(parseInt(popupData.likeCount - 1), 0)}
                      </span>
                      {/* <span className="main-intro-content-info-heart active"></span>
                    <span className="main-intro-content-info-number">1.7k</span> */}
                    </div>
                  </div>
                </div>
                <div className="popup-title-bg">
                  <h2 className="popup-title">{popupData.title}</h2>
                  <p
                    className="popup-desc"
                    dangerouslySetInnerHTML={{ __html: popupData.content }}
                  ></p>
                </div>
                <div className="popup-button-bg">
                  <button
                    className="popup-button"
                    onClick={(e) => handleLike(e, 'events', popupData.id)}
                  >
                    <span className="popup-button-like"></span>
                    <p className="popup-button-desc">좋아요</p>
                  </button>
                </div>
              </div>
            </div>
            <div className="popup-bottom-bg">
              <div className="popup-bottom-container">
                <div className="popup-comment-header-bg">
                  <div className="popup-comment-header">
                    <span>댓글 {comments.length} 건</span>
                  </div>
                  {user && user.grade && (
                    <div className="popup-comment-input-bg">
                      <div className="popup-comment-input">
                        {/* <Profile user={user} showGrade={false} showNick={false} /> */}
                        <span
                          className={'main-intro-content-user-profile-bg ' + `grade-${user.grade}`}
                        // onClick={() => { this.props.history.push(`/otherpage/${user.id}`) }}
                        >
                          <span
                            className={'main-intro-content-user-profile '}
                            style={user.profileImgUrl ? { backgroundImage: `url(${user.profileImgUrl})` } : {}}
                          ></span>
                        </span>
                        <textarea
                          name="content"
                          id=""
                          cols="30"
                          rows="10"
                          placeholder="댓글을 입력해주세요."
                          value={content}
                          onChange={handleChange}
                        ></textarea>
                      </div>
                      <div className="popup-comment-button-bg">
                        <button
                          className="popup-comment-button"
                          onClick={() => handleComment('event', popupData.id)}
                        >
                          댓글쓰기
                        </button>
                      </div>
                    </div>
                  )}
                </div>
                {comments.length !== 0 && (
                  <div className="popup-comment-content-bg">
                    {comments &&
                      comments.map((el, i) => {
                        return (
                          <div key={i}>
                            <div className="popup-comment-content-item">
                              <div className="popup-comment-content-item-title">
                                {/* <Profile user={user} data={el} /> */}
                                <div
                                  className="popup-comment-content-item-user-bg"
                                  onClick={() => {
                                    if (user.id !== el.user.id) {
                                      window.location.href = `/otherpage/${el.user.id}`;
                                      // this.props.history.push(`/otherpage/${el.user.id}`)
                                    }
                                  }
                                  }
                                >
                                  <span
                                    className={
                                      'main-intro-content-user-profile-bg ' +
                                      `grade-${el.user.grade}`
                                    }
                                  >
                                    <span
                                      className={'main-intro-content-user-profile '}
                                      style={el.user.profileImgUrl ? { backgroundImage: `url(${el.user.profileImgUrl})` } : {}}
                                    ></span>
                                  </span>
                                  <span className="popup-comment-content-item-user-name">
                                    {el.user.nickname}
                                  </span>
                                  <span className="popup-comment-content-item-user-grade">
                                    {gradeName(el.user.grade)}
                                  </span>
                                </div>
                                <div className="popup-comment-content-item-time-bg">
                                  <p className="popup-comment-content-item-time">
                                    {compareTime(el.createdAt)}
                                  </p>
                                </div>
                              </div>
                              <div className="popup-comment-content-item-body">
                                <div className="popup-comment-content-item-desc-bg">
                                  <p className="popup-comment-content-item-desc">{el.content}</p>
                                </div>
                                <div className="popup-comment-content-item-func-bg">
                                  {user.id === el.user.id && (
                                    <button
                                      className="popup-comment-content-item-delete"
                                      onClick={() => deleteComment(el.id)}
                                    >
                                      삭제하기
                                    </button>
                                  )}
                                  <button
                                    className="popup-comment-content-item-alert"
                                    onClick={() => openDeclation('comment', el.id)}
                                  >
                                    신고하기
                                  </button>
                                  <button
                                    className="popup-comment-content-item-comment"
                                    onClick={() => handleChildComment(i)}
                                  >
                                    {childComments[i] ? '답글닫기' : '답글달기'}
                                  </button>
                                  <button className="popup-comment-content-item-like">
                                    <span
                                      className={
                                        'main-intro-content-info-like ' +
                                        (commentLike && commentLike.indexOf(el.id) > -1
                                          ? 'active'
                                          : '')
                                      }
                                      onClick={(e) => handleCommentLike(e, el.id)}
                                    ></span>
                                    <span
                                      className="main-intro-content-info-number"
                                      onClick={(e) => handleCommentLike(e, el.id)}
                                    >
                                      {el.liked && commentLike && commentLike.indexOf(el.id) > -1
                                        ? abbreviateNumber(parseInt(el.likeCount), 0)
                                        : !el.liked &&
                                          commentLike &&
                                          commentLike.indexOf(el.id) > -1
                                          ? abbreviateNumber(parseInt(el.likeCount + 1), 0)
                                          : !el.liked &&
                                            commentLike &&
                                            commentLike.indexOf(el.id) === -1
                                            ? abbreviateNumber(parseInt(el.likeCount), 0)
                                            : abbreviateNumber(parseInt(el.likeCount - 1), 0)}
                                    </span>
                                  </button>
                                </div>
                              </div>
                            </div>
                            {el.childComments &&
                              el.childComments.map((child, childIndex) => {
                                if (child.isDeleted) return;
                                return (
                                  <div className="popup-comment-content-item" key={childIndex}>
                                    <div className="child-comment">
                                      <img src="/images/child_comment.svg" alt="" />
                                    </div>
                                    <div className="popup-comment-content-item-title">
                                      {/* <Profile user={user} data={child} showGrade={false} /> */}
                                      <div
                                        className="popup-comment-content-item-user-bg"
                                        onClick={() => {
                                          if (user.id !== child.user.id) {
                                            // this.props.history.push(`/otherpage/${child.user.id}`)
                                            window.location.href = `/otherpage/${child.user.id}`;
                                          }
                                        }
                                        }
                                      >
                                        <span
                                          className={
                                            'main-intro-content-user-profile-bg ' +
                                            `grade-${child.user.grade}`
                                          }
                                        >
                                          <span
                                            className={'main-intro-content-user-profile '}
                                            style={child.user.profileImgUrl ? {
                                              backgroundImage: `url(${child.user.profileImgUrl})`,
                                            } : {}}
                                          ></span>
                                        </span>
                                        <span className="popup-comment-content-item-user-name">
                                          {child.userNickname}
                                        </span>
                                        <span className="popup-comment-content-item-user-grade"></span>
                                      </div>
                                      <div className="popup-comment-content-item-time-bg">
                                        <p className="popup-comment-content-item-time">
                                          {compareTime(child.createdAt)}
                                        </p>
                                      </div>
                                    </div>
                                    <div className="popup-comment-content-item-body">
                                      <div className="popup-comment-content-item-desc-bg">
                                        <p className="popup-comment-content-item-desc">
                                          {child.content}
                                        </p>
                                      </div>
                                      <div className="popup-comment-content-item-func-bg">
                                        {user.id === child.user.id && (
                                          <button
                                            className="popup-comment-content-item-delete"
                                            onClick={() => deleteComment(child.id)}
                                          >
                                            삭제하기
                                          </button>
                                        )}
                                        <button
                                          className="popup-comment-content-item-alert"
                                          onClick={() => openDeclation('comment', child.id)}
                                        >
                                          신고하기
                                        </button>
                                      </div>
                                    </div>
                                  </div>
                                );
                              })}
                            {childComments[i] && (
                              <div className="popup-comment-input-bg">
                                <div className="popup-comment-input">
                                  <div className="child-comment">
                                    <img src="/images/child_comment.svg" alt="" />
                                  </div>
                                  {/* <Profile user={user} showGrade={false} showNick={false} /> */}
                                  <span
                                    className={
                                      'main-intro-content-user-profile-bg ' + `grade-${user.grade}`
                                    }
                                  >
                                    <span
                                      className={'main-intro-content-user-profile '}
                                      style={user.profileImgUrl ? { backgroundImage: `url(${user.profileImgUrl})` } : {}}
                                    ></span>
                                  </span>
                                  <textarea
                                    name="content"
                                    id=""
                                    cols="30"
                                    rows="10"
                                    placeholder="댓글을 입력해주세요."
                                    value={childContents[i]}
                                    onChange={(e) => handleChildConent(e, i)}
                                  ></textarea>
                                </div>
                                <div className="popup-comment-button-bg">
                                  <button
                                    className="popup-comment-button"
                                    onClick={() => handleComment('comment', el.id, i)}
                                  >
                                    답글쓰기
                                  </button>
                                </div>
                              </div>
                            )}
                          </div>
                        );
                      })}
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
        {declation.isClicked && (
          <Declaration
            data={declation}
            handleClose={closeDeclation}
            handleChange={handleDeclation}
            handleSubmit={submitDeclation}
          />
        )}
      </>
    );
  }
}

export default withRouter(EventPopup);
