import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import GetItem from 'components/GetItem';
import GetPopup from 'components/GetPopup';
import './MyArticleGet.css';

class MyArticleGet extends Component {
  // constructor(props) {
  //   super(props);
  //   this.state = {
  //     isOpen: false,
  //   };
  // }

  // handlePopup = () => {
  //   this.setState({
  //     ...this.state,
  //     isOpen: true,
  //   });
  // };

  // closePopup = () => {
  //   this.setState({
  //     ...this.state,
  //     isOpen: false,
  //   });
  // };

  render() {
    // const { handlePopup, closePopup } = this;
    // const { isOpen } = this.state;
    const { myLinkData, like, dib, handleLike, handleDib, currency, handlePopup } = this.props;

    return (
      <>
        <div className="myarticle-get-bg">
          {myLinkData &&
            myLinkData.map((el, i) => {
              return (
                <GetItem
                  key={i}
                  data={el}
                  like={like}
                  dib={dib}
                  handleLike={(e) => handleLike(e, 'links', el.id)}
                  handleDib={(e) => handleDib(e, 'links', el.id)}
                  handlePopup={() => handlePopup('get', el)}
                  currency={currency}
                ></GetItem>
              );
            })}

          {/* <GetItem handlePopup={handlePopup}></GetItem>
          <GetItem handlePopup={handlePopup}></GetItem>
          <GetItem handlePopup={handlePopup}></GetItem>
          <GetItem handlePopup={handlePopup}></GetItem>
          <GetItem handlePopup={handlePopup}></GetItem>
          <GetItem handlePopup={handlePopup}></GetItem>
          <GetItem handlePopup={handlePopup}></GetItem>
          <GetItem handlePopup={handlePopup}></GetItem>
          <GetItem handlePopup={handlePopup}></GetItem> */}
        </div>
        {/* <GetPopup isOpen={isOpen} closePopup={closePopup} /> */}
      </>
    );
  }
}

export default withRouter(MyArticleGet);
