import React, { Component } from 'react';
import Footer from 'components/structure/Footer';

class FooterContainer extends Component {
  render() {
    return <Footer />;
  }
}

export default FooterContainer;
