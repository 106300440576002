import React, { Component } from 'react';
import ReviewItem from 'components/ReviewItem';
import GetItem from 'components/GetItem';
import TalkItem from 'components/TalkItem';
import EventItem from 'components/event/EventItem';
import { withRouter } from 'react-router-dom';
import ReviewPopup from 'components/ReviewPopup';
import TalkPopup from 'components/TalkPopup';
import GetPopup from 'components/GetPopup';
import EventPopup from 'components/event/EventPopup';
import axios from 'axios';
import jwt_decode from 'jwt-decode';
import { API } from 'config';
import './Main.css';

var like = {
  review: [],
  talk: [],
  get: [],
  event: [],
};

var dib = {
  review: [],
  get: [],
};

class Main extends Component {
  state = {
    brands: [],
    events: [],
    links: [],
    reviews: [],
    store: [],
    talks: [],
    currency: {},
    catchPhrase: {},
    like: {
      review: [],
      get: [],
      talk: [],
      event: [],
    },
    dib: {
      review: [],
      get: [],
      talk: [],
    },
    popupData: {},
    popupOpen: {
      review: false,
      get: false,
      talk: false,
      event: false,
    },
    user: {},
    data: {},
  };

  brandRef = React.createRef();
  storeRef = React.createRef();

  handleScroll = (type, move) => {
    if (type === 'brand' && move === 'prev') {
      this.brandRef.current.scrollLeft -= 160;
    } else if (type === 'brand' && move === 'next') {
      this.brandRef.current.scrollLeft += 160;
    } else if (type === 'store' && move === 'prev') {
      this.storeRef.current.scrollLeft -= 160;
    } else {
      this.storeRef.current.scrollLeft += 160;
    }
  };

  componentWillMount() {
    if (localStorage.getItem('token')) {
      this.getUser();
    }
  }

  componentDidMount() {
    localStorage.getItem('token') && this.getUserData();
    this.getCatch();
    this.handleMain();
    this.getCurrency();
  }

  getUser = () => {
    try {
      const user = jwt_decode(localStorage.getItem('token'));
      if (user) {
        this.setState({
          ...this.state,
          user: {
            id: user.id,
          },
        });
      } else {
        localStorage.removeItem('token');
      }
    } catch (e) {
      localStorage.removeItem('token');
      window.location.href = '/';
    }
  };

  getUserData = async () => {
    const user = jwt_decode(localStorage.getItem('token'));
    if (user) {
      try {
        const result = await axios.get(`${API}/users/my`, {
          headers: {
            token: `${localStorage.getItem('token')}`,
          },
        });

        const newToken = result.headers['new-token'] || '';
        if (newToken) {
          axios.defaults.headers.token = newToken;
          localStorage.setItem('token', newToken);
        }

        if (result && result.status === 200) {
          this.setState({
            ...this.state,
            user: result.data,
            data: result.data,
          });
        } else {
          localStorage.removeItem('token');
          window.location.reload();
        }
      } catch (e) {
        localStorage.removeItem('token');
        window.location.reload();
      }
    } else {
      localStorage.removeItem('token');
      window.location.href = '/';
    }
  };

  handleLike = async (e, type, id) => {
    e.stopPropagation();
    const { review, get, talk, event } = this.state.like;
    if (!localStorage.getItem('token')) return alert('로그인이 필요합니다:)');
    try {
      let t = type === 'events' ? 'talks' : type;

      const result = await axios.post(
        `${API}/${t}/${id}/like`,
        {},
        {
          headers: {
            token: `${localStorage.getItem('token')}`,
          },
        },
      );

      let rtype = '';
      if (type === 'reviews') {
        rtype = 'review';
      } else if (type === 'links') {
        rtype = 'get';
      } else if (type === 'talks') {
        rtype = 'talk';
      } else if (type === 'events') {
        rtype = 'event';
      }

      var rlike = [...this.state.like[rtype]];
      if (result.status === 201) {
        rlike.push(id);
        this.setState({
          ...this.state,
          like: {
            ...this.state.like,
            [rtype]: rlike,
          },
        });
      } else if (result.status === 204) {
        rlike = rlike.filter((el) => Number(el) !== Number(id));
        this.setState({
          ...this.state,
          like: {
            ...this.state.like,
            [rtype]: rlike,
          },
        });
      }
    } catch (e) {
      // alert('조금만 뒤에 다시 시도해주세요:)');
      if (
        e.response &&
        e.response.data &&
        e.response.data.msg &&
        e.response.data.msg === '토큰이 만료되었습니다.'
      ) {
        localStorage.removeItem('token');
        window.location.href = '/';
        return;
      }
    }
    // /{reviews}/{id}/like
  };

  // /{reviews}/{id}/dib
  handleDib = async (e, type, id) => {
    e.stopPropagation();
    if (!localStorage.getItem('token')) return alert('로그인이 필요합니다:)');
    try {
      const result = await axios.post(
        `${API}/${type}/${id}/dib`,
        {},
        {
          headers: {
            token: `${localStorage.getItem('token')}`,
          },
        },
      );

      let rtype = '';
      if (type === 'reviews') {
        rtype = 'review';
      } else if (type === 'links') {
        rtype = 'get';
      } else if (type === 'talks') {
        rtype = 'talk';
      }

      var rdib = [...this.state.dib[rtype]];

      if (result.status === 201) {
        rdib.push(id);
        this.setState({
          ...this.state,
          dib: {
            ...this.state.dib,
            [rtype]: rdib,
          },
        });
      } else if (result.status === 204) {
        rdib = rdib.filter((el) => Number(el) !== Number(id));
        this.setState({
          ...this.state,
          dib: {
            ...this.state.dib,
            [rtype]: rdib,
          },
        });
      }
    } catch (e) {
      if (
        e.response &&
        e.response.data &&
        e.response.data.msg &&
        e.response.data.msg === '토큰이 만료되었습니다.'
      ) {
        localStorage.removeItem('token');
        window.location.href = '/';
        return;
      }
    }
  };

  handleClick = (e, type, value) => {
    e.stopPropagation();
    window.location.href = `/search?${type}=${value}`;
  };

  // /extra/catch-phrase
  getCatch = async () => {
    try {
      const result = await axios.get(`${API}/extra/catch-phrase`);
      const catchPhrase = { ...result.data.data };
      this.setState({
        ...this.state,
        catchPhrase,
      });
    } catch (e) {
      if (
        e.response &&
        e.response.data &&
        e.response.data.msg &&
        e.response.data.msg === '토큰이 만료되었습니다.'
      ) {
        localStorage.removeItem('token');
        window.location.href = '/';
        return;
      }
    }
  };

  getCurrency = async () => {
    try {
      let currency = {};
      const result = await axios.get(`${API}/extra/exchange-rate`);
      result.data.map((el) => {
        currency[el.currency] = el.amount;
      });
      this.setState({
        ...this.state,
        currency,
      });
    } catch (e) {
      if (
        e.response &&
        e.response.data &&
        e.response.data.msg &&
        e.response.data.msg === '토큰이 만료되었습니다.'
      ) {
        localStorage.removeItem('token');
        window.location.href = '/';
        return;
      }
    }
  };

  // /extra/main-page
  handleMain = async () => {
    try {
      const result = await axios.get(`${API}/extra/main-page`, {
        headers: {
          token: `${localStorage.getItem('token')}`,
        },
      });

      var like = {
        review: [],
        get: [],
        talk: [],
        event: [],
      };

      var dib = {
        review: [],
        get: [],
        talk: [],
        event: [],
      };

      result.data.reviews.map((el) => {
        if (el.liked) {
          like['review'].push(el.id);
        }

        if (el.dib) {
          dib['review'].push(el.id);
        }
      });

      result.data.links.map((el) => {
        if (el.liked) {
          like['get'].push(el.id);
        }

        if (el.dib) {
          dib['get'].push(el.id);
        }
      });

      result.data.talks.map((el) => {
        if (el.liked) {
          like['talk'].push(el.id);
        }

        if (el.dib) {
          dib['talk'].push(el.id);
        }
      });

      result.data.events.map((el) => {
        if (el.liked) {
          like['event'].push(el.id);
        }

        if (el.dib) {
          dib['event'].push(el.id);
        }
      });

      this.setState({
        ...this.state,
        brands: result.data.brands,
        store: result.data.store,
        reviews: result.data.reviews,
        links: result.data.links,
        talks: result.data.talks,
        events: result.data.events,
        like,
        dib,
      });
    } catch (e) {
      if (
        e.response &&
        e.response.data &&
        e.response.data.msg &&
        e.response.data.msg === '토큰이 만료되었습니다.'
      ) {
        localStorage.removeItem('token');
        window.location.href = '/';
        return;
      }
    }
  };

  // handlePopup = (type, id) => {
  //   return this.props.history.push(`/${type}?id=${id}`);
  // };

  // handlePopup = (type, data) => {

  //   console.log("handlePopup type ", type)
  //   console.log("handlePopup data ", data)

  //   this.setState({
  //     ...this.state,
  //     popupOpen: {
  //       ...this.state.popupOpen,
  //       [type]: true,
  //     },
  //     popupData: data,
  //   });
  //   window.history.pushState(
  //     null,
  //     null,
  //     `/${type}?id=${data.id}`,
  //   );
  // };

  handlePopup = (id, type) => {
    const { events, links, reviews, talks } = this.state;
    let data;
    if (type === 'review') {
      data = reviews.filter((el) => el.id === id)[0] || {};
    } else if (type === 'get') {
      data = links.filter((el) => el.id === id)[0] || {};
    } else if (type === 'talk') {
      data = talks.filter((el) => el.id === id)[0] || {};
    } else if (type === 'event') {
      data = events.filter((el) => el.id === id)[0] || {};
    }
    // events: [],
    // links: [],
    // reviews: [],
    // store: [],
    // talks: [],
    this.setState({
      ...this.state,
      popupOpen: {
        ...this.state.popupOpen,
        [type]: true,
      },
      popupData: data,
    });
    window.history.pushState(null, null, `/${type}?id=${data.id}`);
  };

  closePopup = () => {
    this.setState({
      ...this.state,
      popupOpen: {
        ...this.state.popupOpen,
        review: false,
        get: false,
        talk: false,
        event: false,
      },
      popupData: {},
    });
    window.history.pushState(null, null, `/`);
  };

  editReview = async (id) => {
    window.location.href = `/writing/review/?id=${id}`;
    // this.props.history.push(`/writing/review/?id=${id}`);
  };

  deleteReview = async (id, my) => {
    if (window.confirm('정말 삭제하시겠습니까?')) {
      try {
        const result = await axios.delete(`${API}/reviews/${id}`, {
          headers: {
            token: `${localStorage.getItem('token')}`,
          },
        });
        if (result.status === 200) {
          if (my) {
            this.setState({
              ...this.state,
              popupOpen: {
                review: false,
                get: false,
                talk: false,
                event: false,
              },
              popupData: {},
              reviews: this.state.reviews.filter((el) => el.id !== id),
            });
          } else {
            this.setState({
              ...this.state,
              popupOpen: {
                review: false,
                get: false,
                talk: false,
                event: false,
              },
              popupData: {},
              reviews: this.state.reviews.filter((el) => el.id !== id),
            });
          }

          alert('삭제 성공 :)');
        }
      } catch (e) {
        if (
          e.response &&
          e.response.data &&
          e.response.data.msg &&
          e.response.data.msg === '토큰이 만료되었습니다.'
        ) {
          localStorage.removeItem('token');
          window.location.href = '/';
          return;
        }
        // alert('조금만 뒤에 다시 시도해주세요:)');
      }
    }
  };

  editLink = async (id) => {
    window.location.href = `/writing/get/?id=${id}`;
    // this.props.history.push(`/writing/get/?id=${id}`);
  };

  deleteLink = async (id, my) => {
    if (window.confirm('정말 삭제하시겠습니까?')) {
      try {
        const result = await axios.delete(`${API}/links/${id}`, {
          headers: {
            token: `${localStorage.getItem('token')}`,
          },
        });
        if (result.status === 200) {
          if (my) {
            this.setState({
              ...this.state,
              popupOpen: {
                review: false,
                get: false,
                talk: false,
                event: false,
              },
              popupData: {},
              links: this.state.links.filter((el) => el.id !== id),
            });
          } else {
            this.setState({
              ...this.state,
              popupOpen: {
                review: false,
                get: false,
                talk: false,
                event: false,
              },
              popupData: {},
              links: this.state.links.filter((el) => el.id !== id),
            });
          }

          alert('삭제 성공 :)');
        }
      } catch (e) {
        if (
          e.response &&
          e.response.data &&
          e.response.data.msg &&
          e.response.data.msg === '토큰이 만료되었습니다.'
        ) {
          localStorage.removeItem('token');
          window.location.href = '/';
          return;
        }
        // alert('조금만 뒤에 다시 시도해주세요:)');
      }
    }
  };

  getTalk = async (id) => {
    try {
      const result = await axios.get(`${API}/talks/${id}`, {
        headers: {
          token: `${localStorage.getItem('token')}`,
        },
      });
      if (result.data.liked) {
        like['talk'].push(result.data.id);
      }

      this.setState({
        ...this.state,
        popupOpen: {
          review: false,
          get: false,
          talk: true,
          event: false,
        },
        popupData: result.data,
        like,
      });
    } catch (e) {
      if (
        e.response &&
        e.response.data &&
        e.response.data.msg &&
        e.response.data.msg.indexOf('토큰이 만료되었습니다') > -1
      ) {
        localStorage.removeItem('token');
        window.location.reload();
      }
    }
  };

  editTalk = async (id) => {
    window.location.href = `/writing/talk/?id=${id}`;
    // this.props.history.push(`/writing/talk/?id=${id}`);
  };

  deleteTalk = async (id) => {
    if (window.confirm('정말 삭제하시겠습니까?')) {
      try {
        const result = await axios.delete(`${API}/talks/${id}`, {
          headers: {
            token: `${localStorage.getItem('token')}`,
          },
        });
        if (result.status === 200) {
          this.setState({
            ...this.state,
            popupOpen: {
              review: false,
              get: false,
              talk: false,
              event: false,
            },
            popupData: {},
            talks: this.state.talks.filter((el) => el.id !== id),
          });

          alert('삭제 성공 :)');
        }
      } catch (e) {
        if (
          e.response &&
          e.response.data &&
          e.response.data.msg &&
          e.response.data.msg === '토큰이 만료되었습니다.'
        ) {
          localStorage.removeItem('token');
          window.location.href = '/';
          return;
        }
        // alert('조금만 뒤에 다시 시도해주세요:)');
      }
    }
  };

  render() {
    const {
      brands,
      events,
      links,
      reviews,
      store,
      talks,
      currency,
      catchPhrase,
      like,
      dib,
      popupOpen,
      popupData,
      user,
    } = this.state;
    const {
      brandRef,
      storeRef,
      handleScroll,
      handleLike,
      handleDib,
      handlePopup,
      closePopup,
      editReview,
      deleteReview,
      editLink,
      deleteLink,
      editTalk,
      deleteTalk,
      getTalk,
      handleClick,
      getUserData,
    } = this;

    return (
      <>
        <div className="main-bg">
          <div className="wrapper">
            {/* 오늘의 직구 시작 */}
            <div className="main-info-bg">
              <div className="main-info-title-bg">
                <p className="main-info-sub">오늘의 직구,</p>
                <p className="main-info-title">
                  <strong>{catchPhrase.main1}</strong>
                </p>
              </div>
              <div className="main-comment-bg">
                <div className="main-comment-box-bg">
                  <p className="main-comment-box-title pc-only">
                    <strong>브랜드별</strong> 후기 모아보기
                  </p>
                  <div className="main-comment-box-content-bg">
                    <span
                      className="main-comment-box-prev pc-only"
                      onClick={() => handleScroll('brand', 'prev')}
                    ></span>
                    <div className="main-comment-box-list-bg" ref={brandRef}>
                      <span className="comment-mo-title">브랜드별 모아보기</span>
                      <ul className="main-comment-box-list">
                        {brands &&
                          brands.map((el, i) => {
                            return (
                              <li key={i} onClick={(e) => handleClick(e, 'brand', el.value)}>
                                <img src={el.description} alt="" />
                                <span>{el.value}</span>
                              </li>
                            );
                          })}
                        {brands.length === 0 && (
                          <div className="nodata">
                            브랜드를 준비 중 입니다. 조금만 기다려주세요:)
                          </div>
                        )}
                      </ul>
                    </div>
                    <span
                      className="main-comment-box-next pc-only"
                      onClick={() => handleScroll('brand', 'next')}
                    ></span>
                  </div>
                </div>
              </div>
              <div className="main-comment-bg">
                <div className="main-comment-box-bg">
                  <p className="main-comment-box-title pc-only">
                    <strong>스토어별</strong> 후기 모아보기
                  </p>
                  <div className="main-comment-box-content-bg">
                    <span
                      className="main-comment-box-prev pc-only"
                      onClick={() => handleScroll('store', 'prev')}
                    ></span>
                    <div className="main-comment-box-list-bg" ref={storeRef}>
                      <span className="comment-mo-title">스토어별 모아보기</span>
                      <ul className="main-comment-box-list">
                        {store &&
                          store.map((el, i) => {
                            return (
                              <li key={i} onClick={(e) => handleClick(e, 'store', el.value)}>
                                <img src={el.description} alt="" />
                                <span>{el.value}</span>
                              </li>
                            );
                          })}
                        {store.length === 0 && (
                          <div className="nodata">
                            스토어를 준비 중 입니다. 조금만 기다려주세요:{')'}
                          </div>
                        )}
                      </ul>
                    </div>
                    <span
                      className="main-comment-box-next pc-only"
                      onClick={() => handleScroll('store', 'next')}
                    ></span>
                  </div>
                </div>
              </div>
            </div>
            {/* 오늘의 직구 끝*/}
            {/* 누가잘샀을까? 시작*/}
            <div className="main-intro-bg">
              {/* <div className="main-intro-title-bg">
              <p className="main-intro-sub">오늘은</p>
              <p className="main-intro-title">
                <strong>누가누가 잘샀을까요?</strong>
              </p>
            </div> */}
              <div>
                <div className="main-intro-content-list">
                  <div className="main-intro-content-bg">
                    <span className="main-intro-content-title">
                      오늘의 <strong>BEST 후기</strong>
                    </span>
                    <span
                      className="main-intro-content-more"
                      onClick={() => (window.location.href = '/review')}
                    >
                      더보기
                    </span>
                    <span
                      className="main-intro-content-more-icon"
                      onClick={() => (window.location.href = '/review')}
                    ></span>
                  </div>
                  <div className="main-intro-content-item-bg review-item">
                    {reviews &&
                      reviews.map((el, i) => {
                        return (
                          <ReviewItem
                            data={el}
                            key={i}
                            currency={currency}
                            handleLike={handleLike}
                            handleDib={handleDib}
                            like={like.review}
                            dib={dib.review}
                            handlePopup={handlePopup}
                            user={user}
                          />
                        );
                      })}
                  </div>
                  <div className="main-intro-content-divide-bg">
                    <span className="main-intro-content-title">
                      오늘의 <strong>BEST 득템</strong>
                    </span>
                    <span
                      className="main-intro-content-more"
                      onClick={() => (window.location.href = '/get')}
                    >
                      더보기
                    </span>
                    <span
                      className="main-intro-content-more-icon"
                      onClick={() => (window.location.href = '/get')}
                    ></span>
                  </div>
                  <div className="main-intro-content-item-bg">
                    {links &&
                      links.map((el, i) => {
                        return (
                          <GetItem
                            data={el}
                            key={i}
                            currency={currency}
                            handleLike={handleLike}
                            handleDib={handleDib}
                            handlePopup={handlePopup}
                            like={like.get}
                            dib={dib.get}
                            user={user}
                          />
                        );
                      })}
                  </div>
                  <div className="main-intro-content-divide-bg">
                    <span className="main-intro-content-title">
                      오늘의 <strong>BEST 토크</strong>
                    </span>
                    <span
                      className="main-intro-content-more"
                      onClick={() => (window.location.href = '/talk')}
                    >
                      더보기
                    </span>
                    <span
                      className="main-intro-content-more-icon"
                      onClick={() => (window.location.href = '/talk')}
                    ></span>
                  </div>
                  <div className="main-intro-content-item-bg">
                    {talks &&
                      talks.map((el, i) => {
                        let type =
                          el.category === '자유토크'
                            ? 'free'
                            : el.category === '사요마요'
                            ? 'recommend'
                            : el.category === '정품판독'
                            ? 'genuine'
                            : 'search';

                        return (
                          <TalkItem
                            data={el}
                            key={i}
                            type={type}
                            currency={currency}
                            handlePopup={handlePopup}
                            handleLike={handleLike}
                            like={like.talk}
                            user={user}
                          />
                        );
                      })}
                    {/* <TalkItem />
                  <TalkItem />
                  <TalkItem /> */}
                  </div>
                  <div className="main-intro-content-divide-bg">
                    <span className="main-intro-content-title">
                      오늘의 <strong>이벤트</strong>
                    </span>
                    <span className="main-intro-content-more">더보기</span>
                    <span className="main-intro-content-more-icon"></span>
                  </div>
                  <div className="main-intro-content-item-bg event">
                    {events &&
                      events.map((el, i) => {
                        return (
                          <EventItem
                            data={el}
                            key={i}
                            like={like.event}
                            handlePopup={handlePopup}
                            handleLike={handleLike}
                          />
                        );
                      })}
                    {/* <EventItem />
                  <EventItem />
                  <EventItem /> */}
                  </div>
                </div>
              </div>
            </div>
            {/* 누가잘샀을까? 끝*/}
            {/* 스탬프 시작 */}
            {/* <div className="main-stamp-bg">
            <div className="main-stamp-title-bg">
              <p className="main-info-sub">오늘은</p>
              <p className="main-info-title">오직 러만을 위한 스탬프 :)</p>
            </div>
            <div className="main-stamp-list-bg">
              <div className="main-stamp-list">
                <div className="main-stamp-item">
                  <span className="main-stamp-item-img"></span>
                  <span className="main-stamp-item-name">오직은 처음이지?</span>
                </div>
                <div className="main-stamp-item">
                  <span className="main-stamp-item-img"></span>
                  <span className="main-stamp-item-name">오직은 처음이지?</span>
                </div>
                <div className="main-stamp-item">
                  <span className="main-stamp-item-img"></span>
                  <span className="main-stamp-item-name">오직은 처음이지?</span>
                </div>
                <div className="main-stamp-item">
                  <span className="main-stamp-item-img"></span>
                  <span className="main-stamp-item-name">오직은 처음이지?</span>
                </div>
                <div className="main-stamp-item">
                  <span className="main-stamp-item-img"></span>
                  <span className="main-stamp-item-name">오직은 처음이지?</span>
                </div>
                <div className="main-stamp-item remove">
                  <span className="main-stamp-item-img"></span>
                  <span className="main-stamp-item-name">오직은 처음이지?</span>
                </div>
                <div className="main-stamp-item more">
                  <span className="main-stamp-more-button"></span>
                  <p className="main-stamp-more-desc">더 보러가기</p>
                </div>
              </div>
              <div className="main-stamp-more-bg">
                <span className="main-stamp-more-button"></span>
                <p className="main-stamp-more-desc">더 보러가기</p>
              </div>
            </div>
          </div> */}
            {/* 스탬프 끝 */}
            {/* 마지막 문구 시작 */}
            <div className="main-last-bg">
              <p className="main-info-sub">오늘의 직구,</p>
              <p className="main-info-title">{catchPhrase.main2}</p>
            </div>
            {/* 마지막 문구 끝 */}
          </div>
        </div>
        {popupOpen.review && popupData && popupData.user && popupData.user.id && (
          <ReviewPopup
            isOpen={popupOpen.review}
            popupData={popupData}
            currency={currency}
            closePopup={closePopup}
            user={user}
            handleLike={handleLike}
            handleDib={handleDib}
            like={like.review}
            dib={dib.review}
            deleteReview={deleteReview}
            editReview={editReview}
            getUserData={getUserData}
          />
        )}
        {popupOpen.get && popupData && popupData.user && popupData.user.id && (
          <GetPopup
            isOpen={popupOpen.get}
            popupData={popupData}
            currency={currency}
            closePopup={closePopup}
            user={user}
            handleLike={handleLike}
            handleDib={handleDib}
            like={like.get}
            dib={dib.get}
            deleteLink={deleteLink}
            editLink={editLink}
          />
        )}
        {popupOpen.talk && popupData && popupData.user && popupData.user.id && (
          <>
            {
              <TalkPopup
                type={
                  popupData.category === '자유토크'
                    ? 'free'
                    : popupData.category === '사요마요'
                    ? 'recommend'
                    : popupData.category === '정품판독'
                    ? 'genuine'
                    : 'search'
                }
                popupData={popupData}
                user={user}
                isOpen={popupOpen.talk}
                closePopup={closePopup}
                handleLike={handleLike}
                like={like.talk}
                deleteTalk={deleteTalk}
                editTalk={editTalk}
                getTalk={getTalk}
              />
            }
          </>
        )}
        {popupOpen.event && popupData && popupData.user && popupData.user.id && (
          <EventPopup
            isOpen={popupOpen.event}
            user={user}
            popupData={popupData}
            closePopup={closePopup}
            like={like.event}
            handleLike={handleLike}
          />
        )}
      </>
    );
  }
}

export default withRouter(Main);
