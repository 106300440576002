import React from 'react';
import MainStructure from 'components/structure/MainStructure';
import GetWritingContainer from 'containers/GetWritingContainer';

const GetWriting = () => {
  return (
    <MainStructure>
      <GetWritingContainer />
    </MainStructure>
  );
};

export default GetWriting;
