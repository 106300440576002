import React, { Component } from 'react';
import Main from 'components/Main';

class MainContainer extends Component {
  render() {
    return <Main />;
  }
}

export default MainContainer;
