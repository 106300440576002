import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import axios from 'axios';
import { API } from 'config';
import { Document, Page } from 'react-pdf/dist/esm/entry.webpack';
import jwt_decode from 'jwt-decode';
import { tagMangerService } from 'lib/TagManagerService';
import { gradeName, abbreviateNumber, numberWithCommas } from 'lib/common';

class GetWriting extends Component {
  getImageUrl;

  state = {
    options: {
      styletag: [],
      brand: [],
      store: [],
    },
    directDetail: {
      styletag: '',
      brand: '',
      store: '',
    },
    getId: 0,
    get: {
      imageUrl: '',
      responseUrl: '',
      title: '',
      content: '',
      styletag: '',
      productName: '',
      brand: '',
      store: '',
      price: 0,
      currency: '',
      originalPrice: 0,
      originalCurrency: '',
      purchaseSiteUrl: '',
    },
    user: {},
  };

  componentWillMount() {
    if (localStorage.getItem('token')) {
      const user = jwt_decode(localStorage.getItem('token'));
      if (user.grade < 3) {
        alert('일반 오직러부터 득템을 작성하실 수 있습니다:)');
        window.location.href = '/get';
      } else if (user.grade) {
        this.getUser();
      }
    } else {
      alert('일반 오직러부터 득템을 작성하실 수 있습니다:)');
      window.location.href = '/get';
    }
  }

  componentDidMount() {
    localStorage.getItem('token') && this.getUserData();

    this.getOptions();

    if (this.props.location.search && this.props.location.search.split('id=')) {
      this.getLink(this.props.location.search.split('id=')[1]);
    }
  }

  getUser = () => {
    try {
      const user = jwt_decode(localStorage.getItem('token'));
      if (user) {
        this.setState({
          ...this.state,
          user: {
            id: user.id,
          },
        });
      } else {
        localStorage.removeItem('token');
      }
    } catch (e) {
      localStorage.removeItem('token');
      window.location.reload();
    }
  };

  getUserData = async () => {
    const user = jwt_decode(localStorage.getItem('token'));
    if (user) {
      try {
        const result = await axios.get(`${API}/users/my`, {
          headers: {
            token: `${localStorage.getItem('token')}`,
          },
        });

        const newToken = result.headers['new-token'] || '';
        if (newToken) {
          axios.defaults.headers.token = newToken;
          localStorage.setItem('token', newToken);
        }

        if (result && result.status === 200) {
          let user = result.data;

          this.setState({
            ...this.state,
            user,
          });
        } else {
          localStorage.removeItem('token');
          window.location.reload();
        }
      } catch (e) {
        localStorage.removeItem('token');
        window.location.reload();
      }
    } else {
      localStorage.removeItem('token');
    }
  };

  getOptions = async () => {
    try {
      const reulst1 = await axios.get(`${API}/static/style-tags`);
      const reulst2 = await axios.get(`${API}/static/brands`);
      const reulst3 = await axios.get(`${API}/static/stores`);
      let styletag = [];
      let brand = [];
      let store = [];
      reulst1.data.map((el) => {
        if (!el.hidden) {
          styletag.push(el.value);
        }
      });
      reulst2.data.map((el) => {
        if (!el.hidden) {
          brand.push(el.value);
        }
      });
      reulst3.data.map((el) => {
        if (!el.hidden) {
          store.push(el.value);
        }
      });
      this.setState({
        ...this.state,
        options: {
          styletag,
          brand,
          store,
        },
      });
    } catch (e) {
      if (
        e.response &&
        e.response.data &&
        e.response.data.msg &&
        e.response.data.msg === '토큰이 만료되었습니다.'
      ) {
        localStorage.removeItem('token');
        window.location.href = '/';
        return;
      }
    }
  };

  getLink = async (id) => {
    const { options, user } = this.state;

    try {
      const result = await axios.get(`${API}/links/${id}`, {
        headers: {
          token: `${localStorage.getItem('token')}`,
        },
      });

      if (result.data.user.id !== user.id) return;

      this.setState({
        ...this.state,
        getId: id,
        directDetail: {
          styletag: options.styletag.indexOf(result.data.styletag) > -1 ? '' : result.data.styletag,
          brand: options.brand.indexOf(result.data.brand) > -1 ? '' : result.data.brand,
          store: options.store.indexOf(result.data.store) > -1 ? '' : result.data.store,
        },
        get: {
          imageUrl: result.data.imageUrl,
          responseUrl: result.data.imageUrl,
          title: result.data.title,
          content: result.data.content,
          styletag:
            options.styletag.indexOf(result.data.styletag) > -1
              ? result.data.styletag
              : '기타 (직접입력)',
          productName: result.data.productName,
          brand:
            options.brand.indexOf(result.data.brand) > -1 ? result.data.brand : '기타 (직접입력)',
          store:
            options.store.indexOf(result.data.store) > -1 ? result.data.store : '기타 (직접입력)',
          price: !!Number(result.data.price)
            ? numberWithCommas(result.data.price.replace(/.000/gi, ''))
            : 0,
          currency: result.data.currency,
          originalPrice: !!Number(result.data.originalPrice)
            ? numberWithCommas(result.data.originalPrice.replace(/.000/gi, ''))
            : 0,
          originalCurrency: result.data.originalCurrency,
          purchaseSiteUrl: result.data.purchaseSiteUrl,
        },
      });
    } catch (e) {
      if (
        e.response &&
        e.response.data &&
        e.response.data.msg &&
        e.response.data.msg === '토큰이 만료되었습니다.'
      ) {
        localStorage.removeItem('token');
        window.location.href = '/';
        return;
      }
    }
  };

  openDirect = (type) => {
    this.setState({
      ...this.state,
      direct: {
        ...this.state.direct,
        [type]: true,
      },
    });
  };

  showImg = () => {
    const { imgList, imgLoaing } = this.state;
    let component = [];
    imgLoaing.map((el, i) => {
      component.push(
        <div key={i} className="writing-img-item">
          {imgLoaing[i] ? (
            <div className="img-loader"></div>
          ) : imgList[i] ? (
            <>
              <span className="writing-remove-item" onClick={() => this.removeImg(i)}></span>
              <img src={imgList[i]} alt="" />
            </>
          ) : (
            ''
          )}
        </div>,
      );
    });

    return component;
  };

  searchImg = async (url) => {
    try {
      const result = await axios.post(`${API}/upload/images/url`, {
        url,
      });
      // /upload/images/url
      this.setState({
        ...this.state,
        get: {
          ...this.state.get,
          responseUrl: result.data,
        },
      });
    } catch (e) {
      this.setState({
        ...this.state,
        get: {
          ...this.state.get,
          responseUrl: '',
        },
      });
    }
  };

  handleChange = (e) => {
    if (e.target.name === 'price' || e.target.name === 'originalPrice') {
      // pattern="[0-9]+"
      var numRule = /^[+-]?([0-9]+([.][0-9]{0,3})?|[.][0-9]{0,3})$/i;

      if (!!e.target.value && !numRule.test(e.target.value.replace(/,/gi, ''))) return;
      if (Number(e.target.value.replace(/,/gi, '')) > 1000000000)
        return alert('1,000,000,000보다 작게 입력해주세요:)');

      this.setState({
        ...this.state,
        get: {
          ...this.state.get,
          [e.target.name]: numberWithCommas(e.target.value.replace(/,/gi, '')),
        },
      });
    } else {
      this.setState({
        ...this.state,
        get: {
          ...this.state.get,
          [e.target.name]: e.target.value,
        },
      });
    }

    if (e.target.name === 'imageUrl') {
      const url = e.target.value;
      clearTimeout(this.getImageUrl);
      this.getImageUrl = setTimeout(() => {
        this.searchImg(url);
      }, 500);
    }
  };

  handleDetailChange = (e) => {
    this.setState({
      ...this.state,
      directDetail: {
        ...this.state.directDetail,
        [e.target.name]: e.target.value,
      },
    });
  };

  handleSubmit = async () => {
    const { directDetail, get, getId } = this.state;
    const {
      imageUrl,
      responseUrl,
      title,
      content,
      styletag,
      productName,
      brand,
      store,
      price,
      currency,
      originalPrice,
      originalCurrency,
      purchaseSiteUrl,
    } = get;

    // styletag: '',
    // brand: '',
    // store: '',
    // purchaseMethod: '',
    // howLongTakeToShip: '',
    if (!localStorage.getItem('token')) {
      return alert('작성을 위해 로그인해주세요!');
    }

    if (!imageUrl.trim()) {
      return alert('이미지 URL을 입력해주세요!');
    }

    if (!responseUrl) {
      return alert('이미지 URL이 올바르지 않습니다!');
    }

    if (!title.trim()) {
      return alert('제목을 입력해주세요!');
    }

    if (!content.trim()) {
      return alert('내용을 입력해주세요!');
    }

    if (!styletag) {
      return alert('스타일태그를 선택해주세요!');
    }

    if (styletag === '기타 (직접입력)' && !directDetail.styletag) {
      return alert('스타일태그를 입력해주세요!');
    }

    if (!productName.trim()) {
      return alert('상품이름을 입력해주세요!');
    }

    if (!brand) {
      return alert('브랜드 이름을 선택해주세요!');
    }

    if (brand === '기타 (직접입력)' && !directDetail.brand) {
      return alert('브랜드 이름을 입력해주세요!');
    }

    if (!store) {
      return alert('스토어 이름을 선택해주세요!');
    }

    if (store === '기타 (직접입력)' && !directDetail.store) {
      return alert('스토어 이름을 입력해주세요!');
    }

    if (!price) {
      return alert('구매 가격을 입력해주세요!');
    }

    if (!currency) {
      return alert('구매 통화를 선택해주세요!');
    }

    if (!!originalPrice && !!Number(originalPrice.replace(/,/gi, '')) && !originalCurrency) {
      return alert('상품원가 통화를 선택해주세요!');
    }

    if (!purchaseSiteUrl.trim()) {
      return alert('구매링크를 입력해주세요!');
    }

    try {
      if (!getId) {
        const result = await axios.post(
          `${API}/links `,
          {
            imageUrl: responseUrl,
            title: title.trim(),
            content: content.trim(),
            styletag: styletag === '기타 (직접입력)' ? directDetail.styletag : styletag,
            productName: productName.trim(),
            brand: brand === '기타 (직접입력)' ? directDetail.brand : brand,
            store: store === '기타 (직접입력)' ? directDetail.store : store,
            price: Number(price.replace(/,/gi, '')),
            currency,
            originalPrice: !!originalPrice ? Number(originalPrice.replace(/,/gi, '')) : 0,
            originalCurrency: !!originalPrice ? originalCurrency : '',
            purchaseSiteUrl: purchaseSiteUrl.trim(),
          },
          {
            headers: {
              token: `${localStorage.getItem('token')}`,
            },
          },
        );
        if (result.status === 200) {
          // this.props.history.push(`/get?id=${result.data.id}`);
          window.location.href = `/get?id=${result.data.id}`;
          tagMangerService.getAcquireEvent();
        } else {
          alert('각 항목에 맞게 올바르게 작성해주세요:)');
        }
      } else {
        const result = await axios.patch(
          `${API}/links/${getId} `,
          {
            imageUrl: responseUrl,
            title: title.trim(),
            content: content.trim(),
            styletag: styletag === '기타 (직접입력)' ? directDetail.styletag : styletag,
            productName: productName.trim(),
            brand: brand === '기타 (직접입력)' ? directDetail.brand : brand,
            store: store === '기타 (직접입력)' ? directDetail.store : store,
            price: Number(price.replace(/,/gi, '')),
            currency,
            originalPrice: !!originalPrice ? Number(originalPrice.replace(/,/gi, '')) : 0,
            originalCurrency: !!originalPrice ? originalCurrency : '',
            purchaseSiteUrl: purchaseSiteUrl.trim(),
          },
          {
            headers: {
              token: `${localStorage.getItem('token')}`,
            },
          },
        );
        if (result.status === 200) {
          // this.props.history.push(`/get?id=${result.data.id}`);
          window.location.href = `/get?id=${result.data.id}`;
          tagMangerService.getAcquireEvent();
        } else {
          alert('각 항목에 맞게 올바르게 작성해주세요:)');
        }
      }
    } catch (e) {
      if (
        e.response &&
        e.response.data &&
        e.response.data.msg &&
        e.response.data.msg === '토큰이 만료되었습니다.'
      ) {
        localStorage.removeItem('token');
        window.location.href = '/';
        return;
      }
      // alert('조금만 이따가 다시 시도해주세요 :)');
    }
  };

  render() {
    const { get, directDetail, options, getId } = this.state;
    const {
      handleChange,
      handleImage,
      handleBill,
      showImg,
      showBill,
      handleDetailChange,
      handleDateChange,
      handleRate,
      inputRef,
      imgRef,
      handleSubmit,
    } = this;

    return (
      <div className="writing-bg">
        <div className="writing-container wrapper">
          <div>
            <div className="writing-title-bg">
              <h2 className="writing-title">
                오늘의 <strong>득템 작성</strong>
              </h2>
            </div>
            <div className="writing-box">
              <div className="writing-desc-bg">
                <span className="writing-desc" role="img">
                  ✔️'핫딜 정보 공유' 오늘의 득템 😊
                </span>
              </div>
              <div className="writing-img-bg">
                <div className="writing-img-box">
                  {/* {imgLoaing.length > 0 ? (
                    showImg()
                  ) : (
                    <div className="writing-img-item default"></div>
                  )} */}
                  <div className="writing-img-item default">
                    {get.responseUrl && <img src={get.responseUrl} alt="" />}
                  </div>
                </div>
              </div>
              <div className="write-input-bg">
                <label className="write-input-label" htmlFor="imageUrl">
                  이미지 URL
                </label>
                <input
                  className="write-input-text"
                  type="text"
                  name="imageUrl"
                  value={get.imageUrl}
                  onChange={handleChange}
                  placeholder={'해외사이트 이미지링크 복사 → 붙여넣기'}
                />
              </div>
              <div className="write-input-bg">
                <label className="write-input-label" htmlFor="title">
                  제목
                </label>
                <input
                  className="write-input-text"
                  type="text"
                  name="title"
                  value={get.title}
                  onChange={handleChange}
                  placeholder={'브랜드 & 제품명 필수포함 (정식 명칭)'}
                />
              </div>
              <div className="write-input-bg">
                <label className="write-input-label" htmlFor="content">
                  내용
                </label>
                <textarea
                  className="write-input-textarea"
                  name="content"
                  id=""
                  cols="30"
                  rows="10"
                  value={get.content}
                  onChange={handleChange}
                  placeholder={
                    "✔ '핫딜 정보 공유' 오늘의 득템 😊\n추천 이유를 3줄 이상 작성해주세요 :)"
                  }
                ></textarea>
              </div>
              <div className="write-input-bg">
                <label className="write-input-label">
                  스타일태그
                  <span className="writing-info">
                    ※게시물에 어울리는 스타일 태그를 선택해주세요.
                  </span>
                </label>
                <div className="write-input-child">
                  <select
                    className="write-input-select"
                    name="styletag"
                    value={get.styletag}
                    onChange={handleChange}
                    id=""
                  >
                    <option value="">스타일태그를 선택해주세요</option>
                    {options &&
                      options.styletag &&
                      options.styletag.map((el, i) => {
                        return (
                          <option key={i} value={el}>
                            {el}
                          </option>
                        );
                      })}
                    <option value="기타 (직접입력)">기타 (직접입력)</option>
                  </select>
                  {get && get.styletag === '기타 (직접입력)' && (
                    <input
                      className="write-input-text writing-spacing"
                      name="styletag"
                      type="text"
                      onChange={handleDetailChange}
                      value={directDetail.styletag}
                    />
                  )}
                </div>
              </div>
              <div className="write-input-bg">
                <label className="write-input-label" htmlFor="productName">
                  상품 이름
                </label>
                <input
                  className="write-input-text"
                  type="text"
                  name="productName"
                  value={get.productName}
                  onChange={handleChange}
                />
              </div>
              <div className="write-input-bg">
                <label className="write-input-label" htmlFor="brand">
                  브랜드 이름
                </label>
                <div className="write-input-child">
                  <select
                    className="write-input-select"
                    name="brand"
                    value={get.brand}
                    onChange={handleChange}
                    id=""
                  >
                    <option value="">브랜드를 선택해주세요</option>
                    {options &&
                      options.brand &&
                      options.brand.map((el, i) => {
                        return (
                          <option key={i} value={el}>
                            {el}
                          </option>
                        );
                      })}
                    <option value="기타 (직접입력)">기타 (직접입력)</option>
                  </select>
                  {get && get.brand === '기타 (직접입력)' && (
                    <input
                      className="write-input-text writing-spacing"
                      name="brand"
                      type="text"
                      onChange={handleDetailChange}
                      value={directDetail.brand}
                    />
                  )}
                </div>
              </div>
              <div className="write-input-bg">
                <label className="write-input-label" htmlFor="store">
                  스토어 이름
                </label>
                <div className="write-input-child">
                  <select
                    className="write-input-select"
                    name="store"
                    id=""
                    value={get.store}
                    onChange={handleChange}
                  >
                    <option value="">스토어를 선택해주세요</option>
                    {options &&
                      options.store &&
                      options.store.map((el, i) => {
                        return (
                          <option key={i} value={el}>
                            {el}
                          </option>
                        );
                      })}
                    <option value="기타 (직접입력)">기타 (직접입력)</option>
                  </select>
                  {get && get.store === '기타 (직접입력)' && (
                    <input
                      className="write-input-text writing-spacing"
                      name="store"
                      type="text"
                      onChange={handleDetailChange}
                      value={directDetail.store}
                    />
                  )}
                </div>
              </div>
              <div className="write-input-bg">
                <label className="write-input-label" htmlFor="price">
                  구매 가격
                </label>
                <div className="write-input-child">
                  <input
                    className="write-input-text"
                    type="text"
                    name="price"
                    value={get.price}
                    onChange={handleChange}
                  />
                  <select
                    className="writing-spacing write-input-select"
                    name="currency"
                    value={get.currency}
                    onChange={handleChange}
                    id=""
                  >
                    <option value="">구매 통화를 선택해주세요</option>
                    <option value="KRW">원 (KRW)</option>
                    <option value="USD">달러 (USD)</option>
                    <option value="EUR">유로 (EUR)</option>
                    <option value="GBP">파운드 (GBP)</option>
                    <option value="JPY">엔 (JPY)</option>
                    <option value="CNY">위안 (CNY)</option>
                  </select>
                </div>
              </div>
              <div className="write-input-bg">
                <label className="write-input-label" htmlFor="originalPrice">
                  상품 원가
                </label>
                <div className="write-input-child">
                  <input
                    className="write-input-text"
                    type="text"
                    name="originalPrice"
                    value={get.originalPrice}
                    onChange={handleChange}
                  />
                  <select
                    className="writing-spacing write-input-select"
                    name="originalCurrency"
                    value={get.originalCurrency}
                    onChange={handleChange}
                    id=""
                  >
                    <option value="">구매 통화를 선택해주세요</option>
                    <option value="KRW">원 (KRW)</option>
                    <option value="USD">달러 (USD)</option>
                    <option value="EUR">유로 (EUR)</option>
                    <option value="GBP">파운드 (GBP)</option>
                    <option value="JPY">엔 (JPY)</option>
                    <option value="CNY">위안 (CNY)</option>
                  </select>
                </div>
              </div>
              <div className="write-input-bg">
                <label className="write-input-label" htmlFor="purchaseSiteUrl">
                  구매링크
                </label>
                <input
                  className="write-input-text"
                  type="text"
                  name="purchaseSiteUrl"
                  placeholder="해외사이트 링크 복사 → 붙여넣기 (변환링크X)"
                  value={get.purchaseSiteUrl}
                  onChange={handleChange}
                />
              </div>
            </div>
            <div className="writing-button-bg">
              <button
                className="writing-button-cancel"
                onClick={() => (window.location.href = '/')}
              >
                취소하기
              </button>
              <button className="writing-button-write" onClick={handleSubmit}>
                {getId ? '수정하기' : '작성하기'}
              </button>
            </div>
          </div>
          {/* <div className="rating">
            <span>☆</span>
            <span>☆</span>
            <span className="checked">☆</span>
            <span>☆</span>
            <span>☆</span>
          </div> */}
        </div>
      </div>
    );
  }
}

export default withRouter(GetWriting);
