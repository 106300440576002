import React, { Component } from 'react';
import './GetItem.css';
import Profile from 'components/common/Profile';
import { gradeName, abbreviateNumber, numberWithCommas } from 'lib/common';
import { withRouter } from 'react-router-dom';
import Imagebox from 'components/common/Imagebox';

class GetItem extends Component {
  render() {
    const { handlePopup, data, currency, handleLike, handleDib, like, dib, user } = this.props;
    if (!data) return <></>;

    return (
      <div
        className="main-intro-content-get"
        onClick={() => handlePopup && handlePopup(data.id, 'get')}
      >
        <div className="main-intro-content-get-img-bg">
          {/* <img src={data.imageUrl || ''} alt="" /> */}
          <Imagebox link={data.imageUrl} />
        </div>
        <div className="main-intro-content-get-info-bg">
          <div
            className="main-intro-content-user-bg"
            onClick={(e) => {
              if ((user && user.id) !== (data && data.user && data.user.id)) {
                e.stopPropagation();
                // this.props.history.push(`/otherpage/${data.user.id}`);
                window.location.href = `/otherpage/${data.user.id}`;
              }
            }}
          >
            <span
              className={
                'main-intro-content-user-profile-bg ' + `grade-${data.user && data.user.grade}`
              }
            >
              <span
                className={'main-intro-content-user-profile '}
                style={
                  data.user && data.user.profileImgUrl
                    ? { backgroundImage: `url(${data.user && data.user.profileImgUrl})` }
                    : {}
                }
              ></span>
            </span>
            <span className="main-intro-content-user-name">{data.user && data.user.nickname}</span>
            <div className="get-tags">
              <span className="main-intro-content-tag">#{data.styletag}</span>
            </div>
          </div>
          {/* <Profile user={data.user} data={data} showGrade={false}>
            
          </Profile> */}
          <div className="main-intro-content-get-info-middle-bg">
            <p className="main-intro-content-desc">{data.title}</p>
          </div>
          <div className="main-intro-content-get-info-bottom-bg">
            <div className="main-intro-content-origin-bg">
              {!!data.originalPrice && !!data.originalCurrency && (
                <p className="main-intro-content-origin">
                  ￦{' '}
                  {numberWithCommas(
                    Math.round(data.originalPrice * currency[data.originalCurrency]),
                  ) || '???'}
                </p>
              )}
            </div>
            <div className="main-intro-content-price-bg">
              <p className="main-intro-content-price">
                ￦ {numberWithCommas(Math.round(data.price * currency[data.currency])) || '???'}
              </p>
            </div>
          </div>
          <div className="main-intro-content-get-info-last-bg">
            <div>
              <span className="main-intro-content-store">{data.brand}</span>
            </div>
            <div className="main-intro-content-get-numbers-bg">
              <span
                className={
                  'main-intro-content-info-like ' +
                  (like && like.indexOf(data.id) > -1 ? 'active' : '')
                }
                onClick={(e) => handleLike(e, 'links', data.id)}
              ></span>
              <span
                className="main-intro-content-info-number"
                onClick={(e) => handleLike(e, 'links', data.id)}
              >
                {data.liked && like && like.indexOf(data.id) > -1
                  ? abbreviateNumber(parseInt(data.likeCount), 0)
                  : !data.liked && like && like.indexOf(data.id) > -1
                  ? abbreviateNumber(parseInt(data.likeCount + 1), 0)
                  : !data.liked && like && like.indexOf(data.id) === -1
                  ? abbreviateNumber(parseInt(data.likeCount), 0)
                  : abbreviateNumber(parseInt(data.likeCount - 1), 0)}
              </span>
              <span
                className={
                  'main-intro-content-info-heart ' +
                  (dib && dib.indexOf(data.id) > -1 ? 'active' : '')
                }
                onClick={(e) => handleDib(e, 'links', data.id)}
              ></span>
              <span
                className="main-intro-content-info-number"
                onClick={(e) => handleDib(e, 'links', data.id)}
              >
                {data.dib && dib && dib.indexOf(data.id) > -1
                  ? abbreviateNumber(parseInt(data.dibCount), 0)
                  : !data.dib && dib && dib.indexOf(data.id) > -1
                  ? abbreviateNumber(parseInt(data.dibCount + 1), 0)
                  : !data.dib && dib && dib.indexOf(data.id) === -1
                  ? abbreviateNumber(parseInt(data.dibCount), 0)
                  : abbreviateNumber(parseInt(data.dibCount - 1), 0)}
              </span>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default withRouter(GetItem);
