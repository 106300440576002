import React, { Component } from 'react';
import Pagination from 'components/Pagination';
import jwt_decode from 'jwt-decode';
import axios from 'axios';
import { API } from 'config';
import moment from 'moment';
import './MyPoint.css';

class MyPoint extends Component {
  constructor() {
    super();

    this.state = {
      items: [],
      pageOfItems: [],
    };

    // bind function in constructor instead of render (https://github.com/yannickcr/eslint-plugin-react/blob/master/docs/rules/jsx-no-bind.md)
    this.onChangePage = this.onChangePage.bind(this);
  }

  componentWillMount() {
    if (!localStorage.getItem('token')) {
      window.location.href = '/';
    } else {
      localStorage.getItem('token') && this.getUser();
    }
  }

  componentDidMount() {
    localStorage.getItem('token') && this.getUserData();
    this.getPoints();
  }

  getUser = () => {
    try {
      const user = jwt_decode(localStorage.getItem('token'));
      if (user) {
        this.setState({
          ...this.state,
          user: {
            id: user.id,
          },
        });
      } else {
        localStorage.removeItem('token');
      }
    } catch (e) {
      localStorage.removeItem('token');
      window.location.reload();
    }
  };

  getUserData = async () => {
    const user = jwt_decode(localStorage.getItem('token'));
    if (user) {
      try {
        const result = await axios.get(`${API}/users/my`, {
          headers: {
            token: `${localStorage.getItem('token')}`,
          }
        });

        const newToken = result.headers["new-token"] || '';
        if (newToken) {
          axios.defaults.headers.token = newToken;
          localStorage.setItem("token", newToken);
        }

        if (result && result.status === 200) {
          let user = result.data;

          this.setState({
            ...this.state,
            user,
          });
        } else {
          localStorage.removeItem('token');
          window.location.reload();
        }
      } catch (e) {
        localStorage.removeItem('token');
        window.location.reload();
      }
    } else {
      localStorage.removeItem('token');
    }
  };

  getPoints = async () => {
    const { user } = this.state;
    try {
      const result = await axios.get(`${API}/users/${user.id}/points`, {
        headers: {
          token: `${localStorage.getItem('token')}`,
        },
      });
      this.setState({
        ...this.state,
        items: result.data,
      });
    } catch (e) {
      if (e.response && e.response.data && e.response.data.msg && e.response.data.msg === '토큰이 만료되었습니다.') {
        localStorage.removeItem('token');
        window.location.href = "/";
        return;
      }
    }
  };

  // localhost:7000/v1/users/55/points

  onChangePage = (pageOfItems) => {
    // update state with new page of items
    this.setState({ pageOfItems: pageOfItems });
  };

  render() {
    const { onChangePage } = this;
    const { items, pageOfItems } = this.state;

    return (
      <div className="mypoint-bg">
        <table className="mypoint-table">
          <thead>
            <tr>
              <th>일자</th>
              <th>사유</th>
              <th>증감</th>
              <th>잔액</th>
            </tr>
          </thead>
          <tbody>
            {pageOfItems.length === 0 && (
              <tr>
                <td className="mypoint-no-data" colSpan="4">
                  포인트 내역이 없습니다.
                </td>
              </tr>
            )}
            {pageOfItems.length > 0 &&
              pageOfItems.map((el, i) => {
                return (
                  <tr key={i}>
                    <td>{moment(el.createdAt).format('YYYY-MM-DD HH:MM')}</td>
                    <td>{el.description}</td>
                    <td className={Number(el.value) > 0 ? 'plus' : 'minus'}>{el.value}</td>
                    <td>{el.secondValue}</td>
                  </tr>
                );
              })}
          </tbody>
        </table>
        <div className="pagination-bg">
          <Pagination items={items} onChangePage={this.onChangePage} />
        </div>
      </div>
    );
  }
}

export default MyPoint;
