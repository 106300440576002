import React from 'react';
import MainStructure from 'components/structure/MainStructure';
import MyInfoContainer from 'containers/MyInfoContainer';

const MyInfo = () => {
  return (
    <MainStructure>
      <MyInfoContainer />
    </MainStructure>
  );
};

export default MyInfo;
