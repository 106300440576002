import React, { Component } from 'react';
import Privacy from 'components/Privacy';

class PrivacyContainer extends Component {
  render() {
    return <Privacy />;
  }
}

export default PrivacyContainer;
