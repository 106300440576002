import React, { Component } from 'react';
import Review from 'components/Review';

class ReviewContainer extends Component {
  render() {
    return <Review />;
  }
}

export default ReviewContainer;
