import React from 'react';
import MainStructure from 'components/structure/MainStructure';
import ReviewContainer from 'containers/ReviewContainer';

const Review = () => {
  return (
    <MainStructure>
      <ReviewContainer />
    </MainStructure>
  );
};

export default Review;
