import React, { Component } from 'react';
import axios from 'axios';
import { API } from 'config';
import './Terms.css';

class Terms extends Component {
  // 서비스 운영약관
  // localhost:7000/v1/extra/service-rule
  state = {
    data: '',
  };

  componentDidMount() {
    this.getTerms();
  }

  getTerms = async () => {
    try {
      const result = await axios.get(`${API}/extra/service-rule`);
      this.setState({
        ...this.state,
        data: result.data.data,
      });
    } catch (e) {
      if (e.response && e.response.data && e.response.data.msg && e.response.data.msg === '토큰이 만료되었습니다.') {
        localStorage.removeItem('token');
        window.location.href = "/";
        return;
      }
    }
  };

  render() {
    const { data } = this.state;
    return (
      <div className="terms-bg">
        <div className="wrapper">
          <h2 className="terms-title">이용약관</h2>
          <pre className="terms-desc">{data}</pre>
        </div>
      </div>
    );
  }
}

export default Terms;
