import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import axios from 'axios';
import { API } from 'config';
import jwt_decode from 'jwt-decode';
import { Document, Page } from 'react-pdf/dist/esm/entry.webpack';
import moment from 'moment';
import { tagMangerService } from 'lib/TagManagerService';
import { gradeName, abbreviateNumber, numberWithCommas } from 'lib/common';

class ReviewWriting extends Component {
  inputRef = React.createRef();
  imgRef = React.createRef();
  state = {
    imgLoaing: [],
    imgList: [],
    billList: [],
    billLoading: [],
    hashtagList: [],
    options: {
      styletag: [],
      brand: [],
      store: [],
    },
    date: {
      year: '',
      month: '',
      day: '',
    },
    directDetail: {
      styletag: '',
      brand: '',
      store: '',
      purchaseMethod: '',
      howLongTakeToShip: '',
    },
    reviewId: 0,
    review: {
      title: '',
      content: '',
      styletag: '',
      productImgUrls: [],
      productRate: 0,
      priceRate: 0,
      shipRate: 0,
      productName: '',
      brand: '',
      store: '',
      price: 0,
      currency: '',
      purchaseMethod: '',
      purchaseDate: '',
      purchaseSiteUrl: '',
      howLongTakeToShip: '',
      receiptImgUrl: '',
      hashtag: '',
    },
    user: {},
  };

  componentWillMount() {
    if (localStorage.getItem('token')) {
      const user = jwt_decode(localStorage.getItem('token'));
      if (user.grade < 2) {
        alert('초보 오직러부터 후기를 작성하실 수 있습니다:)');
        window.location.href = '/review';
      } else if (user.grade) {
        this.getUser();
      }
    } else {
      alert('초보 오직러부터 후기를 작성하실 수 있습니다:)');
      window.location.href = '/review';
    }
  }

  componentDidMount() {
    localStorage.getItem('token') && this.getUserData();
    // const {} = this.props.history;
    this.getOptions();

    if (this.props.location.search && this.props.location.search.split('id=')) {
      this.getReview(this.props.location.search.split('id=')[1]);
    }
  }

  // handleReview = () => {
  //   const { user } = this.state;
  //   if (user.grade < 2) return alert('초보 오직러부터 후기를 작성하실 수 있습니다:)');
  //   this.props.history.push('/writing/review');
  // };

  // handleGet = () => {
  //   const { user } = this.state;
  //   if (user.grade < 3) return alert('일반 오직러부터 득템을 작성하실 수 있습니다:)');
  //   this.props.history.push('/writing/get');
  // };

  getUser = () => {
    try {
      const user = jwt_decode(localStorage.getItem('token'));
      if (user) {
        this.setState({
          ...this.state,
          user: {
            id: user.id,
          },
        });
      } else {
        localStorage.removeItem('token');
      }
    } catch (e) {
      localStorage.removeItem('token');
      window.location.reload();
    }
  };

  getUserData = async () => {
    const user = jwt_decode(localStorage.getItem('token'));
    if (user) {
      try {
        const result = await axios.get(`${API}/users/my`, {
          headers: {
            token: `${localStorage.getItem('token')}`,
          },
        });

        const newToken = result.headers['new-token'] || '';
        if (newToken) {
          axios.defaults.headers.token = newToken;
          localStorage.setItem('token', newToken);
        }

        if (result && result.status === 200) {
          let user = result.data;

          this.setState({
            ...this.state,
            user,
          });
        } else {
          localStorage.removeItem('token');
          window.location.reload();
        }
      } catch (e) {
        localStorage.removeItem('token');
        window.location.reload();
      }
    } else {
      localStorage.removeItem('token');
    }
  };

  getOptions = async () => {
    try {
      const reulst1 = await axios.get(`${API}/static/style-tags`);
      const reulst2 = await axios.get(`${API}/static/brands`);
      const reulst3 = await axios.get(`${API}/static/stores`);
      let styletag = [];
      let brand = [];
      let store = [];
      reulst1.data.map((el) => {
        if (!el.hidden) {
          styletag.push(el.value);
        }
      });
      reulst2.data.map((el) => {
        if (!el.hidden) {
          brand.push(el.value);
        }
      });
      reulst3.data.map((el) => {
        if (!el.hidden) {
          store.push(el.value);
        }
      });
      this.setState({
        ...this.state,
        options: {
          styletag,
          brand,
          store,
        },
      });
    } catch (e) {
      if (
        e.response &&
        e.response.data &&
        e.response.data.msg &&
        e.response.data.msg === '토큰이 만료되었습니다.'
      ) {
        localStorage.removeItem('token');
        window.location.href = '/';
        return;
      }
    }
  };

  // 에딧하려고 하는데 내글인지 확인 필요할 듯
  getReview = async (id) => {
    const { options, user } = this.state;

    try {
      const result = await axios.get(`${API}/reviews/${id}`, {
        headers: {
          token: `${localStorage.getItem('token')}`,
        },
      });

      if (result.data.user.id !== user.id) return;

      let imgLoaing = [];
      let billLoading = [];

      result.data.productImgUrls.map((el) => {
        imgLoaing.push(false);
      });

      if (result.data.receiptImgUrl) {
        billLoading.push(false);
      }

      const purchaseMethodList = ['직구직배', '배송대행', '구매대행', '사주세요'];
      const howLongTakeToShipList = ['2 ~ 4일 이내', '5 ~ 7일 이내', '8 ~ 14일 이내'];

      // <option value="직구직배">직구직배</option>
      // <option value="배송대행">배송대행</option>
      // <option value="구매대행">구매대행</option>
      // <option value="사주세요">사주세요</option>
      // <option value="기타 (직접입력)">기타 (직접입력)</option>

      // <option value="2 ~ 4일 이내">2 ~ 4일 이내</option>
      // <option value="5 ~ 7일 이내">5 ~ 7일 이내</option>
      // <option value="8 ~ 14일 이내">8 ~ 14일 이내</option>
      // <option value="기타 (직접입력)">기타 (직접입력)</option>

      this.setState({
        ...this.state,
        imgList: result.data.productImgUrls,
        imgLoaing,
        billList: [result.data.receiptImgUrl],
        billLoading,
        reviewId: id,
        date: {
          year: moment(result.data.purchaseDate).format('YYYY-MM-DD').split('-')[0],
          month: moment(result.data.purchaseDate).format('YYYY-MM-DD').split('-')[1],
          day: moment(result.data.purchaseDate).format('YYYY-MM-DD').split('-')[2],
        },
        directDetail: {
          styletag: options.styletag.indexOf(result.data.styletag) > -1 ? '' : result.data.styletag,
          brand: options.brand.indexOf(result.data.brand) > -1 ? '' : result.data.brand,
          store: options.store.indexOf(result.data.store) > -1 ? '' : result.data.store,
          purchaseMethod:
            purchaseMethodList.indexOf(result.data.purchaseMethod) > -1
              ? ''
              : result.data.purchaseMethod,
          howLongTakeToShip:
            howLongTakeToShipList.indexOf(result.data.howLongTakeToShip) > -1
              ? ''
              : result.data.howLongTakeToShip,
        },
        review: {
          title: result.data.title,
          content: result.data.content,
          styletag:
            options.styletag.indexOf(result.data.styletag) > -1
              ? result.data.styletag
              : '기타 (직접입력)',
          productImgUrls: result.data.productImgUrls,
          productRate: result.data.productRate,
          priceRate: result.data.priceRate,
          shipRate: result.data.shipRate,
          productName: result.data.productName,
          brand:
            options.brand.indexOf(result.data.brand) > -1 ? result.data.brand : '기타 (직접입력)',
          store:
            options.store.indexOf(result.data.store) > -1 ? result.data.store : '기타 (직접입력)',
          price: !!Number(result.data.price)
            ? numberWithCommas(result.data.price.replace(/.000/gi, ''))
            : 0,
          currency: result.data.currency,
          purchaseMethod:
            purchaseMethodList.indexOf(result.data.purchaseMethod) > -1
              ? result.data.purchaseMethod
              : '기타 (직접입력)',
          purchaseDate: result.data.purchaseDate,
          purchaseSiteUrl: result.data.purchaseSiteUrl,
          howLongTakeToShip:
            howLongTakeToShipList.indexOf(result.data.howLongTakeToShip) > -1
              ? result.data.howLongTakeToShip
              : '기타 (직접입력)',
          receiptImgUrl: result.data.receiptImgUrl,
          hashtag: '',
        },
      });
    } catch (e) {
      if (
        e.response &&
        e.response.data &&
        e.response.data.msg &&
        e.response.data.msg === '토큰이 만료되었습니다.'
      ) {
        localStorage.removeItem('token');
        window.location.href = '/';
        return;
      }
    }
  };

  handleImage = async (e) => {
    const { imgList, imgLoaing } = this.state;
    const length = imgLoaing.length;
    let imgs = [...imgList];
    let loadings = [...imgLoaing];
    let formData = new FormData();
    [...e.target.files].map((el) => {
      formData.append(e.target.name, el);
      loadings.push(true);
    });
    this.setState({
      ...this.state,
      imgLoaing: loadings,
    });

    try {
      const result = await axios.post(`${API}/upload/images`, formData);
      result.data.map((el, i) => {
        imgs.push(el.location);
        loadings[length + i] = false;
      });
      this.setState({
        ...this.state,
        imgList: imgs,
        imgLoaing: loadings,
      });
    } catch (e) {
      if (
        e.response &&
        e.response.data &&
        e.response.data.msg &&
        e.response.data.msg === '토큰이 만료되었습니다.'
      ) {
        localStorage.removeItem('token');
        window.location.href = '/';
        return;
      }
    }
  };

  handleBill = async (e) => {
    const { billList, billLoading } = this.state;
    const length = billLoading.length;
    let bills = [...billList];
    let loadings = [...billLoading];
    let formData = new FormData();
    [...e.target.files].map((el) => {
      formData.append(e.target.name, el);
      loadings.push(true);
    });
    this.setState({
      ...this.state,
      billLoading: loadings,
    });

    try {
      const result = await axios.post(`${API}/upload/images`, formData);
      result.data.map((el, i) => {
        bills.push(el.location);
        loadings[length + i] = false;
      });
      this.setState({
        ...this.state,
        billList: bills,
        billLoading: loadings,
      });
    } catch (e) {
      if (
        e.response &&
        e.response.data &&
        e.response.data.msg &&
        e.response.data.msg === '토큰이 만료되었습니다.'
      ) {
        localStorage.removeItem('token');
        window.location.href = '/';
        return;
      }
    }
  };

  removeImg = (index) => {
    const { imgList, imgLoaing } = this.state;
    const imgs = [...imgList].filter((el, i) => i !== index);
    const loadings = [...imgLoaing].filter((el, i) => i !== index);

    this.setState({
      ...this.state,
      imgList: imgs,
      imgLoaing: loadings,
    });
    this.imgRef.current.value = '';
  };

  showImg = () => {
    const { imgList, imgLoaing } = this.state;
    let component = [];
    imgLoaing.map((el, i) => {
      component.push(
        <div key={i} className="writing-img-item">
          {imgLoaing[i] ? (
            <div className="img-loader"></div>
          ) : imgList[i] ? (
            <>
              <span className="writing-remove-item" onClick={() => this.removeImg(i)}></span>
              <img src={imgList[i]} alt="" />
            </>
          ) : (
            ''
          )}
        </div>,
      );
    });

    return component;
  };

  removeBill = () => {
    const { billList, billLoading } = this.state;
    this.setState({
      ...this.state,
      billList: [],
      billLoading: [],
    });
    this.inputRef.current.value = '';
  };

  showBill = () => {
    const { billList, billLoading } = this.state;
    let component = [];

    billLoading.map((el, i) => {
      component.push(
        <div key={i} className="writing-img-item">
          {billLoading[i] ? (
            <div className="img-loader"></div>
          ) : billList[i] ? (
            <>
              <span className="writing-remove-item" onClick={this.removeBill}></span>
              {billList[i].indexOf('.pdf') > -1 ? (
                <Document file={billList[i]}>
                  <Page pageNumber={1} />
                </Document>
              ) : (
                <img src={billList[i]} alt="" />
              )}
            </>
          ) : (
            ''
          )}
        </div>,
      );
    });

    return component;
  };

  handleChange = (e) => {
    if (e.target.name === 'price') {
      var numRule = /^[+-]?([0-9]+([.][0-9]{0,3})?|[.][0-9]{0,3})$/i;

      if (!!e.target.value && !numRule.test(e.target.value.replace(/,/gi, ''))) return;
      if (Number(e.target.value.replace(/,/gi, '')) > 1000000000)
        return alert('1,000,000,000보다 작게 입력해주세요:)');

      this.setState({
        ...this.state,
        review: {
          ...this.state.review,
          [e.target.name]: numberWithCommas(e.target.value.replace(/,/gi, '')),
        },
      });
    } else {
      this.setState({
        ...this.state,
        review: {
          ...this.state.review,
          [e.target.name]: e.target.value,
        },
      });
    }
  };

  handleDateChange = (e) => {
    const date = new Date();

    const year = date.getFullYear();
    const month = date.getMonth() + 1;
    const day = date.getDate();

    if (e.target.name === 'year') {
      var numRule = /^[+-]?([0-9]{1,4})$/i;
      if (!!e.target.value && !numRule.test(e.target.value.replace(/,/gi, ''))) return;

      if (Number(e.target.value) > year) {
        return alert('오늘 날짜 보다 클 수 없습니다.');
      }

      if (e.target.value.length >= 4 && Number(e.target.value) < 2000) {
        return alert('2000년도 보다 작을 수 없습니다.');
      }
    }

    if (e.target.name === 'month') {
      var numRule = /^[+-]?([0-9]{1,2})$/i;
      if (!!e.target.value && !numRule.test(e.target.value.replace(/,/gi, ''))) return;

      if (Number(e.target.value) > 12) {
        return alert('12월까지 입력가능합니다');
      }

      if (Number(this.state.date.year) === year && Number(e.target.value) > month) {
        return alert('오늘 날짜 보다 클 수 없습니다.');
      }
    }

    if (e.target.name === 'day') {
      var numRule = /^[+-]?([0-9]{1,2})$/i;
      if (!!e.target.value && !numRule.test(e.target.value.replace(/,/gi, ''))) return;
      console.log(e.target.value);
      console.log(e.target.value);
      if (e.target.value && String(e.target.value).length > 1 && Number(e.target.value) <= 0) {
        return alert('1일부터 입력가능합니다');
      }

      if (Number(e.target.value) > 31) {
        return alert('31일까지 입력가능합니다');
      }

      if (
        Number(this.state.date.year) === year &&
        Number(this.state.date.month) === month &&
        Number(e.target.value) > day
      ) {
        return alert('오늘 날짜 보다 클 수 없습니다.');
      }
    }

    this.setState({
      ...this.state,
      date: {
        ...this.state.date,
        [e.target.name]: e.target.value.trim(),
      },
    });
  };

  handleDetailChange = (e) => {
    this.setState({
      ...this.state,
      directDetail: {
        ...this.state.directDetail,
        [e.target.name]: e.target.value.trim(),
      },
    });
  };

  handleRate = (rate, i) => {
    this.setState({
      ...this.state,
      review: {
        ...this.state.review,
        [rate]: i,
      },
    });
  };

  handleSubmit = async () => {
    const { imgList, billList, date, directDetail, review, reviewId } = this.state;
    const {
      title,
      content,
      styletag,
      productImgUrls,
      productRate,
      priceRate,
      shipRate,
      productName,
      brand,
      store,
      price,
      currency,
      purchaseMethod,
      purchaseDate,
      purchaseSiteUrl,
      howLongTakeToShip,
      receiptImgUrl,
      hashtag,
    } = review;
    // styletag: '',
    // brand: '',
    // store: '',
    // purchaseMethod: '',
    // howLongTakeToShip: '',
    if (!localStorage.getItem('token')) {
      return alert('작성을 위해 로그인해주세요!');
    }

    if (imgList.length === 0) {
      return alert('사진을 최소 1개 선택해주세요!');
    }

    if (!title.trim()) {
      return alert('제목을 입력해주세요!');
    }

    if (!content.trim()) {
      return alert('내용을 입력해주세요!');
    }

    if (!styletag) {
      return alert('스타일태그를 선택해주세요!');
    }

    if (styletag === '기타 (직접입력)' && !directDetail.styletag) {
      return alert('스타일태그를 입력해주세요!');
    }

    if (!productRate) {
      return alert('제품 만족도를 선택해주세요!');
    }

    if (!priceRate) {
      return alert('구매가격 만족도를 선택해주세요!');
    }

    if (!shipRate) {
      return alert('구매처 만족도를 선택해주세요!');
    }

    if (!productName.trim()) {
      return alert('상품이름을 입력해주세요!');
    }

    if (!brand) {
      return alert('브랜드 이름을 선택해주세요!');
    }

    if (brand === '기타 (직접입력)' && !directDetail.brand) {
      return alert('브랜드 이름을 입력해주세요!');
    }

    if (!store) {
      return alert('스토어 이름을 선택해주세요!');
    }

    if (store === '기타 (직접입력)' && !directDetail.store) {
      return alert('스토어 이름을 입력해주세요!');
    }

    if (!price) {
      return alert('구매 가격을 입력해주세요!');
    }

    if (!currency) {
      return alert('구매 통화를 선택해주세요!');
    }

    if (!purchaseMethod) {
      return alert('구매방법을 선택해주세요!');
    }

    if (purchaseMethod === '기타 (직접입력)' && !directDetail.purchaseMethod) {
      return alert('구매방법을 입력해주세요!');
    }

    if (!date.year || !date.month || !date.day) {
      return alert('구매일자를 입력해주세요!');
    }

    const d = new Date();

    const year = d.getFullYear();
    const month = d.getMonth() + 1;
    const day = d.getDate();

    if (Number(date.year) > year) {
      return alert('구매일자를 확인해주세요:)\n오늘 날짜 보다 클 수 없습니다.');
    }

    if (Number(date.year) < 2000) {
      return alert('구매일자를 확인해주세요:)\n2000년 보다 작을 수 없습니다.');
    }

    if (Number(date.year) === year && Number(date.month) > month) {
      return alert('구매일자를 확인해주세요:)\n오늘 날짜 보다 클 수 없습니다.');
    }

    if (Number(date.year) === year && Number(date.month) === month && Number(date.day) > day) {
      return alert('구매일자를 확인해주세요:)\n오늘 날짜 보다 클 수 없습니다.');
    }

    if (!purchaseSiteUrl.trim()) {
      return alert('구매링크를 입력해주세요!');
    }

    if (!howLongTakeToShip) {
      return alert('배송기간을 선택해주세요!');
    }

    if (howLongTakeToShip === '기타 (직접입력)' && !directDetail.howLongTakeToShip) {
      return alert('배송기간을 입력해주세요!');
    }

    try {
      if (!reviewId) {
        const result = await axios.post(
          `${API}/reviews `,
          {
            title: title.trim(),
            content: content.trim(),
            styletag: styletag === '기타 (직접입력)' ? directDetail.styletag : styletag,
            productImgUrls: imgList,
            productRate,
            priceRate,
            shipRate,
            productName: productName.trim(),
            brand: brand === '기타 (직접입력)' ? directDetail.brand : brand,
            store: store === '기타 (직접입력)' ? directDetail.store : store,
            price: Number(price.replace(/,/gi, '')),
            currency,
            purchaseMethod:
              purchaseMethod === '기타 (직접입력)' ? directDetail.purchaseMethod : purchaseMethod,
            purchaseDate:
              date.year +
              '-' +
              (date.month.length === 2 ? date.month : '0' + date.month) +
              '-' +
              (date.day.length === 2 ? date.day : '0' + date.day),
            purchaseSiteUrl: purchaseSiteUrl.trim(),
            howLongTakeToShip:
              howLongTakeToShip === '기타 (직접입력)'
                ? directDetail.howLongTakeToShip
                : howLongTakeToShip,
            receiptImgUrl: billList[0],
            hashtag,
          },
          {
            headers: {
              token: `${localStorage.getItem('token')}`,
            },
          },
        );
        if (result.status === 200) {
          // this.props.history.push(`/review?id=${result.data.id}`);
          window.location.href = `/review?id=${result.data.id}`;

          tagMangerService.reviewAcquireEvent();
        } else {
          alert('각 항목에 맞게 올바르게 작성해주세요:)');
        }
      } else {
        const result = await axios.patch(
          `${API}/reviews/${reviewId}`,
          {
            title: title.trim(),
            content: content.trim(),
            styletag: styletag === '기타 (직접입력)' ? directDetail.styletag : styletag,
            productImgUrls: imgList,
            productRate,
            priceRate,
            shipRate,
            productName: productName.trim(),
            brand: brand === '기타 (직접입력)' ? directDetail.brand : brand,
            store: store === '기타 (직접입력)' ? directDetail.store : store,
            price: Number(price.replace(/,/gi, '')),
            currency,
            purchaseMethod:
              purchaseMethod === '기타 (직접입력)' ? directDetail.purchaseMethod : purchaseMethod,
            purchaseDate:
              date.year +
              '-' +
              (date.month.length === 2 ? date.month : '0' + date.month) +
              '-' +
              (date.day.length === 2 ? date.day : '0' + date.day),
            purchaseSiteUrl: purchaseSiteUrl.trim(),
            howLongTakeToShip:
              howLongTakeToShip === '기타 (직접입력)'
                ? directDetail.howLongTakeToShip
                : howLongTakeToShip,
            receiptImgUrl: billList[0],
            hashtag,
          },
          {
            headers: {
              token: `${localStorage.getItem('token')}`,
            },
          },
        );

        if (result.status === 200) {
          // this.props.history.push(`/review?id=${result.data.id}`);
          window.location.href = `/review?id=${result.data.id}`;
          tagMangerService.reviewAcquireEvent();
        } else {
          alert('각 항목에 맞게 올바르게 작성해주세요:)');
        }
      }
    } catch (e) {
      if (
        e.response &&
        e.response.data &&
        e.response.data.msg &&
        e.response.data.msg === '토큰이 만료되었습니다.'
      ) {
        localStorage.removeItem('token');
        window.location.href = '/';
        return;
      }
      // alert('조금만 이따가 다시 시도해주세요 :)');
    }
  };

  render() {
    const {
      imgList,
      imgLoaing,
      billLoading,
      billList,
      hashtagList,
      review,
      date,
      directDetail,
      options,
      reviewId,
    } = this.state;
    const {
      handleChange,
      handleImage,
      handleBill,
      showImg,
      showBill,
      handleDetailChange,
      handleDateChange,
      handleRate,
      inputRef,
      imgRef,
      handleSubmit,
    } = this;
    return (
      <div className="writing-bg">
        <div className="writing-container wrapper">
          <div>
            <div className="writing-title-bg">
              <h2 className="writing-title">
                오늘의 <strong>후기 작성</strong>
              </h2>
            </div>
            <div className="writing-box">
              <div className="writing-desc-bg">
                <span className="writing-desc" role="img">
                  ✔️ 솔직한 '제품 후기'를 남겨주세요 😊
                </span>
              </div>
              <div className="writing-img-bg">
                <div className="writing-img-box">
                  {imgLoaing.length > 0 ? (
                    showImg()
                  ) : (
                    <div className="writing-img-item default"></div>
                  )}
                </div>
                <div className="writing-file-select-bg">
                  <input
                    className="writing-file-button"
                    type="file"
                    name="images"
                    multiple
                    onChange={(e) => {
                      handleImage(e);
                    }}
                    ref={imgRef}
                  />
                  <button className="writing-file-select">사진선택</button>
                  <span className="writing-file-number">{imgList.length} / 10</span>{' '}
                  <br className="mo-only" />
                  <span className="writing-info">※1개 이상 사진을 반드시 업로드해주세요.</span>
                </div>
              </div>
              <div className="write-input-bg">
                <label className="write-input-label" htmlFor="title">
                  제목
                </label>
                <input
                  className="write-input-text"
                  type="text"
                  name="title"
                  value={review.title}
                  onChange={handleChange}
                  placeholder={'브랜드 & 제품명 필수포함 (정식 명칭)'}
                />
              </div>
              <div className="write-input-bg">
                <label className="write-input-label" htmlFor="content">
                  내용
                </label>
                <textarea
                  className="write-input-textarea"
                  name="content"
                  id=""
                  cols="30"
                  rows="10"
                  value={review.content}
                  onChange={handleChange}
                  placeholder={
                    "✔ 솔직한 '제품 후기'를 남겨주세요 😊\n사진(1장 이상) 제품 후기 (10줄 이상)\n※ 핏, 재질, 착용감, 사이즈 TIP 등\n※ 사진이 흔들리거나 임의로 수정된 경우는 삭제될 수 있어요.\n(이벤트-후기가이드 참고)"
                  }
                ></textarea>
              </div>
              {/* <div className="write-input-bg">
                <label className="write-input-label" htmlFor="">
                  해시태그 <span className="writing-info">※최대 10개까지 등록가능합니다.</span>
                </label>
                <input className="write-input-text" type="text" />
                <div></div>
              </div> */}
              <div className="write-input-bg">
                <label className="write-input-label">
                  스타일태그
                  <br className="mo-only" />
                  <span className="writing-info">
                    ※게시물에 어울리는 스타일 태그를 선택해주세요.
                  </span>
                </label>
                <div className="write-input-child">
                  <select
                    className="write-input-select"
                    name="styletag"
                    value={review.styletag}
                    onChange={handleChange}
                    id=""
                  >
                    <option value="">스타일태그를 선택해주세요</option>
                    {options &&
                      options.styletag &&
                      options.styletag.map((el, i) => {
                        return (
                          <option key={i} value={el}>
                            {el}
                          </option>
                        );
                      })}
                    <option value="기타 (직접입력)">기타 (직접입력)</option>
                  </select>
                  {review && review.styletag === '기타 (직접입력)' && (
                    <input
                      className="write-input-text writing-spacing"
                      name="styletag"
                      type="text"
                      onChange={handleDetailChange}
                      value={directDetail.styletag}
                    />
                  )}
                </div>
              </div>
            </div>
            <div className="writing-box">
              <div className="writing-desc-bg">
                <p className="writing-desc">구매 만족도</p>
              </div>
              <div className="writing-rating-bg">
                <div className="writing-rating-item">
                  <div className="rating clickable" name="productRate">
                    <span
                      className={review.productRate === 5 ? 'checked' : ''}
                      onClick={() => handleRate('productRate', 5)}
                    >
                      ☆
                    </span>
                    <span
                      className={review.productRate === 4 ? 'checked' : ''}
                      onClick={() => handleRate('productRate', 4)}
                    >
                      ☆
                    </span>
                    <span
                      className={review.productRate === 3 ? 'checked' : ''}
                      onClick={() => handleRate('productRate', 3)}
                    >
                      ☆
                    </span>
                    <span
                      className={review.productRate === 2 ? 'checked' : ''}
                      onClick={() => handleRate('productRate', 2)}
                    >
                      ☆
                    </span>
                    <span
                      className={review.productRate === 1 ? 'checked' : ''}
                      onClick={() => handleRate('productRate', 1)}
                    >
                      ☆
                    </span>
                  </div>
                  <p className="writing-rating-desc">제품 만족도</p>
                </div>
                <div className="writing-rating-item">
                  <div className="rating clickable" name="priceRate">
                    <span
                      className={review.priceRate === 5 ? 'checked' : ''}
                      onClick={() => handleRate('priceRate', 5)}
                    >
                      ☆
                    </span>
                    <span
                      className={review.priceRate === 4 ? 'checked' : ''}
                      onClick={() => handleRate('priceRate', 4)}
                    >
                      ☆
                    </span>
                    <span
                      className={review.priceRate === 3 ? 'checked' : ''}
                      onClick={() => handleRate('priceRate', 3)}
                    >
                      ☆
                    </span>
                    <span
                      className={review.priceRate === 2 ? 'checked' : ''}
                      onClick={() => handleRate('priceRate', 2)}
                    >
                      ☆
                    </span>
                    <span
                      className={review.priceRate === 1 ? 'checked' : ''}
                      onClick={() => handleRate('priceRate', 1)}
                    >
                      ☆
                    </span>
                  </div>
                  <p className="writing-rating-desc">구매가격 만족도</p>
                </div>
                <div className="writing-rating-item">
                  <div className="rating clickable" name="shipRate">
                    <span
                      className={review.shipRate === 5 ? 'checked' : ''}
                      onClick={() => handleRate('shipRate', 5)}
                    >
                      ☆
                    </span>
                    <span
                      className={review.shipRate === 4 ? 'checked' : ''}
                      onClick={() => handleRate('shipRate', 4)}
                    >
                      ☆
                    </span>
                    <span
                      className={review.shipRate === 3 ? 'checked' : ''}
                      onClick={() => handleRate('shipRate', 3)}
                    >
                      ☆
                    </span>
                    <span
                      className={review.shipRate === 2 ? 'checked' : ''}
                      onClick={() => handleRate('shipRate', 2)}
                    >
                      ☆
                    </span>
                    <span
                      className={review.shipRate === 1 ? 'checked' : ''}
                      onClick={() => handleRate('shipRate', 1)}
                    >
                      ☆
                    </span>
                  </div>
                  <p className="writing-rating-desc">구매처 만족도</p>
                </div>
              </div>
            </div>
            <div className="writing-box">
              <div className="writing-desc-bg">
                <p className="writing-desc">제품 정보</p>
              </div>
              <div className="write-input-bg">
                <label className="write-input-label" htmlFor="productName">
                  상품 이름
                </label>
                <input
                  className="write-input-text"
                  type="text"
                  name="productName"
                  value={review.productName}
                  onChange={handleChange}
                />
              </div>
              <div className="write-input-bg">
                <label className="write-input-label" htmlFor="brand">
                  브랜드 이름
                </label>
                <div className="write-input-child">
                  <select
                    className="write-input-select"
                    name="brand"
                    value={review.brand}
                    onChange={handleChange}
                    id=""
                  >
                    <option value="">브랜드를 선택해주세요</option>
                    {options &&
                      options.brand &&
                      options.brand.map((el, i) => {
                        return (
                          <option key={i} value={el}>
                            {el}
                          </option>
                        );
                      })}
                    <option value="기타 (직접입력)">기타 (직접입력)</option>
                  </select>
                  {review && review.brand === '기타 (직접입력)' && (
                    <input
                      className="write-input-text writing-spacing"
                      name="brand"
                      type="text"
                      onChange={handleDetailChange}
                      value={directDetail.brand}
                    />
                  )}
                </div>
              </div>
              <div className="write-input-bg">
                <label className="write-input-label" htmlFor="store">
                  스토어 이름
                </label>
                <div className="write-input-child">
                  <select
                    className="write-input-select"
                    name="store"
                    id=""
                    value={review.store}
                    onChange={handleChange}
                  >
                    <option value="">스토어를 선택해주세요</option>
                    {options &&
                      options.store &&
                      options.store.map((el, i) => {
                        return (
                          <option key={i} value={el}>
                            {el}
                          </option>
                        );
                      })}
                    <option value="기타 (직접입력)">기타 (직접입력)</option>
                  </select>
                  {review && review.store === '기타 (직접입력)' && (
                    <input
                      className="write-input-text writing-spacing"
                      name="store"
                      type="text"
                      onChange={handleDetailChange}
                      value={directDetail.store}
                    />
                  )}
                </div>
              </div>
              <div className="write-input-bg">
                <label className="write-input-label" htmlFor="price">
                  구매 가격
                </label>
                <div className="write-input-child">
                  <input
                    className="write-input-text"
                    type="text"
                    name="price"
                    value={review.price}
                    onChange={handleChange}
                  />
                  <select
                    className="writing-spacing write-input-select"
                    name="currency"
                    value={review.currency}
                    onChange={handleChange}
                    id=""
                  >
                    <option value="">구매 통화를 선택해주세요</option>
                    <option value="KRW">원 (KRW)</option>
                    <option value="USD">달러 (USD)</option>
                    <option value="EUR">유로 (EUR)</option>
                    <option value="GBP">파운드 (GBP)</option>
                    <option value="JPY">엔 (JPY)</option>
                    <option value="CNY">위안 (CNY)</option>
                  </select>
                </div>
              </div>
              <div className="write-input-bg">
                <label className="write-input-label" htmlFor="purchaseMethod">
                  구매방법
                </label>
                <div className="write-input-child">
                  <select
                    className="write-input-select"
                    name="purchaseMethod"
                    value={review.purchaseMethod}
                    onChange={handleChange}
                    id=""
                  >
                    <option value="">구매방법을 선택해주세요</option>
                    <option value="직구직배">직구직배</option>
                    <option value="배송대행">배송대행</option>
                    <option value="구매대행">구매대행</option>
                    <option value="사주세요">사주세요</option>
                    <option value="기타 (직접입력)">기타 (직접입력)</option>
                  </select>
                  {review && review.purchaseMethod === '기타 (직접입력)' && (
                    <input
                      className="write-input-text writing-spacing"
                      name="purchaseMethod"
                      type="text"
                      onChange={handleDetailChange}
                      value={directDetail.purchaseMethod}
                    />
                  )}
                </div>
              </div>
              <div className="write-input-bg">
                <label className="write-input-label" htmlFor="purchaseDate">
                  구매일자
                </label>
                <div className="write-input-child">
                  <div className="write-input-date">
                    <label className="write-input-label" htmlFor="year">
                      년
                    </label>
                    <input
                      className="write-input-text"
                      type="text"
                      name="year"
                      value={date.year}
                      onChange={handleDateChange}
                      placeholder="0000"
                    />
                  </div>
                  <div className="write-input-date">
                    <label className="write-input-label" htmlFor="month">
                      월
                    </label>
                    <input
                      className="write-input-text"
                      type="text"
                      name="month"
                      value={date.month}
                      onChange={handleDateChange}
                      placeholder="00"
                    />
                  </div>
                  <div className="write-input-date">
                    <label className="write-input-label" htmlFor="day">
                      일
                    </label>
                    <input
                      className="write-input-text"
                      type="text"
                      name="day"
                      value={date.day}
                      onChange={handleDateChange}
                      placeholder="00"
                    />
                  </div>
                </div>
              </div>
              <div className="write-input-bg">
                <label className="write-input-label" htmlFor="purchaseSiteUrl">
                  구매링크
                </label>
                <input
                  className="write-input-text"
                  type="text"
                  name="purchaseSiteUrl"
                  placeholder="해외사이트 링크 복사 → 붙여넣기 (변환링크X)"
                  value={review.purchaseSiteUrl}
                  onChange={handleChange}
                />
              </div>
              <div className="write-input-bg">
                <label className="write-input-label" htmlFor="howLongTakeToShip">
                  배송기간
                </label>
                <div className="write-input-child">
                  <select
                    className="write-input-select"
                    name="howLongTakeToShip"
                    value={review.howLongTakeToShip}
                    onChange={handleChange}
                    id=""
                  >
                    <option value="">배송기간을 선택해주세요</option>
                    <option value="2 ~ 4일 이내">2 ~ 4일 이내</option>
                    <option value="5 ~ 7일 이내">5 ~ 7일 이내</option>
                    <option value="8 ~ 14일 이내">8 ~ 14일 이내</option>
                    <option value="기타 (직접입력)">기타 (직접입력)</option>
                  </select>
                  {review && review.howLongTakeToShip === '기타 (직접입력)' && (
                    <input
                      className="write-input-text writing-spacing"
                      name="howLongTakeToShip"
                      type="text"
                      onChange={handleDetailChange}
                      value={directDetail.howLongTakeToShip}
                    />
                  )}
                </div>
              </div>
            </div>
            <div className="writing-box">
              <div className="writing-desc-bg">
                <p className="writing-desc">영수증 인증</p>
              </div>
              <div className="writing-img-bg">
                <div className="writing-img-box">
                  {billLoading.length > 0 ? (
                    showBill()
                  ) : (
                    <div className="writing-img-item default"></div>
                  )}
                </div>
                <div className="writing-file-select-bg">
                  <input
                    className="writing-file-button"
                    type="file"
                    name="images"
                    onChange={(e) => {
                      handleBill(e);
                    }}
                    ref={inputRef}
                  />
                  <button className="writing-file-select">영수증 등록</button>
                  <p className="writing-file-desc">
                    선택사항 입니다 :) <br /> ※ 영수증 업로드 시 개인정보는 반드시 미리 가려서
                    올려주세요.
                  </p>
                </div>
              </div>
            </div>
            <div className="writing-button-bg">
              <button
                className="writing-button-cancel"
                onClick={() => (window.location.href = '/')}
              >
                취소하기
              </button>
              <button className="writing-button-write" onClick={handleSubmit}>
                {reviewId ? '수정하기' : '작성하기'}
              </button>
            </div>
          </div>
          {/* <div className="rating">
            <span>☆</span>
            <span>☆</span>
            <span className="checked">☆</span>
            <span>☆</span>
            <span>☆</span>
          </div> */}
        </div>
      </div>
    );
  }
}

export default withRouter(ReviewWriting);
