import React, { Component } from 'react';
import MyPoint from 'components/myinfo/MyPoint';
import MyGrade from 'components/myinfo/MyGrade';
import MyData from 'components/myinfo/MyData';
import axios from 'axios';
import { API } from 'config';
import jwt_decode from 'jwt-decode';
import { withRouter } from 'react-router-dom';
import { gradeName } from 'lib/common';
import './MyInfo.css';

class MyInfo extends Component {
  imgRef = React.createRef();

  state = {
    imgLoaing: [],
    imgList: [],
    menu: {
      info: {
        isActive: true,
      },
      point: {
        isActive: false,
      },
      grade: {
        isActive: false,
      },
    },
    user: {},
    profile: '',
  };
  componentWillMount() {
    if (!localStorage.getItem('token')) {
      window.location.href = '/';
    } else {
      localStorage.getItem('token') && this.getUser();
    }

    if (this.props.location.search.indexOf('point=true') > -1) {
      this.setState({
        ...this.state,
        menu: {
          info: {
            isActive: false,
          },
          point: {
            isActive: true,
          },
          grade: {
            isActive: false,
          },
        },
      });
    }
  }

  componentDidMount() {
    localStorage.getItem('token') && this.getUserData();
  }

  getUser = () => {
    try {
      const user = jwt_decode(localStorage.getItem('token'));
      if (user) {
        this.setState({
          ...this.state,
          user: {
            id: user.id,
          },
        });
      } else {
        localStorage.removeItem('token');
      }
    } catch (e) {
      localStorage.removeItem('token');
      window.location.reload();
    }
  };

  getUserData = async () => {
    const user = jwt_decode(localStorage.getItem('token'));
    if (user) {
      try {
        const result = await axios.get(`${API}/users/my`, {
          headers: {
            token: `${localStorage.getItem('token')}`,
          },
        });

        const newToken = result.headers['new-token'] || '';
        if (newToken) {
          axios.defaults.headers.token = newToken;
          localStorage.setItem('token', newToken);
        }

        if (result && result.status === 200) {
          let user = result.data;

          this.setState({
            ...this.state,
            user,
          });
        } else {
          localStorage.removeItem('token');
          window.location.reload();
        }
      } catch (e) {
        localStorage.removeItem('token');
        window.location.reload();
      }
    } else {
      localStorage.removeItem('token');
    }
  };

  handleMenu = (e, type) => {
    e.stopPropagation();
    let menu = {
      info: {
        isActive: false,
      },
      point: {
        isActive: false,
      },
      grade: {
        isActive: false,
      },
    };

    menu[type]['isActive'] = true;

    this.setState({
      ...this.state,
      menu,
    });

    window.history.pushState(null, null, `/myinfo`);
  };

  handleImage = async (e) => {
    const { imgList, imgLoaing } = this.state;
    const length = imgLoaing.length;
    let imgs = [...imgList];
    let loadings = [...imgLoaing];
    let formData = new FormData();
    [...e.target.files].map((el) => {
      formData.append(e.target.name, el);
      loadings.push(true);
    });
    this.setState({
      ...this.state,
      imgLoaing: loadings,
    });

    try {
      const result = await axios.post(`${API}/upload/images`, formData);
      result.data.map((el, i) => {
        imgs.push(el.location);
        loadings[length + i] = false;
      });
      this.setState(
        {
          ...this.state,
          imgList: imgs,
          imgLoaing: loadings,
        },
        () => {
          this.editProfile();
        },
      );
    } catch (e) {
      if (
        e.response &&
        e.response.data &&
        e.response.data.msg &&
        e.response.data.msg === '토큰이 만료되었습니다.'
      ) {
        localStorage.removeItem('token');
        window.location.href = '/';
        return;
      }
    }
  };

  editProfile = async () => {
    const { imgList, user } = this.state;
    try {
      const result = await axios.patch(`${API}/users/${user.id}`, {
        profileImgUrl: imgList[0],
      });
      if (result && result.status === 200) {
        // this.getUserData();
        window.location.href = '/myinfo';
      }
    } catch (e) {
      if (
        e.response &&
        e.response.data &&
        e.response.data.msg &&
        e.response.data.msg === '토큰이 만료되었습니다.'
      ) {
        localStorage.removeItem('token');
        window.location.href = '/';
        return;
      }
    }
  };

  removeImage = async () => {
    const { imgList, user } = this.state;
    try {
      const result = await axios.patch(`${API}/users/${user.id}`, {
        profileImgUrl: '',
      });
      if (result && result.status === 200) {
        // this.getUserData();
        window.location.href = '/myinfo';
      }
    } catch (e) {
      if (
        e.response &&
        e.response.data &&
        e.response.data.msg &&
        e.response.data.msg === '토큰이 만료되었습니다.'
      ) {
        localStorage.removeItem('token');
        window.location.href = '/';
        return;
      }
    }
  };

  render() {
    const { menu, user, profile, imgList } = this.state;
    const { handleMenu, handleImage, imgRef, removeImage } = this;

    return (
      <div className="myinfo-bg">
        <div className="wrapper">
          <div className="myinfo-header-bg">
            <span className={'main-intro-content-user-profile-bg ' + `grade-${user.grade}`}>
              <span className="remove-profile" onClick={removeImage}></span>
              {imgList.length > 0 ? (
                <span
                  className={'main-intro-content-user-profile '}
                  style={imgList[0] ? { backgroundImage: `url(${imgList[0]})` } : {}}
                ></span>
              ) : (
                <span
                  className={'main-intro-content-user-profile '}
                  style={
                    user.profileImgUrl ? { backgroundImage: `url(${user.profileImgUrl})` } : {}
                  }
                ></span>
              )}
            </span>
            <span className="main-intro-content-user-name">{user.nickname}</span>
            <span className={'main-intro-content-user-grade ' + `grade-${user.grade}`}>
              {gradeName(user.grade)}
            </span>
            <div className="edit-profile-bg">
              <input
                type="file"
                name="images"
                className="edit-profile-input"
                onChange={(e) => {
                  handleImage(e);
                }}
                ref={imgRef}
              />
              <button className="edit-profile"></button>
            </div>
            <div className="myinfo-setting-bg"></div>
          </div>
          <div className="myinfo-menu-bg">
            <ul className="myinfo-menu">
              <li
                className={menu.info.isActive ? 'active' : ''}
                onClick={(e) => handleMenu(e, 'info')}
              >
                회원정보 수정
              </li>
              <li
                className={menu.point.isActive ? 'active' : ''}
                onClick={(e) => handleMenu(e, 'point')}
              >
                내 포인트 현황
              </li>
              <li
                className={menu.grade.isActive ? 'active' : ''}
                onClick={(e) => handleMenu(e, 'grade')}
              >
                회원등급
              </li>
            </ul>
          </div>
          <div className="myinfo-body-bg">
            {menu.info.isActive && <MyData />}
            {menu.point.isActive && <MyPoint />}
            {menu.grade.isActive && <MyGrade />}
          </div>
        </div>
      </div>
    );
  }
}

export default withRouter(MyInfo);
