import React, { Component } from 'react';
import { checkPassword } from 'lib/common';
import axios from 'axios';
import { API } from 'config';
import jwt_decode from 'jwt-decode';
import { withRouter } from 'react-router-dom';
import './MyData.css';
import moment from 'moment';
import SignoutModal from 'components/myinfo/SignoutModal';
import {
  gradeName,
  abbreviateNumber,
  numberWithCommas,
  handleLike,
  handleDib,
  like,
  dib,
} from 'lib/common';

class MyData extends Component {
  state = {
    change: {
      login: false,
      user: false,
      add: false,
    },
    needValidate: {
      email: false,
      password: false,
      phone: false,
    },
    confirm: {
      email: false,
      password: false,
      phone: false,
    },
    origin: {
      email: '',
      password: '',
      newPassword1: '',
      newPassword2: '',
      nickname: '',
      name: '',
      phone: '',
      year: '',
      month: '',
      day: '',
      gender: '',
      address1: '',
      address2: '',
      pccc: '',
      weight: 'XS',
      height: 0,
      bodyShape: 1,
      shoeSize: 0,
      openBodyInfo: true,
    },
    data: {
      email: '',
      password: '',
      newPassword1: '',
      newPassword2: '',
      nickname: '',
      name: '',
      phone: '',
      year: '',
      month: '',
      day: '',
      gender: '',
      address1: '',
      address2: '',
      pccc: '',
      weight: 'XS',
      height: 0,
      bodyShape: 1,
      shoeSize: 0,
      openBodyInfo: true,
      phoneCode: '',
    },
    shape: 1,
    user: {},
    signout: {
      isClicked: false,
      title: '',
      content: '',
    },
  };

  componentWillMount() {
    if (localStorage.getItem('token')) {
      this.getUser();
    } else {
      window.location.href = '/';
    }
  }

  componentDidMount() {
    localStorage.getItem('token') && this.getUserData();
  }

  getUserData = async () => {
    const user = jwt_decode(localStorage.getItem('token'));
    if (user) {
      try {
        const result = await axios.get(`${API}/users/my`, {
          headers: {
            token: `${localStorage.getItem('token')}`,
          }
        });

        const newToken = result.headers["new-token"] || '';
        if (newToken) {
          axios.defaults.headers.token = newToken;
          localStorage.setItem("token", newToken);
        }

        if (result && result.status === 200) {

          let user = result.data;

          let bodyShape = '';

          switch (user.bodyShape) {
            case '상체에 비해 하체가 발달한 편':
              bodyShape = 1;
              break;
            case '하체에 비해 상체가 발달한 편':
              bodyShape = 2;
              break;
            case '전체적으로 몸과 팔다리가 가는 편':
              bodyShape = 3;
              break;
            case '다른곳에 비해 허리가 많이 잘록한편':
              bodyShape = 4;
              break;
            case '상하체가 균형있고 평범한 편':
              bodyShape = 5;
              break;
            default:
              break;
          }

          user.bodyShape = bodyShape;
          user.point = String(result.data.point);

          this.setState({
            ...this.state,
            user,
            origin: {
              email: user.email,
              password: '',
              newPassword1: '',
              newPassword2: '',
              nickname: user.nickname,
              name: user.name,
              phone: user.phone,
              year: (user.birthday && moment(user.birthday).format('YYYY-MM-DD').split('-')[0]) || '',
              month:
                (user.birthday && moment(user.birthday).format('YYYY-MM-DD').split('-')[1]) || '',
              day: (user.birthday && moment(user.birthday).format('YYYY-MM-DD').split('-')[2]) || '',
              gender: user.gender,
              address1: user.address1,
              address2: user.address2,
              pccc: user.pccc,
              weight: user.weight,
              height: user.height,
              bodyShape,
              shoeSize: user.shoeSize,
              openBodyInfo: user.openBodyInfo,
            },
            data: {
              email: user.email,
              password: '',
              newPassword1: '',
              newPassword2: '',
              nickname: user.nickname,
              name: user.name,
              phone: user.phone,
              year: (user.birthday && moment(user.birthday).format('YYYY-MM-DD').split('-')[0]) || '',
              month:
                (user.birthday && moment(user.birthday).format('YYYY-MM-DD').split('-')[1]) || '',
              day: (user.birthday && moment(user.birthday).format('YYYY-MM-DD').split('-')[2]) || '',
              gender: user.gender,
              address1: user.address1,
              address2: user.address2,
              pccc: user.pccc,
              weight: user.weight,
              height: user.height,
              bodyShape,
              shoeSize: user.shoeSize,
              openBodyInfo: user.openBodyInfo,
            },
          });
        } else {
          localStorage.removeItem('token');
          window.location.reload();
        }
      } catch (e) {
        localStorage.removeItem('token');
        window.location.reload();
      }
    } else {
      localStorage.removeItem('token');
    }
  };

  getUser = () => {
    try {
      const user = jwt_decode(localStorage.getItem('token'));
      if (user) {
        this.setState({
          ...this.state,
          user: {
            id: user.id,
          },
        });
      } else {
        localStorage.removeItem('token');
      }
    } catch (e) {
      localStorage.removeItem('token');
      window.location.reload();
    }
  };

  // getUser = async () => {
  //   try {
  //     const user = jwt_decode(localStorage.getItem('token'));
  //     const result = await axios.get(`${API}/users/${user.id}`);

  //     if (result && result.status === 200) {
  //       user.point = String(result.data.point);
  //     }

  //     let bodyShape = '';

  //     switch (user.bodyShape) {
  //       case '상체에 비해 하체가 발달한 편':
  //         bodyShape = 1;
  //         break;
  //       case '하체에 비해 상체가 발달한 편':
  //         bodyShape = 2;
  //         break;
  //       case '전체적으로 몸과 팔다리가 가는 편':
  //         bodyShape = 3;
  //         break;
  //       case '다른곳에 비해 허리가 많이 잘록한편':
  //         bodyShape = 4;
  //         break;
  //       case '상하체가 균형있고 평범한 편':
  //         bodyShape = 5;
  //         break;
  //       default:
  //         break;
  //     }

  //     user.bodyShape = bodyShape;

  //     this.setState({
  //       ...this.state,
  //       user,
  //       origin: {
  //         email: user.email,
  //         password: '',
  //         newPassword1: '',
  //         newPassword2: '',
  //         nickname: user.nickname,
  //         name: user.name,
  //         phone: user.phone,
  //         year: (user.birthday && moment(user.birthday).format('YYYY-MM-DD').split('-')[0]) || '',
  //         month: (user.birthday && moment(user.birthday).format('YYYY-MM-DD').split('-')[1]) || '',
  //         day: (user.birthday && moment(user.birthday).format('YYYY-MM-DD').split('-')[2]) || '',
  //         gender: user.gender,
  //         address1: user.address1,
  //         address2: user.address2,
  //         zonecode: '',
  //         pccc: user.pccc,
  //         weight: user.weight,
  //         height: user.height,
  //         bodyShape,
  //         shoeSize: user.shoeSize,
  //         openBodyInfo: user.openBodyInfo,
  //       },
  //       data: {
  //         email: user.email,
  //         password: '',
  //         newPassword1: '',
  //         newPassword2: '',
  //         nickname: user.nickname,
  //         name: user.name,
  //         phone: user.phone,
  //         year: (user.birthday && moment(user.birthday).format('YYYY-MM-DD').split('-')[0]) || '',
  //         month: (user.birthday && moment(user.birthday).format('YYYY-MM-DD').split('-')[1]) || '',
  //         day: (user.birthday && moment(user.birthday).format('YYYY-MM-DD').split('-')[2]) || '',
  //         gender: user.gender,
  //         zonecode: '',
  //         address1: user.address1,
  //         address2: user.address2,
  //         pccc: user.pccc,
  //         weight: user.weight,
  //         height: user.height,
  //         bodyShape,
  //         shoeSize: user.shoeSize,
  //         openBodyInfo: user.openBodyInfo,
  //       },
  //     });
  //   } catch (e) {
  //     localStorage.removeItem('token');
  //     window.location.href = '/';
  //   }
  // };

  handleButton = async (type) => {
    const { password, user } = this.state.data;

    if (type === 'login') {
      try {
        const result = await axios.patch(
          `${API}/users/${user.id}`,
          {
            password,
          },
          {
            headers: {
              token: `${localStorage.getItem('token')}`,
            },
          },
        );
      } catch (e) {
        if (e.response && e.response.data && e.response.data.msg && e.response.data.msg === '토큰이 만료되었습니다.') {
          localStorage.removeItem('token');
          window.location.href = "/";
          return;
        }
      }
    }
    this.setState({
      ...this.state,
      change: {
        ...this.state.change,
        [type]: true,
      },
    });
  };

  handleChange = (e) => {
    if (e.target.name === 'phone') {
      if (this.state.origin.phone !== e.target.value) {
        this.setState({
          ...this.state,
          needValidate: {
            ...this.state.needValidate,
            phone: true,
          },
          data: {
            ...this.state.data,
            [e.target.name]: e.target.value,
          },
        });
      } else {
        this.setState({
          ...this.state,
          data: {
            ...this.state.data,
            [e.target.name]: e.target.value,
          },
        });
      }
    } else {
      this.setState({
        ...this.state,
        data: {
          ...this.state.data,
          [e.target.name]: e.target.value,
        },
      });
    }
  };

  editInformation = async () => {
    const { user, data, needValidate, confirm } = this.state;
    const { nickname, name, phone, birthday, gender } = data;
    if (needValidate.phone && !confirm.phone) return alert('휴대폰 인증을 먼저 진행해주세요:)');
    try {
      const result = await axios.patch(`${API}/users/${user.id}`, {
        nickname,
        name,
        phone,
        birthday,
        gender,
      });
      if (result && result.status === 200) {
        this.setState({
          ...this.state,
          change: {
            ...this.state.change,
            user: false,
          },
        });
        this.getUserData();
      }
    } catch (e) {
      if (e.response && e.response.data && e.response.data.msg && e.response.data.msg === '토큰이 만료되었습니다.') {
        localStorage.removeItem('token');
        window.location.href = "/";
        return;
      }
    }
  };

  editAddInfo = async () => {
    const { user, data } = this.state;
    const { address1, address2, pcc, weight, height, bodyShape, shoeSize, openBodyInfo } = data;

    let b = '';

    switch (bodyShape) {
      case 1:
        b = '상체에 비해 하체가 발달한 편';
        break;
      case 2:
        b = '하체에 비해 상체가 발달한 편';
        break;
      case 3:
        b = '전체적으로 몸과 팔다리가 가는 편';
        break;
      case 4:
        b = '다른곳에 비해 허리가 많이 잘록한편';
        break;
      case 5:
        b = '상하체가 균형있고 평범한 편';
        break;
      default:
        break;
    }

    try {
      const result = await axios.patch(`${API}/users/${user.id}`, {
        address1,
        address2,
        pcc,
        weight: weight,
        height: Number(height),
        bodyShape: b,
        shoeSize: Number(shoeSize),
        openBodyInfo,
      });
      if (result && result.status === 200) {
        this.setState({
          ...this.state,
          change: {
            ...this.state.change,
            add: false,
          },
        });
        this.getUserData();
      }
    } catch (e) {
      if (e.response && e.response.data && e.response.data.msg && e.response.data.msg === '토큰이 만료되었습니다.') {
        localStorage.removeItem('token');
        window.location.href = "/";
        return;
      }
    }
  };

  handlePhoneVerify = async () => {
    const { phone } = this.state.data;
    try {
      const check = await axios.get(`${API}/users`, {
        params: {
          phone,
        },
      });

      if (check.data[0].length > 0) return alert('이미 존재하는 휴대전화입니다!');

      const result = await axios.post(`${API}/users/verify-phone`, {
        phone,
      });
      if (result.data === 'ok') {
        alert('휴대폰 인증 번호 발송:)');
        this.setState({
          ...this.state,
          needValidate: {
            ...this.state.needValidate,
            phone: true,
          },
        });
      }
    } catch (e) {
      if (e.response && e.response.data && e.response.data.msg && e.response.data.msg === '토큰이 만료되었습니다.') {
        localStorage.removeItem('token');
        window.location.href = "/";
        return;
      }
    }
  };

  handlePhoneCode = async () => {
    const { phone, phoneCode } = this.state.data;
    try {
      const result = await axios.post(`${API}/users/verify-phone-code`, {
        phone,
        code: phoneCode,
      });
      if (result.data === 'ok') {
        alert('휴대폰 인증 성공:)');
        this.setState({
          ...this.state,
          confirm: {
            ...this.state.confirm,
            phone: true,
          },
        });
      } else {
        alert('휴대폰 인증을 다시 시도해주세요:)');
      }
    } catch (e) {
      if (e.response && e.response.data && e.response.data.msg && e.response.data.msg === '토큰이 만료되었습니다.') {
        localStorage.removeItem('token');
        window.location.href = "/";
        return;
      }
    }
  };

  handleMap = () => {
    const _this = this;
    window.daum.postcode.load(function () {
      new window.daum.Postcode({
        oncomplete: function (result) {
          if (result.userSelectedType === 'R') {
            _this.setState({
              ..._this.state,
              data: {
                ..._this.state.data,
                zonecode: result.zonecode,
                address1: result.roadAddress,
              },
            });
          } else {
            _this.setState({
              ..._this.state,
              data: {
                ..._this.state.data,
                zonecode: result.zonecode,
                address1: result.jibunAddress,
              },
            });
          }
        },
      }).open();
    });
  };

  // /v1/users/update-password
  // PATCH
  // {
  //   oldPassword: 예전 비밀번호
  //   password: 새로운 비밀번호
  // }

  hanldeLoginData = async () => {
    const { password, newPassword1, newPassword2 } = this.state.data;

    var passRule = /(?=.*\d)(?=.*[a-z]).{10,}$/;

    // if (!checkPassword(newPassword1)) return alert('패스워드 설정안내를 확인해 주세요:)');
    if (newPassword1 !== newPassword2) return alert('입력하신 패스워드가 다릅니다:)');
    if (password === newPassword1) return alert('이전 패스워드와 동일합니다:)');
    if (!passRule.test(newPassword1)) return alert('패스워드 형식에 맞춰 작성 해주세요:)');

    try {
      const result = await axios.patch(
        `${API}/users/update-password`,
        {
          oldPassword: password,
          password: newPassword1,
        },
        {
          headers: {
            token: `${localStorage.getItem('token')}`,
          },
        },
      );

      if (result && result.status === 200) {
        this.setState({
          ...this.state,
          change: {
            ...this.state.change,
            login: false,
          },
        });
        this.getUserData();
      }
    } catch (e) {
      if (e.response && e.response.data && e.response.data.msg && e.response.data.msg === '토큰이 만료되었습니다.') {
        localStorage.removeItem('token');
        window.location.href = "/";
        return;
      }
    }
  };

  handleShape = (bodyShape) => {
    this.setState({
      ...this.state,
      data: {
        ...this.state.data,
        bodyShape,
      },
    });
  };

  handleOpenBody = () => {
    this.setState({
      ...this.state,
      data: {
        ...this.state.data,
        openBodyInfo: !this.state.data.openBodyInfo,
      },
    });
  };

  closeButton = (type) => {
    this.setState({
      ...this.state,
      change: {
        ...this.state.change,
        [type]: false,
      },
    });
  };

  clickSignout = () => {
    this.setState({
      ...this.state,
      signout: {
        ...this.state.signout,
        isClicked: true,
      },
    });
  };

  closeSignout = () => {
    this.setState({
      ...this.state,
      signout: {
        ...this.state.signout,
        isClicked: false,
        content: '',
      },
    });
  };

  handleSignout = (e) => {
    this.setState({
      ...this.state,
      signout: {
        ...this.state.signout,
        [e.target.name]: e.target.value,
      },
    });
  };

  doSignout = async () => {
    const { user, signout } = this.state;
    try {
      const result = await axios.delete(`${API}/users/${user.id}`, {
        headers: {
          token: localStorage.getItem('token'),
        },
      });
      if (result && result.status === 200) {
        localStorage.removeItem('token');
        // this.props.history.push('/');
        window.location.href = '/';
        alert('오직을 이용해주셔서 감사합니다:)');
      }
    } catch (e) {
      if (e.response && e.response.data && e.response.data.msg && e.response.data.msg === '토큰이 만료되었습니다.') {
        localStorage.removeItem('token');
        window.location.href = "/";
        return;
      }
    }
  };

  render() {
    const { change, shape, data, user, origin, needValidate, confirm, signout } = this.state;
    const {
      handleButton,
      closeButton,
      handleShape,
      handleChange,
      hanldeLoginData,
      handleOpenBody,
      handlePhoneVerify,
      handlePhoneCode,
      editInformation,
      handleMap,
      editAddInfo,
      clickSignout,
      closeSignout,
      handleSignout,
      doSignout,
    } = this;

    return (
      <>
        <div className="mydata-bg">
          <div className="wrapper">
            <div className="mydata-item">
              <div className="writing-desc-bg">
                <p className="writing-desc">로그인 정보</p>
              </div>

              <div className="mydata-static-bg">
                <span className="mydata-static-title">이메일</span>
                <span className="mydata-static-content">{user.email}</span>
              </div>
              {change.login && (
                <>
                  <div className="write-input-bg">
                    <label className="write-input-label" htmlFor="title">
                      비밀번호
                    </label>

                    <>
                      <input
                        className="write-input-text"
                        type="password"
                        name="password"
                        placeholder="현재 비밀번호를 입력하세요"
                        value={data.password}
                        onChange={handleChange}
                      />
                      <input
                        className="write-input-text"
                        type="password"
                        name="newPassword1"
                        placeholder="새 비밀번호를 입력하세요"
                        value={data.newPassword1}
                        onChange={handleChange}
                      />
                      <input
                        className="write-input-text"
                        type="password"
                        name="newPassword2"
                        placeholder="소문자 및 숫자를 함께 이용해서 10자리이상 입력해주세요:)"
                        value={data.newPassword2}
                        onChange={handleChange}
                      />
                    </>
                  </div>
                </>
              )}
              <div className="mydata-button-bg">
                {change.login ? (
                  <>
                    <button
                      className="mydata-button-cancel"
                      onClick={() => {
                        closeButton('login');
                      }}
                    >
                      변경 취소
                    </button>
                    <button className="mydata-button-save" onClick={hanldeLoginData}>
                      변경하기
                    </button>
                  </>
                ) : (
                    <>
                      <button className="mydata-button-change" onClick={() => handleButton('login')}>
                        비밀번호 변경
                    </button>
                    </>
                  )}
              </div>
            </div>
            <div className="mydata-item">
              <div className="writing-desc-bg">
                <p className="writing-desc">회원 정보</p>
              </div>
              <div className="mydata-static-bg">
                <span className="mydata-static-title">회원등급</span>
                <span className={'mydata-static-content ' + `grade-${user.grade}`}>
                  {gradeName(user.grade)}
                </span>
              </div>
              <div className="mydata-static-bg">
                <span className="mydata-static-title">포인트</span>
                <span className="mydata-static-content point">
                  {(user.point && numberWithCommas(user.point)) || 0} P
                </span>
              </div>
              {change.user ? (
                <>
                  <div className="write-input-bg line">
                    <label className="write-input-label" htmlFor="nickname">
                      닉네임
                    </label>
                    <input
                      className="write-input-text"
                      type="text"
                      name="nickname"
                      maxLength={6}
                      value={data.nickname}
                      onChange={handleChange}
                    />
                  </div>
                  <div className="write-input-bg">
                    <label className="write-input-label" htmlFor="name">
                      성명
                    </label>
                    <input
                      className="write-input-text"
                      type="text"
                      name="name"
                      value={data.name}
                      onChange={handleChange}
                    />
                  </div>
                  <div className="write-input-bg">
                    <label className="write-input-label" htmlFor="phone">
                      휴대전화
                    </label>
                    <div className="write-input-child">
                      <input
                        className="write-input-text"
                        type="text"
                        name="phone"
                        value={data.phone}
                        onChange={handleChange}
                      />
                      {origin.phone !== data.phone && needValidate.phone && !confirm.phone && (
                        <button className="mydata-button-phone" onClick={handlePhoneVerify}>
                          인증번호 받기
                        </button>
                      )}
                    </div>
                  </div>
                  {origin.phone !== data.phone && needValidate.phone && !confirm.phone && (
                    <div className="write-input-bg">
                      <label className="write-input-label" htmlFor="name">
                        인증번호
                      </label>
                      <div className="write-input-child">
                        <input
                          className="write-input-text"
                          type="text"
                          name="phoneCode"
                          value={data.phoneCode}
                          onChange={handleChange}
                        />
                        <button className="mydata-button-phone" onClick={handlePhoneCode}>
                          휴대폰 인증
                        </button>
                      </div>
                    </div>
                  )}
                  <div className="write-input-bg">
                    <label className="write-input-label" htmlFor="purchaseDate">
                      생년월일
                    </label>
                    <div className="write-input-child">
                      <div className="write-input-date">
                        <label className="write-input-label" htmlFor="year">
                          년
                        </label>
                        <input
                          className="write-input-text"
                          type="text"
                          name="year"
                          value={data.year}
                          onChange={handleChange}
                          placeholder="0000"
                        />
                      </div>
                      <div className="write-input-date">
                        <label className="write-input-label" htmlFor="month">
                          월
                        </label>
                        <input
                          className="write-input-text"
                          type="text"
                          name="month"
                          value={data.month}
                          onChange={handleChange}
                          placeholder="00"
                        />
                      </div>
                      <div className="write-input-date">
                        <label className="write-input-label" htmlFor="day">
                          일
                        </label>
                        <input
                          className="write-input-text"
                          type="text"
                          name="day"
                          value={data.day}
                          onChange={handleChange}
                          placeholder="00"
                        />
                      </div>
                    </div>
                  </div>
                  <div className="write-input-bg">
                    <label className="write-input-label" htmlFor="gender">
                      성별
                    </label>
                    <div className="write-input-child">
                      <select
                        className="write-input-select"
                        name="gender"
                        value={data.gender}
                        onChange={handleChange}
                      >
                        <option value="male">남성</option>
                        <option value="female">여성</option>
                      </select>
                    </div>
                  </div>
                </>
              ) : (
                  <>
                    <div className="mydata-static-bg line">
                      <span className="mydata-static-title">닉네임</span>
                      <span className="mydata-static-content">{user.nickname}</span>
                    </div>
                    <div className="mydata-static-bg">
                      <span className="mydata-static-title">성명</span>
                      <span className="mydata-static-content">{user.name}</span>
                    </div>
                    <div className="mydata-static-bg">
                      <span className="mydata-static-title">연락처</span>
                      <span className="mydata-static-content">{user.phone}</span>
                    </div>
                    <div className="mydata-static-bg">
                      <span className="mydata-static-title">생년월일</span>
                      <span className="mydata-static-content">
                        {(user.birthday && moment(user.birthday).format('YYYY-MM-DD')) || '-'}
                      </span>
                    </div>
                    <div className="mydata-static-bg">
                      <span className="mydata-static-title">성별</span>
                      <span className="mydata-static-content">
                        {user.gender === 'male' ? '남성' : '여성'}
                      </span>
                    </div>
                  </>
                )}

              <div className="mydata-button-bg">
                {change.user ? (
                  <>
                    <button className="mydata-button-cancel" onClick={() => closeButton('user')}>
                      변경 취소
                    </button>
                    <button className="mydata-button-save" onClick={() => editInformation()}>
                      저장하기
                    </button>
                  </>
                ) : (
                    <button className="mydata-button-change" onClick={() => handleButton('user')}>
                      변경
                  </button>
                  )}
              </div>
            </div>
            <div className="mydata-item">
              <div className="writing-desc-bg">
                <p className="writing-desc">추가 정보</p>
              </div>
              {change.add ? (
                <>
                  <div className="write-input-bg">
                    <label className="write-input-label" htmlFor="productName">
                      주소
                    </label>
                    <div className="write-input-child column">
                      <div className="mydata-button-search-bg">
                        <input
                          className="write-input-text"
                          type="text"
                          name="address1"
                          value={data.address1 || ''}
                          readOnly
                        />
                        <button className="mydata-button-search" onClick={handleMap}>
                          주소검색
                        </button>
                      </div>
                      {/* <input
                      className="write-input-text"
                      type="text"
                      name="address1"
                      value={data.address1 || ''}
                      readOnly
                    /> */}
                      <input
                        className="write-input-text"
                        type="text"
                        name="address2"
                        value={data.address2 || ''}
                        onChange={handleChange}
                      />
                    </div>
                  </div>
                  <div className="write-input-bg">
                    <label className="write-input-label" htmlFor="pccc">
                      개인통관부호
                    </label>
                    <input
                      className="write-input-text"
                      type="text"
                      name="pccc"
                      value={data.pccc || ''}
                      onChange={handleChange}
                    />
                  </div>
                  <div className="write-input-bg">
                    <label className="write-input-label" htmlFor="height">
                      키
                    </label>
                    <input
                      className="write-input-text"
                      type="text"
                      name="height"
                      value={data.height || ''}
                      onChange={handleChange}
                    />
                  </div>
                  <div className="write-input-bg">
                    <label className="write-input-label" htmlFor="weight">
                      신체사이즈
                    </label>
                    <select
                      className="write-input-select"
                      name="weight"
                      value={data.weight}
                      onChange={handleChange}
                    >
                      <option value="XS">XS</option>
                      <option value="S">S</option>
                      <option value="M">M</option>
                      <option value="L">L</option>
                      <option value="XL">XL</option>
                      <option value="XXL">XXL</option>
                    </select>
                    {/* <input
                      className="write-input-text"
                      type="text"
                      name="weight"
                      value={data.weight || ''}
                      onChange={handleChange}
                    /> */}
                  </div>
                  <div className="write-input-bg">
                    <label className="write-input-label" htmlFor="productName">
                      체형
                    </label>
                    <div className="write-input-child shape">
                      <button onClick={() => handleShape(1)}>
                        {data.bodyShape === 1 ? (
                          <img src="/images/shape_1_active.svg" alt="" />
                        ) : (
                            <img src="/images/shape_1_default.svg" alt="" />
                          )}
                      </button>
                      <button onClick={() => handleShape(2)}>
                        {data.bodyShape === 2 ? (
                          <img src="/images/shape_2_active.svg" alt="" />
                        ) : (
                            <img src="/images/shape_2_default.svg" alt="" />
                          )}
                      </button>
                      <button onClick={() => handleShape(3)}>
                        {data.bodyShape === 3 ? (
                          <img src="/images/shape_3_active.svg" alt="" />
                        ) : (
                            <img src="/images/shape_3_default.svg" alt="" />
                          )}
                      </button>
                      <button onClick={() => handleShape(4)}>
                        {data.bodyShape === 4 ? (
                          <img src="/images/shape_4_active.svg" alt="" />
                        ) : (
                            <img src="/images/shape_4_default.svg" alt="" />
                          )}
                      </button>
                      <button onClick={() => handleShape(5)}>
                        {data.bodyShape === 5 ? (
                          <img src="/images/shape_5_active.svg" alt="" />
                        ) : (
                            <img src="/images/shape_5_default.svg" alt="" />
                          )}
                      </button>
                    </div>
                  </div>
                  <div className="write-input-bg">
                    <label className="write-input-label" htmlFor="shoeSize">
                      신발사이즈
                    </label>
                    <input
                      className="write-input-text"
                      type="text"
                      name="shoeSize"
                      value={data.shoeSize || ''}
                      onChange={handleChange}
                    />
                  </div>
                  <div className="mydata-static-bg">
                    <span className="mydata-static-title">신체정보 공개</span>
                    <span className="mydata-static-content">
                      <input
                        type="checkbox"
                        className={'mydata-check ' + (data.openBodyInfo ? 'checked' : '')}
                        id="mb1"
                        name={'openBodyInfo'}
                        onClick={handleOpenBody}
                      />
                      <label htmlFor="mb1"></label>
                    </span>
                  </div>
                </>
              ) : (
                  <>
                    <div className="mydata-static-bg column">
                      <span className="mydata-static-title">주소</span>
                      <span className="mydata-static-content">
                        {(user.address1 && user.address1) + ' ' + (user.address2 && user.address2)}
                      </span>
                    </div>
                    <div className="mydata-static-bg column">
                      <span className="mydata-static-title">개인통관부호</span>
                      <span className="mydata-static-content">{user.pccc}</span>
                    </div>
                    <div className="mydata-static-bg">
                      <span className="mydata-static-title">키</span>
                      <span className="mydata-static-content">{user.height}</span>
                    </div>
                    <div className="mydata-static-bg">
                      <span className="mydata-static-title">신체사이즈</span>
                      <span className="mydata-static-content">{user.weight}</span>
                    </div>
                    <div className="mydata-static-bg">
                      <span className="mydata-static-title">체형</span>
                      <span className="mydata-static-content">
                        <img src={`/images/shape_${user.bodyShape}_active.svg`} alt="" />
                      </span>
                    </div>
                    <div className="mydata-static-bg">
                      <span className="mydata-static-title">신발 사이즈</span>
                      <span className="mydata-static-content">{user.shoeSize}</span>
                    </div>
                    <div className="mydata-static-bg">
                      <span className="mydata-static-title">신체정보 공개</span>
                      <span className="mydata-static-content">
                        <input
                          type="checkbox"
                          className={'mydata-check ' + (user.openBodyInfo ? 'checked' : '')}
                          id="mb1"
                          name={'agree'}
                        />
                        <label htmlFor="mb1"></label>
                      </span>
                    </div>
                  </>
                )}
              <div className="mydata-button-bg">
                {change.add ? (
                  <>
                    <button className="mydata-button-cancel" onClick={() => closeButton('add')}>
                      변경 취소
                    </button>
                    <button className="mydata-button-save" onClick={editAddInfo}>
                      저장하기
                    </button>
                  </>
                ) : (
                    <button className="mydata-button-change" onClick={() => handleButton('add')}>
                      변경
                  </button>
                  )}
              </div>
              <div className="mydata-button-bg">
                <button className="mydata-button-signout" onClick={clickSignout}>
                  회원 탈퇴하기
                </button>
              </div>
            </div>
          </div>
        </div>
        <SignoutModal
          isOpen={signout.isClicked}
          handleClose={closeSignout}
          handleChange={handleSignout}
          handleClick={doSignout}
          data={signout}
        />
      </>
    );
  }
}

export default withRouter(MyData);
