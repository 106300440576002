import React from 'react';
import MainStructure from 'components/structure/MainStructure';
import OtherPageContainer from 'containers/OtherPageContainer';

const OtherPage = () => {
  return (
    <MainStructure>
      <OtherPageContainer />
    </MainStructure>
  );
};

export default OtherPage;
