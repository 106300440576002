import React, { Component } from 'react';
import jwt_decode from 'jwt-decode';
import axios from 'axios';
import { API } from 'config';
import './MyGrade.css';
import { gradeName, abbreviateNumber } from 'lib/common';

class MyGrade extends Component {
  state = {
    user: {},
    data: {},
  };

  componentWillMount() {
    if (!localStorage.getItem('token')) {
      window.location.href = '/';
    } else {
      this.getUser();
    }
  }

  componentDidMount() {
    localStorage.getItem('token') && this.getUserData();
  }

  getUser = () => {
    try {
      const user = jwt_decode(localStorage.getItem('token'));
      if (user) {
        this.setState({
          ...this.state,
          user: {
            id: user.id,
          },
        });
      } else {
        localStorage.removeItem('token');
      }
    } catch (e) {
      localStorage.removeItem('token');
      window.location.reload();
    }
  };

  getUserData = async () => {
    const user = jwt_decode(localStorage.getItem('token'));
    if (user) {
      try {
        const result = await axios.get(`${API}/users/my`, {
          headers: {
            token: `${localStorage.getItem('token')}`,
          },
        });

        const newToken = result.headers['new-token'] || '';
        if (newToken) {
          axios.defaults.headers.token = newToken;
          localStorage.setItem('token', newToken);
        }

        if (result && result.status === 200) {
          this.setState({
            ...this.state,
            data: result.data,
          });
        } else {
          localStorage.removeItem('token');
          window.location.reload();
        }
      } catch (e) {
        localStorage.removeItem('token');
        window.location.reload();
      }
    } else {
      localStorage.removeItem('token');
    }
  };

  render() {
    const { user, data } = this.state;

    const option = {
      1: '모든 게시물 읽기, 댓글 및 [오늘의 토크] 작성 가능',
      2: '[오늘의 후기] 작성 가능',
      3: '[오늘의 득템] 작성 가능',
      4: '보러가기에 대한 수익(포인트) 창출 가능',
      5: 'VIP 오직러만의 특별한 혜택♥',
    };

    return (
      <div className="mygrade-bg">
        <div className="mygrade-top">
          <p className="mygrade-title">내 등급</p>
          <div className="mygrade-mo-item-bg">
            <div className="mygrade-mo-header-bg">
              <span className="mygrade-mo-header-title">나의 등급</span>
              <span className={'mygrade-mo-header-grade' + `grade-${data.grade}`}>
                {gradeName(data.grade) || ''}
              </span>
            </div>
            <div className="mygrade-mo-content-bg">
              <div className="mygrade-mo-item">
                <span className="mygrade-mo-item-title">사주세요 횟수</span>
                <span className="mygrade-mo-item-number">{data.buyingCount}</span>
              </div>
              <div className="mygrade-mo-item">
                <span className="mygrade-mo-item-title">작성한 후기</span>
                <span className="mygrade-mo-item-number">{data.reviewCount}</span>
              </div>
              <div className="mygrade-mo-item">
                <span className="mygrade-mo-item-title">작성한 득템</span>
                <span className="mygrade-mo-item-number">{data.linkCount}</span>
              </div>
              <div className="mygrade-mo-item">
                <span className="mygrade-mo-item-title">작성한 댓글</span>
                <span className="mygrade-mo-item-number">{data.commentCount}</span>
              </div>
            </div>
          </div>
          <table className="mygrade-table">
            <thead>
              <tr>
                <th>나의 등급</th>
                <th>사주세요 횟수</th>
                <th>작성한 후기</th>
                <th>작성한 득템</th>
                <th>작성한 댓글</th>
                <th>나의 혜택</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>
                  <span className={`grade-${data.grade}`}>{gradeName(data.grade) || ''}</span>
                </td>
                <td>
                  <span>{data.buyingCount}</span>
                </td>
                <td>
                  <span>{data.reviewCount}</span>
                </td>
                <td>
                  <span>{data.linkCount}</span>
                </td>
                <td>
                  <span>{data.commentCount}</span>
                </td>
                <td>
                  <span>{option[Number(data.grade)] || '-'}</span>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <div className="mygrade-bottom">
          <p className="mygrade-title">등급별 안내</p>
          <div className="mygrade-mo-item-bg">
            <div className="mygrade-mo-content-bg">
              <p className="grade-1">신입 오직러</p>
              <p>
                <span className="mygrade-mo-content-title">기준</span> 가입 즉시
              </p>
              <p className="mygrade-mo-content-title-bg">
                <span className="mygrade-mo-content-title">혜택</span>{' '}
                <span className="mygrade-mo-content-desc">
                  모든 게시물 읽기, 댓글 및 [오늘의 토크] <br />
                  작성 가능
                </span>
              </p>
            </div>
            <div className="mygrade-mo-content-bg">
              <p className="grade-2">초보 오직러</p>
              <p className="mygrade-mo-content-title-bg">
                <span className="mygrade-mo-content-title">기준</span>
                <span className="mygrade-mo-content-desc">
                  댓글 10개 작성 or 사주세요 1건 완료 시
                </span>
              </p>
              <p className="mygrade-mo-content-title-bg">
                <span className="mygrade-mo-content-title">혜택</span>
                <span className="mygrade-mo-content-desc">[오늘의 후기] 작성 가능</span>
              </p>
            </div>
            <div className="mygrade-mo-content-bg">
              <p className="grade-3">일반 오직러</p>
              <p className="mygrade-mo-content-title-bg">
                <span className="mygrade-mo-content-title">기준</span>
                <span className="mygrade-mo-content-desc">
                  후기 5개 작성 or 사주세요 3건 완료 시
                </span>
              </p>
              <p>
                <span className="mygrade-mo-content-title">혜택</span> [오늘의 득템] 작성 가능
              </p>
            </div>
            <div className="mygrade-mo-content-bg">
              <p className="grade-4">프로 오직러</p>
              <p className="mygrade-mo-content-title-bg">
                <span className="mygrade-mo-content-title">기준</span>
                <span className="mygrade-mo-content-desc">
                  후기 10개 + 득템 5개 작성 + 사주세요 1건 완료 시 or 사주세요 10건 완료 시
                </span>
              </p>
              <p className="mygrade-mo-content-title-bg">
                <span className="mygrade-mo-content-title">혜택</span>
                <span className="mygrade-mo-content-desc">
                  보러가기에 대한 수익(포인트) 창출 가능
                </span>
              </p>
            </div>
            <div className="mygrade-mo-content-bg">
              <p className="grade-5">VIP 오직러</p>
              <p className="mygrade-mo-content-title-bg">
                <span className="mygrade-mo-content-title">기준</span>
                <span className="mygrade-mo-content-desc">
                  후기 30개 + 득템 10개 작성 + 사주세요 5건 완료 시 or 사주세요 30건 완료 시
                </span>
              </p>
              <p>
                <span className="mygrade-mo-content-title">혜택</span>
                VIP 오직러만의 특별한 혜택♥
              </p>
            </div>
          </div>
          <table className="mygrade-table">
            <thead>
              <tr>
                <th>회원등급</th>
                <th>기준</th>
                <th>혜택</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>
                  <span className="grade-1">신입 오직러</span>
                </td>
                <td>
                  <span>가입 즉시</span>
                </td>
                <td>
                  <span>모든 게시물 읽기, 댓글 및 [오늘의 토크] 작성 가능</span>
                </td>
              </tr>
              <tr>
                <td>
                  <span className="grade-2">초보 오직러</span>
                </td>
                <td>
                  <span>댓글 10개 작성 or 사주세요 1건 완료 시</span>
                </td>
                <td>
                  <span>[오늘의 후기] 작성 가능</span>
                </td>
              </tr>
              <tr>
                <td>
                  <span className="grade-3">일반 오직러</span>
                </td>
                <td>
                  <span>후기 5개 작성 or 사주세요 3건 완료 시</span>
                </td>
                <td>
                  <span>[오늘의 득템] 작성 가능</span>
                </td>
              </tr>
              <tr>
                <td>
                  <span className="grade-4">프로 오직러</span>
                </td>
                <td>
                  <span>
                    후기 10개 + 득템 5개 작성 + 사주세요 1건 완료 시 or 사주세요 10건 완료 시
                  </span>
                </td>
                <td>
                  <span>보러가기에 대한 수익(포인트) 창출 가능</span>
                </td>
              </tr>
              <tr>
                <td>
                  <span className="grade-5">VIP 오직러</span>
                </td>
                <td>
                  <span>
                    후기 30개 + 득템 10개 작성 + 사주세요 5건 완료 시 or 사주세요 30건 완료 시
                  </span>
                </td>
                <td>
                  <span>VIP 오직러만의 특별한 혜택♥</span>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    );
  }
}

export default MyGrade;
