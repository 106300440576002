import React, { Component } from 'react';
import './TalkItem.css';
import { gradeName, abbreviateNumber } from 'lib/common';
import Imagebox from 'components/common/Imagebox';
import { withRouter } from 'react-router-dom';

class TalkItem extends Component {
  render() {
    const { handlePopup, type, data, handleLike, like, user } = this.props;
    return (
      <div
        className="main-intro-content-talk"
        onClick={() => handlePopup && handlePopup(data.id, 'talk', type)}
      >
        <div className="main-intro-content-get-img-bg">
          {/* <img src={(data.productImgUrls && data.productImgUrls[0]) || ''} alt="" /> */}
          <Imagebox link={data.productImgUrls && data.productImgUrls[0]} />
        </div>
        <div className="main-intro-content-get-info-bg">
          <div className="main-intro-content-user-bg">
            <span
              className={'main-intro-content-user-profile-bg ' + `grade-${data.user.grade}`}
              onClick={(e) => {
                if ((user && user.id) !== (data && data.user && data.user.id)) {
                  e.stopPropagation();
                  // this.props.history.push(`/otherpage/${data.user.id}`);
                  window.location.href = `/otherpage/${data.user.id}`;
                }
              }}
            >
              <span
                className={'main-intro-content-user-profile '}
                style={data.user && data.user.profileImgUrl ? {
                  backgroundImage: `url(${data.user && data.user.profileImgUrl})`,
                } : {}}
              ></span>
            </span>
            <span className="main-intro-content-user-name">
              {data && data.user && data.user.nickname}
            </span>
            {/* <span
              className={
                'main-intro-content-user-grade ' + `grade-${data && data.user && data.user.grade}`
              }
            >
              {gradeName(data && data.user && data.user.grade)}
            </span> */}
          </div>
          <div className="main-intro-content-get-info-middle-bg">
            <p className="main-intro-content-desc-talk">{data && data.title}</p>
          </div>
          <div className="main-intro-content-get-info-last-bg">
            <div>
              <span className="main-intro-content-talk-type">
                {type === 'free'
                  ? '자유토크'
                  : type === 'recommend'
                    ? '사요마요'
                    : type === 'search'
                      ? '찾아줘요'
                      : '정품판독'}
              </span>
            </div>
            <div className="main-intro-content-get-numbers-bg">
              <span
                className={
                  'main-intro-content-info-like ' +
                  (like && like.indexOf(data.id) > -1 ? 'active' : '')
                }
                onClick={(e) => handleLike(e, 'talks', data.id)}
              ></span>
              <span
                className="main-intro-content-info-number"
                onClick={(e) => handleLike(e, 'talks', data.id)}
              >
                {data.liked && like && like.indexOf(data.id) > -1
                  ? abbreviateNumber(parseInt(data.likeCount), 0)
                  : !data.liked && like && like.indexOf(data.id) > -1
                    ? abbreviateNumber(parseInt(data.likeCount + 1), 0)
                    : !data.liked && like && like.indexOf(data.id) === -1
                      ? abbreviateNumber(parseInt(data.likeCount), 0)
                      : abbreviateNumber(parseInt(data.likeCount - 1), 0)}
              </span>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default withRouter(TalkItem);
