import React, { Component } from 'react';
import ReviewItem from 'components/ReviewItem';
import ReviewPopup from 'components/ReviewPopup';
import { withRouter } from 'react-router-dom';
import './MyArticleReview.css';

class MyArticleReview extends Component {
  // constructor(props) {
  //   super(props);
  //   this.state = {
  //     isOpen: false,
  //   };
  // }

  // handlePopup = () => {
  //   this.setState({
  //     ...this.state,
  //     isOpen: true,
  //   });
  // };

  // closePopup = () => {
  //   this.setState({
  //     ...this.state,
  //     isOpen: false,
  //   });
  // };

  render() {
    // const { handlePopup, closePopup } = this;
    // const { isOpen } = this.state;
    const { myReviewData, dib, like, handleLike, handleDib, handlePopup } = this.props;

    return (
      <>
        <div className="myarticle-review-bg">
          {myReviewData &&
            myReviewData.map((el, i) => {
              return (
                <ReviewItem
                  key={i}
                  data={el}
                  handlePopup={handlePopup}
                  handleLike={handleLike}
                  handleDib={handleDib}
                  dib={dib}
                  like={like}
                  handlePopup={(e => handlePopup('review', el))}
                ></ReviewItem>
              );
            })}
        </div>
        {/* <ReviewPopup isOpen={isOpen} closePopup={closePopup} /> */}
      </>
    );
  }
}

export default withRouter(MyArticleReview);
