import React from 'react';
import MainStructure from 'components/structure/MainStructure';
import GetContainer from 'containers/GetContainer';

const Get = () => {
  return (
    <MainStructure>
      <GetContainer />
    </MainStructure>
  );
};

export default Get;
