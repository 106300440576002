import React, { Component } from 'react';
import TalkItem from 'components/TalkItem';
import TalkPopup from 'components/TalkPopup';
import { withRouter } from 'react-router-dom';
import './MyArticleTalk.css';

class MyArticleTalk extends Component {
  // constructor(props) {
  //   super(props);
  //   this.state = {
  //     // 자유토크(free), 사요마요(recommend), 정품판독(genuine), 찾아줘요(search)
  //     type: 'free',
  //     isOpen: false,
  //   };
  // }

  // handlePopup = (type) => {
  //   this.setState({
  //     ...this.state,
  //     type,
  //     isOpen: true,
  //   });
  // };

  // closePopup = () => {
  //   this.setState({
  //     ...this.state,
  //     isOpen: false,
  //   });
  // };

  render() {
    // const { handlePopup, closePopup } = this;
    // const { isOpen, type } = this.state;
    const { myTalkData, like, dib, handleLike, handleDib, currency, handlePopup } = this.props;

    return (
      <>
        <div className="myarticle-talk-bg">
          {myTalkData &&
            myTalkData.map((el, i) => {
              let type =
                el.type === '자유토크'
                  ? 'free'
                  : el.type === '사요마요'
                    ? 'recommend'
                    : el.type === '정품판독'
                      ? 'genuine'
                      : 'search';

              return (
                <TalkItem
                  data={el}
                  key={i}
                  type={type}
                  currency={currency}
                  handlePopup={() => handlePopup('talk', el)}
                  handleLike={(e) => handleLike(e, 'talks', el.id)}
                  like={like}
                />
              );
            })}
        </div>
        {/* <TalkPopup type={type} isOpen={isOpen} closePopup={closePopup} /> */}
      </>
    );
  }
}

export default withRouter(MyArticleTalk);
