import React from 'react';
import MainStructure from 'components/structure/MainStructure';
import PrivacyContainer from 'containers/PrivacyContainer';

const Privacy = () => {
  return (
    <MainStructure>
      <PrivacyContainer />
    </MainStructure>
  );
};

export default Privacy;
