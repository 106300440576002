import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import './GlobalNavigation.css';
import { tagMangerService } from 'lib/TagManagerService';

class GlobalNavigation extends Component {
  state = {
    menu: {
      today: false,
      review: false,
      get: false,
      talk: false,
      event: false,
    },
  };

  componentDidMount() {
    tagMangerService.pageView();

    // console.log(this.props.history.location.pathname);
    const url = this.props.history.location.pathname;

    switch (url) {
      case '/':
        this.setState({
          ...this.state,
          menu: {
            ...this.state.menu,
            today: true,
          },
        });
        break;
      case '/review':
        this.setState({
          ...this.state,
          menu: {
            ...this.state.menu,
            review: true,
          },
        });
        break;
      case '/get':
        this.setState({
          ...this.state,
          menu: {
            ...this.state.menu,
            get: true,
          },
        });
        break;
      case '/talk':
        this.setState({
          ...this.state,
          menu: {
            ...this.state.menu,
            talk: true,
          },
        });
        break;
      case '/event':
        this.setState({
          ...this.state,
          menu: {
            ...this.state.menu,
            event: true,
          },
        });
        break;
      default:
        break;
    }
  }

  handlePage = (page) => {
    // this.props.history.push(page);
    window.location.href = page;
  };

  render() {
    const { menu } = this.state;
    const { handlePage } = this;
    return (
      <>
        <nav>
          <div className="global-nav-bg">
            <div
              className={'global-nav-item ' + (menu.today ? 'active' : '')}
              onClick={() => handlePage('/')}
            ></div>
            <div
              className={'global-nav-item ' + (menu.review ? 'active' : '')}
              onClick={() => handlePage('/review')}
            ></div>
            <div
              className={'global-nav-item ' + (menu.get ? 'active' : '')}
              onClick={() => handlePage('/get')}
            ></div>
            <div
              className={'global-nav-item ' + (menu.talk ? 'active' : '')}
              onClick={() => handlePage('/talk')}
            ></div>
            <div
              className={'global-nav-item ' + (menu.event ? 'active' : '')}
              onClick={() => handlePage('/event')}
            ></div>
          </div>
        </nav>
        {menu.review && (
          <button className="edit-button" onClick={() => handlePage('/writing/review')}></button>
        )}
        {menu.get && (
          <button className="edit-button" onClick={() => handlePage('/writing/get')}></button>
        )}
        {menu.talk && (
          <button className="edit-button" onClick={() => handlePage('/writing/talk')}></button>
        )}
      </>
    );
  }
}

export default withRouter(GlobalNavigation);
