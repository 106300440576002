import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import axios from 'axios';
import { API } from 'config';
import jwt_decode from 'jwt-decode';
import { Document, Page } from 'react-pdf/dist/esm/entry.webpack';
import { tagMangerService } from 'lib/TagManagerService';

class TalkWriting extends Component {
  inputRef = React.createRef();
  imgRef = React.createRef();
  state = {
    imgLoaing: [],
    imgList: [],
    talkId: 0,
    talk: {
      title: '',
      content: '',
      category: '',
      productImgUrls: [],
    },
    user: {},
  };

  componentWillMount() {
    localStorage.getItem('token') && this.getUser();
  }

  componentDidMount() {
    // const {} = this.props.history;
    localStorage.getItem('token') && this.getUserData();
    if (this.props.location.search && this.props.location.search.split('id=')) {
      this.getTalk(this.props.location.search.split('id=')[1]);
    }
  }

  getUser = () => {
    try {
      const user = jwt_decode(localStorage.getItem('token'));
      if (user) {
        this.setState({
          ...this.state,
          user: {
            id: user.id,
          },
        });
      } else {
        localStorage.removeItem('token');
      }
    } catch (e) {
      localStorage.removeItem('token');
      window.location.reload();
    }
  };

  getUserData = async () => {
    const user = jwt_decode(localStorage.getItem('token'));
    if (user) {
      try {
        const result = await axios.get(`${API}/users/my`, {
          headers: {
            token: `${localStorage.getItem('token')}`,
          },
        });

        const newToken = result.headers['new-token'] || '';
        if (newToken) {
          axios.defaults.headers.token = newToken;
          localStorage.setItem('token', newToken);
        }

        if (result && result.status === 200) {
          let user = result.data;

          this.setState({
            ...this.state,
            user,
          });
        } else {
          localStorage.removeItem('token');
          window.location.reload();
        }
      } catch (e) {
        localStorage.removeItem('token');
        window.location.reload();
      }
    } else {
      localStorage.removeItem('token');
    }
  };

  getTalk = async (id) => {
    const { user } = this.state;

    try {
      const result = await axios.get(`${API}/talks/${id}`, {
        headers: {
          token: `${localStorage.getItem('token')}`,
        },
      });
      if (result.data.user.id !== user.id) return;

      let imgLoaing = [];

      result.data.productImgUrls.map((el) => {
        imgLoaing.push(false);
      });

      this.setState({
        ...this.state,
        imgList: result.data.productImgUrls,
        imgLoaing,
        talkId: id,
        talk: {
          title: result.data.title,
          content: result.data.content,
          category: result.data.category,
          productImgUrls: result.data.productImgUrls,
        },
      });
    } catch (e) {
      if (
        e.response &&
        e.response.data &&
        e.response.data.msg &&
        e.response.data.msg === '토큰이 만료되었습니다.'
      ) {
        localStorage.removeItem('token');
        window.location.href = '/';
        return;
      }
    }
  };

  handleImage = async (e) => {
    const { imgList, imgLoaing } = this.state;
    const length = imgLoaing.length;
    let imgs = [...imgList];
    let loadings = [...imgLoaing];
    let formData = new FormData();
    [...e.target.files].map((el) => {
      formData.append(e.target.name, el);
      loadings.push(true);
    });
    this.setState({
      ...this.state,
      imgLoaing: loadings,
    });

    try {
      const result = await axios.post(`${API}/upload/images`, formData);
      result.data.map((el, i) => {
        imgs.push(el.location);
        loadings[length + i] = false;
      });
      this.setState({
        ...this.state,
        imgList: imgs,
        imgLoaing: loadings,
      });
    } catch (e) {
      if (
        e.response &&
        e.response.data &&
        e.response.data.msg &&
        e.response.data.msg === '토큰이 만료되었습니다.'
      ) {
        localStorage.removeItem('token');
        window.location.href = '/';
        return;
      }
    }
  };

  removeImg = (index) => {
    const { imgList, imgLoaing } = this.state;
    const imgs = [...imgList].filter((el, i) => i !== index);
    const loadings = [...imgLoaing].filter((el, i) => i !== index);

    this.setState({
      ...this.state,
      imgList: imgs,
      imgLoaing: loadings,
    });
    this.imgRef.current.value = '';
  };

  showImg = () => {
    const { imgList, imgLoaing } = this.state;
    let component = [];
    imgLoaing.map((el, i) => {
      component.push(
        <div key={i} className="writing-img-item">
          {imgLoaing[i] ? (
            <div className="img-loader"></div>
          ) : imgList[i] ? (
            <>
              <span className="writing-remove-item" onClick={() => this.removeImg(i)}></span>
              <img src={imgList[i]} alt="" />
            </>
          ) : (
            ''
          )}
        </div>,
      );
    });

    return component;
  };

  handleChange = (e) => {
    this.setState({
      ...this.state,
      talk: {
        ...this.state.talk,
        [e.target.name]: e.target.value,
      },
    });
  };

  // patch / delete 메써드
  // `${API}/talks/${talkId} `,

  handleSubmit = async () => {
    const { imgList, talk, talkId } = this.state;
    const { title, content, category, productImgUrls } = talk;
    // styletag: '',
    // brand: '',
    // store: '',
    // purchaseMethod: '',
    // howLongTakeToShip: '',
    if (!localStorage.getItem('token')) {
      return alert('작성을 위해 로그인해주세요!');
    }

    if (imgList.length === 0) {
      return alert('사진을 최소 1개 선택해주세요!');
    }

    if (!title.trim()) {
      return alert('제목을 입력해주세요!');
    }

    if (!content.trim()) {
      return alert('내용을 입력해주세요!');
    }

    if (!category) {
      return alert('카테고리를 선택해주세요!');
    }

    try {
      if (!talkId) {
        const result = await axios.post(
          `${API}/talks `,
          {
            title: title.trim(),
            content: content.trim(),
            category,
            productImgUrls: imgList,
          },
          {
            headers: {
              token: `${localStorage.getItem('token')}`,
            },
          },
        );
        if (result.status === 200) {
          window.location.href = `/talk?id=${result.data.id}`;
          // this.props.history.push(`/talk?id=${result.data.id}`);
          tagMangerService.talkAcquireEvent();
        } else {
          alert('각 항목에 맞게 올바르게 작성해주세요:)');
        }
      } else {
        const result = await axios.patch(
          `${API}/talks/${talkId}`,
          {
            title: title.trim(),
            content: content.trim(),
            category,
            productImgUrls: imgList,
          },
          {
            headers: {
              token: `${localStorage.getItem('token')}`,
            },
          },
        );
        if (result.status === 200) {
          window.location.href = `/talk?id=${result.data.id}`;
          // this.props.history.push(`/talk?id=${result.data.id}`);
          tagMangerService.talkAcquireEvent();
        } else {
          alert('각 항목에 맞게 올바르게 작성해주세요:)');
        }
      }
    } catch (e) {
      if (
        e.response &&
        e.response.data &&
        e.response.data.msg &&
        e.response.data.msg === '토큰이 만료되었습니다.'
      ) {
        localStorage.removeItem('token');
        window.location.href = '/';
        return;
      }
      // alert('조금만 이따가 다시 시도해주세요 :)');
    }
  };

  render() {
    const { imgList, imgLoaing, talk, category, talkId } = this.state;
    const { handleChange, handleImage, showImg, imgRef, handleSubmit } = this;
    return (
      <div className="writing-bg">
        <div className="writing-container wrapper">
          <div>
            <div className="writing-title-bg">
              <h2 className="writing-title">
                오늘의 <strong>토크 작성</strong>
              </h2>
            </div>
            <div className="writing-box">
              <div className="writing-desc-bg">
                <span className="writing-desc">{"✔️ '패션&직구 정보 토크' 오늘의 토크 😊"}</span>
              </div>
              <div className="writing-img-bg">
                <div className="writing-img-box">
                  {imgLoaing.length > 0 ? (
                    showImg()
                  ) : (
                    <div className="writing-img-item default"></div>
                  )}
                </div>
                <div className="writing-file-select-bg">
                  <input
                    className="writing-file-button"
                    type="file"
                    name="images"
                    multiple
                    onChange={(e) => {
                      handleImage(e);
                    }}
                    ref={imgRef}
                  />
                  <button className="writing-file-select">사진선택</button>
                  <span className="writing-file-number">{imgList.length} / 10</span>
                  <span className="writing-info">※1개 이상 사진을 반드시 업로드해주세요.</span>
                </div>
              </div>
              <div className="write-input-bg">
                <label className="write-input-label" htmlFor="title">
                  제목
                </label>
                <input
                  className="write-input-text"
                  type="text"
                  name="title"
                  value={talk.title}
                  onChange={handleChange}
                />
              </div>
              <div className="write-input-bg">
                <label className="write-input-label" htmlFor="content">
                  내용
                </label>
                <textarea
                  className="write-input-textarea"
                  name="content"
                  id=""
                  cols="30"
                  rows="10"
                  value={talk.content}
                  onChange={handleChange}
                  placeholder={
                    "✔️ '패션&직구 정보 토크' 오늘의 토크 😊\n자유토크 / 사요마요 / 정품판독 / 찾아줘요\n각 카테고리에 맞게 올려주세요 :)"
                  }
                ></textarea>
              </div>
              {/* <div className="write-input-bg">
                <label className="write-input-label" htmlFor="">
                  해시태그 <span className="writing-info">※최대 10개까지 등록가능합니다.</span>
                </label>
                <input className="write-input-text" type="text" />
                <div></div>
              </div> */}
              <div className="write-input-bg">
                <label className="write-input-label">카테고리 선택</label>
                <div className="write-input-child">
                  <select
                    className="write-input-select"
                    name="category"
                    value={talk.category}
                    onChange={handleChange}
                    id=""
                  >
                    <option value="">카테고리를 선택해주세요</option>
                    <option value="자유토크">자유토크</option>
                    <option value="사요마요">사요마요</option>
                    <option value="정품판독">정품판독</option>
                    <option value="찾아줘요">찾아줘요</option>
                  </select>
                </div>
              </div>
            </div>
            <div className="writing-button-bg">
              <button
                className="writing-button-cancel"
                onClick={() => {
                  // this.props.history.push('/')
                  window.location.href = '/';
                }}
              >
                취소하기
              </button>
              <button className="writing-button-write" onClick={handleSubmit}>
                {talkId ? '수정하기' : '작성하기'}
              </button>
            </div>
          </div>
          {/* <div className="rating">
            <span>☆</span>
            <span>☆</span>
            <span className="checked">☆</span>
            <span>☆</span>
            <span>☆</span>
          </div> */}
        </div>
      </div>
    );
  }
}

export default withRouter(TalkWriting);
