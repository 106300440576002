import React, { Component } from 'react';
import { gradeName, abbreviateNumber, numberWithCommas, compareTime } from 'lib/common';
import { withRouter } from 'react-router-dom';
import './Profile.css';

class Profile extends Component {
  render() {
    const { user, data, showGrade = true, showNick = true, children } = this.props;

    return (
      <div
        className="main-intro-content-user-bg"
        onClick={(e) => {
          e.stopPropagation();
          // this.props.history.push(`/ohterpage/${user.id}`);
          window.location.href = `/ohterpage/${user.id}`;
        }}
      >
        <span className={'main-intro-content-user-profile-bg ' + `grade-${user.grade}`}>
          <span
            className={'main-intro-content-user-profile '}
            style={user.profileImgUrl ? { backgroundImage: `url(${user.profileImgUrl})` } : {}}
          ></span>
        </span>
        {showNick && <span className="main-intro-content-user-name">{data.user.nickname}</span>}
        {showGrade && (
          <span className={'main-intro-content-user-grade ' + `grade-${data.user.grade}`}>
            {gradeName(data.user.grade)}
          </span>
        )}
        {children}
      </div>
    );
  }
}

export default withRouter(Profile);
