import React from 'react';
import MainStructure from 'components/structure/MainStructure';
import MyPageContainer from 'containers/MyPageContainer';

const MyPage = () => {
  return (
    <MainStructure>
      <MyPageContainer />
    </MainStructure>
  );
};

export default MyPage;
