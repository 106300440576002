import React from 'react';
import MainStructure from 'components/structure/MainStructure';
import TalkWritingContainer from 'containers/TalkWritingContainer';

const TalkWriting = () => {
  return (
    <MainStructure>
      <TalkWritingContainer />
    </MainStructure>
  );
};

export default TalkWriting;
