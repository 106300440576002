import React, { Component } from 'react';
import './ReviewItem.css';
import { gradeName, abbreviateNumber } from 'lib/common';
import Imagebox from 'components/common/Imagebox';
import PrivateInfo from 'components/common/PrivateInfo';
import { withRouter } from 'react-router-dom';

class ReviewItem extends Component {
  render() {
    const { handlePopup, data, handleLike, handleDib, like, dib, user } = this.props;
    // grade 1
    // grade 2
    // grade 3
    // grade 4
    // grade 5
    // grade 6 관리자
    if (!data) return <></>;
    // if (!data.user) return <></>;

    return (
      <div
        className="main-intro-content-review"
        onClick={() => handlePopup && handlePopup(data.id, 'review')}
      >
        {/* <span
          className="main-intro-content-review-img"
          style={{ backgroundImage: `url(${data.productImgUrls[0]})` }}
        ></span> */}
        <Imagebox link={data.productImgUrls[0]} />
        <div className="main-intro-content-review-box">
          <div className="main-intro-content-review-box-top">
            <div
              className="main-intro-content-user-bg"
              onClick={(e) => {
                if ((user && user.id) !== (data && data.user && data.user.id)) {
                  e.stopPropagation();
                  // this.props.history.push(`/otherpage/${data.user.id}`);
                  window.location.href = `/otherpage/${data.user.id}`;
                }
              }}
            >
              <span
                className={
                  'main-intro-content-user-profile-bg ' + `grade-${data.user && data.user.grade}`
                }
              >
                <span
                  className={'main-intro-content-user-profile '}
                  style={data.user && data.user.profileImgUrl ? {
                    backgroundImage: `url(${data.user && data.user.profileImgUrl})`,
                  } : {}}
                ></span>
              </span>
              <span className="main-intro-content-user-name">
                {data && data.user && data.user.nickname}
              </span>
              <span
                className={
                  'main-intro-content-user-grade ' + `grade-${data && data.user && data.user.grade}`
                }
              >
                {gradeName(data && data.user && data.user.grade)}
              </span>
            </div>
            <div className="main-intro-content-review-info-bg">
              <span
                className={
                  'main-intro-content-info-like ' +
                  (like && like.indexOf(data.id) > -1 ? 'active' : '')
                }
                onClick={(e) => handleLike(e, 'reviews', data.id)}
              ></span>
              <span
                className="main-intro-content-info-number"
                onClick={(e) => handleLike(e, 'reviews', data.id)}
              >
                {data.liked && like && like.indexOf(data.id) > -1
                  ? abbreviateNumber(parseInt(data.likeCount), 0)
                  : !data.liked && like && like.indexOf(data.id) > -1
                    ? abbreviateNumber(parseInt(data.likeCount + 1), 0)
                    : !data.liked && like && like.indexOf(data.id) === -1
                      ? abbreviateNumber(parseInt(data.likeCount), 0)
                      : abbreviateNumber(parseInt(data.likeCount - 1), 0)}
              </span>
              <span
                className={
                  'main-intro-content-info-heart ' +
                  (dib && dib.indexOf(data.id) > -1 ? 'active' : '')
                }
                onClick={(e) => handleDib(e, 'reviews', data.id)}
              ></span>
              <span
                className="main-intro-content-info-number"
                onClick={(e) => handleDib(e, 'reviews', data.id)}
              >
                {data.dib && dib && dib.indexOf(data.id) > -1
                  ? abbreviateNumber(parseInt(data.dibCount), 0)
                  : !data.dib && dib && dib.indexOf(data.id) > -1
                    ? abbreviateNumber(parseInt(data.dibCount + 1), 0)
                    : !data.dib && dib && dib.indexOf(data.id) === -1
                      ? abbreviateNumber(parseInt(data.dibCount), 0)
                      : abbreviateNumber(parseInt(data.dibCount - 1), 0)}
              </span>
            </div>
          </div>
          <div className="main-intro-content-review-box-middle">
            {data.user && data.user.openBodyInfo && (
              <div className="main-intro-content-review-value-bg">
                <PrivateInfo data={data.user} />
                {/* <span className="main-intro-content-value-point"></span>
                <span className="main-intro-content-value">
                  {!!data.user.height ? data.user.height : '?(키)'} /{' '}
                  {!!data.user.weight ? data.user.weight : '?(몸무게)'} /{' '}
                  {data.user.genger === 'male' ? 'M' : 'F'}
                </span> */}
              </div>
            )}
            <div className="main-intro-content-review-tag-bg">
              <span className="main-intro-content-tag">#{data.styletag}</span>
            </div>
          </div>
          <div className="main-intro-content-review-box-bottom">
            <p className="main-intro-content-desc">{data.title}</p>
          </div>
        </div>
      </div>
    );
  }
}

export default withRouter(ReviewItem);
