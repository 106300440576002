import React, { Component } from 'react';
import './Event.css';
import EventItem from 'components/event/EventItem';
import EventPopup from 'components/event/EventPopup';
import axios from 'axios';
import { API } from 'config';
import jwt_decode from 'jwt-decode';
import { withRouter } from 'react-router-dom';

var like = {
  event: [],
};

class Event extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: false,
      isOpen: false,
      isFirst: true,
      total: 0,
      items: [],
      offset: 0,
      limit: 20,
      catchPhrase: {},
      popupData: {},
      user: {},
      like: {
        event: [],
      },
    };
  }

  // 좋아요 API
  // `${API}/talks/${id}/like`,

  componentWillMount() {
    localStorage.getItem('token') && this.getUser();
  }

  componentDidMount() {
    localStorage.getItem('token') && this.getUserData();
    this.getEvents();
    this.getCatch();
    if (this.props.location.search && this.props.location.search.split('id=')) {
      this.getEvent(this.props.location.search.split('id=')[1]);
    }

    window.addEventListener('scroll', this._infiniteScroll, true);
  }

  componentWillUnmount() {
    this.setState({
      isLoading: false,
      isOpen: false,
      isFirst: true,
      total: 0,
      items: [],
      offset: 0,
      limit: 20,
      catchPhrase: {},
      popupData: {},
      user: {},
      like: {
        event: [],
      },
    });
    window.removeEventListener('scroll', this._infiniteScroll);
  }

  handleLike = async (e, type, id) => {
    e.stopPropagation();
    const { review } = this.state.like;
    if (!localStorage.getItem('token')) return alert('로그인이 필요합니다:)');
    try {
      let t = type === 'events' ? 'talks' : type;

      const result = await axios.post(
        `${API}/${t}/${id}/like`,
        {},
        {
          headers: {
            token: `${localStorage.getItem('token')}`,
          },
        },
      );

      let rtype = '';
      if (type === 'reviews') {
        rtype = 'review';
      } else if (type === 'links') {
        rtype = 'get';
      } else if (type === 'talks') {
        rtype = 'talk';
      } else if (type === 'events') {
        rtype = 'event';
      }

      var rlike = [...this.state.like[rtype]];

      if (result.status === 201) {
        rlike.push(id);
        this.setState({
          ...this.state,
          like: {
            ...this.state.like,
            [rtype]: rlike,
          },
        });
      } else if (result.status === 204) {
        rlike = rlike.filter((el) => Number(el) !== Number(id));
        this.setState({
          ...this.state,
          like: {
            ...this.state.like,
            [rtype]: rlike,
          },
        });
      }
    } catch (e) {
      if (e.response && e.response.data && e.response.data.msg && e.response.data.msg === '토큰이 만료되었습니다.') {
        localStorage.removeItem('token');
        window.location.href = "/";
        return;
      }
    }
  };

  getUser = () => {
    try {
      const user = jwt_decode(localStorage.getItem('token'));
      if (user) {
        this.setState({
          ...this.state,
          user: {
            id: user.id,
          },
        });
      } else {
        localStorage.removeItem('token');
      }
    } catch (e) {
      localStorage.removeItem('token');
      window.location.reload();
    }
  };

  getUserData = async () => {
    const user = jwt_decode(localStorage.getItem('token'));
    if (user) {
      try {
        const result = await axios.get(`${API}/users/my`, {
          headers: {
            token: `${localStorage.getItem('token')}`,
          }
        });

        const newToken = result.headers["new-token"] || '';
        if (newToken) {
          axios.defaults.headers.token = newToken;
          localStorage.setItem("token", newToken);
        }

        if (result && result.status === 200) {
          let user = result.data;

          this.setState({
            ...this.state,
            user,
          });
        } else {
          localStorage.removeItem('token');
          window.location.reload();
        }
      } catch (e) {
        localStorage.removeItem('token');
        window.location.reload();
      }
    } else {
      localStorage.removeItem('token');
    }
  };

  getCatch = async () => {
    try {
      const result = await axios.get(`${API}/extra/catch-phrase`);
      const catchPhrase = { ...result.data.data };
      this.setState({
        ...this.state,
        catchPhrase,
      });
    } catch (e) {
      if (e.response && e.response.data && e.response.data.msg && e.response.data.msg === '토큰이 만료되었습니다.') {
        localStorage.removeItem('token');
        window.location.href = "/";
        return;
      }
    }
  };

  getEvent = async (id) => {
    try {
      const result = await axios.get(`${API}/talks/${id}`, {
        headers: {
          token: `${localStorage.getItem('token')}`,
        },
      });
      if (result.data.liked) {
        like['event'].push(result.data.id);
      }

      this.setState({
        ...this.state,
        isOpen: true,
        popupData: result.data,
        like,
      });
    } catch (e) {
      if (e.response && e.response.data && e.response.data.msg && e.response.data.msg.indexOf('토큰이 만료되었습니다') > -1) {
        localStorage.removeItem('token');
        window.location.reload();
      }
    }
  };

  getEvents = async () => {
    const { isFirst } = this.state;

    try {
      const result = await axios.get(`${API}/talks`, {
        params: {
          category: '이벤트',
          limit: this.state.limit,
          offset: this.state.offset,
        },
        headers: {
          token: `${localStorage.getItem('token')}`,
        },
      });

      result.data[0].map((el) => {
        if (el.liked) {
          like['event'].push(el.id);
        }
      });

      if (isFirst) {
        this.setState({
          ...this.state,
          isFirst: false,
          total: result.data[1],
          items: result.data[0],
          isLoading: false,
          like,
        });
      } else {
        this.setState({
          ...this.state,
          isFirst: false,
          total: result.data[1],
          items: [...this.state.items, ...result.data[0]],
          isLoading: false,
          like,
        });
      }
    } catch (e) {
      if (e.response && e.response.data && e.response.data.msg && e.response.data.msg.indexOf('토큰이 만료되었습니다') > -1) {
        localStorage.removeItem('token');
        window.location.reload();
      }
    }
  };

  _infiniteScroll = () => {
    let scrollHeight = Math.max(document.documentElement.scrollHeight, document.body.scrollHeight);
    let scrollTop = Math.max(document.documentElement.scrollTop, document.body.scrollTop);

    let clientHeight = document.documentElement.clientHeight;

    if (!this.state.isLoading && (Number(scrollTop) + Number(clientHeight) + 1000) >= Number(scrollHeight)) {
      this.setState({
        ...this.state,
        offset: this.state.offset + 20,
        limit: this.state.limit,
        isLoading: true,
      }, () => {
        if (this.state.offset <= this.state.total) {
          this.getEvents();
        }
      });
    }
  };

  handlePopup = (id) => {
    this.setState({
      ...this.state,
      isOpen: true,
      popupData: this.state.items.filter((el) => el.id === id)[0],
    });
    window.history.pushState(
      null,
      null,
      `/event?id=${id}`,
    );
  };

  closePopup = () => {
    this.setState({
      ...this.state,
      isOpen: false,
    });
    window.history.pushState(
      null,
      null,
      `/event`,
    );
  };

  render() {
    const { handlePopup, closePopup, handleLike } = this;
    const { isOpen, items, catchPhrase, user, popupData, like } = this.state;

    return (
      <>
        <div className="event-bg">
          <div className="wrapper">
            <div className="main-event-title-bg">
              <p className="main-intro-sub">오늘의 이벤트,</p>
              <p className="main-intro-title">
                <strong>{catchPhrase.event}</strong>
              </p>
            </div>
            <div className="event-content-bg">
              {items &&
                items.map((el, i) => {
                  return (
                    <EventItem
                      key={i}
                      data={el}
                      handlePopup={handlePopup}
                      like={like.event}
                      handleLike={handleLike}
                    ></EventItem>
                  );
                })}

              {items.length === 0 && (
                <div className="content-no-data">
                  이벤트를 준비 중입니다. 조금만 기다려주세요:{')'}
                </div>
              )}
            </div>
          </div>
        </div>
        {popupData && popupData.user && popupData.user.id && (
          <EventPopup
            isOpen={isOpen}
            user={user}
            popupData={popupData}
            closePopup={closePopup}
            like={like.event}
            handleLike={handleLike}
          />
        )}
      </>
    );
  }
}

export default withRouter(Event);
