import React, { Component } from 'react';
import './PrivateInfo.css'

class PrivateInfo extends Component {

  // let bodyShape = '';

  // switch (user.bodyShape) {
  //   case '상체에 비해 하체가 발달한 편':
  //     bodyShape = 1;
  //     break;
  //   case '하체에 비해 상체가 발달한 편':
  //     bodyShape = 2;
  //     break;
  //   case '전체적으로 몸과 팔다리가 가는 편':
  //     bodyShape = 3;
  //     break;
  //   case '다른곳에 비해 허리가 많이 잘록한편':
  //     bodyShape = 4;
  //     break;
  //   case '상하체가 균형있고 평범한 편':
  //     bodyShape = 5;
  //     break;
  //   default:
  //     break;
  // }

  findBodyShpape = (bodyShape) => {

    let shape = 0;
    // console.log("bodyShapebodyShapebodyShape  ", bodyShape)

    switch (bodyShape) {
      case "상체에 비해 하체가 발달한 편":
        shape = 1;
        break;
      case "하체에 비해 상체가 발달한 편":
        shape = 2;
        break;
      case "전체적으로 몸과 팔다리가 가는 편":
        shape = 3;
        break;
      case "다른곳에 비해 허리가 많이 잘록한편":
        shape = 4;
        break;
      case "상하체가 균형있고 평범한 편":
        shape = 5;
        break;
      default:
        break;
    }

    // console.log("shapeshapeshapeshapeshape  ", shape)
    return shape
  }

  render() {
    const { data } = this.props;
    const { findBodyShpape } = this;
    // console.log("PrivateInfo data ", data)
    return (
      <>
        <span className="main-intro-content-value">
          {
            findBodyShpape(data.bodyShape) !== 0 ?
              <span className={"main-intro-content-value-point " + (`type-${findBodyShpape(data.bodyShape)}`)}></span>
              : <span className={"main-intro-content-value-point type-default"}></span>
          }
          {' / '}
          {!!data.height ? data.height : '-'}
          {' / '}
          {!!data.weight ? data.weight : '-'}
          {' / '}
          {!!data.shoeSize ? data.shoeSize : '-'}
        </span>
        <span></span>
        <span></span>
      </>
    )
  }
}

export default PrivateInfo;