import React from 'react';
import MainStructure from 'components/structure/MainStructure';
import SearchContainer from 'containers/SearchContainer';

const Search = () => {
  return (
    <MainStructure>
      <SearchContainer />
    </MainStructure>
  );
};

export default Search;
