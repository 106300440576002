import React, { Component } from 'react';
import ReviewItem from 'components/ReviewItem';
import { withRouter } from 'react-router-dom';
import ReviewPopup from 'components/ReviewPopup';
import axios from 'axios';
import { API } from 'config';
import './MyWishReview.css';

class MyWishReview extends Component {
  // constructor(props) {
  //   super(props);
  //   this.state = {
  //     isOpen: false,
  //     offset: 0,
  //     limit: 1,
  //   };
  // }

  // componentDidMount() {
  //   this.getWishReview();
  // }

  // getWishReview = async () => {
  //   try {
  //     const result = await axios.get(`${API}/users/dib/reviews`, {
  //       params: {
  //         limit: this.state.limit,
  //         offset: this.state.offset,
  //       },
  //       headers: {
  //         token: `${localStorage.getItem('token')}`,
  //       },
  //     });
  //   } catch (e) {}
  // };

  // handlePopup = () => {
  //   this.setState({
  //     ...this.state,
  //     isOpen: true,
  //   });
  // };

  // closePopup = () => {
  //   this.setState({
  //     ...this.state,
  //     isOpen: false,
  //   });
  // };

  render() {
    // const { handlePopup, closePopup } = this;
    // const { isOpen } = this.state;
    const { myWishReviewData, like, dib, handleLike, handleDib, user, handlePopup } = this.props;

    return (
      <>
        <div className="mywish-review-bg">
          {myWishReviewData &&
            myWishReviewData.map((el, i) => {
              return (
                <ReviewItem
                  key={i}
                  data={el}
                  handleLike={(e) => handleLike(e, 'reviews', el.id, 'wishReview')}
                  handleDib={(e) => handleDib(e, 'reviews', el.id, 'wishReview')}
                  dib={dib}
                  like={like}
                  handlePopup={() => handlePopup('wishreview', el)}
                ></ReviewItem>
              );
            })}
          {/* <ReviewItem handlePopup={handlePopup}></ReviewItem>
          <ReviewItem handlePopup={handlePopup}></ReviewItem>
          <ReviewItem handlePopup={handlePopup}></ReviewItem>
          <ReviewItem handlePopup={handlePopup}></ReviewItem>
          <ReviewItem handlePopup={handlePopup}></ReviewItem>
          <ReviewItem handlePopup={handlePopup}></ReviewItem>
          <ReviewItem handlePopup={handlePopup}></ReviewItem>
          <ReviewItem handlePopup={handlePopup}></ReviewItem>
          <ReviewItem handlePopup={handlePopup}></ReviewItem>
          <ReviewItem handlePopup={handlePopup}></ReviewItem> */}
        </div>
        {/* <ReviewPopup isOpen={isOpen} closePopup={closePopup} /> */}
      </>
    );
  }
}

export default withRouter(MyWishReview);
