import React, { Component } from 'react';
import './TalkCategory.css';
import axios from 'axios';
import { API } from 'config';

class TalkCategory extends Component {
  state = {
    index: 0,
  };

  handleMenu = (index) => {
    this.setState({
      ...this.state,
      index,
    });
  };

  render() {
    const { handleMenu } = this;
    const { handleCategory } = this.props;
    const { index } = this.state;
    const catergoryList = ['전체', '자유토크', '사요마요', '정품판독', '찾아줘요'];

    return (
      <div className="header-tag-box-content-bg">
        <div className="header-tag-box-list-bg">
          <ul className="header-tag-box-list talk-category">
            {catergoryList &&
              catergoryList.map((el, i) => {
                return (
                  <li
                    key={i}
                    onClick={() => {
                      handleMenu(i);
                      handleCategory(el);
                    }}
                    className={i === index ? 'active' : ''}
                  >
                    <span>{el}</span>
                  </li>
                );
              })}
          </ul>
        </div>
      </div>
    );
  }
}

export default TalkCategory;
