import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import axios from 'axios';
import { API } from 'config';
import './Footer.css';

class Footer extends Component {
  state = {
    siteInfo: {},
  };

  componentDidMount() {
    this.getSiteInfo();
  }

  // /extra/site-info
  getSiteInfo = async () => {
    try {
      const result = await axios.get(`${API}/extra/site-info`);
      this.setState({
        ...this.state,
        siteInfo: result.data.data,
      });
    } catch (e) {
      if (e.response && e.response.data && e.response.data.msg && e.response.data.msg === '토큰이 만료되었습니다.') {
        localStorage.removeItem('token');
        window.location.href = "/";
        return;
      }
    }
  };

  handleSns = (url) => {
    window.open(url, '_blank');
  };

  render() {
    const { siteInfo } = this.state;
    const { handleSns } = this;
    // 개인정보책임자: "오진구"
    // 고객센터번호: "1588-7979"
    // 대표자: "임현주"
    // 사업자등록번호: "199-88-01568"
    // 상호명: "(주)오늘의 직구"
    // 운영시간: "평일 10:00 - 17:00 / Off-time 12:00 - 13:00 (주말/공휴일 휴무)"
    // 이메일: "help@ojjk.com"
    // 인스타: "https://www.instagram.com/ojik_office/"
    // 주소: "제주특별자치도 제주시 고마로 15길 5"
    // 통신판매업신고: "2020-제주직구-12345"
    // 트위터: "https://blog.naver.com/ojik_office"
    // 페이스북: "https://naver.com"
    return (
      <footer>
        <div className="footer-bg">
          <div className="wrapper footer-wrapper">
            <div className="footer-title-bg">
              <p
                className="footer-title cursor"
                onClick={() => this.props.history.push('/privacy')}
              >
                개인정보처리방침
              </p>
              <p className="footer-title cursor" onClick={() => this.props.history.push('/terms')}>
                이용약관
              </p>
              <p className="footer-copy">COPYRIGHT © 오늘의 직구</p>
            </div>
            <div className="footer-company-bg">
              <ul className="footer-info-list">
                <li>{siteInfo['주소']}</li>
                <li>상호명 : {siteInfo['상호명']}</li>
                <li>대표자 : {siteInfo['대표자']}</li>
                <li>사업자등록번호 : {siteInfo['사업자등록번호']}</li>
                <li>통신판매업신고 : {siteInfo['통신판매업신고']}</li>
              </ul>
            </div>
            <div className="footer-info-bg">
              <ul className="footer-info-list">
                <li>고객센터 : {siteInfo['고객센터번호']}</li>
                <li>{siteInfo['운영시간']}</li>
                <li>이메일 : {siteInfo['이메일']}</li>
                <li>개인정보책임자 : {siteInfo['개인정보책임자']}</li>
              </ul>
            </div>
            <div className="footer-sns-bg">
              <ul className="footer-sns-list">
                <li>
                  <span className="insta" onClick={() => handleSns(siteInfo['인스타'])}></span>
                </li>
                <li>
                  <span className="twiter" onClick={() => handleSns(siteInfo['트위터'])}></span>
                </li>
                <li>
                  <span className="facebook" onClick={() => handleSns(siteInfo['페이스북'])}></span>
                </li>
              </ul>
            </div>
          </div>
          <div className="wrapper footer-mo-wrapper">
            <div className="footer-mo-sns-bg">
              <ul className="footer-mo-sns-list">
                <li>
                  <span className="insta" onClick={() => handleSns(siteInfo['인스타'])}></span>
                </li>
                <li>
                  <span className="twiter" onClick={() => handleSns(siteInfo['트위터'])}></span>
                </li>
                <li>
                  <span className="facebook" onClick={() => handleSns(siteInfo['페이스북'])}></span>
                </li>
              </ul>
            </div>
            <div className="footer-mo-title-bg">
              <p
                className="footer-mo-title cursor"
                onClick={() => this.props.history.push('/privacy')}
              >
                >개인정보처리방침
              </p>
              <p
                className="footer-mo-title cursor"
                onClick={() => this.props.history.push('/terms')}
              >
                이용약관
              </p>
            </div>
            <div className="footer-mo-copy-bg">
              <p className="footer-mo-copy">COPYRIGHT © 오늘의 직구</p>
            </div>
          </div>
        </div>
      </footer>
    );
  }
}

export default withRouter(Footer);
