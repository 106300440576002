import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import './LoginSearchPassword.css';
import axios from 'axios';
import { API } from 'config';

class LoginSearchPassword extends Component {
  state = {
    searchProcess: 1,
    email: '',
    confirmEmail: '',
  };

  handleChange = (e) => {
    this.setState({
      ...this.state,
      [e.target.name]: e.target.value,
    });
  };

  findPassword = async (email) => {
    try {
      const check = await axios.get(`${API}/users/?email=${email}`);
      if (check && check.status === 200 && check.data[0] && check.data[0][0]) {
        const user = check.data[0][0];
        const result = await axios.post(`${API}/users/${user.id}/temp-password`, {
          email: user.email,
        });
        if (result && result.status === 201) {
          this.setState({
            ...this.state,
            email: '',
            confirmEmail: user.email,
            searchProcess: 2,
          });
        } else {
          this.setState({
            ...this.state,
            email: '',
            searchProcess: 3,
          });
        }
      } else {
        this.setState({
          ...this.state,
          email: '',
          searchProcess: 3,
        });
      }
    } catch (e) {
      this.setState({
        ...this.state,
        email: '',
        searchProcess: 3,
      });
    }
  };

  // 비멀번호 찾기
  // /v1/users/1/temp-password
  // POST 토큰X
  // 바디에 email

  hanldeSearchProcess = (searchProcess) => {
    this.setState({
      ...this.state,
      searchProcess,
    });
  };

  render() {
    const { hanldeSearchProcess, handleChange, findPassword } = this;
    const { searchProcess, email, confirmEmail } = this.state;
    const { value } = this.props;

    return (
      <>
        {searchProcess === 1 && (
          <div className="bjs-bg">
            <div className="bjs-password">
              <div className="bjs-logo">
                <img src="/images/logo.png" alt="logo" />
              </div>
              <h2>비밀번호 재설정</h2>
              <p>비밀번호를 잃어버리셨나요?</p>
              <p>오늘의 직구 계정의 아이디를 입력해 주세요.</p>
              <div className="bjs-address">
                <label htmlFor="">E-MAIL (이메일 주소)</label>
                <input type="text" name="email" value={email} onChange={handleChange} />
              </div>
              <button onClick={() => findPassword(email)}>다음</button>
            </div>
          </div>
        )}
        {searchProcess === 2 && (
          <div className="bjs-bg">
            <div className="bjs-password">
              <div className="bjs-logo">
                <img src="/images/logo.png" alt="logo" />
              </div>
              <h2>이메일 발송완료</h2>
              <div className="bjs-email-txt">
                <p>사용자 계정으로 임시 비밀번호 Email이 전송 되었습니다.</p>
                <p>받으신 이메일을 확인하시고 임시 비밀번호를 이용하여 비밀번호를 재설정하세요.</p>
                <p>만약 메일을 받지 못하셨다면, 스팸 메일함을 확인해주세요.</p>
              </div>
              <p className="bjs-id">{value}</p>
              <button
                className="bjs-button-top cursor"
                onClick={() => {
                  window.location.href = '/login';
                }}
              >
                로그인 화면
              </button>
              <button className="bjs-button-top cursor" onClick={() => findPassword(confirmEmail)}>
                임시 비밀번호 재발송
              </button>
            </div>
          </div>
        )}
        {searchProcess === 3 && (
          <div className="bjs-bg recycle">
            <div className="bjs-password">
              <div className="bjs-logo">
                <img src="/images/logo.png" alt="logo" />
              </div>
              <h2>비밀번호 재설정</h2>
              <div className="bjs-email-txt">
                <p>입력하신 정보와 일치하는 계정을 찾지 못하였습니다.</p>
                <p>
                  신규 사용자이신가요?
                  <strong
                    className="cursor"
                    onClick={() => {
                      // this.props.history.push('/signup');
                      window.location.href = '/signup';
                    }}
                  >
                    계정만들기
                  </strong>
                </p>
                <p>
                  정보를 다시 입력하시겠어요?
                  <strong className="cursor" onClick={() => hanldeSearchProcess(1)}>
                    되돌아가기
                  </strong>
                </p>
              </div>
              <button
                className="cursor"
                onClick={() => {
                  window.location.href = '/login';
                }}
              >
                로그인 화면
              </button>
            </div>
          </div>
        )}
      </>
    );
  }
}

export default withRouter(LoginSearchPassword);
