import React, { Component } from 'react';
import './MyBuy.css';
import axios from 'axios';
import { API } from 'config';
import Pagination from 'components/Pagination';
import moment from 'moment';

class MyBuy extends Component {
  constructor() {
    super();

    this.state = {
      items: [],
      pageOfItems: [],
    };

    // bind function in constructor instead of render (https://github.com/yannickcr/eslint-plugin-react/blob/master/docs/rules/jsx-no-bind.md)
    this.onChangePage = this.onChangePage.bind(this);
  }

  componentDidMount() {
    this.getWantToBuy();
  }

  //

  // 사주세요 get
  getWantToBuy = async () => {
    try {
      const result = await axios.get(`${API}/users/orders`, {
        headers: {
          token: `${localStorage.getItem('token')}`,
        },
      });
      this.setState({
        ...this.state,
        items: result.data[0],
      });
    } catch (e) {
      if (e.response && e.response.data && e.response.data.msg && e.response.data.msg === '토큰이 만료되었습니다.') {
        localStorage.removeItem('token');
        window.location.href = "/";
        return;
      }
    }
  };

  onChangePage = (pageOfItems) => {
    // update state with new page of items
    this.setState({ pageOfItems: pageOfItems });
  };

  // additionalMargin: 0
  // cardName: null
  // createdAt: "2020-11-29T10:53:39.546Z"
  // id: 200
  // lastModifiedUserId: null
  // linkId: null
  // orderPrice: 0
  // orderShippingPrice: 0
  // purchaseCardPrice: 0
  // purchaseShippingPrice: 0
  // purchaseTax: 0
  // realPrice: 0
  // realPriceCurrency: null
  // reviewId: 63
  // serviceProvider: null
  // shippingCompany: null
  // status: "요청"
  // updatedAt: "2020-11-29T10:53:39.546Z"
  // userId: 55

  // 결제 금액 orderPrice
  // 배송비 orderShippingPrice

  //   결제 금액 orderPrice
  // 배송비 orderShippingPrice
  // 수수료 orderFee

  render() {
    const { items, pageOfItems } = this.state;
    return (
      <div className="mybuy-bg">
        <p className="mybuy-title">요청 현황</p>
        <table className="mybuy-table">
          <thead>
            <tr>
              <th>견적여부</th>
              <th>요청일자</th>
              <th>요청상품</th>
              <th>견적금액</th>
            </tr>
          </thead>
          <tbody>
            {pageOfItems.length === 0 && (
              <tr>
                <td className="mybuy-no-data" colSpan="4">
                  사주세요 요청 내역이 없습니다.
                </td>
              </tr>
            )}
            {pageOfItems.length > 0 &&
              pageOfItems.map((el, i) => {
                return (
                  <tr key={i}>
                    <td>{el.status}</td>
                    <td>{moment(el.createdAt).format('YYYY-MM-DD HH:MM')}</td>
                    <td className="mybuy-product-td">
                      <img src="/images/productImg.svg" alt="" />
                      {el.product}
                    </td>
                    <td className="mybuy-price-td">
                      <div>
                        <span className="mybuy-price-title">결제금액</span>
                        <span className="mybuy-price">{el.orderPrice}</span>
                      </div>
                      <div>
                        <span className="mybuy-price-title">수수료</span>
                        <span className="mybuy-price">{el.orderFee}</span>
                      </div>
                      <div>
                        <span className="mybuy-price-title">배송비</span>
                        <span className="mybuy-price">{el.orderShippingPrice}</span>
                      </div>
                      <div>
                        <span className="mybuy-price-title">총금액</span>
                        <span className="mybuy-price">
                          {Number(el.orderPrice) +
                            Number(el.orderFee) +
                            Number(el.orderShippingPrice) || 0}
                        </span>
                      </div>
                    </td>
                  </tr>
                );
              })}
          </tbody>
        </table>
        <div className="mybuy-mo-list">
          {pageOfItems.length === 0 && (
            <div className="mybuy-mo-no-data">사주세요 요청 내역이 없습니다.</div>
          )}
          {pageOfItems.length > 0 &&
            pageOfItems.map((el, i) => {
              return (
                <div key={i} className="mybuy-mo-item">
                  <div className="mybuy-mo-product-bg">
                    <img className="mybuy-mo-product-img" src="/images/productImg.svg" alt="" />
                    <span className="mybuy-mo-product-title">{el.product}</span>
                  </div>
                  <div className="mybuy-mo-dt-bg">
                    <span className="mybuy-mo-dt">견적여부</span> {el.status}
                  </div>
                  <div className="mybuy-mo-dt-bg">
                    <span className="mybuy-mo-dt">요청일자</span>{' '}
                    {moment(el.createdAt).format('YYYY-MM-DD HH:MM')}
                  </div>
                  <div className="mybuy-mo-price-bg">
                    <div className="mybuy-mo-price-item">
                      <span className="mybuy-mo-price-title">결제금액</span>
                      <span className="mybuy-mo-price">{el.orderPrice}</span>
                    </div>
                    <div className="mybuy-mo-price-item">
                      <span className="mybuy-mo-price-title">수수료</span>
                      <span className="mybuy-mo-price">{el.orderFee}</span>
                    </div>
                    <div className="mybuy-mo-price-item">
                      <span className="mybuy-mo-price-title">배송비</span>
                      <span className="mybuy-mo-price">{el.orderShippingPrice}</span>
                    </div>
                    <div className="mybuy-mo-price-item">
                      <strong className="mybuy-mo-price-title">총금액</strong>
                      <strong className="mybuy-mo-price">
                        {Number(el.orderPrice) +
                          Number(el.orderFee) +
                          Number(el.orderShippingPrice) || 0}
                      </strong>
                    </div>
                  </div>
                </div>
              );
            })}
        </div>
        <div className="pagination-bg">
          <Pagination items={items} onChangePage={this.onChangePage} />
        </div>
      </div>
    );
  }
}

export default MyBuy;
