import React from 'react';
import MainStructure from 'components/structure/MainStructure';
import TermsContainer from 'containers/TermsContainer';

const Terms = () => {
  return (
    <MainStructure>
      <TermsContainer />
    </MainStructure>
  );
};

export default Terms;
