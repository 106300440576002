import React, { Component } from 'react';
import './Imagebox.css';

class Imagebox extends Component {
  state = {
    standard: '',
    width: 0,
    height: 0,
    ratio: 0,
    imageWidth: 0,
    imageHeight: 0,
    link: '',
  };

  imageRef = React.createRef();

  componentDidMount() {
    this.getMeta(this.props.link);
    window.addEventListener('resize', this.updateSize);
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps && nextProps.link) {
      this.getMeta(nextProps && nextProps.link);
    }
  }

  componentWillMount() {
    window.removeEventListener('resize', this.updateSize);
  }

  updateSize = () => {
    const { standard, imageWidth, imageHeight } = this.state;

    var ratio, height, width;

    if (standard === 'height') {
      ratio = this.imageRef && this.imageRef.current && this.imageRef.current.offsetHeight && this.imageRef.current.offsetHeight / imageHeight || 0;
      height = this.imageRef && this.imageRef.current && this.imageRef.current.offsetHeight && this.imageRef.current.offsetHeight || 0;
      width = imageWidth * ratio || 0;
    } else {
      ratio = this.imageRef && this.imageRef.current && this.imageRef.current.offsetWidth && this.imageRef.current.offsetWidth / imageWidth || 0;
      width = this.imageRef && this.imageRef.current && this.imageRef.current.offsetWidth && this.imageRef.current.offsetWidth || 0;
      height = imageHeight * ratio || 0;
    }

    this.setState({
      ...this.state,
      width,
      height,
      ratio,
    });
  };

  getMeta = (link) => {
    var img = new Image();
    var _this = this;
    img.onload = function () {
      var standard, ratio, width, height, imageWidth, imageHeight;

      imageWidth = this.width || 0;
      imageHeight = this.height || 0;

      if (this.width >= this.height) {
        standard = 'height';
        ratio = _this.imageRef && _this.imageRef.current && _this.imageRef.current.offsetHeight && _this.imageRef.current.offsetHeight / this.height || 0;
        height = _this.imageRef && _this.imageRef.current && _this.imageRef.current.offsetHeight && _this.imageRef.current.offsetHeight || 0;
        width = this.width * ratio || 0;
      } else {
        standard = 'width';
        ratio = _this.imageRef && _this.imageRef.current && _this.imageRef.current.offsetWidth && _this.imageRef.current.offsetWidth / this.width || 0;
        width = _this.imageRef && _this.imageRef.current && _this.imageRef.current.offsetWidth && _this.imageRef.current.offsetWidth || 0;
        height = this.height * ratio || 0;
      }

      _this.setState({
        ..._this.state,
        standard,
        imageWidth,
        imageHeight,
        width,
        height,
        ratio,
        link
      });

    };
    img.src = link;
  };

  render() {
    const { imageRef } = this;
    const { standard, width, height, ratio } = this.state;
    const { link, option = 'round' } = this.props;

    return (
      <div className={"image-box-bg " + option} ref={imageRef}>
        <img
          className="image-box"
          src={link}
          style={{ display: 'inline-block', width: `${width}px`, height: `${height}px` }}
          alt=""
        />
      </div>
    );
  }
}

export default Imagebox;
