import React, { Component } from 'react';
import MyInfo from 'components/myinfo/MyInfo';

class MyInfoContainer extends Component {
  render() {
    return <MyInfo />;
  }
}

export default MyInfoContainer;
