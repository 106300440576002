import React from 'react';
import './MainStructure.css';
import HeaderContainer from 'containers/HeaderContainer';
import FooterContainer from 'containers/FooterContainer';
import GlobalNavigationContainer from 'containers/GlobalNavigationContainer';

const MainStructure = ({ children }) => {
  return (
    <div>
      <HeaderContainer />
      <GlobalNavigationContainer />
      <main>
        {children}
        {/* <button className="kakao-button"></button> */}
      </main>
      <FooterContainer />
    </div>
  );
};

export default MainStructure;
