import React, { Component } from 'react';
import './HeaderTag.css';
import axios from 'axios';
import { API } from 'config';
import { withRouter } from 'react-router-dom';

class HeaderTag extends Component {
  state = {
    styleTagList: [],
  };

  listRef = React.createRef();

  componentDidMount() {
    this.getStyleTags();
  }

  handleScroll = (move) => {
    if (move === 'prev') {
      this.listRef.current.scrollLeft -= 50;
    } else {
      this.listRef.current.scrollLeft += 50;
    }
  };

  getStyleTags = async () => {
    try {
      const result = await axios.get(`${API}/static/style-tags?hidden=false`);
      this.setState({
        ...this.state,
        styleTagList: result.data,
      });
    } catch (e) {
      if (e.response && e.response.data && e.response.data.msg && e.response.data.msg === '토큰이 만료되었습니다.') {
        localStorage.removeItem('token');
        window.location.href = "/";
        return;
      }
    }
  };

  // scrollLeft

  render() {
    const { styleTagList } = this.state;
    const { handleScroll } = this;

    return (
      <div className="header-tag-box-content-bg">
        <span className="header-tag-box-prev pc-only" onClick={() => handleScroll('prev')}></span>
        <div className="header-tag-box-list-bg" ref={this.listRef}>
          <ul className="header-tag-box-list">
            {styleTagList &&
              styleTagList.map((el, i) => {
                return (
                  <li
                    key={i}
                    onClick={() => {
                      // this.props.history.push(`/search?styletag=${el.value}`)
                      window.location.href = `/search?styletag=${el.value}`;
                    }}
                  >
                    <span>#{el.value}</span>
                  </li>
                );
              })}
          </ul>
        </div>
        <span className="header-tag-box-next pc-only" onClick={() => handleScroll('next')}></span>
      </div>
    );
  }
}

export default withRouter(HeaderTag);
