import React, { Component } from 'react';
import jwt_decode from 'jwt-decode';
import { withRouter } from 'react-router-dom';
import { gradeName, abbreviateNumber, numberWithCommas, compareTime } from 'lib/common';
import axios from 'axios';
import { API } from 'config';
import './PCMenu.css';

class PCMenu extends Component {
  state = {
    user: {},
  };

  componentWillMount() {
    localStorage.getItem('token') && this.getUser();
  }

  componentDidMount() {
    localStorage.getItem('token') && this.getUserData();
  }

  getUser = () => {
    try {
      const user = jwt_decode(localStorage.getItem('token'));
      if (user) {
        this.setState({
          ...this.state,
          user: {
            id: user.id,
          },
        });
      } else {
        localStorage.removeItem('token');
      }
    } catch (e) {
      localStorage.removeItem('token');
      window.location.reload();
    }
  };

  getUserData = async () => {
    const user = jwt_decode(localStorage.getItem('token'));
    if (user) {
      try {
        const result = await axios.get(`${API}/users/my`, {
          headers: {
            token: `${localStorage.getItem('token')}`,
          },
        });

        const newToken = result.headers['new-token'] || '';
        if (newToken) {
          axios.defaults.headers.token = newToken;
          localStorage.setItem('token', newToken);
        }

        if (result && result.status === 200) {
          let user = result.data;

          this.setState({
            ...this.state,
            user,
          });
        } else {
          localStorage.removeItem('token');
          window.location.reload();
        }
      } catch (e) {
        localStorage.removeItem('token');
        window.location.reload();
      }
    } else {
      localStorage.removeItem('token');
    }
  };

  render() {
    const { user } = this.state;

    if (!user) return <></>;

    return (
      <div className="pc-menu-bg">
        <div className="pc-menu">
          <div>
            <div className="pc-menu-user">
              <div
                className={
                  'main-intro-content-user-profile-bg pc-menu-user-profile-bg ' +
                  `grade-${user.grade}`
                }
              >
                <span
                  className={'main-intro-content-user-profile '}
                  style={
                    user.profileImgUrl
                      ? {
                          backgroundImage: `url(${user.profileImgUrl})`,
                        }
                      : {}
                  }
                ></span>
              </div>
              <div className="pc-menu-info">
                <div className="pc-menu-name-bg">
                  <span className="pc-menu-name">{user.nickname}</span>
                  <span className={'pc-menu-grade ' + `grade-${user.grade}`}>
                    {gradeName(user.grade)}
                  </span>
                </div>
                <div className="pc-menu-email-bg">
                  <p className="pc-menu-email">{user.email}</p>
                </div>
                <div className="pc-menu-follow-bg">
                  <span className="pc-menu-follow">
                    팔로우 {abbreviateNumber(parseInt(user.followerCount), 0)}명
                  </span>
                  <span className="pc-menu-follow">·</span>
                  <span className="pc-menu-follow">
                    팔로잉 {abbreviateNumber(parseInt(user.followingCount), 0)}명
                  </span>
                </div>
              </div>
            </div>
          </div>
          <div
            className="pc-menu-item-bg"
            onClick={() => {
              // this.props.history.push('/mypage');
              window.location.href = '/myinfo?point=true';
            }}
          >
            <span className="pc-menu-item point">마이 포인트</span>
            <span className="pc-menu-point">
              {(user.point && numberWithCommas(user.point)) || 0} P
            </span>
          </div>
          <div className="pc-menu-item-bg">
            <button className="pc-menu-item my" onClick={() => (window.location.href = '/mypage')}>
              마이 페이지
            </button>
          </div>
          <div className="pc-menu-item-bg">
            <button
              className="pc-menu-item info"
              onClick={() => (window.location.href = '/myinfo')}
            >
              개인정보 보기
            </button>
          </div>
          <div
            className="pc-menu-item-bg"
            onClick={() => {
              localStorage.removeItem('token');
              window.location.href = '/';
            }}
          >
            <button
              className="pc-menu-item logout"
              onClick={() => {
                localStorage.removeItem('token');
                window.location.href = '/';
              }}
            >
              로그아웃
            </button>
          </div>
        </div>
      </div>
    );
  }
}

export default withRouter(PCMenu);
