import React from 'react';
import ReactDOM from 'react-dom';
import Root from './Root';
import 'react-app-polyfill/ie9';
import smoothscroll from 'smoothscroll-polyfill';

import 'styles/reset.css';
import 'styles/common.css';
import 'styles/writing.css';
import * as serviceWorker from './serviceWorker';

ReactDOM.render(<Root />, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
smoothscroll.polyfill();

serviceWorker.unregister();
