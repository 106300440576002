import React, { Component } from 'react';
import { Link, withRouter } from 'react-router-dom';
import MobileMenu from 'components/structure/MobileMenu';
import PCMenu from 'components/structure/PCMenu';
import SearchMenu from 'components/structure/SearchMenu';
import jwt_decode from 'jwt-decode';
import axios from 'axios';
import { API } from 'config';
import './Header.css';
import { gradeName, abbreviateNumber, numberWithCommas, compareTime } from 'lib/common';

class Header extends Component {
  constructor(props) {
    super(props);
    this.state = {
      clickSearch: false,
      clickPlus: false,
      menu: {
        today: false,
        review: false,
        get: false,
        talk: false,
        event: false,
      },
      word: '',
      user: {},
    };
  }

  search;
  searchInput = React.createRef();

  handleEnter = (e) => {
    if (e.key === 'Enter') {
      this.getSearch();
    }
  };

  typeSearch = () => {
    try {
      const _this = this;
      clearTimeout(this.search);
      this.search = setTimeout(function () {
        _this.getSearch();
      }, 1000);
    } catch (e) {
      if (e.response && e.response.data && e.response.data.msg && e.response.data.msg === '토큰이 만료되었습니다.') {
        localStorage.removeItem('token');
        window.location.href = "/";
        return;
      }
    }
  };

  handleWord = (e) => {
    this.setState(
      {
        ...this.state,
        [e.target.name]: e.target.value,
      },
      () => {
        this.typeSearch();
      },
    );
  };

  getSearch = () => {
    const { word } = this.state;
    const findWord = word.trim();
    if (!findWord) return;
    if (this.searchInput && this.searchInput.current) {
      this.searchInput.current.value = '';
    }
    // this.props.history.push(`/search?word=${findWord}`);
    window.location.href = `/search?word=${findWord}`;
    this.setState({
      ...this.state,
      word: '',
      clickSearch: false,
      clickPlus: false,
    });
  };

  componentWillMount() {
    localStorage.getItem('token') && this.getUser();
  }

  componentDidMount() {
    localStorage.getItem('token') && this.getUserData();
    const url = this.props.history.location.pathname;

    switch (url) {
      case '/':
        this.setState({
          ...this.state,
          menu: {
            ...this.state.menu,
            today: true,
          },
        });
        break;
      case '/review':
        this.setState({
          ...this.state,
          menu: {
            ...this.state.menu,
            review: true,
          },
        });
        break;
      case '/get':
        this.setState({
          ...this.state,
          menu: {
            ...this.state.menu,
            get: true,
          },
        });
        break;
      case '/talk':
        this.setState({
          ...this.state,
          menu: {
            ...this.state.menu,
            talk: true,
          },
        });
        break;
      case '/event':
        this.setState({
          ...this.state,
          menu: {
            ...this.state.menu,
            event: true,
          },
        });
        break;
      default:
        break;
    }
  }

  getUser = () => {
    try {
      const user = jwt_decode(localStorage.getItem('token'));
      if (user) {
        this.setState({
          ...this.state,
          user: {
            id: user.id,
          },
        });
      } else {
        localStorage.removeItem('token');
      }
    } catch (e) {
      localStorage.removeItem('token');
      window.location.reload();
    }
  };

  getUserData = async () => {
    const user = jwt_decode(localStorage.getItem('token'));
    if (user) {
      try {
        const result = await axios.get(`${API}/users/my`, {
          headers: {
            token: `${localStorage.getItem('token')}`,
          }
        });

        const newToken = result.headers["new-token"] || '';
        if (newToken) {
          axios.defaults.headers.token = newToken;
          localStorage.setItem("token", newToken);
        }

        if (result && result.status === 200) {
          let user = result.data;

          this.setState({
            ...this.state,
            user,
          });
        } else {
          localStorage.removeItem('token');
          window.location.reload();
        }
      } catch (e) {
        localStorage.removeItem('token');
        window.location.reload();
      }
    } else {
      localStorage.removeItem('token');
    }
  };

  handleSearch = (e) => {
    e.stopPropagation();

    this.setState({
      ...this.state,
      clickSearch: true,
    });
  };

  closeSearch = (e) => {

    if (!e) return;
    if (e.target.getAttribute('id') === 'header-search') return;
    if (e.target.getAttribute('id') === 'search-menu-mo-input') return;

    this.setState({
      ...this.state,
      clickSearch: false,
    });
  };

  handlePlus = (e) => {
    e.stopPropagation();
    this.setState({
      ...this.state,
      clickPlus: true,
    });
  };

  closePlus = () => {
    this.setState({
      ...this.state,
      clickPlus: false,
    });
  };

  handleReview = () => {
    const { user } = this.state;
    if (user.grade < 2) return alert('초보 오직러부터 후기를 작성하실 수 있습니다:)');
    // this.props.history.push('/writing/review');
    window.location.href = '/writing/review';
  };

  handleGet = () => {
    const { user } = this.state;
    if (user.grade < 3) return alert('일반 오직러부터 득템을 작성하실 수 있습니다:)');
    // this.props.history.push('/writing/get');
    window.location.href = '/writing/get';
  };

  render() {
    const { menu, clickPlus, clickSearch, user, word } = this.state;
    const {
      handlePlus,
      closePlus,
      handleSearch,
      closeSearch,
      handleWord,
      handleEnter,
      handleReview,
      handleGet,
      getSearch
    } = this;

    return (
      <>
        <header onClick={closeSearch}>
          <div className="header-bg">
            <div className="wrapper header-wrapper">
              <div className="header-logo-bg">
                <span className="header-logo" to="/" onClick={() => (window.location.href = '/')}>
                  <img className="pc-img" src="/images/logo.png" alt="" />
                  <img className="mo-img" src="/images/mobile-logo.png" alt="" />
                </span>
              </div>
              <div className="header-menu-bg">
                <ul className="header-menu">
                  <li className={'active'}>
                    <Link to="/">
                      <span>오늘의</span>
                    </Link>
                  </li>
                  <li className={menu.review ? 'active_link' : ''}>
                    <Link to="/review">
                      <span className="header-link">
                        후기
                        <span className="header-underline"></span>
                      </span>
                    </Link>
                  </li>
                  <li className={menu.get ? 'active_link' : ''}>
                    <Link to="/get">
                      <span className="header-link">
                        득템<span className="header-underline"></span>
                      </span>
                    </Link>
                  </li>
                  <li className={menu.talk ? 'active_link' : ''}>
                    <Link to="/talk">
                      <span className="header-link">
                        토크<span className="header-underline"></span>
                      </span>
                    </Link>
                  </li>
                  <li className={menu.event ? 'active_link' : ''}>
                    <Link to="/event">
                      <span className="header-link">
                        이벤트<span className="header-underline"></span>
                      </span>
                    </Link>
                  </li>
                </ul>
              </div>
              <div className="header-search-bg">
                <label className="header-search-icon" htmlFor="header-search"></label>
                <input
                  id="header-search"
                  type="text"
                  name="word"
                  value={word}
                  className="header-search-input"
                  placeholder="원하는 브랜드, 스타일을 검색해보세요 :)"
                  onFocus={handleSearch}
                  // onBlur={closeSearch}
                  onChange={(e) => handleWord(e)}
                  onKeyDown={handleEnter}
                  ref={this.searchInput}
                />
                <button className="header-search-dropdown" onClick={getSearch}></button>
                {menu.review && (
                  <button className="header-button-write" onClick={() => handleReview()}></button>
                )}
                {menu.get && (
                  <button className="header-button-write" onClick={() => handleGet()}></button>
                )}
                {menu.talk && (
                  <button
                    className="header-button-write"
                    onClick={() => window.location.href = '/writing/talk'}
                  ></button>
                )}
              </div>
              <div className="header-button-bg">
                {user && user.id ? (
                  <>
                    <div className="main-intro-content-user-bg">
                      <span
                        className={'main-intro-content-user-profile-bg ' + `grade-${user.grade}`}
                      >
                        <span
                          className={'main-intro-content-user-profile '}
                          style={user.profileImgUrl ? { backgroundImage: `url(${user.profileImgUrl})` } : {}}
                        ></span>
                      </span>
                      <span className="main-intro-content-user-name">{user.nickname}</span>
                      <span className={'main-intro-content-user-grade ' + `grade-${user.grade}`}>
                        {gradeName(user.grade)}
                      </span>
                    </div>
                    <PCMenu />
                  </>
                ) : (
                    <>
                      <button
                        className="header-button-login"
                        onClick={() => window.location.href = '/login'}
                      >
                        로그인
                    </button>
                      <button
                        className="header-button-join"
                        onClick={() => window.location.href = '/signup'}
                      >
                        회원가입
                    </button>
                    </>
                  )}
              </div>
              <div className="header-status-bg mo-only">
                {user && user.id ? (
                  <>
                    <button className="header-button-search" onClick={handleSearch}></button>
                    <button className="header-button-plus" onClick={handlePlus}></button>
                  </>
                ) : (
                    <>
                      <button
                        className="header-button-login"
                        onClick={() => window.location.href = '/login'}
                      >
                        로그인
                    </button>
                      <button
                        className="header-button-join"
                        onClick={() => window.location.href = '/signup'}
                      >
                        회원가입
                    </button>
                    </>
                  )}
              </div>
            </div>
          </div>
        </header>
        <SearchMenu isOpen={clickSearch} closeSearch={closeSearch} getSearch={getSearch} />
        {clickPlus && <MobileMenu isOpen={clickPlus} closePlus={closePlus} />}
      </>
    );
  }
}

export default withRouter(Header);
