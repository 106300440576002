import React, { Component } from 'react';
import Signup from 'components/Signup';

class SignupContainer extends Component {
  render() {
    return <Signup />;
  }
}

export default SignupContainer;
