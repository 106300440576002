import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import axios from 'axios';
import { API } from 'config';
import './SearchMenu.css';

class SearchMenu extends Component {
  state = {
    word: '',
    store: [],
    brands: [],
    styletags: [],
  };

  search;
  searchInput = React.createRef();

  componentDidMount() {
    this.getMenu();
    this.getStyletags();
  }

  getMenu = async () => {
    try {
      const result = await axios.get(`${API}/extra/main-page`);
      if (result && result.status === 200) {
        this.setState({
          ...this.state,
          brands: result.data.brands,
          store: result.data.store,
        });
      }
    } catch (e) {
      if (e.response && e.response.data && e.response.data.msg && e.response.data.msg === '토큰이 만료되었습니다.') {
        localStorage.removeItem('token');
        window.location.href = "/";
        return;
      }
    }
  };

  getStyletags = async () => {
    try {
      const result = await axios.get(`${API}/static/style-tags`);
      if (result && result.status === 200) {
        this.setState({
          ...this.state,
          styletags: result.data,
        });
      }
    } catch (e) {
      if (e.response && e.response.data && e.response.data.msg && e.response.data.msg === '토큰이 만료되었습니다.') {
        localStorage.removeItem('token');
        window.location.href = "/";
        return;
      }
    }
  };

  handleEnter = (e) => {
    if (e.key === 'Enter') {
      this.getSearch();
    }
  };

  typeSearch = () => {
    try {
      const _this = this;
      clearTimeout(this.search);
      this.search = setTimeout(function () {
        _this.getSearch();
      }, 1000);
    } catch (e) {
      if (e.response && e.response.data && e.response.data.msg && e.response.data.msg === '토큰이 만료되었습니다.') {
        localStorage.removeItem('token');
        window.location.href = "/";
        return;
      }
    }
  };

  handleWord = (e) => {
    this.setState(
      {
        ...this.state,
        [e.target.name]: e.target.value,
      },
      () => {
        this.typeSearch();
      },
    );
  };

  getSearch = () => {
    const { word } = this.state;
    const findWord = word.trim();
    if (!findWord) return;
    if (this.searchInput && this.searchInput.current) {
      this.searchInput.current.value = '';
    }
    window.location.href = `/search?word=${findWord}`;
    // this.props.history.push(`/search?word=${findWord}`);
    this.setState({
      ...this.state,
      word: '',
    });
    this.props.closeSearch();
  };

  handleClick = (e, type, value) => {
    e.stopPropagation();
    window.location.href = `/search?${type}=${value}`;
  };

  render() {
    const { handleWord, handleEnter, handleClick, getSearch } = this;
    const { isOpen, closeSearch } = this.props;
    const { word, store, brands, styletags } = this.state;
    // store: [],
    // brands: [],
    // styletags: [],

    return (
      <div className={'search-menu-bg ' + (isOpen ? 'show' : '')} onClick={closeSearch}>
        <div className="search-menu-mo">
          <div className="search-menu-mo-input-bg">
            <img src="/images/search-menu.svg" alt="" />
            <input
              id="search-menu-mo-input"
              className="search-menu-mo-input"
              type="text"
              name="word"
              onChange={(e) => handleWord(e)}
              onKeyDown={handleEnter}
              ref={this.searchInput}
            />
            <span className="search-menu-mo-dropdown" onClick={getSearch}></span>
          </div>
          <span className="search-menu-mo-close" onClick={closeSearch}></span>
        </div>
        {/* <div className="search-menu-item-bg">
          <div className="search-menu-item">
            <div className="search-menu-title-bg">
              <p className="search-menu-title">브랜드</p>
            </div>
            <div className="search-menu-content-bg">
              <ul className="search-menu-content">
                {brands &&
                  brands.map((el, i) => {
                    return (
                      <li key={i} onClick={(e) => handleClick(e, 'brand', el.value)}>
                        {el.value}
                      </li>
                    );
                  })}
              </ul>
            </div>
          </div>
          <div className="search-menu-item">
            <div className="search-menu-title-bg">
              <p className="search-menu-title">스토어</p>
            </div>
            <div className="search-menu-content-bg">
              <ul className="search-menu-content">
                {store &&
                  store.map((el, i) => {
                    return (
                      <li key={i} onClick={(e) => handleClick(e, 'store', el.value)}>
                        {el.value}
                      </li>
                    );
                  })}
              </ul>
            </div>
          </div>
          <div className="search-menu-item">
            <div className="search-menu-title-bg">
              <p className="search-menu-title">스타일태그</p>
            </div>
            <div className="search-menu-content-bg">
              <ul className="search-menu-content">
                {styletags &&
                  styletags.map((el, i) => {
                    return (
                      <li key={i} onClick={(e) => handleClick(e, 'styletag', el.value)}>
                        #{el.value}
                      </li>
                    );
                  })}
              </ul>
            </div>
          </div>
        </div> */}
      </div>
    );
  }
}

export default withRouter(SearchMenu);
