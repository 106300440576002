import React, { Component } from 'react';
import './GetBuying.css';
import { gradeName, abbreviateNumber, numberWithCommas, compareTime } from 'lib/common';

class GetBuying extends Component {
  render() {
    const { data, handleClick, handleCancel, currency } = this.props;
    return (
      <div className="sub-popup-bg">
        <div className="sub-popup">
          <div className="bsj-detail">
            <h2>사주세요 상세내역</h2>
            <div className="bsj-image" style={{ backgroundImage: `url(${data.imageUrl})` }}>
              {/* <h2>해당 오늘의 후기 첫번째 이야기</h2> */}
            </div>
            <div className="bsj-border">
              <ul className="bsj-left-items">
                <li>상품 이름</li>
                <li>브랜드 이름</li>
                <li>스토어 이름</li>
                <li>구매가격</li>
              </ul>
              <ul className="bsj-right-items">
                <li>{data.productName}</li>
                <li>{data.brand}</li>
                <li>{data.store}</li>
                <li>{numberWithCommas(Math.round(data.price * currency[data.currency]))} 원</li>
                <li>
                  {numberWithCommas(Math.round(data.price))} {data.currency} (*관세청 오늘 환율기준)
              </li>
              </ul>
            </div>
            <div className="bsj-caution">
              <p>*구매 가격은 현지 사정에 따라서 달라질 수 있습니다.</p>
              <p>*'요청 확정'과 '채널톡' 문의를 통해 최종 결제 금액에 대한 견적이 진행됩니다.</p>
              <p>*결제 금액은 구매 가격에서 수수료와 배송비가 추가된 금액으로 책정됩니다.</p>
            </div>
            <div className="bsj-inquiry">
              <p>
                확정 이후 반드시 '채널톡'으로 문의 주시기 바랍니다 :{')'}
                <br />
                사주세요 내역은 마이 페이지에서 확인 가능합니다.
            </p>
            </div>
            <div className="bsj-btn">
              <button onClick={handleCancel}>취소</button>
              <button onClick={() => handleClick(data.id)}>요청</button>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default GetBuying;
