import TagManager from 'react-gtm-module';

class TagManagerService {
  #CONTAINER_ID = 'GTM-WFFJCVV';

  init() {
    TagManager.initialize({
      gtmId: this.#CONTAINER_ID,
    });
  }

  setDataLayer(event) {
    TagManager.dataLayer({ dataLayer: { event } });
  }

  pageView() {
    this.setDataLayer('pageview');
  }

  signUpEvent() {
    this.setDataLayer('signup');
  }

  reviewAcquireEvent() {
    this.setDataLayer('review-upload');
  }

  getAcquireEvent() {
    this.setDataLayer('get-upload');
  }

  talkAcquireEvent() {
    this.setDataLayer('talk-upload');
  }

  buyButtonClickEvent() {
    this.setDataLayer('buy-button-click');
  }

  buyRequestButtonClickEvent() {
    this.setDataLayer('buy-request-button-click');
  }

  seeButtonClickEvent() {
    this.setDataLayer('see-button-click');
  }

  reportButtonClickEvent() {
    this.setDataLayer('report-button-click');
  }

  followButtonClickEvent() {
    this.setDataLayer('follow-button-click');
  }
}

export const tagMangerService = new TagManagerService();
