import axios from 'axios';
import { API } from 'config';
import moment from 'moment';

export const gradeName = (grade) => {
  switch (Number(grade)) {
    case 1:
      return '신입 오직러';
    case 2:
      return '초보 오직러';
    case 3:
      return '일반 오직러';
    case 4:
      return '프로 오직러';
    case 5:
      return 'VIP 오직러';
    case 6:
      return '매니저';
    default:
      return '?';
  }
};

export const abbreviateNumber = (num, fixed) => {
  if (num === null) {
    return null;
  } // terminate early
  if (num === 0) {
    return '0';
  } // terminate early
  fixed = !fixed || fixed < 0 ? 0 : fixed; // number of decimal places to show
  var b = num.toPrecision(2).split('e'), // get power
    k = b.length === 1 ? 0 : Math.floor(Math.min(b[1].slice(1), 14) / 3), // floor at decimals, ceiling at trillions
    c = k < 1 ? num.toFixed(0 + fixed) : (num / Math.pow(10, k * 3)).toFixed(1 + fixed), // divide by power
    d = c < 0 ? c : Math.abs(c), // enforce -0 is 0
    e = d + ['', 'K', 'M', 'B', 'T'][k]; // append power
  return e;
};

export const numberWithCommas = (x) => {
  return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
};

export const compareTime = (time) => {
  const m = moment(new Date()).diff(time, 'minutes');
  if (m === 0) return '방금 전';
  if (m < 60) {
    return m + '분 전';
  }

  const h = moment(new Date()).diff(time, 'hours');
  if (h < 24) {
    return h + '시간 전';
  }

  const d = moment(new Date()).diff(time, 'days');
  if (d < 32) {
    return d + '일 전';
  }

  const month = moment(new Date()).diff(time, 'months');
  if (month < 12) {
    return month + '개월 전';
  }

  return moment(time).format('YYYY년 MM월 DD일');
};

export const checkPassword = (password) => {
  var check1 = /^(?=.*[a-zA-Z])(?=.*[0-9]).{10,12}$/.test(password); //영문,숫자

  var check2 = /^(?=.*[a-zA-Z])(?=.*[^a-zA-Z0-9]).{10,12}$/.test(password); //영문,특수문자

  var check3 = /^(?=.*[^a-zA-Z0-9])(?=.*[0-9]).{10,12}$/.test(password); //특수문자, 숫자

  if (!(check1 || check2 || check3)) {
    return false;
  }
};
