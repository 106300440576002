import React from 'react';
import MainStructure from 'components/structure/MainStructure';
import TalkContainer from 'containers/TalkContainer';

const Talk = () => {
  return (
    <MainStructure>
      <TalkContainer />
    </MainStructure>
  );
};

export default Talk;
