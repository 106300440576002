import React, { Component } from 'react';
import LoginSearchEmail from 'components/LoginSearchEmail';
import LoginSearchPassword from 'components/LoginSearchPassword';
import './Login.css';
import axios from 'axios';
import { API } from 'config';
import { withRouter } from 'react-router-dom';

class Login extends Component {
  emailRef = React.createRef();
  passwordRef = React.createRef();

  state = {
    process: 'login',
    email: '',
    password: '',
    error: {
      email: false,
      password: false,
    },
    search: {
      email: '',
    },
  };

  handleLogin = async () => {
    const { email, password, search } = this.state;

    try {
      const result = await axios.post(`${API}/users/login`, {
        email,
        password,
      });

      // /extra/test-token
      // const result = await axios.post(`${API}/extra/test-token`, {
      //   email,
      //   password,
      // });

      if (result.status === 200) {
        // alert('로그인 성공!');
        localStorage.setItem('token', result.data.token);
        // this.props.history.push('/');
        window.location.href = '/';
      } else {
        // alert('로그인 실패!');
        this.setState({
          ...this.state,
          error: {
            email: true,
            password: true,
          },
        });
      }
    } catch (e) {
      // alert('로그인 실패!');
      if (e && e.response && e.response.data && e.response.data.msg && e.response.data.msg.indexOf("user doesn't exist.") > -1) {
        alert('일치하는 회원정보가 없습니다.\n아이디와 패스워드를 다시 한 번 확인해주세요:)')
        if (this.emailRef && this.emailRef.current) {
          this.emailRef.current.value = '';
        }

        if (this.passwordRef && this.passwordRef.current) {
          this.passwordRef.current.value = '';
        }

        this.setState({
          ...this.state,
          process: 'login',
          email: '',
          password: '',
          error: {
            email: false,
            password: false,
          },
          search: {
            email: '',
          },
        })
      }
      // this.setState({
      //   ...this.state,
      //   error: {
      //     email: true,
      //     password: true,
      //   },
      // });
    }
  };

  handleEnter = (e) => {
    if (e.key === 'Enter') {
      this.handleLogin();
    }
  }

  handleProcess = (process) => {
    this.setState({
      ...this.state,
      process,
    });
  };

  handleChange = (e) => {
    this.setState({
      ...this.state,
      [e.target.name]: e.target.value,
      error: {
        email: false,
        password: false,
      },
    });
  };

  handleSeachPassword = (e) => {
    this.setState({
      ...this.state,
      search: {
        ...this.state.search,
        [e.target.name]: e.target.value,
      },
    });
  };

  render() {
    const { handleLogin, handleChange, handleProcess, handleSeachPassword, emailRef, passwordRef, handleEnter } = this;
    const { email, password, error, process, search } = this.state;
    return (
      <>
        {process === 'login' && (
          <div className="login-bg">
            <div className="login-popup">
              <div className="login-popup-header">
                <img className="login-logo" src="/images/login_logo.svg" alt="" />
                <h2 className="login-popup-title">로그인</h2>
                <span className="login-popup-desc">신규 오직러신가요?</span>
                <button
                  className="login-popup-create"
                  onClick={() => {
                    // this.props.history.push('/signup')
                    window.location.href = '/signup';
                  }}
                >
                  계정만들기
                </button>
              </div>
              <div className="login-popup-body">
                <div className="popup-input-bg">
                  <label htmlFor="">이메일</label>
                  <input
                    className={'popup-input ' + (error.email ? 'error' : '')}
                    type="text"
                    name="email"
                    onChange={(e) => handleChange(e)}
                    ref={emailRef}
                  />
                </div>
                <div className="popup-input-bg">
                  <label htmlFor="">비밀번호</label>
                  <input
                    className={'popup-input ' + (error.password ? 'error' : '')}
                    type="password"
                    name="password"
                    onChange={(e) => handleChange(e)}
                    ref={passwordRef}
                    onKeyDown={handleEnter}
                  />
                </div>
                <div className="error-bg">
                  <p className="error"></p>
                </div>
                <div className="login-popup-submit-bg">
                  <button className="login-popup-submit" onClick={handleLogin}>
                    로그인 하기
                  </button>
                </div>
              </div>
              <div className="login-popup-button-bg">
                <button onClick={() => handleProcess('id')}>아이디 찾기</button>
                <button onClick={() => handleProcess('password')}>비밀번호 찾기</button>
                <button onClick={() => window.location.href = '/signup'}>회원가입</button>
              </div>
            </div>
          </div>
        )}
        {process === 'id' && (
          <LoginSearchEmail value={search.email} handleChange={handleSeachPassword} />
        )}
        {process === 'password' && (
          <LoginSearchPassword value={search.email} handleChange={handleSeachPassword} />
        )}
      </>
    );
  }
}

export default withRouter(Login);
