import React, { Component } from 'react';
import ReactSwipe from 'react-swipe';
import Imagebox from 'components/common/Imagebox';
import './Carousel.css';

class Carousel extends Component {
  render() {
    const { images } = this.props;
    let reactSwipeEl;

    return (
      <div className="carousel-bg">
        <ReactSwipe
          className="carousel"
          swipeOptions={{ continuous: false }}
          ref={(el) => (reactSwipeEl = el)}
          childCount={images.length}
        >
          {images &&
            images.map((el, i) => {
              return (
                <div key={i}>
                  <div className="item carousel-img-bg">
                    <Imagebox link={el} option='rect' />
                    {/* <img className="carousel-img" alt={`slide${i}`} src={el} /> */}
                  </div>
                </div>
              );
            })}
          {/* {data.images} */}
        </ReactSwipe>
        {images.length > 1 && (
          <button className="carousel-button-next" onClick={() => reactSwipeEl.next()}></button>
        )}
        {/* Next */}

        {images.length > 1 && (
          <button className="carousel-button-prev" onClick={() => reactSwipeEl.prev()}></button>
        )}
        {/* Previous */}
      </div>
    );
  }
}

export default Carousel;
