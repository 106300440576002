import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import MyArticleReview from 'components/mypage/MyArticleReview';
import MyArticleGet from 'components/mypage/MyArticleGet';
import MyArticleTalk from 'components/mypage/MyArticleTalk';
import MyWishReview from 'components/mypage/MyWishReview';
import MyWishGet from 'components/mypage/MyWishGet';
import MyBuy from 'components/mypage/MyBuy';
import FollowPopup from 'components/mypage/FollowPopup';
import ReviewPopup from 'components/ReviewPopup';
import TalkPopup from 'components/TalkPopup';
import GetPopup from 'components/GetPopup';
import jwt_decode from 'jwt-decode';
import axios from 'axios';
import { API } from 'config';
import { gradeName } from 'lib/common';
import './MyPage.css';


var like = {
  review: [],
  talk: [],
  get: [],
  wishReview: [],
  wishLink: [],
};

var dib = {
  review: [],
  get: [],
  wishReview: [],
  wishLink: []
};

class MyPage extends Component {
  state = {
    isFirst: true,
    total: 0,
    offset: 0,
    limit: 20,
    currency: {},
    isOpen: false,
    menu: {
      isOpen: false,
      type: 'follower',
      article: {
        isActive: true,
        review: true,
        get: false,
        talk: false,
      },
      wish: {
        isActive: false,
        review: false,
        get: false,
      },
      buy: {
        isActive: false,
      },
    },
    user: {},
    data: {},
    myReviewData: [],
    myLinkData: [],
    myTalkData: [],
    myWishReviewData: [],
    myWishLinkData: [],
    like: {
      review: [],
      get: [],
      talk: [],
      wishReview: [],
      wishLink: [],
    },
    dib: {
      review: [],
      get: [],
      talk: [],
      wishReview: [],
      wishLink: [],
    },
    popupOpen: {
      review: false,
      get: false,
      talk: false,
      wishreview: false,
      wishlink: false,
    },
    popupData: {},
  };

  componentWillMount() {
    if (localStorage.getItem('token')) {
      this.getUser();
    } else {
      window.location.href = '/';
    }
  }

  componentDidMount() {
    localStorage.getItem('token') && this.getUserData();
    this.getCurrency();
    this.getMyReviews();
    this.getMyLinks();
    this.getMyTalks();
    this.getWishReview();
    this.getWishLink();

    window.addEventListener('scroll', this._infiniteScroll, true);
  }

  componentWillUnmount() {
    this.setState(
      {
        isFirst: true,
        total: 0,
        offset: 0,
        limit: 20,
        currency: {},
        isOpen: false,
        menu: {
          isOpen: false,
          type: 'follower',
          article: {
            isActive: true,
            review: true,
            get: false,
            talk: false,
          },
          wish: {
            isActive: false,
            review: false,
            get: false,
          },
          buy: {
            isActive: false,
          },
        },
        user: {},
        data: {},
        myReviewData: [],
        myLinkData: [],
        myTalkData: [],
        myWishReviewData: [],
        myWishLinkData: [],
        like: {
          review: [],
          get: [],
          talk: [],
          wishReview: [],
          wishLink: [],
        },
        dib: {
          review: [],
          get: [],
          talk: [],
          wishReview: [],
          wishLink: [],
        },
        popupOpen: {
          review: false,
          get: false,
          talk: false,
          wishreview: false,
          wishlink: false,
        },
        popupData: {},
      }
    )
    window.removeEventListener('scroll', this._infiniteScroll);
  }

  getCurrency = async () => {
    try {
      let currency = {};
      const result = await axios.get(`${API}/extra/exchange-rate`);
      result.data.map((el) => {
        currency[el.currency] = el.amount;
      });
      this.setState({
        ...this.state,
        currency,
      });
    } catch (e) {
      if (e.response && e.response.data && e.response.data.msg && e.response.data.msg === '토큰이 만료되었습니다.') {
        localStorage.removeItem('token');
        window.location.href = "/";
        return;
      }
    }
  };

  handleLike = async (e, type, id, my) => {
    e.stopPropagation();
    // const { review, get, talk, event } = this.state.like;

    if (!localStorage.getItem('token')) return alert('로그인이 필요합니다:)');
    try {
      const result = await axios.post(
        `${API}/${type}/${id}/like`,
        {},
        {
          headers: {
            token: `${localStorage.getItem('token')}`,
          },
        },
      );

      let rtype = '';
      if (!my && type === 'reviews') {
        rtype = 'review';
      } else if (!my && type === 'links') {
        rtype = 'get';
      } else if (!my && type === 'talks') {
        rtype = 'talk';
      } else if (my === 'wishReview') {
        rtype = 'wishReview';
      } else if (my === 'wishLink') {
        rtype = 'wishLink';
      }

      var rlike = [...this.state.like[rtype]];

      if (result.status === 201) {
        rlike.push(id);
        this.setState({
          ...this.state,
          like: {
            ...this.state.like,
            [rtype]: rlike,
          },
        });
      } else if (result.status === 204) {
        rlike = rlike.filter((el) => Number(el) !== Number(id));
        this.setState({
          ...this.state,
          like: {
            ...this.state.like,
            [rtype]: rlike,
          },
        });
      }
    } catch (e) {
      if (e.response && e.response.data && e.response.data.msg && e.response.data.msg === '토큰이 만료되었습니다.') {
        localStorage.removeItem('token');
        window.location.href = "/";
        return;
      }
      // alert('조금만 뒤에 다시 시도해주세요:)');
    }
    // /{reviews}/{id}/like
  };

  handleDib = async (e, type, id, my) => {
    e.stopPropagation();
    if (!localStorage.getItem('token')) return alert('로그인이 필요합니다:)');
    try {
      const result = await axios.post(
        `${API}/${type}/${id}/dib`,
        {},
        {
          headers: {
            token: `${localStorage.getItem('token')}`,
          },
        },
      );

      let rtype = '';
      if (!my && type === 'reviews') {
        rtype = 'review';
      } else if (!my && type === 'links') {
        rtype = 'get';
      } else if (!my && type === 'talks') {
        rtype = 'talk';
      } else if (my === 'wishReview') {
        rtype = 'wishReview';
      } else if (my === 'wishLink') {
        rtype = 'wishLink';
      }

      var rdib = [...this.state.dib[rtype]];

      if (result.status === 201) {
        rdib.push(id);
        this.setState({
          ...this.state,
          dib: {
            ...this.state.dib,
            [rtype]: rdib,
          },
        });
      } else if (result.status === 204) {
        rdib = rdib.filter((el) => Number(el) !== Number(id));
        this.setState({
          ...this.state,
          dib: {
            ...this.state.dib,
            [rtype]: rdib,
          },
        });
      }

    } catch (e) {
      if (e.response && e.response.data && e.response.data.msg && e.response.data.msg === '토큰이 만료되었습니다.') {
        localStorage.removeItem('token');
        window.location.href = "/";
        return;
      }
    }
  };

  _infiniteScroll = () => {
    let scrollHeight = Math.max(document.documentElement.scrollHeight, document.body.scrollHeight);
    let scrollTop = Math.max(document.documentElement.scrollTop, document.body.scrollTop);

    let clientHeight = document.documentElement.clientHeight;

    if (!this.state.isLoading && (Number(scrollTop) + Number(clientHeight) + 1000) >= Number(scrollHeight)) {
      this.setState({
        ...this.state,
        offset: this.state.offset + 20,
        limit: this.state.limit,
        isLoading: true,
      }, () => {
        if (this.state.offset <= this.state.total) {
          if (this.state.menu.article.review) {
            this.getMyReviews();
          } else if (this.state.menu.article.get) {
            this.getMyLinks();
          } else if (this.state.menu.article.talk) {
            this.getMyTalks();
          } else if (this.state.menu.wish.review) {
            this.getWishReview();
          } else if (this.state.menu.wish.link) {
            this.getWishLink();
          }
        }
      });
    }
  };

  getUser = () => {
    try {
      const user = jwt_decode(localStorage.getItem('token'));
      if (user) {
        this.setState({
          ...this.state,
          user: {
            id: user.id,
          },
        });
      } else {
        localStorage.removeItem('token');
      }
    } catch (e) {
      localStorage.removeItem('token');
      window.location.href = '/';
    }
  };

  getUserData = async () => {
    const user = jwt_decode(localStorage.getItem('token'));
    if (user) {
      try {
        const result = await axios.get(`${API}/users/my`, {
          headers: {
            token: `${localStorage.getItem('token')}`,
          }
        });

        const newToken = result.headers["new-token"] || '';
        if (newToken) {
          axios.defaults.headers.token = newToken;
          localStorage.setItem("token", newToken);
        }

        if (result && result.status === 200) {
          this.setState({
            ...this.state,
            user: result.data,
            data: result.data,
          });
        } else {
          localStorage.removeItem('token');
          window.location.reload();
        }
      } catch (e) {
        localStorage.removeItem('token');
        window.location.reload();
      }
    } else {
      localStorage.removeItem('token');
      window.location.href = '/';
    }
  };

  handleItemPopup = (type, data) => {

    this.setState({
      ...this.state,
      popupOpen: {
        ...this.state.popupOpen,
        [type]: true,
      },
      popupData: data,
    });
    window.history.pushState(
      null,
      null,
      `/${type}?id=${data.id}`,
    );
  };

  closeItemPopup = () => {
    this.setState({
      ...this.state,
      popupOpen: {
        ...this.state.popupOpen,
        review: false,
        get: false,
        talk: false,
        wishreview: false,
        wishlink: false,
      },
      popupData: {},
    });
    window.history.pushState(
      null,
      null,
      `/mypage`,
    );
  };

  getMyReviews = async () => {
    const { user, isFirst } = this.state;
    try {
      const result = await axios.get(`${API}/reviews/?userId=${user.id}`, {
        params: {
          limit: this.state.limit,
          offset: this.state.offset,
        },
        headers: {
          token: `${localStorage.getItem('token')}`,
        },
      });

      var like = {
        review: [],
      };

      var dib = {
        review: [],
      };

      result.data[0].map((el) => {
        if (el.liked) {
          like['review'].push(el.id);
        }

        if (el.dib) {
          dib['review'].push(el.id);
        }
      });

      if (isFirst) {
        this.setState({
          ...this.state,
          isFirst: false,
          total: result.data[1],
          myReviewData: [...result.data[0]],
          like: {
            ...this.state.like,
            review: like.review,
          },
          dib: {
            ...this.state.dib,
            review: dib.review,
          },
        });
      } else {
        this.setState({
          ...this.state,
          isFirst: false,
          total: result.data[1],
          myReviewData: [...this.state.myReviewData, ...result.data[0]],
          like: {
            ...this.state.like,
            review: like.review,
          },
          dib: {
            ...this.state.dib,
            review: dib.review,
          },
        });
      }
    } catch (e) {
      if (e.response && e.response.data && e.response.data.msg && e.response.data.msg.indexOf('토큰이 만료되었습니다') > -1) {
        localStorage.removeItem('token');
        window.location.href = '/';
      }
    }
  };

  getMyLinks = async () => {
    const { user, isFirst } = this.state;
    try {
      const result = await axios.get(`${API}/links/?userId=${user.id}`, {
        params: {
          limit: this.state.limit,
          offset: this.state.offset,
        },
        headers: {
          token: `${localStorage.getItem('token')}`,
        },
      });

      var like = {
        get: [],
      };

      var dib = {
        get: [],
      };

      result.data[0].map((el) => {
        if (el.liked) {
          like['get'].push(el.id);
        }

        if (el.dib) {
          dib['get'].push(el.id);
        }
      });

      if (isFirst) {
        this.setState({
          ...this.state,
          isFirst: false,
          total: result.data[1],
          myLinkData: [...result.data[0]],
          like: {
            ...this.state.like,
            get: like.get,
          },
          dib: {
            ...this.state.dib,
            get: dib.get,
          },
        });
      } else {
        this.setState({
          ...this.state,
          isFirst: false,
          total: result.data[1],
          myLinkData: [...this.state.myLinkData, ...result.data[0]],
          like: {
            ...this.state.like,
            get: like.get,
          },
          dib: {
            ...this.state.dib,
            get: dib.get,
          },
        });
      }
    } catch (e) {
      if (e.response && e.response.data && e.response.data.msg && e.response.data.msg.indexOf('토큰이 만료되었습니다') > -1) {
        localStorage.removeItem('token');
        window.location.href = '/';
      }
    }
  };

  getMyTalks = async () => {
    const { user, isFirst } = this.state;
    try {
      const result = await axios.get(`${API}/talks/?userId=${user.id}`, {
        params: {
          limit: this.state.limit,
          offset: this.state.offset,
        },
        headers: {
          token: `${localStorage.getItem('token')}`,
        },
      });

      var like = {
        talk: [],
      };

      var dib = {
        talk: [],
      };

      result.data[0].map((el) => {
        if (el.liked) {
          like['talk'].push(el.id);
        }

        if (el.dib) {
          dib['talk'].push(el.id);
        }
      });

      if (isFirst) {
        this.setState({
          ...this.state,
          isFirst: false,
          total: result.data[1],
          myTalkData: [...result.data[0]],
          like: {
            ...this.state.like,
            talk: like.talk,
          },
          dib: {
            ...this.state.dib,
            talk: dib.talk,
          },
        });
      } else {
        this.setState({
          ...this.state,
          isFirst: false,
          total: result.data[1],
          myTalkData: [...this.state.myTalkData, ...result.data[0]],
          like: {
            ...this.state.like,
            talk: like.talk,
          },
          dib: {
            ...this.state.dib,
            talk: dib.talk,
          },
        });
      }
    } catch (e) {
      if (e.response && e.response.data && e.response.data.msg && e.response.data.msg.indexOf('토큰이 만료되었습니다') > -1) {
        localStorage.removeItem('token');
        window.location.href = '/';
      }
    }
  };

  getWishReview = async () => {
    const { user, isFirst } = this.state;
    const result = await axios.get(`${API}/users/dib/reviews`, {
      params: {
        limit: this.state.limit,
        offset: this.state.offset,
      },
      headers: {
        token: `${localStorage.getItem('token')}`,
      },
    });

    var like = {
      wishReview: [],
    };

    var dib = {
      wishReview: [],
    };

    result.data[0].map((el) => {
      if (el.liked) {
        like['wishReview'].push(el.id);
      }

      if (el.dib) {
        dib['wishReview'].push(el.id);
      }
    });

    if (isFirst) {
      this.setState({
        ...this.state,
        isFirst: false,
        total: result.data[1],
        myWishReviewData: [...result.data[0]],
        like: {
          ...this.state.like,
          wishReview: like.wishReview,
        },
        dib: {
          ...this.state.dib,
          wishReview: dib.wishReview,
        },
      });
    } else {
      this.setState({
        ...this.state,
        isFirst: false,
        total: result.data[1],
        myWishReviewData: [...this.state.myWishReviewData, ...result.data[0]],
        like: {
          ...this.state.like,
          wishReview: like.wishReview,
        },
        dib: {
          ...this.state.dib,
          wishReview: dib.wishReview,
        },
      });
    }
  };

  getWishLink = async () => {
    const { user, isFirst } = this.state;
    const result = await axios.get(`${API}/users/dib/links`, {
      params: {
        limit: this.state.limit,
        offset: this.state.offset,
      },
      headers: {
        token: `${localStorage.getItem('token')}`,
      },
    });

    var like = {
      wishLink: [],
    };

    var dib = {
      wishLink: [],
    };

    result.data[0].map((el) => {
      if (el.liked) {
        like['wishLink'].push(el.id);
      }

      if (el.dib) {
        dib['wishLink'].push(el.id);
      }
    });

    if (isFirst) {
      this.setState({
        ...this.state,
        isFirst: false,
        total: result.data[1],
        myWishLinkData: [...result.data[0]],
        like: {
          ...this.state.like,
          wishLink: like.wishLink,
        },
        dib: {
          ...this.state.dib,
          wishLink: dib.wishLink,
        },
      });
    } else {
      this.setState({
        ...this.state,
        isFirst: false,
        total: result.data[1],
        myWishLinkData: [...this.state.myWishLinkData, ...result.data[0]],
        like: {
          ...this.state.like,
          wishLink: like.wishLink,
        },
        dib: {
          ...this.state.dib,
          wishLink: dib.wishLink,
        },
      });
    }
  };

  // 내 게시글 후기
  // const result = await axios.get(`${API}/reviews/?userId=${id}&limit=6`);
  // 내 게시글 득템
  // const result = await axios.get(`${API}/links/?userId=${id}&limit=6`);
  // 내 게시글 토크
  // const result = await axios.get(`${API}/talks/?userId=${id}&limit=6`);

  handleType = (type) => {
    this.setState({
      ...this.state,
      type,
    });
  };

  handlePopup = (type) => {
    this.setState({
      ...this.state,
      isOpen: true,
      type,
    });
  };

  closePopup = () => {
    this.setState({
      ...this.state,
      isOpen: false,
    });
  };

  handleMenu = (e, type, sub) => {
    e.stopPropagation();
    let menu = {
      article: {
        isActive: false,
        review: false,
        get: false,
        talk: false,
      },
      wish: {
        isActive: false,
        review: false,
        get: false,
      },
      buy: {
        isActive: false,
      },
    };

    menu[type]['isActive'] = true;
    if (sub) {
      menu[type][sub] = true;
    }

    this.setState(
      {
        ...this.state,
        menu,
        isFirst: true,
        total: 0,
        offset: 0,
        limit: 20,
        myReviewData: [],
        myGetData: [],
        myTalkData: [],
        like: {
          review: [],
          get: [],
          talk: [],
          wishReview: [],
          wishLink: [],
        },
        dib: {
          review: [],
          get: [],
          talk: [],
          wishReview: [],
          wishLink: [],
        },
      },
      () => {
        if (this.state.menu.article.review) {
          this.getMyReviews();
        } else if (this.state.menu.article.get) {
          this.getMyLinks();
        } else if (this.state.menu.article.talk) {
          this.getMyTalks();
        } else if (this.state.menu.wish.review) {
          this.getWishReview();
        } else if (this.state.menu.wish.link) {
          this.getWishLink();
        }
      },
    );
  };

  handleFollow = async (userId) => {
    try {
      const result = await axios.post(
        `${API}/users/${userId}/follow`,
        {},
        {
          headers: {
            token: `${localStorage.getItem('token')}`,
          },
        },
      );
      this.getUserData();
    } catch (e) {
      if (e.response && e.response.data && e.response.data.msg && e.response.data.msg === "본인은 팔로우 할 수 없습니다.") {
        alert('본인은 팔로우 할 수 없습니다:)');
      } else {
        if (e.response && e.response.data && e.response.data.msg && e.response.data.msg === '토큰이 만료되었습니다.') {
          localStorage.removeItem('token');
          window.location.href = "/";
          return;
        }
        // alert('조금만 뒤에 다시 시도해주세요:)');
      }
    }
  };

  handleUnFollow = async (userId) => {
    try {
      const result = await axios.post(
        `${API}/users/${userId}/unfollow`,
        {},
        {
          headers: {
            token: `${localStorage.getItem('token')}`,
          },
        },
      );
      this.getUserData();
    } catch (e) {
      if (e.response && e.response.data && e.response.data.msg && e.response.data.msg === '토큰이 만료되었습니다.') {
        localStorage.removeItem('token');
        window.location.href = "/";
        return;
      }
    }
  };


  editReview = async (id) => {
    window.location.href = `/writing/review/?id=${id}`;
    // this.props.history.push(`/writing/review/?id=${id}`);
  };

  deleteReview = async (id, my) => {
    if (window.confirm('정말 삭제하시겠습니까?')) {
      try {
        const result = await axios.delete(`${API}/reviews/${id}`, {
          headers: {
            token: `${localStorage.getItem('token')}`,
          },
        });
        if (result.status === 200) {
          if (my) {
            this.setState({
              ...this.state,
              popupOpen: {
                review: false,
                get: false,
                talk: false,
                wishreview: false,
                wishlink: false,
              },
              popupData: {},
              myWishReviewData: this.state.myWishReviewData.filter((el) => el.id !== id),
            });
          } else {
            this.setState({
              ...this.state,
              popupOpen: {
                review: false,
                get: false,
                talk: false,
                wishreview: false,
                wishlink: false,
              },
              popupData: {},
              myReviewData: this.state.myReviewData.filter((el) => el.id !== id),
            });
          }

          alert('삭제 성공 :)');
        }
      } catch (e) {
        // alert('조금만 뒤에 다시 시도해주세요:)');
        if (e.response && e.response.data && e.response.data.msg && e.response.data.msg === '토큰이 만료되었습니다.') {
          localStorage.removeItem('token');
          window.location.href = "/";
          return;
        }
      }
    }
  };

  editLink = async (id) => {
    window.location.href = `/writing/get/?id=${id}`;
    // this.props.history.push(`/writing/get/?id=${id}`);
  };

  deleteLink = async (id, my) => {
    if (window.confirm('정말 삭제하시겠습니까?')) {
      try {
        const result = await axios.delete(`${API}/links/${id}`, {
          headers: {
            token: `${localStorage.getItem('token')}`,
          },
        });
        if (result.status === 200) {
          if (my) {
            this.setState({
              ...this.state,
              popupOpen: {
                review: false,
                get: false,
                talk: false,
                wishreview: false,
                wishlink: false,
              },
              popupData: {},
              myWishLinkData: this.state.myWishLinkData.filter((el) => el.id !== id),
            });
          } else {
            this.setState({
              ...this.state,
              popupOpen: {
                review: false,
                get: false,
                talk: false,
                wishreview: false,
                wishlink: false,
              },
              popupData: {},
              myLinkData: this.state.myLinkData.filter((el) => el.id !== id),
            });
          }

          alert('삭제 성공 :)');
        }
      } catch (e) {
        if (e.response && e.response.data && e.response.data.msg && e.response.data.msg === '토큰이 만료되었습니다.') {
          localStorage.removeItem('token');
          window.location.href = "/";
          return;
        }
        // alert('조금만 뒤에 다시 시도해주세요:)');
      }
    }
  };

  getTalk = async (id) => {
    try {
      const result = await axios.get(`${API}/talks/${id}`, {
        headers: {
          token: `${localStorage.getItem('token')}`,
        },
      });
      if (result.data.liked) {
        like['talk'].push(result.data.id);
      }

      this.setState({
        ...this.state,
        popupOpen: {
          review: false,
          get: false,
          talk: true,
          wishreview: false,
          wishlink: false,
        },
        popupData: result.data,
        like,
      });
    } catch (e) {
      if (e.response && e.response.data && e.response.data.msg && e.response.data.msg.indexOf('토큰이 만료되었습니다') > -1) {
        localStorage.removeItem('token');
        window.location.reload();
      }
    }
  };

  editTalk = async (id) => {
    window.location.href = `/writing/talk/?id=${id}`;
    // this.props.history.push(`/writing/talk/?id=${id}`);
  };

  deleteTalk = async (id) => {
    if (window.confirm('정말 삭제하시겠습니까?')) {
      try {
        const result = await axios.delete(`${API}/talks/${id}`, {
          headers: {
            token: `${localStorage.getItem('token')}`,
          },
        });
        if (result.status === 200) {
          this.setState({
            ...this.state,
            popupOpen: {
              review: false,
              get: false,
              talk: false,
              wishreview: false,
              wishlink: false,
            },
            popupData: {},
            myTalkData: this.state.myTalkData.filter((el) => el.id !== id),
          });

          alert('삭제 성공 :)');
        }
      } catch (e) {
        if (e.response && e.response.data && e.response.data.msg && e.response.data.msg === '토큰이 만료되었습니다.') {
          localStorage.removeItem('token');
          window.location.href = "/";
          return;
        }
        // alert('조금만 뒤에 다시 시도해주세요:)');
      }
    }
  };


  // /users/:userId/follow:
  //   post:
  //     tags:
  //     - "유저"
  //     summary: "유저 팔로우"
  //     produces:
  //     - "application/json"
  //     responses:
  //       "201":
  //         description: "successful operation"
  //       "403":
  //         description: "토큰 필요"
  // /users/:userId/unfollow:
  //   post:
  //     tags:
  //     - "유저"
  //     summary: "유저 언 팔로우"
  //     produces:
  //     - "application/json"
  //     responses:
  //       "201":
  //         description: "successful operation"
  //       "403":
  //         description: "토큰 필요"

  render() {
    const {
      menu,
      isOpen,
      type,
      user,
      data,
      myReviewData,
      dib,
      like,
      myLinkData,
      myTalkData,
      currency,
      myWishReviewData,
      myWishLinkData,
      popupData,
      popupOpen
    } = this.state;
    const {
      handleMenu,
      handlePopup,
      closePopup,
      handleType,
      handleLike,
      handleDib,
      handleFollow,
      handleUnFollow,
      handleItemPopup,
      closeItemPopup,
      editReview,
      deleteReview,
      editLink,
      deleteLink,
      editTalk,
      deleteTalk,
      getTalk,
      getUserData
    } = this;


    return (
      <>
        <div className="mypage-bg">
          <div className="wrapper">
            <div className="mypage-header-bg">
              <span className={'main-intro-content-user-profile-bg ' + `grade-${user.grade}`}>
                <span
                  className={'main-intro-content-user-profile '}
                  style={user.profileImgUrl ? { backgroundImage: `url(${user.profileImgUrl})` } : {}}
                ></span>
              </span>
              <span className="main-intro-content-user-name">{user.nickname}</span>
              <span className={'main-intro-content-user-grade ' + `grade-${user.grade}`}>
                {gradeName(user.grade)}
              </span>
              <div className="mypage-setting-bg">
                <span className="mypage-follower" onClick={() => handlePopup('follower')}>
                  팔로우 <strong>{data.followerCount}</strong>
                </span>
                <button
                  className="mypage-setting"
                  onClick={() => { window.location.href = '/myinfo'; }}
                ></button>
                <span className="mypage-following" onClick={() => handlePopup('following')}>
                  팔로잉 <strong>{data.followingCount}</strong>
                </span>
              </div>
            </div>
            <div className="mypage-menu-bg">
              <ul className="mypage-menu">
                <li
                  className={menu.article.isActive ? 'active' : ''}
                  onClick={(e) => handleMenu(e, 'article', 'review')}
                >
                  내 게시글
                  <ul className={'mypage-menu-sub ' + (menu.article.isActive ? 'active' : '')}>
                    <li
                      className={menu.article.review ? 'active' : ''}
                      onClick={(e) => handleMenu(e, 'article', 'review')}
                    >
                      오늘의 <strong>후기</strong>
                    </li>
                    <li
                      className={menu.article.get ? 'active' : ''}
                      onClick={(e) => handleMenu(e, 'article', 'get')}
                    >
                      오늘의 <strong>득템</strong>
                    </li>
                    <li
                      className={menu.article.talk ? 'active' : ''}
                      onClick={(e) => handleMenu(e, 'article', 'talk')}
                    >
                      오늘의 <strong>토크</strong>
                    </li>
                  </ul>
                </li>
                <li
                  className={menu.wish.isActive ? 'active' : ''}
                  onClick={(e) => handleMenu(e, 'wish', 'review')}
                >
                  찜하기
                  <ul className={'mypage-menu-sub ' + (menu.wish.isActive ? 'active' : '')}>
                    <li
                      className={menu.wish.review ? 'active' : ''}
                      onClick={(e) => handleMenu(e, 'wish', 'review')}
                    >
                      오늘의 <strong>후기</strong>
                    </li>
                    <li
                      className={menu.wish.get ? 'active' : ''}
                      onClick={(e) => handleMenu(e, 'wish', 'get')}
                    >
                      오늘의 <strong>득템</strong>
                    </li>
                  </ul>
                </li>
                <li
                  className={menu.buy.isActive ? 'active' : ''}
                  onClick={(e) => handleMenu(e, 'buy')}
                >
                  사주세요
                </li>
              </ul>
            </div>
            <div className="mypage-body-bg">
              {menu.article.review && (
                <MyArticleReview
                  myReviewData={myReviewData}
                  like={like.review}
                  dib={dib.review}
                  handleLike={handleLike}
                  handleDib={handleDib}
                  handlePopup={handleItemPopup}
                  user={user}
                />
              )}
              {menu.article.get && (
                <MyArticleGet
                  myLinkData={myLinkData}
                  like={like.get}
                  dib={dib.get}
                  handleLike={handleLike}
                  handleDib={handleDib}
                  currency={currency}
                  handlePopup={handleItemPopup}
                  user={user}
                />
              )}
              {menu.article.talk && (
                <MyArticleTalk
                  myTalkData={myTalkData}
                  like={like.talk}
                  dib={dib.talk}
                  handleLike={handleLike}
                  handleDib={handleDib}
                  currency={currency}
                  handlePopup={handleItemPopup}
                  user={user}
                />
              )}
              {menu.wish.review && (
                <MyWishReview
                  myWishReviewData={myWishReviewData}
                  like={like.wishReview}
                  dib={dib.wishReview}
                  handleLike={handleLike}
                  handleDib={handleDib}
                  handlePopup={handleItemPopup}
                  user={user}
                />
              )}
              {menu.wish.get && (
                <MyWishGet
                  myWishLinkData={myWishLinkData}
                  like={like.wishLink}
                  dib={dib.wishLink}
                  handleLike={handleLike}
                  handleDib={handleDib}
                  currency={currency}
                  handlePopup={handleItemPopup}
                  user={user}
                />
              )}
              {menu.buy.isActive && <MyBuy user={user} />}
            </div>
          </div>
        </div>
        <FollowPopup
          type={type}
          isOpen={isOpen}
          closePopup={closePopup}
          handleType={handleType}
          data={data}
          handleFollow={handleFollow}
          handleUnFollow={handleUnFollow}
        />
        {popupOpen.review && popupData && popupData.user && popupData.user.id && (
          <ReviewPopup
            isOpen={popupOpen.review}
            popupData={popupData}
            currency={currency}
            closePopup={closeItemPopup}
            user={user}
            handleLike={handleLike}
            handleDib={handleDib}
            like={like.review}
            dib={dib.review}
            deleteReview={deleteReview}
            editReview={editReview}
            getUserData={getUserData}
          />
        )}
        {popupOpen.get && popupData && popupData.user && popupData.user.id && (
          <GetPopup
            isOpen={popupOpen.get}
            popupData={popupData}
            currency={currency}
            closePopup={closeItemPopup}
            user={user}
            handleLike={handleLike}
            handleDib={handleDib}
            like={like.get}
            dib={dib.get}
            deleteLink={deleteLink}
            editLink={editLink}
          />
        )}
        {popupOpen.talk && popupData && popupData.user && popupData.user.id && (
          <>
            {
              <TalkPopup
                type={popupData.category === '자유토크'
                  ? 'free'
                  : popupData.category === '사요마요'
                    ? 'recommend'
                    : popupData.category === '정품판독'
                      ? 'genuine'
                      : 'search'}
                popupData={popupData}
                user={user}
                isOpen={popupOpen.talk}
                closePopup={closeItemPopup}
                handleLike={handleLike}
                like={like.talk}
                deleteTalk={deleteTalk}
                editTalk={editTalk}
                getTalk={getTalk}
              />
            }
          </>
        )}
        {popupOpen.wishreview && popupData && popupData.user && popupData.user.id && (
          <ReviewPopup
            isOpen={popupOpen.wishreview}
            popupData={popupData}
            currency={currency}
            closePopup={closeItemPopup}
            user={user}
            handleLike={handleLike}
            handleDib={handleDib}
            like={like.wishReview}
            dib={dib.wishReview}
            deleteReview={deleteReview}
            editReview={editReview}
            getUserData={getUserData}
            my={'wishReview'}
          />
        )}
        {popupOpen.wishlink && popupData && popupData.user && popupData.user.id && (
          <GetPopup
            isOpen={popupOpen.wishlink}
            popupData={popupData}
            currency={currency}
            closePopup={closeItemPopup}
            user={user}
            handleLike={handleLike}
            handleDib={handleDib}
            like={like.wishLink}
            dib={dib.wishLink}
            deleteLink={deleteLink}
            editLink={editLink}
            my={'wishLink'}
          />
        )}
      </>
    );
  }
}

export default withRouter(MyPage);
