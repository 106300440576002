import React, { Component } from 'react';
import jwt_decode from 'jwt-decode';
import { withRouter } from 'react-router-dom';
import { gradeName, abbreviateNumber, numberWithCommas, compareTime } from 'lib/common';
import axios from 'axios';
import { API } from 'config';
import './MobileMenu.css';

class MobileMenu extends Component {
  state = {
    user: {},
  };

  componentWillMount() {
    localStorage.getItem('token') && this.getUser();
  }

  componentDidMount() {
    localStorage.getItem('token') && this.getUserData();
  }

  getUser = () => {
    try {
      const user = jwt_decode(localStorage.getItem('token'));
      if (user) {
        this.setState({
          ...this.state,
          user: {
            id: user.id,
          },
        });
      } else {
        localStorage.removeItem('token');
      }
    } catch (e) {
      localStorage.removeItem('token');
      window.location.reload();
    }
  };

  getUserData = async () => {
    const user = jwt_decode(localStorage.getItem('token'));
    if (user) {
      try {
        const result = await axios.get(`${API}/users/my`, {
          headers: {
            token: `${localStorage.getItem('token')}`,
          },
        });

        const newToken = result.headers['new-token'] || '';
        if (newToken) {
          axios.defaults.headers.token = newToken;
          localStorage.setItem('token', newToken);
        }

        if (result && result.status === 200) {
          let user = result.data;

          this.setState({
            ...this.state,
            user,
          });
        } else {
          localStorage.removeItem('token');
          window.location.reload();
        }
      } catch (e) {
        localStorage.removeItem('token');
        window.location.reload();
      }
    } else {
      localStorage.removeItem('token');
    }
  };

  render() {
    const { user } = this.state;
    const { isOpen, closePlus } = this.props;

    if (!user) return <></>;

    return (
      <div
        className={'mobile-menu-bg ' + (isOpen ? 'show' : '')}
        data="mobile-menu-popup"
        onClick={(e) => {
          if (e.target.getAttribute('data') === 'mobile-menu-popup') {
            closePlus();
          }
        }}
      >
        <div className="mobile-menu">
          <div className="mobile-menu-top">
            {/* <div className="mobile-menu-edge"></div> */}
            <div className="mobile-menu-user">
              <div
                className={
                  'main-intro-content-user-profile-bg mobile-menu-user-profile-bg ' +
                  `grade-${user.grade}`
                }
              >
                <span
                  className="main-intro-content-user-profile"
                  style={
                    user.profileImgUrl
                      ? {
                          backgroundImage: `url(${user.profileImgUrl})`,
                        }
                      : {}
                  }
                ></span>
              </div>
              <div className="mobile-menu-info">
                <div className="mobile-menu-name-bg">
                  <span className="mobile-menu-name">{user.nickname}</span>
                  <span className={'mobile-menu-grade ' + `grade-${user.grade}`}>
                    {gradeName(user.grade)}
                  </span>
                </div>
                <div className="mobile-menu-email-bg">
                  <p className="mobile-menu-email">{user.email}</p>
                </div>
                <div className="mobile-menu-follow-bg">
                  <span className="mobile-menu-follow">
                    팔로우 {abbreviateNumber(parseInt(user.followerCount), 0)}명
                  </span>
                  <span className="mobile-menu-follow">·</span>
                  <span className="mobile-menu-follow">
                    팔로잉 {abbreviateNumber(parseInt(user.followingCount), 0)}명
                  </span>
                </div>
              </div>
            </div>
          </div>
          <div className="mobile-menu-bottom">
            <div
              className="mobile-menu-item-bg"
              onClick={() => {
                closePlus();
                // this.props.history.push('/mypage');
                window.location.href = '/myinfo?point=true';
              }}
            >
              <span className="mobile-menu-item point">마이 포인트</span>
              <span className="mobile-menu-point">
                {(user.point && numberWithCommas(user.point)) || 0} P
              </span>
            </div>
            <div
              className="mobile-menu-item-bg"
              onClick={() => {
                closePlus();
                // this.props.history.push('/mypage');
                window.location.href = '/mypage';
              }}
            >
              <button className="mobile-menu-item my">마이 페이지</button>
            </div>
            <div
              className="mobile-menu-item-bg"
              onClick={() => {
                closePlus();
                // this.props.history.push('/myinfo');
                window.location.href = '/myinfo';
              }}
            >
              <button className="mobile-menu-item info">개인정보 보기</button>
            </div>
            {/* <div className="mobile-menu-item-bg">
              <button className="mobile-menu-item cs">고객센터</button>
            </div> */}
            <div
              className="mobile-menu-item-bg"
              onClick={() => {
                closePlus();
                localStorage.removeItem('token');
                window.location.href = '/';
              }}
            >
              <button
                className="mobile-menu-item logout"
                onClick={() => {
                  closePlus();
                  localStorage.removeItem('token');
                  window.location.href = '/';
                }}
              >
                로그아웃
              </button>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default withRouter(MobileMenu);
