import React from 'react';
import MainStructure from 'components/structure/MainStructure';
import MainContainer from 'containers/MainContainer';

const Main = () => {
  return (
    <MainStructure>
      <MainContainer />
    </MainStructure>
  );
};

export default Main;
