import React, { Component } from 'react';
import Terms from 'components/Terms';

class TermsContainer extends Component {
  render() {
    return <Terms />;
  }
}

export default TermsContainer;
