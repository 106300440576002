import React, { Component } from 'react';
import ReviewItem from 'components/ReviewItem';
import GetItem from 'components/GetItem';
import TalkItem from 'components/TalkItem';
import { withRouter } from 'react-router-dom';
import axios from 'axios';
import jwt_decode from 'jwt-decode';
import { API } from 'config';
import ReviewPopup from 'components/ReviewPopup';
import TalkPopup from 'components/TalkPopup';
import GetPopup from 'components/GetPopup';
import './Search.css';

var like = {
  review: [],
  talk: [],
  get: [],
  event: [],
};

var dib = {
  review: [],
  get: [],
};

class Search extends Component {
  state = {
    searchType: '',
    type: '',
    links: [],
    reviews: [],
    talks: [],
    currency: {},
    isLoading: {
      talk: false,
      get: false,
      review: false,
    },
    isFirst: {
      talk: true,
      get: true,
      review: true,
    },
    total: {
      talk: 0,
      get: 0,
      review: 0,
    },
    offset: {
      talk: 0,
      get: 0,
      review: 0,
    },
    limit: {
      talk: 20,
      get: 20,
      review: 20,
    },
    isMore: {
      talks: false,
      links: false,
      reviews: false,
    },
    like: {
      review: [],
      get: [],
      talk: [],
    },
    dib: {
      review: [],
      get: [],
      talk: [],
    },
    word: '',
    popupData: {},
    popupOpen: {
      review: false,
      get: false,
      talk: false,
    },
    user: {},
    data: {}
  };

  componentWillMount() {
    const search = this.props.history.location.search;
    let searchType = '';
    let word = '';

    if (localStorage.getItem('token')) {
      this.getUser();
    }

    if (search.indexOf('?word=') > -1) {
      searchType = 'word';
      word = search.replace('?word=', '');
    } else if (search.indexOf('?brand=') > -1) {
      searchType = 'brand';
      word = search.replace('?brand=', '');
    } else if (search.indexOf('?store=') > -1) {
      searchType = 'store';
      word = search.replace('?store=', '');
    } else if (search.indexOf('?styletag=') > -1) {
      searchType = 'styletag';
      word = search.replace('?styletag=', '');
    }

    if (word) {
      this.setState({
        ...this.state,
        searchType,
        word: decodeURI(word),
      });
    }
  }

  componentWillReceiveProps(next) {
    const search = next.history.location.search;
    let searchType = '';
    let word = '';

    if (search.indexOf('?word=') > -1) {
      searchType = 'word';
      word = search.replace('?word=', '');
    } else if (search.indexOf('?brand=') > -1) {
      searchType = 'brand';
      word = search.replace('?brand=', '');
    } else if (search.indexOf('?store=') > -1) {
      searchType = 'store';
      word = search.replace('?store=', '');
    } else if (search.indexOf('?styletag=') > -1) {
      searchType = 'styletag';
      word = search.replace('?styletag=', '');
    }

    if (this.state.word !== word || this.state.searchType !== searchType) {
      this.setState(
        {
          ...this.state,
          searchType,
          word: decodeURI(word),
        },
        () => {
          this.handleSearchReviews();
          this.handleSearchLinks();
          this.handleSearchTalks();
        },
      );
    }
  }

  componentDidMount() {
    localStorage.getItem('token') && this.getUserData();
    this.handleSearchReviews();
    this.handleSearchLinks();
    this.handleSearchTalks();
    this.getCurrency();
    window.addEventListener('scroll', this._infiniteScroll, true);
  }

  componentWillUnmount() {
    this.setState(
      {
        searchType: '',
        type: '',
        links: [],
        reviews: [],
        talks: [],
        currency: {},
        isLoading: {
          talk: false,
          get: false,
          review: false,
        },
        isFirst: {
          talk: true,
          get: true,
          review: true,
        },
        total: {
          talk: 0,
          get: 0,
          review: 0,
        },
        offset: {
          talk: 0,
          get: 0,
          review: 0,
        },
        limit: {
          talk: 20,
          get: 20,
          review: 20,
        },
        isMore: {
          talks: false,
          links: false,
          reviews: false,
        },
        like: {
          review: [],
          get: [],
          talk: [],
        },
        dib: {
          review: [],
          get: [],
          talk: [],
        },
        word: '',
        popupData: {},
        popupOpen: {
          review: false,
          get: false,
          talk: false,
        },
        user: {},
        data: {}
      }
    )
    window.removeEventListener('scroll', this._infiniteScroll);
  }

  getUser = () => {
    try {
      const user = jwt_decode(localStorage.getItem('token'));
      if (user) {
        this.setState({
          ...this.state,
          user: {
            id: user.id,
          },
        });
      } else {
        localStorage.removeItem('token');
      }
    } catch (e) {
      localStorage.removeItem('token');
      window.location.href = '/';
    }
  };

  getUserData = async () => {
    const user = jwt_decode(localStorage.getItem('token'));
    if (user) {
      try {
        const result = await axios.get(`${API}/users/my`, {
          headers: {
            token: `${localStorage.getItem('token')}`,
          }
        });

        const newToken = result.headers["new-token"] || '';
        if (newToken) {
          axios.defaults.headers.token = newToken;
          localStorage.setItem("token", newToken);
        }

        if (result && result.status === 200) {
          this.setState({
            ...this.state,
            user: result.data,
            data: result.data,
          });
        } else {
          localStorage.removeItem('token');
          window.location.reload();
        }
      } catch (e) {
        localStorage.removeItem('token');
        window.location.reload();
      }
    } else {
      localStorage.removeItem('token');
      window.location.href = '/';
    }
  };

  _infiniteScroll = () => {
    const { isMore, type } = this.state;
    if (type && isMore[type]) {
      let dataType = '';

      // isMore: {
      //   talks: false,
      //   links: false,
      //   reviews: false,
      // },
      // like: {
      //   review: [],
      //   get: [],
      //   talk: [],
      // },
      switch (type) {
        case 'reviews':
          dataType = 'review';
          break;
        case 'links':
          dataType = 'get';
          break;
        case 'talks':
          dataType = 'talk';
          break;
        default:
          break;
      }
      let scrollHeight = Math.max(
        document.documentElement.scrollHeight,
        document.body.scrollHeight,
      );
      let scrollTop = Math.max(document.documentElement.scrollTop, document.body.scrollTop);

      let clientHeight = document.documentElement.clientHeight;

      if (!this.state.isLoading[dataType] && (Number(scrollTop) + Number(clientHeight) + 1000) >= Number(scrollHeight)) {
        this.setState({
          ...this.state,
          offset: {
            ...this.state.offset,
            [dataType]: this.state.offset[dataType] + 20,
          },
          // limit: {
          //   ...this.state.limit,
          //   [dataType]: this.state.limit[dataType] + 1,
          // },
          isLoading: {
            ...this.state.isLoading,
            [dataType]: true,
          },
        }, () => {
          if (this.state.offset[dataType] <= this.state.total[dataType]) {
            // if (type) {
            // }
            if (dataType === 'review') {
              this.handleSearchReviews();
            } else if (dataType === 'get') {
              this.handleSearchLinks();
            } else if (dataType === 'talk') {
              this.handleSearchTalks();
            }
          }
        });
      }
    }
  };

  handleLike = async (e, type, id) => {
    e.stopPropagation();
    const { review, get, talk, event } = this.state.like;
    if (!localStorage.getItem('token')) return alert('로그인이 필요합니다:)');
    try {
      const result = await axios.post(
        `${API}/${type}/${id}/like`,
        {},
        {
          headers: {
            token: `${localStorage.getItem('token')}`,
          },
        },
      );

      let rtype = '';
      if (type === 'reviews') {
        rtype = 'review';
      } else if (type === 'links') {
        rtype = 'get';
      } else if (type === 'talks') {
        rtype = 'talk';
      }

      var rlike = [...this.state.like[rtype]];

      if (result.status === 201) {
        rlike.push(id);
        this.setState({
          ...this.state,
          like: {
            ...this.state.like,
            [rtype]: rlike,
          },
        });
      } else if (result.status === 204) {
        rlike = rlike.filter((el) => Number(el) !== Number(id));
        this.setState({
          ...this.state,
          like: {
            ...this.state.like,
            [rtype]: rlike,
          },
        });
      }
    } catch (e) {
      if (e.response && e.response.data && e.response.data.msg && e.response.data.msg === '토큰이 만료되었습니다.') {
        localStorage.removeItem('token');
        window.location.href = "/";
        return;
      }
      // alert('조금만 뒤에 다시 시도해주세요:)');
    }
    // /{reviews}/{id}/like
  };

  // /{reviews}/{id}/dib
  handleDib = async (e, type, id) => {
    e.stopPropagation();
    if (!localStorage.getItem('token')) return alert('로그인이 필요합니다:)');
    try {
      const result = await axios.post(
        `${API}/${type}/${id}/dib`,
        {},
        {
          headers: {
            token: `${localStorage.getItem('token')}`,
          },
        },
      );

      let rtype = '';
      if (type === 'reviews') {
        rtype = 'review';
      } else if (type === 'links') {
        rtype = 'get';
      } else if (type === 'talks') {
        rtype = 'talk';
      }

      var rdib = [...this.state.dib[rtype]];

      if (result.status === 201) {
        rdib.push(id);
        this.setState({
          ...this.state,
          dib: {
            ...this.state.dib,
            [rtype]: rdib,
          },
        });
      } else if (result.status === 204) {
        rdib = rdib.filter((el) => Number(el) !== Number(id));
        this.setState({
          ...this.state,
          dib: {
            ...this.state.dib,
            [rtype]: rdib,
          },
        });
      }
    } catch (e) {
      if (e.response && e.response.data && e.response.data.msg && e.response.data.msg === '토큰이 만료되었습니다.') {
        localStorage.removeItem('token');
        window.location.href = "/";
        return;
      }
    }
  };

  getCurrency = async () => {
    try {
      let currency = {};
      const result = await axios.get(`${API}/extra/exchange-rate`);
      result.data.map((el) => {
        currency[el.currency] = el.amount;
      });
      this.setState({
        ...this.state,
        currency,
      });
    } catch (e) {
      if (e.response && e.response.data && e.response.data.msg && e.response.data.msg === '토큰이 만료되었습니다.') {
        localStorage.removeItem('token');
        window.location.href = "/";
        return;
      }
    }
  };

  handleBack = () => {
    this.setState(
      {
        ...this.state,
        isMore: {
          talks: false,
          links: false,
          reviews: false,
        },
        isLoading: {
          talk: false,
          get: false,
          review: false,
        },
        isFirst: {
          talk: true,
          get: true,
          review: true,
        },
        links: [],
        reviews: [],
        talks: [],
        total: {
          talk: 0,
          get: 0,
          review: 0,
        },
        offset: {
          talk: 0,
          get: 0,
          review: 0,
        },
        limit: {
          talk: 20,
          get: 20,
          review: 20,
        },
        isMore: {
          talks: false,
          links: false,
          reviews: false,
        },
      },
      () => {
        this.handleSearchReviews();
        this.handleSearchLinks();
        this.handleSearchTalks();
      },
    );
  };

  handleMore = (type) => {
    this.setState({
      ...this.state,
      type,
      isMore: {
        ...this.state.isMore,
        [type]: true,
      },
    });
  };

  hanldeSearch = () => {
    const { word } = this.state;
    if (!word) return;
  };

  // brand
  // store

  handleSearchReviews = async () => {
    const { word, searchType, isFirst } = this.state;
    if (!word || !searchType) return;
    try {
      let params = {};
      switch (searchType) {
        case 'word':
          params = {
            limit: this.state.limit['review'],
            offset: this.state.offset['review'],
            title: word,
            content: word,
          };
          break;
        case 'brand':
          params = {
            limit: this.state.limit['review'],
            offset: this.state.offset['review'],
            brand: word,
          };
          break;
        case 'store':
          params = {
            limit: this.state.limit['review'],
            offset: this.state.offset['review'],
            store: word,
          };
          break;
        case 'styletag':
          params = {
            limit: this.state.limit['review'],
            offset: this.state.offset['review'],
            styletag: word,
          };
          break;
      }
      const result = await axios.get(`${API}/reviews/`, {
        params,
        headers: {
          token: `${localStorage.getItem('token')}`,
        },
      });
      if (result && result.status === 200) {
        var like = {
          review: [],
        };

        var dib = {
          review: [],
        };

        result.data[0].map((el) => {
          if (el.liked) {
            like['review'].push(el.id);
          }

          if (el.dib) {
            dib['review'].push(el.id);
          }
        });

        if (isFirst.review) {
          this.setState({
            ...this.state,
            isFirst: {
              ...this.state.isFirst,
              review: false,
            },
            isLoading: {
              ...this.state.isLoading,
              review: false,
            },
            total: {
              ...this.state.total,
              review: result.data[1],
            },
            reviews: result.data[0],
            like: {
              ...this.state.like,
              review: like.review,
            },
            dib: {
              ...this.state.dib,
              review: dib.review,
            },
          });
        } else {
          this.setState({
            ...this.state,
            isFirst: {
              ...this.state.isFirst,
              review: false,
            },
            isLoading: {
              ...this.state.isLoading,
              review: false,
            },
            total: {
              ...this.state.total,
              review: result.data[1],
            },
            reviews: [...this.state.reviews, ...result.data[0]],
            like: {
              ...this.state.like,
              review: like.review,
            },
            dib: {
              ...this.state.dib,
              review: dib.review,
            },
          });
        }
      }
    } catch (e) {
      if (e.response && e.response.data && e.response.data.msg && e.response.data.msg === '토큰이 만료되었습니다.') {
        localStorage.removeItem('token');
        window.location.href = "/";
        return;
      }
    }
  };

  handleSearchLinks = async () => {
    const { word, searchType, isFirst } = this.state;
    if (!word || !searchType) return;
    try {
      let params = {};
      switch (searchType) {
        case 'word':
          params = {
            limit: this.state.limit['get'],
            offset: this.state.offset['get'],
            title: word,
            content: word,
          };
          break;
        case 'brand':
          params = {
            limit: this.state.limit['get'],
            offset: this.state.offset['get'],
            brand: word,
          };
          break;
        case 'store':
          params = {
            limit: this.state.limit['get'],
            offset: this.state.offset['get'],
            store: word,
          };
          break;
        case 'styletag':
          params = {
            limit: this.state.limit['get'],
            offset: this.state.offset['get'],
            styletag: word,
          };
          break;
      }

      const result = await axios.get(`${API}/links/`, {
        params, headers: {
          token: `${localStorage.getItem('token')}`,
        },
      });
      if (result && result.status === 200) {
        var like = {
          get: [],
        };

        var dib = {
          get: [],
        };

        result.data[0].map((el) => {
          if (el.liked) {
            like['get'].push(el.id);
          }

          if (el.dib) {
            dib['get'].push(el.id);
          }
        });

        if (isFirst.get) {
          this.setState({
            ...this.state,
            isFirst: {
              ...this.state.isFirst,
              get: false,
            },
            isLoading: {
              ...this.state.isLoading,
              get: false,
            },
            total: {
              ...this.state.total,
              get: result.data[1],
            },
            links: result.data[0],
            like: {
              ...this.state.like,
              get: like.get,
            },
            dib: {
              ...this.state.dib,
              get: dib.get,
            },
          });
        } else {
          this.setState({
            ...this.state,
            isFirst: {
              ...this.state.isFirst,
              get: false,
            },
            isLoading: {
              ...this.state.isLoading,
              get: false,
            },
            total: {
              ...this.state.total,
              get: result.data[1],
            },
            links: [...this.state.links, ...result.data[0]],
            like: {
              ...this.state.like,
              get: like.get,
            },
            dib: {
              ...this.state.dib,
              get: dib.get,
            },
          });
        }
      }
    } catch (e) {
      if (e.response && e.response.data && e.response.data.msg && e.response.data.msg === '토큰이 만료되었습니다.') {
        localStorage.removeItem('token');
        window.location.href = "/";
        return;
      }
    }
  };

  handleSearchTalks = async () => {
    const { word, searchType, isFirst } = this.state;
    if (!word || !searchType) return;
    try {
      let params = {};
      switch (searchType) {
        case 'word':
          params = {
            limit: this.state.limit['talk'],
            offset: this.state.offset['talk'],
            title: word,
            content: word,
          };
          break;
        case 'brand':
          params = {
            limit: this.state.limit['talk'],
            offset: this.state.offset['talk'],
            brand: word,
          };
          break;
        case 'store':
          params = {
            limit: this.state.limit['talk'],
            offset: this.state.offset['talk'],
            store: word,
          };
          break;
        case 'styletag':
          params = {
            limit: this.state.limit['talk'],
            offset: this.state.offset['talk'],
            styletag: word,
          };
          break;
      }

      const result = await axios.get(`${API}/talks/`, {
        params, headers: {
          token: `${localStorage.getItem('token')}`,
        },
      });
      if (result && result.status === 200) {
        var like = {
          talk: [],
        };

        var dib = {
          talk: [],
        };

        result.data[0].map((el) => {
          if (el.liked) {
            like['talk'].push(el.id);
          }

          if (el.dib) {
            dib['talk'].push(el.id);
          }
        });

        if (isFirst.talk) {
          this.setState({
            ...this.state,
            isFirst: {
              ...this.state.isFirst,
              talk: false,
            },
            isLoading: {
              ...this.state.isLoading,
              talk: false,
            },
            total: {
              ...this.state.total,
              talk: result.data[1],
            },
            talks: result.data[0],
            like: {
              ...this.state.like,
              talk: like.talk,
            },
            dib: {
              ...this.state.dib,
              talk: dib.talk,
            },
          });
        } else {
          this.setState({
            ...this.state,
            isFirst: {
              ...this.state.isFirst,
              talk: false,
            },
            isLoading: {
              ...this.state.isLoading,
              talk: false,
            },
            total: {
              ...this.state.total,
              talk: result.data[1],
            },
            talks: [...this.state.talks, ...result.data[0]],
            like: {
              ...this.state.like,
              talk: like.talk,
            },
            dib: {
              ...this.state.dib,
              talk: dib.talk,
            },
          });
        }
      }
    } catch (e) {
      if (e.response && e.response.data && e.response.data.msg && e.response.data.msg === '토큰이 만료되었습니다.') {
        localStorage.removeItem('token');
        window.location.href = "/";
        return;
      }
    }
  };

  showBest = (kind, data) => {
    let components = [];
    const { currency, like, dib, user } = this.state;

    for (let i = 0; i <= 2; i++) {
      if (kind === 'reviews') {
        if (data[i]) {
          components.push(
            data[i] && (
              <ReviewItem
                data={data[i]}
                key={i}
                currency={currency}
                like={like.review}
                dib={dib.review}
                handleLike={this.handleLike}
                handleDib={this.handleDib}
                handlePopup={this.handlePopup}
                user={user}
              />
            ),
          );
        }
      } else if (kind === 'links') {
        if (data[i]) {
          components.push(
            <GetItem
              data={data[i]}
              key={i}
              currency={currency}
              like={like.get}
              dib={dib.get}
              handlePopup={this.handlePopup}
              handleLike={this.handleLike}
              handleDib={this.handleDib}
              user={user}
            />,
          );
        }
      } else if (kind === 'talks') {
        if (data[i]) {
          let type =
            data[i].type === '자유토크'
              ? 'free'
              : data[i].type === '사요마요'
                ? 'recommend'
                : data[i].type === '정품판독'
                  ? 'genuine'
                  : 'search';
          components.push(
            <TalkItem
              data={data[i]}
              key={i}
              type={type}
              currency={currency}
              like={like.talk}
              dib={dib.talk}
              handlePopup={this.handlePopup}
              handleLike={this.handleLike}
              user={user}
            />,
          );
        }
      }
    }

    return components;
  };

  handlePopup = (id, type) => {
    const { links, reviews, talks } = this.state;
    let data;
    if (type === 'review') {
      data = reviews.filter((el) => el.id === id)[0] || {};
    } else if (type === 'get') {
      data = links.filter((el) => el.id === id)[0] || {};
    } else if (type === 'talk') {
      data = talks.filter((el) => el.id === id)[0] || {};
    }
    // events: [],
    // links: [],
    // reviews: [],
    // store: [],
    // talks: [],
    this.setState({
      ...this.state,
      popupOpen: {
        ...this.state.popupOpen,
        [type]: true,
      },
      popupData: data,
    });
    window.history.pushState(
      null,
      null,
      `/${type}?id=${data.id}`,
    );
  };

  closePopup = () => {
    this.setState({
      ...this.state,
      popupOpen: {
        ...this.state.popupOpen,
        review: false,
        get: false,
        talk: false,
      },
      popupData: {}
    });
    window.history.pushState(
      null,
      null,
      `/`,
    );
  };

  editReview = async (id) => {
    window.location.href = `/writing/review/?id=${id}`;
    // this.props.history.push(`/writing/review/?id=${id}`);
  };

  deleteReview = async (id, my) => {
    if (window.confirm('정말 삭제하시겠습니까?')) {
      try {
        const result = await axios.delete(`${API}/reviews/${id}`, {
          headers: {
            token: `${localStorage.getItem('token')}`,
          },
        });
        if (result.status === 200) {
          if (my) {
            this.setState({
              ...this.state,
              popupOpen: {
                review: false,
                get: false,
                talk: false,
              },
              popupData: {},
              reviews: this.state.reviews.filter((el) => el.id !== id),
            });
          } else {
            this.setState({
              ...this.state,
              popupOpen: {
                review: false,
                get: false,
                talk: false,
              },
              popupData: {},
              reviews: this.state.reviews.filter((el) => el.id !== id),
            });
          }

          alert('삭제 성공 :)');
        }
      } catch (e) {
        if (e.response && e.response.data && e.response.data.msg && e.response.data.msg === '토큰이 만료되었습니다.') {
          localStorage.removeItem('token');
          window.location.href = "/";
          return;
        }
        // alert('조금만 뒤에 다시 시도해주세요:)');
      }
    }
  };

  editLink = async (id) => {
    window.location.href = `/writing/get/?id=${id}`;
    // this.props.history.push(`/writing/get/?id=${id}`);
  };

  deleteLink = async (id, my) => {
    if (window.confirm('정말 삭제하시겠습니까?')) {
      try {
        const result = await axios.delete(`${API}/links/${id}`, {
          headers: {
            token: `${localStorage.getItem('token')}`,
          },
        });
        if (result.status === 200) {
          if (my) {
            this.setState({
              ...this.state,
              popupOpen: {
                review: false,
                get: false,
                talk: false,
              },
              popupData: {},
              links: this.state.links.filter((el) => el.id !== id),
            });
          } else {
            this.setState({
              ...this.state,
              popupOpen: {
                review: false,
                get: false,
                talk: false,
              },
              popupData: {},
              links: this.state.links.filter((el) => el.id !== id),
            });
          }

          alert('삭제 성공 :)');
        }
      } catch (e) {
        if (e.response && e.response.data && e.response.data.msg && e.response.data.msg === '토큰이 만료되었습니다.') {
          localStorage.removeItem('token');
          window.location.href = "/";
          return;
        }
        // alert('조금만 뒤에 다시 시도해주세요:)');
      }
    }
  };

  getTalk = async (id) => {
    try {
      const result = await axios.get(`${API}/talks/${id}`, {
        headers: {
          token: `${localStorage.getItem('token')}`,
        },
      });
      if (result.data.liked) {
        like['talk'].push(result.data.id);
      }

      this.setState({
        ...this.state,
        popupOpen: {
          review: false,
          get: false,
          talk: true,
          event: false,
        },
        popupData: result.data,
        like,
      });
    } catch (e) {
      if (e.response && e.response.data && e.response.data.msg && e.response.data.msg.indexOf('토큰이 만료되었습니다') > -1) {
        localStorage.removeItem('token');
        window.location.reload();
      }
    }
  };

  editTalk = async (id) => {
    window.location.href = `/writing/talk/?id=${id}`;
    // this.props.history.push(`/writing/talk/?id=${id}`);
  };

  deleteTalk = async (id) => {
    if (window.confirm('정말 삭제하시겠습니까?')) {
      try {
        const result = await axios.delete(`${API}/talks/${id}`, {
          headers: {
            token: `${localStorage.getItem('token')}`,
          },
        });
        if (result.status === 200) {
          this.setState({
            ...this.state,
            popupOpen: {
              review: false,
              get: false,
              talk: false,
            },
            popupData: {},
            talks: this.state.talks.filter((el) => el.id !== id),
          });

          alert('삭제 성공 :)');
        }
      } catch (e) {
        if (e.response && e.response.data && e.response.data.msg && e.response.data.msg === '토큰이 만료되었습니다.') {
          localStorage.removeItem('token');
          window.location.href = "/";
          return;
        }
        // alert('조금만 뒤에 다시 시도해주세요:)');
      }
    }
  };

  render() {
    const { handleLike, handleDib, handleMore, handleBack, showBest, handlePopup, closePopup, editReview,
      deleteReview,
      editLink,
      deleteLink,
      editTalk,
      deleteTalk,
      getTalk, getUserData } = this;
    const { reviews, links, talks, currency, isMore, word, like, dib, searchType, popupOpen,
      popupData, user } = this.state;

    return (
      <>
        <div className="search-bg">
          <div className="wrapper">
            <div className="main-search-title-bg">
              <p className="main-search-title">
                {'"'}
                {word}
                {'"'}에 대한 검색 결과
            </p>
              {(isMore.reviews || isMore.links || isMore.talks) && (
                <span className="main-search-back" onClick={handleBack}></span>
              )}
            </div>
            <div className="main-intro-bg">
              <div className="main-intro-content-bg">
                <div className="main-intro-content-list">
                  {!isMore.links && !isMore.talks && (
                    <>
                      <div className="main-intro-content-bg">
                        <span className="main-intro-content-title">
                          오늘의 <strong>후기</strong>
                        </span>
                        {!isMore.reviews && reviews.length > 3 && (
                          <>
                            <span
                              className="main-intro-content-more"
                              onClick={() => handleMore('reviews')}
                            >
                              더보기
                          </span>
                            <span className="main-intro-content-more-icon"></span>
                          </>
                        )}
                      </div>
                      <div className="main-intro-content-item-bg review-item">
                        {reviews.length === 0 && (
                          <div className="search-no-data">검색결과가 없습니다.</div>
                        )}
                        {!isMore.reviews && reviews && showBest('reviews', reviews)}
                        {isMore.reviews &&
                          reviews &&
                          reviews.map((el, i) => {
                            return (
                              <ReviewItem
                                data={el}
                                key={i}
                                currency={currency}
                                like={like.review}
                                dib={dib.review}
                                handlePopup={handlePopup}
                                handleLike={handleLike}
                                handleDib={handleDib}
                                user={user}
                              />
                            );
                          })}
                      </div>
                    </>
                  )}
                  {!isMore.reviews && !isMore.talks && (
                    <>
                      <div className="main-intro-content-divide-bg">
                        <span className="main-intro-content-title">
                          오늘의 <strong>득템</strong>
                        </span>
                        {!isMore.links && links.length > 3 && (
                          <>
                            <span
                              className="main-intro-content-more"
                              onClick={() => handleMore('links')}
                            >
                              더보기
                          </span>
                            <span className="main-intro-content-more-icon"></span>
                          </>
                        )}
                      </div>
                      <div className="main-intro-content-item-bg">
                        {links.length === 0 && (
                          <div className="search-no-data">검색결과가 없습니다.</div>
                        )}
                        {!isMore.links && links && showBest('links', links)}
                        {isMore.links &&
                          links &&
                          links.map((el, i) => {
                            return (
                              <GetItem
                                data={el}
                                key={i}
                                currency={currency}
                                like={like.get}
                                dib={dib.get}
                                handlePopup={handlePopup}
                                handleLike={handleLike}
                                handleDib={handleDib}
                                user={user}
                              />
                            );
                          })}
                      </div>
                    </>
                  )}
                  {searchType === 'word' && !isMore.reviews && !isMore.links && (
                    <>
                      <div className="main-intro-content-divide-bg">
                        <span className="main-intro-content-title">
                          오늘의 <strong>토크</strong>
                        </span>
                        {!isMore.talks && talks.length > 3 && (
                          <>
                            <span
                              className="main-intro-content-more"
                              onClick={() => handleMore('talks')}
                            >
                              더보기
                          </span>
                            <span className="main-intro-content-more-icon"></span>
                          </>
                        )}
                      </div>
                      <div className="main-intro-content-item-bg">
                        {talks.length === 0 && (
                          <div className="search-no-data">검색결과가 없습니다.</div>
                        )}
                        {!isMore.talks && talks && showBest('talks', talks)}
                        {isMore.talks &&
                          talks &&
                          talks.map((el, i) => {
                            let type =
                              el.type === '자유토크'
                                ? 'free'
                                : el.type === '사요마요'
                                  ? 'recommend'
                                  : el.type === '정품판독'
                                    ? 'genuine'
                                    : 'search';

                            return (
                              <TalkItem
                                data={el}
                                key={i}
                                type={type}
                                currency={currency}
                                like={like.talk}
                                dib={dib.talk}
                                handlePopup={handlePopup}
                                handleLike={handleLike}
                                user={user}
                              />
                            );
                          })}
                      </div>
                    </>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
        {popupOpen.review && popupData && popupData.user && popupData.user.id && (
          <ReviewPopup
            isOpen={popupOpen.review}
            popupData={popupData}
            currency={currency}
            closePopup={closePopup}
            user={user}
            handleLike={handleLike}
            handleDib={handleDib}
            like={like.review}
            dib={dib.review}
            deleteReview={deleteReview}
            editReview={editReview}
            getUserData={getUserData}
          />
        )}
        {popupOpen.get && popupData && popupData.user && popupData.user.id && (
          <GetPopup
            isOpen={popupOpen.get}
            popupData={popupData}
            currency={currency}
            closePopup={closePopup}
            user={user}
            handleLike={handleLike}
            handleDib={handleDib}
            like={like.get}
            dib={dib.get}
            deleteLink={deleteLink}
            editLink={editLink}
          />
        )}
        {popupOpen.talk && popupData && popupData.user && popupData.user.id && (
          <>
            {
              <TalkPopup
                type={popupData.category === '자유토크'
                  ? 'free'
                  : popupData.category === '사요마요'
                    ? 'recommend'
                    : popupData.category === '정품판독'
                      ? 'genuine'
                      : 'search'}
                popupData={popupData}
                user={user}
                isOpen={popupOpen.talk}
                closePopup={closePopup}
                handleLike={handleLike}
                like={like.talk}
                deleteTalk={deleteTalk}
                editTalk={editTalk}
                getTalk={getTalk}
              />
            }
          </>
        )}
      </>
    );
  }
}

export default withRouter(Search);
