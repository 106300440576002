import React, { Component } from 'react';
import axios from 'axios';
import { API } from 'config';
import './Privacy.css';

class Privacy extends Component {
  state = {
    data: '',
  };

  componentDidMount() {
    this.getPrivacy();
  }

  getPrivacy = async () => {
    try {
      const result = await axios.get(`${API}/extra/personal-info-rule`);
      this.setState({
        ...this.state,
        data: result.data.data,
      });
    } catch (e) {
      if (e.response && e.response.data && e.response.data.msg && e.response.data.msg === '토큰이 만료되었습니다.') {
        localStorage.removeItem('token');
        window.location.href = "/";
        return;
      }
    }
  };

  render() {
    const { data } = this.state;
    return (
      <div className="privacy-bg">
        <div className="wrapper">
          <h2 className="privacy-title">개인정보처리방침</h2>
          <pre className="privacy-desc">{data}</pre>
        </div>
      </div>
    );
  }
}

export default Privacy;
