import React, { Component } from 'react';
import GetWriting from 'components/GetWriting';

class GetWritingContainer extends Component {
  render() {
    return <GetWriting />;
  }
}

export default GetWritingContainer;
